import { debounce } from 'lodash';
import React, { useRef, useImperativeHandle, useCallback } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

const CustomScrollbars = (props, ref) => {
  const { onScrollEnd, loading, autoHide = true, ...rest } = props;
  const scrollbar = useRef(null);

  const handleScrollEnd = () => {
    if (onScrollEnd) {
      onScrollEnd();
    }
  };

  const debouncingScrollEnd = useCallback(debounce(handleScrollEnd, 200), [
    onScrollEnd
  ]);

  const handleScroll = (event) => {
    const { target } = event;
    if (
      parseFloat(target.scrollTop + target.offsetHeight).toFixed(0) >=
      parseFloat(target.scrollHeight).toFixed(0) - 10
    ) {
      if (onScrollEnd && !loading) {
        debouncingScrollEnd();
      }
    }
  };

  const resetScroll = () => {
    scrollbar.current.scrollTop(0);
  };

  useImperativeHandle(ref, () => {
    return {
      resetScroll
    };
  });

  return (
    <Scrollbars
      {...rest}
      ref={scrollbar}
      autoHide={autoHide}
      onScroll={handleScroll}
      renderView={(renderViewProps) => (
        <div
          {...renderViewProps}
          style={{
            ...renderViewProps.style,
            overflowX: 'hidden',
            marginBottom: '0px'
          }}
        />
      )}
      renderTrackHorizontal={(renderProps) => (
        <div
          {...renderProps}
          style={{ display: 'none' }}
          className="track-horizontal"
        />
      )}
    />
  );
};

export default React.forwardRef(CustomScrollbars);
