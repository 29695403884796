import React from 'react';
import { useSelector } from 'react-redux';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Auxiliary from 'components/Auxiliary';
import { buildMatchPath } from 'react-structure-admin';
import CustomScrollbars from 'components/CustomScrollbars';
import useSubscription from 'core/useSubscription';
import SidebarLogo from './SidebarLogo';

import { THEME_TYPE_LITE } from '../../constants/themeSettings';
import { NotificationOutlined } from '@ant-design/icons';

const SidebarContent = ({ routes = [], match }) => {
  const { themeType, pathname } = useSelector((c) => c.settings ?? {});
  const { hasFeature } = useSubscription();

  const userHasFeature = (features = []) => {
    return features?.length == 0 || features.some((c) => hasFeature(c));
  };

  const allowRenderRoute = (route) => {
    if (userHasFeature(route.features)) {
      return !!route.menu;
    }

    return false;
  };

  let selectedKeys = pathname;
  if (selectedKeys === '') {
    selectedKeys = 'index';
  } else if (selectedKeys === '/p') {
    selectedKeys = '/p/';
  }

  return (
    <Auxiliary>
      <SidebarLogo />

      <div className="gx-sidebar-content">
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultSelectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
            mode="inline"
          >
            {routes &&
              routes.filter(allowRenderRoute).map(({ menu, ...route }) => (
                <Menu.Item
                  key={buildMatchPath(match, route.path)}
                  icon={
                    <>
                      {menu && menu.icon && (
                        <FontAwesomeIcon className="icon" icon={menu.icon} />
                      )}
                      {menu && menu.ComponentIcon && <menu.ComponentIcon />}
                    </>
                  }
                >
                  <Link to={`${buildMatchPath(match, route.path)}`}>
                    <span className="title">{route.title}</span>
                  </Link>
                  {menu.isNew && <span className="tag">NOVO</span>}
                </Menu.Item>
              ))}
          </Menu>
        </CustomScrollbars>
      </div>
    </Auxiliary>
  );
};
export default SidebarContent;
