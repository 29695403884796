/* eslint-disable react/prefer-es6-class */
/* eslint-disable react/prefer-stateless-function */

import React from 'react';
import createReactClass from 'create-react-class';
import { useSelector } from 'react-redux';

const StatefullInternalView = (props) => {
  const { children, resource, currentAttr } = props;

  const resourceState = useSelector((state) => {
    return state.resources[resource] ? state.resources[resource] : {};
  });

  const state = currentAttr ? resourceState[currentAttr] : resourceState;

  return React.cloneElement(children, {
    ...props,
    ...state
  });
};

class StatefullComponent {
  static create(WrappedComponent, { resource, currentAttr }) {
    return createReactClass({
      render() {
        return (
          <StatefullInternalView
            {...this.props}
            resource={resource}
            currentAttr={currentAttr}
          >
            <WrappedComponent />
          </StatefullInternalView>
        );
      }
    });
  }
}

export default StatefullComponent;
