import React from 'react';
import { Tag, Tooltip } from 'antd';
import { SERVICEINVOICE, display } from 'constants/types';
import { InfoCircleOutlined } from '@ant-design/icons';

const InvoiceModelTag = ({ model, isDevolution = false }) => {
  const color = model === SERVICEINVOICE ? 'cyan' : 'orange';
  return (
    <Tag color={color}>
      {display[model]}
      {isDevolution && (
        <Tooltip title="Nota fiscal de devolução">
          <InfoCircleOutlined className="gx-ml-2" />
        </Tooltip>
      )}
    </Tag>
  );
};

export default InvoiceModelTag;
