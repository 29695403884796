import React from 'react';
import { Button, Row, Col } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Icon = ({ checked }) => {
  return (
    <FontAwesomeIcon
      icon="redo-alt"
      className="gx-mr-2"
      color={checked ? '#40c057' : '#495057'}
    />
  );
};

const CustomToolbar = ({
  readOnly,
  backButtonText,
  onBackClick,
  okButtonText,
  onOkClick,
  saving,
  leftComponent = null
}) => {
  return (
    <Row
      className="no-margin no-padding"
      align="bottom"
      justify="space-between"
    >
      <Col>{leftComponent}</Col>
      <Col>
        {readOnly ? (
          <Button onClick={onBackClick} style={{ marginRight: 8 }}>
            {backButtonText}
          </Button>
        ) : (
          <>
            <Button onClick={onBackClick} style={{ marginRight: 8 }}>
              {backButtonText}
            </Button>
            <Button onClick={onOkClick} type="primary" loading={saving}>
              {okButtonText}
            </Button>
          </>
        )}
      </Col>
    </Row>
  );
};

export default CustomToolbar;
