import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useCompany from 'core/useCompany';
import { formatFederalTaxNumber } from 'util/formatters';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { changeCompany } from 'stores/auth/authActions';
import RemoteSelectPopover from './RemoteSelectPopover';

const CompanyChooserPopover = () => {
  const dispatch = useDispatch();
  const { company, isMultiCompany, enabled } = useCompany();
  const { width } = useSelector((state) => state.settings);

  if (!company) {
    return null;
  }

  const HandleSelect = (newCompany) => {
    if (newCompany.id === company.id) {
      return;
    }

    dispatch(changeCompany(newCompany.id));
  };

  const handleAllowHide = (newCompany) => {
    if (newCompany.id !== company.id) {
      return true;
    }

    return false;
  };

  return isMultiCompany ? (
    <RemoteSelectPopover
      resource="companies"
      disabled={!isMultiCompany}
      renderItem={(item) => (
        <div className={`gx-p-2 ${item.id === company.id ? 'selected' : ''}`}>
          <div
            className={`gx-fs-md gx-font-weight-semi-bold ${
              item.enabled ? '' : 'gx-text-grey'
            }`}
          >
            {item.legalName}
          </div>
          <div className="gx-text-grey gx-fs-sm">
            {formatFederalTaxNumber(item.federalTaxNumber)}
          </div>
        </div>
      )}
      allowHide={handleAllowHide}
      onSelect={HandleSelect}
    >
      <div
        className={`gx-companychooserpopover-button gx-flex-row gx-align-items-center ${
          isMultiCompany ? 'gx-pointer' : ''
        }`}
      >
        <div>
          <div
            className={`gx-fs-md gx-font-weight-semi-bold gx-text-truncate ${
              enabled ? '' : 'gx-text-grey'
            }`}
          >
            {company.legalName}
          </div>
          <div className="gx-text-grey gx-fs-sm gx-text-truncate">
            {formatFederalTaxNumber(company.federalTaxNumber)}
          </div>
        </div>
        {isMultiCompany ? (
          <FontAwesomeIcon
            className="icon gx-ml-2 gx-text-grey"
            icon="angle-down"
          />
        ) : null}
      </div>
    </RemoteSelectPopover>
  ) : width < 800 ? (
    <div className="gx-companychooserpopover-button gx-flex-row gx-align-items-center">
      <div>
        <div className="gx-fs-md gx-font-weight-semi-bold gx-text-truncate">
          {company.legalName}
        </div>
        <div className="gx-text-grey gx-fs-sm gx-text-truncate">
          {formatFederalTaxNumber(company.federalTaxNumber)}
        </div>
      </div>
    </div>
  ) : (
    <div className="gx-p-0">
      <div className="gx-fs-md gx-font-weight-semi-bold">
        {company.legalName}
      </div>
      <div className="gx-text-grey gx-fs-sm">
        {formatFederalTaxNumber(company.federalTaxNumber)}
      </div>
    </div>
  );
};

export default CompanyChooserPopover;
