import React, { useState } from 'react';
import { Input } from 'antd';
import { EditOutlined } from '@ant-design/icons';

const InputHide = ({ style, className = '', value, onChange, maxLength }) => {
  const [editing, setEditing] = useState(false);

  const handleEndOfEdit = () => {
    setEditing(false);
  };

  const handleEdit = () => {
    setEditing(true);
  };

  return editing ? (
    <Input
      autoFocus
      className={`gx-inputhide-input ${className}`}
      value={value}
      onChange={({ target }) => onChange(target.value)}
      style={style}
      maxLength={maxLength}
      onBlur={handleEndOfEdit}
      onPressEnter={handleEndOfEdit}
    />
  ) : (
    <span
      className={`gx-inputhide-label ${className}`}
      onClick={handleEdit}
      role="button"
      tabIndex={0}
      onKeyPress={() => {}}
    >
      <span>{value}</span>
      <EditOutlined className="gx-ml-2" />
    </span>
  );
};

export default InputHide;
