const findDuplicates = (arr) => {
  const sortedArr = arr.slice().sort(); // You can define the comparing function here.
  const results = [];

  for (let i = 0; i < sortedArr.length - 1; i += 1) {
    if (sortedArr[i + 1] === sortedArr[i]) {
      results.push(sortedArr[i]);
    }
  }

  return results;
};

export default findDuplicates;
