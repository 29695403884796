class ObjectHelper {
  static isNullOrUndefined(obj) {
    return obj === null || obj === undefined;
  }

  static isEmpty(obj) {
    if (this.isNullOrUndefined(obj)) {
      return true;
    }

    return Object.keys(obj).length === 0;
  }

  static hasProperty(obj) {
    if (this.isNullOrUndefined(obj)) {
      return false;
    }

    return Object.keys(obj).length > 0;
  }

  static hasSpecifyProperty(obj, propertyName) {
    if (!this.hasProperty(obj)) {
      return false;
    }

    return Object.keys(obj).some((c) => c === propertyName);
  }
}

export default ObjectHelper;
