import React, { Component } from 'react';
import { Form } from 'antd';
import { debounce, values } from 'lodash';
import FormInstance from 'core/FormInstance';

class Filter extends Component {
  constructor(props) {
    super(props);
    this.onChangeDebounce = debounce((value) => props.onChange(value), 800);
  }

  handleSubmit = (allFields, allValues) => {
    const {
      onChange,
      onBeforeSubmit,
      debounceFields = ['filterText']
    } = this.props;

    let values = allValues;

    if (onBeforeSubmit) {
      values = onBeforeSubmit(values);
    }

    if (debounceFields.some((c) => allFields.hasOwnProperty(c))) {
      this.onChangeDebounce(values);
    } else if (onChange) {
      onChange(values);
    }
  };

  componentDidMount = () => {
    const { form, queryParams } = this.props;
    const initialValues = this.handleBeforeBinding(queryParams || {});
    form.setFieldsValue(initialValues);
  };

  clearFilters = () => {
    const { form } = this.props;
    var newValues = this.handleBeforeBinding({});
    form.resetFields();
    form.setFieldsValue(newValues);
    this.handleSubmit({}, newValues);
  };

  handleSetValues = (queryParams) => {
    const { form } = this.props;

    if (form) {
      form.setFieldsValue({ ...queryParams });
    }
  };

  handleBeforeBinding = (values) => {
    const { onBeforeBinding } = this.props;

    if (onBeforeBinding) {
      return onBeforeBinding(values);
    }

    return values;
  };

  render() {
    const { children, form, onChange } = this.props;

    return (
      <Form
        form={form}
        layout="inline"
        className="gx-filter-form"
        onValuesChange={this.handleSubmit}        
      >
        {React.cloneElement(children, {
          setValues: this.handleSetValues,
          clearFilters: this.clearFilters,
          form,
          setQueryParams: onChange
        })}
      </Form>
    );
  }
}

export default FormInstance.create(Filter);
