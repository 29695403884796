import { dispatcher, api, RESOURCE_FETCH } from 'react-structure-admin';
import getEndpointByInvoiceModel from 'util/getEndpointByInvoiceModel';

export const ORDER = 'ORDER';
export const ORDER_ITEMS_SELECTED = 'ORDER_ITEMS_SELECTED';

export const ORDER_VIEW_GET = 'ORDER_VIEW_GET';
export const ORDER_VIEW_GET_FINISHED = 'ORDER_VIEW_GET_FINISHED';

export const ORDER_CANCEL_BATCH = 'ORDER_CANCEL_BATCH';
export const ORDER_CANCEL_BATCH_FINISHED = 'ORDER_CANCEL_BATCH_FINISHED';

export const ORDER_ISSUE_BATCH_INVOICE = 'ORDER_ISSUE_BATCH_INVOICE';
export const ORDER_ISSUE_BATCH_INVOICE_FINISHED =
  'ORDER_ISSUE_BATCH_INVOICE_FINISHED';
export const ORDER_ISSUE_BATCH_INVOICE_FAIL = 'ORDER_ISSUE_BATCH_INVOICE_FAIL';

export const ORDER_ISSUE_INVOICE = 'ORDER_ISSUE_INVOICE';
export const ORDER_CLOSE_ISSUE_INVOICE = 'ORDER_CLOSE_ISSUE_INVOICE';

export const ORDER_REFRESH = 'ORDER_REFRESH';

export const ORDER_EXPORT_CSV = 'ORDER_EXPORT_CSV';
export const ORDER_EXPORT_CSV_FINISHED = 'ORDER_EXPORT_CSV_FINISHED';

export const ORDER_UPDATE_RECURRING = 'ORDER_UPDATE_RECURRING';
export const ORDER_UPDATE_RECURRING_FINISHED =
  'ORDER_UPDATE_RECURRING_FINISHED';

export const ORDER_OPEN_RECURRING = 'ORDER_OPEN_RECURRING';
export const ORDER_CLOSE_RECURRING = 'ORDER_CLOSE_RECURRING';

export const ORDER_OPEN_IMPORT_CSV = 'ORDER_OPEN_IMPORT_CSV';
export const ORDER_CLOSE_IMPORT_CSV = 'ORDER_CLOSE_IMPORT_CSV';

export const ORDER_IMPORT_CSV = 'ORDER_IMPORT_CSV';
export const ORDER_IMPORT_CSV_FINISHED = 'ORDER_IMPORT_CSV_FINISHED';

export const ORDER_OPEN_REMOVE_PENDING_INVOICES =
  'ORDER_OPEN_REMOVE_PENDING_INVOICES';
export const ORDER_CLOSE_REMOVE_PENDING_INVOICES =
  'ORDER_CLOSE_REMOVE_PENDING_INVOICES';

export const ORDER_REMOVE_PENDING_INVOICES = 'ORDER_REMOVE_PENDING_INVOICES';
export const ORDER_REMOVE_PENDING_INVOICES_FINISHED =
  'ORDER_REMOVE_PENDING_INVOICES_FINISHED';

export const ORDER_REQUEST_STATUS_CHECK = 'ORDER_REQUEST_STATUS_CHECK';
export const ORDER_REQUEST_STATUS_CHECK_FINISHED =
  'ORDER_REQUEST_STATUS_CHECK_FINISHED';

export const ORDER_ENQUEUE_ISSUE_AFFILIATE_ORDERS =
  'ORDER_ENQUEUE_ISSUE_AFFILIATE_ORDERS';
export const ORDER_ENQUEUE_ISSUE_AFFILIATE_ORDERS_FINISHED =
  'ORDER_ENQUEUE_ISSUE_AFFILIATE_ORDERS_FINISHED';

export const ORDER_OPEN_UPDATE_COST = 'ORDER_OPEN_UPDATE_COST';
export const ORDER_CLOSE_UPDATE_COST = 'ORDER_CLOSE_UPDATE_COST';

export const ORDER_UPDATE_COST = 'ORDER_UPDATE_COST';
export const ORDER_UPDATE_COST_FINISHED = 'ORDER_UPDATE_COST_FINISHED';

export const ORDER_UNLINK_INVOICE = 'ORDER_UNLINK_INVOICE';
export const ORDER_UNLINK_INVOICE_FINISHED = 'ORDER_UNLINK_INVOICE_FINISHED';

export const ORDER_OPEN_CHANGE_ISSUE_INVOICE_MODE =
  'ORDER_OPEN_CHANGE_ISSUE_INVOICE_MODE';
export const ORDER_CLOSE_CHANGE_ISSUE_INVOICE_MODE =
  'ORDER_CLOSE_CHANGE_ISSUE_INVOICE_MODE';

export const ORDER_CHANGE_ISSUE_INVOICE_MODE =
  'ORDER_CHANGE_ISSUE_INVOICE_MODE';
export const ORDER_CHANGE_ISSUE_INVOICE_MODE_FINISHED =
  'ORDER_CHANGE_ISSUE_INVOICE_MODE_FINISHED';

export const ORDER_OPEN_CANCEL = 'ORDER_OPEN_CANCEL';
export const ORDER_CLOSE_CANCEL = 'ORDER_CLOSE_CANCEL';

export const ORDER_SHOW_STATUS_CHANGE = 'ORDER_SHOW_STATUS_CHANGE';
export const ORDER_CLOSE_STATUS_CHANGE = 'ORDER_CLOSE_STATUS_CHANGE';

export const ORDER_CHANGE_STATUS = 'ORDER_CHANGE_STATUS';
export const ORDER_CHANGE_STATUS_FINISHED = 'ORDER_CHANGE_STATUS_FINISHED';

export const ORDER_ENQUEUE_ISSUE_BATCH = 'ORDER_ENQUEUE_ISSUE_BATCH';
export const ORDER_ENQUEUE_ISSUE_BATCH_FINISHED =
  'ORDER_ENQUEUE_ISSUE_BATCH_FINISHED';

export const ORDER_OPEN_DISABLE_NUMERING_PRODUCT_INVOICE =
  'ORDER_OPEN_DISABLE_NUMERING_PRODUCT_INVOICE';
export const ORDER_CLOSE_DISABLE_NUMERING_PRODUCT_INVOICE =
  'ORDER_CLOSE_DISABLE_NUMERING_PRODUCT_INVOICE';

export const ORDER_DISABLE_NUMERING_PRODUCT_INVOICE =
  'ORDER_DISABLE_NUMERING_PRODUCT_INVOICE';
export const ORDER_DISABLE_NUMERING_PRODUCT_INVOICE_FINISHED =
  'ORDER_DISABLE_NUMERING_PRODUCT_INVOICE_FINISHED';

export const ORDER_REQUEST_STATUS_CHECK_BATCH =
  'ORDER_REQUEST_STATUS_CHECK_BATCH';
export const ORDER_REQUEST_STATUS_CHECK_BATCH_FINISHED =
  'ORDER_REQUEST_STATUS_CHECK_BATCH_FINISHED';

export const ORDER_OPEN_ENQUEUE_CANCELLATION =
  'ORDER_OPEN_ENQUEUE_CANCELLATION';
export const ORDER_CLOSE_ENQUEUE_CANCELLATION =
  'ORDER_CLOSE_ENQUEUE_CANCELLATION';
export const ORDER_ENQUEUE_CANCELLATION = 'ORDER_ENQUEUE_CANCELLATION';
export const ORDER_ENQUEUE_CANCELLATION_FINISHED =
  'ORDER_ENQUEUE_CANCELLATION_FINISHED';

export const selectedRows = (items = []) => (dispatch) => {
  dispatch(dispatcher.createAction(ORDER_ITEMS_SELECTED, 'orders', items));
};

export const showIssueInvoice = (params) => (dispatch) => {
  dispatch(dispatcher.createAction(ORDER_ISSUE_INVOICE, 'orders', params));
};

export const closeIssueInvoice = () => (dispatch) => {
  dispatch(dispatcher.createAction(ORDER_CLOSE_ISSUE_INVOICE, 'orders'));
};

export const showStatusChange = () => (dispatch) => {
  dispatch(dispatcher.createAction(ORDER_SHOW_STATUS_CHANGE, 'orders'));
};

export const closeStatusChange = () => (dispatch) => {
  dispatch(dispatcher.createAction(ORDER_CLOSE_STATUS_CHANGE, 'orders'));
};

export const statusChange = (data) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'orders',
    actionType: ORDER_CHANGE_STATUS,
    effect: async () => api.post('orders/change-status', data)
  });
};

export const cancelBatch = (data) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'orders',
    actionType: ORDER_CANCEL_BATCH,
    effect: async () => api.post('orders/cancel-batch', data)
  });
};

export const issueBatchInvoice = (params) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'orders',
    actionType: ORDER_ISSUE_BATCH_INVOICE,
    effect: async () => api.post('orders/issue-batch-invoice', params)
  });
};

export const getView = (id) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'orders',
    actionType: ORDER_VIEW_GET,
    effect: async () => api.get('orders', id)
  });
};

export const refresh = () => (dispatch) => {
  dispatch(dispatcher.createAction(ORDER_REFRESH, 'orders'));
};

export const createFileExport = (querySearch, onSuccess) => async (
  dispatch
) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'orders',
    actionType: ORDER_EXPORT_CSV,
    effect: async () => api.post(`orders/file-export?${querySearch}`),
    onSuccess
  });
};

export const updateRecurring = (recurring, onSuccess) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'orders',
    actionType: ORDER_UPDATE_RECURRING,
    effect: async () => api.put('orders/recurring', recurring),
    onSuccess
  });
};

export const openRecurring = () => (dispatch) => {
  dispatch(dispatcher.createAction(ORDER_OPEN_RECURRING, 'orders'));
};

export const closeRecurring = () => (dispatch) => {
  dispatch(dispatcher.createAction(ORDER_CLOSE_RECURRING, 'orders'));
};

export const postFileImport = (formData, onSuccess) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'orders',
    actionType: ORDER_IMPORT_CSV,
    effect: async () => api.post('orders/import-file', formData),
    onSuccess
  });
};

export const openImportFile = () => (dispatch) => {
  dispatch(dispatcher.createAction(ORDER_OPEN_IMPORT_CSV, 'orders'));
};

export const closeImportFile = () => (dispatch) => {
  dispatch(dispatcher.createAction(ORDER_CLOSE_IMPORT_CSV, 'orders'));
};

export const openRemovePendingInvoices = () => (dispatch) => {
  dispatch(
    dispatcher.createAction(ORDER_OPEN_REMOVE_PENDING_INVOICES, 'orders')
  );
};

export const closeRemovePendingInvoices = () => (dispatch) => {
  dispatch(
    dispatcher.createAction(ORDER_CLOSE_REMOVE_PENDING_INVOICES, 'orders')
  );
};

export const removePendingInvoices = (data) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'orders',
    actionType: ORDER_REMOVE_PENDING_INVOICES,
    effect: async () => api.post('orders/remove-pending-invoices', data)
  });
};

export const requestStatusCheck = (invoiceModel, invoiceid) => async (
  dispatch
) => {
  const endpoint = getEndpointByInvoiceModel(invoiceModel);
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'orders',
    actionType: ORDER_REQUEST_STATUS_CHECK,
    effect: async () => api.put(`${endpoint}/${invoiceid}/status`)
  });
};

export const enqueueIssueAffiliateOrders = (data, onSuccess) => async (
  dispatch
) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'orders',
    actionType: ORDER_ENQUEUE_ISSUE_AFFILIATE_ORDERS,
    effect: async () => api.post('orders/enqueue-issue-affiliate-orders', data),
    onSuccess
  });
};

export const openUpdateCost = () => (dispatch) => {
  dispatch(dispatcher.createAction(ORDER_OPEN_UPDATE_COST, 'orders'));
};

export const closeUpdateCost = () => (dispatch) => {
  dispatch(dispatcher.createAction(ORDER_CLOSE_UPDATE_COST, 'orders'));
};

export const updateCost = (data) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'orders',
    actionType: ORDER_UPDATE_COST,
    effect: async () => api.put('orders/update-cost', data)
  });
};

export const unlinkInvoice = (orderIds) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'orders',
    actionType: ORDER_UNLINK_INVOICE,
    effect: async () => api.post('orders/unlink-invoice', { ids: orderIds })
  });
};

export const openChangeIssueInvoiceMode = () => (dispatch) => {
  dispatch(
    dispatcher.createAction(ORDER_OPEN_CHANGE_ISSUE_INVOICE_MODE, 'orders')
  );
};

export const closeChangeIssueInvoiceMode = () => (dispatch) => {
  dispatch(
    dispatcher.createAction(ORDER_CLOSE_CHANGE_ISSUE_INVOICE_MODE, 'orders')
  );
};

export const changeIssueInvoiceMode = (data) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'orders',
    actionType: ORDER_CHANGE_ISSUE_INVOICE_MODE,
    effect: async () => api.post('orders/change-invoice-issue-mode', data)
  });
};

export const openCancel = (ordersIds) => (dispatch) => {
  dispatch(dispatcher.createAction(ORDER_OPEN_CANCEL, 'orders', ordersIds));
};

export const closeCancel = () => (dispatch) => {
  dispatch(dispatcher.createAction(ORDER_CLOSE_CANCEL, 'orders'));
};

export const enqueueIssueBatch = (data, onSuccess) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'orders',
    actionType: ORDER_ENQUEUE_ISSUE_BATCH,
    effect: async () => api.post('orders/enqueue-issues', data),
    onSuccess
  });
};

export const openDisableNumeringProductInvoice = (invoiceIds) => (dispatch) => {
  dispatch(
    dispatcher.createAction(
      ORDER_OPEN_DISABLE_NUMERING_PRODUCT_INVOICE,
      'orders',
      invoiceIds
    )
  );
};

export const closeDisableNumeringProductInvoice = () => (dispatch) => {
  dispatch(
    dispatcher.createAction(
      ORDER_CLOSE_DISABLE_NUMERING_PRODUCT_INVOICE,
      'orders'
    )
  );
};

export const createProductInvoiceDisablement = (data) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'orders',
    actionType: ORDER_DISABLE_NUMERING_PRODUCT_INVOICE,
    effect: async () => api.post('product-invoices/disablements', data)
  });
};

export const requestStatusCheckBatch = (data) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'orders',
    actionType: ORDER_REQUEST_STATUS_CHECK_BATCH,
    effect: async () => api.put(`invoices/check-status`, data)
  });
};

export const openEnqueueCancellation = (profileType) => (dispatch) => {
  dispatch(
    dispatcher.createAction(
      ORDER_OPEN_ENQUEUE_CANCELLATION,
      'orders',
      profileType
    )
  );
};

export const closeEnqueueCancellation = () => (dispatch) => {
  dispatch(dispatcher.createAction(ORDER_CLOSE_ENQUEUE_CANCELLATION, 'orders'));
};

export const enqueueCancellation = (data, onSuccess) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'orders',
    actionType: ORDER_ENQUEUE_CANCELLATION,
    effect: async () => api.post('orders/enqueue-cancellation', data),
    onSuccess
  });
};
