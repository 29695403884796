/* eslint-disable react/prefer-es6-class */
/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import createReactClass from 'create-react-class';
import { Form } from 'antd';

const FormInstanceCreate = ({ children, ...rest }) => {
  const [form] = Form.useForm();  
  return React.cloneElement(children, { form, ...rest });
};

class FormInstance {
  static create(WrappedComponent) {
    return createReactClass({
      render() {
        const { children, ...rest } = this.props;
        return (
          <FormInstanceCreate {...rest}>
            <WrappedComponent>{children}</WrappedComponent>
          </FormInstanceCreate>
        );
      }
    });
  }
}

export default FormInstance;
