import React from 'react';
import { Select } from 'antd';
import {
  AFTERPAYMENT,
  AFTERWARRENCY,
  DISABLED,
  display,
  IMMEDIATELY
} from 'constants/types';

const OrderSelectAutoIssueMode = ({ hideImmediately, ...rest }) => {
  return (
    <Select {...rest}>
      <Option value={DISABLED}>{display[DISABLED]}</Option>
      <Option value={AFTERWARRENCY}>{display[AFTERWARRENCY]}</Option>
      <Option value={AFTERPAYMENT}>{display[AFTERPAYMENT]}</Option>
      {!hideImmediately && (
        <Option value={IMMEDIATELY}>{display[IMMEDIATELY]}</Option>
      )}
    </Select>
  );
};

export default OrderSelectAutoIssueMode;
