import React from 'react';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

const TooltipInfoIcon = ({ title, style = {}, ...rest }) => {
  return (
    <Tooltip title={title} {...rest}>
      <InfoCircleOutlined style={{ marginLeft: '5px', ...style }} />
    </Tooltip>
  );
};

export default TooltipInfoIcon;
