import {
  createResourceReducer,
  concatReducers,
  authInitialState
} from 'react-structure-admin';

import {
  LOGIN_REQUEST_FINISHED,
  LOGOUT,
  AUTH_REFRESH_USER,
  AUTH_REFRESH_TOKEN_FINISHED,
  LOGIN_REQUEST,
  AUTH_CREATE_SUBSCRIBE_FINISHED,
  AUTH_SUBSCRIPTION_VALIDATE,
  AUTH_SUBSCRIPTION_VALIDATE_FINISHED,
  AUTH_COMPANY_UPDATE
} from './authActions';

const updateLocalStorage = (auth) => {
  localStorage.setItem('auth_data', btoa(JSON.stringify(auth)));
};

export const authReducer = createResourceReducer(
  'AUTH',
  { ...authInitialState },
  {
    [LOGIN_REQUEST](state) {
      return {
        ...state,
        loading: true
      };
    },

    [LOGIN_REQUEST_FINISHED](state, { payload }) {
      const now = new Date();
      now.setSeconds(now.getSeconds() + payload.data.expireInSeconds);
      return {
        ...state,
        ...payload.data,
        expirationDate: now,
        loading: false
      };
    },

    [AUTH_REFRESH_TOKEN_FINISHED](state, { payload }) {
      const now = new Date();
      now.setSeconds(now.getSeconds() + payload.data.expireInSeconds);
      return {
        ...state,
        ...payload.data,
        expirationDate: now
      };
    },

    [LOGOUT]() {
      return {                
        permissions: [],
        claims: []
      };
    },

    [AUTH_COMPANY_UPDATE](state, { payload }) {
      const newState = {
        ...state,
        company: { ...state.company, ...payload }
      };
      updateLocalStorage(newState);
      return newState;
    },

    [AUTH_REFRESH_USER](state, { payload }) {
      const auth = {
        ...state,
        user: {
          ...state.user,
          ...payload
        }
      };

      updateLocalStorage(auth);
      return auth;
    },

    [AUTH_CREATE_SUBSCRIBE_FINISHED](state, { payload }) {
      const { subscription } = payload.data.result;

      const auth = {
        ...state,
        user: {
          ...state.user
        },
        subscription
      };
      updateLocalStorage(auth);
      return auth;
    },
    [AUTH_SUBSCRIPTION_VALIDATE](state, action) {
      return { ...state, activating: true };
    },
    [AUTH_SUBSCRIPTION_VALIDATE_FINISHED](state, action) {
      const newState = {
        ...state,
        subscription: action.payload.data.result,
        activating: false
      };
      updateLocalStorage(newState);
      return newState;
    }
  },
  (state) => {
    return {
      ...state,
      loading: false,
      activating: false
    };
  }
);

export default concatReducers([authReducer], authInitialState);
