import React from 'react';

const Footer = ({ children, reverse = false }) => {
  return (
    <div
      style={{
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '10px 16px',
        background: '#fff',
        textAlign: reverse ? 'left' : 'right'
      }}
    >
      <div>{children}</div>
    </div>
  );
};

export default Footer;
