import React from 'react';
import { Layout } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE
} from 'constants/themeSettings';
import {
  onNavStyleChange,
  toggleCollapsedSideNav
} from 'stores/settings/settingsActions';
import CurrentAdminProfile from 'components/CurrentAdminProfile';

const { Header } = Layout;

const TopbarAdmin = () => {
  const dispatch = useDispatch();

  const { width, navCollapsed } = useSelector((state) => state.settings);

  let { navStyle } = useSelector((state) => state.settings);

  if (width < TAB_SIZE && navStyle === NAV_STYLE_FIXED) {
    navStyle = NAV_STYLE_DRAWER;
  }

  const toggleClickHandle = () => {
    if (navStyle === NAV_STYLE_DRAWER) {
      dispatch(toggleCollapsedSideNav(!navCollapsed));
    } else if (navStyle === NAV_STYLE_FIXED) {
      dispatch(onNavStyleChange(NAV_STYLE_MINI_SIDEBAR));
    } else if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      dispatch(toggleCollapsedSideNav(!navCollapsed));
    } else {
      dispatch(onNavStyleChange(NAV_STYLE_FIXED));
    }
  };

  return (
    <>
      <Header>
        <div className="gx-linebar gx-mr-3">
          <i
            className="gx-icon-btn icon icon-menu"
            aria-hidden="true"
            onClick={toggleClickHandle}
          />
        </div>
        <div className="gx-flex-row gx-align-items-center">
          <ul className="gx-header-notifications gx-ml-auto">
            <li className="gx-user-nav">
              <CurrentAdminProfile />
            </li>
          </ul>
        </div>
      </Header>
    </>
  );
};

export default TopbarAdmin;
