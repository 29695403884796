import React from 'react';
import { Crisp } from 'crisp-sdk-web';
import { useEffect } from 'react';
import { useAuthContext, configManager, api } from 'react-structure-admin';

const CrispChat = ({ allowAnonymous = false }) => {
  const {
    company,
    user,
    subscription,
    isAuthenticated,
    isAdmin
  } = useAuthContext();

  const showChat = () => {
    try {
      Crisp.chat.show();
      configManager.setConfig({ allowChat: true });
    } catch (error) {
      console.log(error);
    }
  };

  const hideChat = () => {
    try {
      Crisp.chat.hide();
      configManager.setConfig({ allowChat: false });
    } catch (error) {
      console.log(error);
    }
  };

  const setAdminUserData = () => {
    try {
      Crisp.user.setNickname(user.name);
      Crisp.user.setEmail(user.email);

      if (user.profileImage) {
        Crisp.user.setAvatar(`${api.getBaseUrl()}/users/${user.id}/image`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const setUserData = () => {
    if (!user) {
      return;
    }

    try {
      Crisp.user.setNickname(user.name);
      Crisp.user.setEmail(user.email);

      if (user.phoneNumber) {
        Crisp.user.setPhone(user.phoneNumber);
      }

      if (company && company.name) {
        Crisp.user.setCompany(company.name);
      }

      if (user.profileImage) {
        Crisp.user.setAvatar(`${api.getBaseUrl()}/users/${user.id}/image`);
      }

      Crisp.session.setData({ Plano: subscription?.planName });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isAdmin()) {
      setAdminUserData();
    } else if (isAuthenticated || allowAnonymous) {
      setUserData();
      showChat();
    } else {
      hideChat();
    }
  }, [isAuthenticated, company]);

  return <></>;
};

export default CrispChat;
