import { dispatcher, api } from 'react-structure-admin';

export const REPORTS = 'REPORTS';

export const REPORTS_GET = 'REPORTS_GET';
export const REPORTS_GET_FINISHED = 'REPORTS_GET_FINISHED';

export const REPORTS_GET_EVOLUTION = 'REPORTS_GET_EVOLUTION';
export const REPORTS_GET_EVOLUTION_FINISHED = 'REPORTS_GET_EVOLUTION_FINISHED';

export const REPORTS_GET_PRODUCT_RANKING = 'REPORTS_GET_PRODUCT_RANKING';
export const REPORTS_GET_PRODUCT_RANKING_FINISHED =
  'REPORTS_GET_PRODUCT_RANKING_FINISHED';

export const REPORTS_GET_PLATFORM_RANKING = 'REPORTS_GET_PLATFORM_RANKING';
export const REPORTS_GET_PLATFORM_RANKING_FINISHED =
  'REPORTS_GET_PLATFORM_RANKING_FINISHED';

export const getDailySummaryInvoices = (params) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'reports',
    actionType: REPORTS_GET,
    effect: async () =>
      api.fetch(`reports/daily-summary-invoices`, { ...params })
  });
};

export const getMonthlyEvolution = (params = {}) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'reports',
    actionType: REPORTS_GET_EVOLUTION,
    effect: async () => api.fetch(`reports/monthly-evolution`, { ...params })
  });
};

export const getProductRanking = (params = {}) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'reports',
    actionType: REPORTS_GET_PRODUCT_RANKING,
    effect: async () => api.fetch(`reports/product-ranking`, { ...params })
  });
};

export const getPlatformRanking = (params) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'reports',
    actionType: REPORTS_GET_PLATFORM_RANKING,
    payload: params,
    effect: async () => api.fetch(`reports/platform-ranking`, { ...params })
  });
};
