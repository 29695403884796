import React, { useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';

const InputCurrency = ({
  prefix = 'R$',
  decimalSeparator = ',',
  groupSeparator = '.',
  decimalsLimit = 2,
  decimalScale = 2,
  onChange,
  value,
  ...rest
}) => {
  const [userValue, setUserValue] = useState(value);

  const triggerChange = (changedValue) => {
    onChange?.(
      changedValue ? parseFloat(changedValue.replace(decimalSeparator, '.')) : 0
    );

    setUserValue(changedValue);
  };

  useEffect(() => {
    setUserValue(value ?? 0);
  }, [value, decimalSeparator, groupSeparator]);

  return (
    <CurrencyInput
      className="ant-input"
      prefix={`${prefix} `}
      decimalScale={decimalScale}
      decimalSeparator={decimalSeparator}
      decimalsLimit={decimalsLimit}
      groupSeparator={groupSeparator}
      allowNegativeValue={false}
      onValueChange={triggerChange}
      value={userValue}
      {...rest}
    />
  );
};
export default InputCurrency;
