import React, { useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { message, Upload, Button } from 'antd';
import join from 'util/functions/join';
import truncate from 'util/functions/truncate';

const UploadArchiveManual = ({
  placeholder,
  extensions = [],
  onRequest,
  messageError,
  name,
  width = 220
}) => {
  const [uploading, setUploading] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [extension, setExtension] = useState(null);
  const extractingExtension = (file) => {
    const text = file.match(/\.[0-9a-z]+$/i)[0];
    return text != null ? text : '';
  };

  const handleBeforeUpload = (file) => {
    let isCorrectFormat = false;

    const extractedExtension = extractingExtension(file.name).replace('.', '');
    if (extensions.includes(extractedExtension)) {
      isCorrectFormat = true;
    }

    if (!isCorrectFormat) {
      const names = join(extensions, ', ', ' e ');
      message.error(
        messageError ?? `Selecione um arquivo com a extensão ${names}.`
      );
    }

    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
      message.error('O tamanho do arquivo não pode ser superior a 2MB');
    }

    setFileName(file.name);
    setExtension(extractedExtension);
    setUploading(isCorrectFormat && isLt2M);
    return isCorrectFormat && isLt2M;
  };

  const handleCustomRequest = (options) => {
    if (onRequest) {
      onRequest(options);
    }
  };

  const uploadProps = {
    name,
    beforeUpload: handleBeforeUpload,
    showUploadList: false,
    customRequest: handleCustomRequest
  };

  return uploading ? (
    <Upload {...uploadProps}>
      <Button style={{ width }} icon={<UploadOutlined />}>
        {truncate(fileName, 25, `....${extension}`)}
      </Button>
    </Upload>
  ) : (
    <Upload {...uploadProps}>
      <Button style={{ width }} icon={<UploadOutlined />}>
        {placeholder}
      </Button>
    </Upload>
  );
};

export default UploadArchiveManual;
