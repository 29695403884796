const emailValidator = (limit = 1) => (_, value = '') => {
  if (!value) {
    return Promise.resolve();
  }

  const isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return (
      emailRegex.test(email) && !email.includes('..') && !email.includes('.@')
    );
  };

  const invalidEmails = value.filter((value) => !isValidEmail(value));
  if (invalidEmails.length > 0) {
    return Promise.reject(`${invalidEmails.join(', ')} não é válido`);
  }

  if (value?.length > limit) {
    return Promise.reject(new Error(`Maximo de ${limit} e-mails`));
  }
  return Promise.resolve();
};

export default emailValidator;
