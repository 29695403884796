import {
  updateResourceState,
  createResourceReducer,
  RESOURCE
} from 'react-structure-admin';

import {
  CITY,
  CITY_SET_AVAILABLE_FINISHED,
  CITY_OPEN_PROVIDER_SETTINGS,
  CITY_CLOSE_PROVIDER_SETTINGS,
  CITY_GET_PROVIDER_SETTINGS,
  CITY_GET_PROVIDER_SETTINGS_FINISHED,
  CITY_PUT_PROVIDER_SETTINGS,
  CITY_PUT_PROVIDER_SETTINGS_FINISHED
} from './cityActions';

const cityReducer = createResourceReducer(
  CITY,
  [],
  {
    [CITY_SET_AVAILABLE_FINISHED](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        loaded: false
      }));
    },

    [CITY_OPEN_PROVIDER_SETTINGS](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        city: action.payload,
        queryParams: {
          id: action.payload.id,
          provider: action.payload.provider
        },
        activeModal: CITY_OPEN_PROVIDER_SETTINGS
      }));
    },

    [CITY_CLOSE_PROVIDER_SETTINGS](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        city: null,
        activeModal: null,
        queryParams: null
      }));
    },

    [CITY_GET_PROVIDER_SETTINGS](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        activeModal: CITY_OPEN_PROVIDER_SETTINGS,
        loadingProviderSettings: true
      }));
    },

    [CITY_GET_PROVIDER_SETTINGS_FINISHED](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        providerSettings: action.payload.data,
        loadingProviderSettings: false
      }));
    },

    [CITY_PUT_PROVIDER_SETTINGS](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        activeModal: CITY_OPEN_PROVIDER_SETTINGS,
        loading: true
      }));
    },

    [CITY_PUT_PROVIDER_SETTINGS_FINISHED](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        loading: false
      }));
    }
  },

  (state, action) => {
    return updateResourceState(state, action, RESOURCE, () => ({
      loading: false,
      loadingProviderSettings: false
    }));
  }
);

export default cityReducer;
