import React from 'react';
import { Select } from 'antd';
import { taxCodes } from 'constants/taxCodes';

const TaxCodeSelect = ({ type, ...rest }) => {
  const codes = taxCodes[type] ?? [];

  return (
    <Select {...rest} dropdownMatchSelectWidth={450}>
      {codes.map(({ code, title }) => (
        <Select.Option key={code} value={code}>
          {title}
        </Select.Option>
      ))}
    </Select>
  );
};

export default TaxCodeSelect;
