import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { configManager } from 'react-structure-admin';
import Iugu from 'util/iugu';

const ChangeSubscriptionEventHandler = () => {
  const config = configManager.getConfig();
  const subscription = useSelector((c) => c.auth?.subscription);

  useEffect(() => {
    if (!Iugu?.setAccountID) {
      return;
    }

    if (subscription?.subscriptionManagerAccountId) {
      Iugu.setAccountID(subscription?.subscriptionManagerAccountId);
    } else {
      Iugu.setAccountID(config.iuguAccountId);
    }
  }, [subscription?.id]);

  return null;
};

export default ChangeSubscriptionEventHandler;
