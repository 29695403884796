import React from 'react';
import { useHistory } from 'react-router-dom';
import { Menu, Dropdown } from 'antd';
import { useAuthContext, RECORD_FIELD } from 'react-structure-admin';
import UserProfileImage from 'components/UserProfileImage';
import { useDispatch, useSelector } from 'react-redux';
import { updatePassword, currentUserGet } from 'stores/users/userActions';

import UpdatePassword from 'pages/shared/UpdatePassword';
import CurrentUserEdit from 'pages/shared/CurrentUserEdit';
import CompanyEdit from 'pages/app/companies/CompanyEdit';
import CompanySettings from 'pages/shared/CompanySettings';
import { get, getSettings } from 'stores/companies/companyActions';
import SubscriptionPlanEdit from 'pages/shared/Subscription/SubscriptionPlanEdit';
import { ShowChildrenIf } from 'components';
import { getActiveSubscription } from 'stores/subscriptions/subscriptionActions';
import { changeTenant } from 'stores/auth/authActions';

const MenuItem = ({ children, onClick, features = [], ...rest }) => {
  const { features: userFeatures = [] } = useAuthContext();

  const userHasFeature = () => {
    return (
      features.length == 0 ||
      features.findIndex((c) => userFeatures.includes(c)) >= 0
    );
  };

  return (
    <Menu.Item {...rest} onClick={onClick} hidden={!userHasFeature()}>
      {children}
    </Menu.Item>
  );
};

const CurrentUserProfile = () => {
  const { user, isMultiTenant } = useAuthContext();
  const dispatch = useDispatch();
  const history = useHistory();

  const { width } = useSelector((state) => state.settings);

  const { currentUser, [RECORD_FIELD]: resourceUsers } = useSelector(
    (state) => state.resources.users || {}
  );

  const {
    [RECORD_FIELD]: resourceCompanies,
    editingSettings,
    uploading = false
  } = useSelector((state) => state.resources.companies || {});

  const { company } = useSelector((state) => state.auth);

  const signoutClickHandle = () => {
    history.push('/signout');
  };

  const editProfileClickHandle = () => {
    dispatch(currentUserGet(user.id));
  };

  const changePasswordClickHandle = () => {
    dispatch(updatePassword({ userId: user.id }));
  };

  const changeTenantClickHandle = () => {
    history.push('/accounts-chooser');
  };

  const editCompanyClickHandle = () => {
    dispatch(get(company.id));
  };

  const editCompanySettingsClickHandle = () => {
    dispatch(getSettings(company.id));
  };

  const subscriptionClickHandle = () => {
    dispatch(getActiveSubscription());
  };

  const handleShowSubscriptionEdit = (state = {}) => {
    const { subscriptionShow = false } = state;
    return subscriptionShow;
  };

  const collaboratorsClickHandle = () => {
    history.push('/p/contributors');
  };

  const taxationGroupClickHandle = () => {
    history.push('/p/taxation-groups');
  };

  const handleBackToRootTenant = () => {
    dispatch(
      changeTenant({}, () => {
        history.push('/');
      })
    );
  };

  const userMenuOptions = (
    <Menu>
      <MenuItem onClick={editProfileClickHandle}>Meus dados</MenuItem>
      <MenuItem
        onClick={editCompanyClickHandle}
        features={['profile_customer']}
      >
        Minha empresa
      </MenuItem>
      <MenuItem
        onClick={subscriptionClickHandle}
        features={['profile_customer']}
      >
        Minha assinatura
      </MenuItem>
      <MenuItem
        onClick={editCompanySettingsClickHandle}
        features={['profile_customer']}
      >
        Configurações
      </MenuItem>
      <MenuItem
        onClick={taxationGroupClickHandle}
        features={['profile_customer']}
      >
        Tributação
      </MenuItem>
      <MenuItem
        onClick={collaboratorsClickHandle}
        features={['profile_customer']}
      >
        Colaboradores
      </MenuItem>
      <MenuItem onClick={changePasswordClickHandle}>Alterar senha</MenuItem>
      {isMultiTenant ? (
        <MenuItem
          onClick={changeTenantClickHandle}
          features={['profile_customer']}
        >
          Trocar de conta
        </MenuItem>
      ) : null}
      {width < 800 ? (
        <MenuItem
          onClick={handleBackToRootTenant}
          className="gx-bg-primary gx-text-white"
          features={['profile_customer']}
        >
          Administração
        </MenuItem>
      ) : null}
      <MenuItem onClick={signoutClickHandle}>Sair</MenuItem>
    </Menu>
  );

  const userName = user ? user.displayName || user.name : null;

  if (!user) {
    return null;
  }

  return (
    <>
      <Dropdown overlay={userMenuOptions} trigger={['click']}>
        <div className="gx-d-flex gx-align-items-center gx-avatar-row">
          <UserProfileImage
            className="gx-size-40 gx-pointer gx-mr-2"
            id={user.id}
            base64={user.profileImage ? user.profileImage.base64 : undefined}
          />

          <div className="gx-avatar-name">
            {userName ? (
              <span className="gx-currentuserprofile-name">{userName}</span>
            ) : (
              <span
                className="gx-currentuserprofile-name"
                style={{ color: '#ccc' }}
              >
                Carregando...
              </span>
            )}
            <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
          </div>
        </div>
      </Dropdown>
      {resourceCompanies ? <CompanyEdit params={{ uploading }} /> : null}
      {currentUser ? <CurrentUserEdit /> : null}
      {editingSettings ? <CompanySettings /> : null}
      <ShowChildrenIf
        resource="subscriptions"
        when={handleShowSubscriptionEdit}
      >
        <SubscriptionPlanEdit />
      </ShowChildrenIf>

      {resourceUsers && resourceUsers.editingPassword && (
        <UpdatePassword updatePassword={resourceUsers.editingPassword} />
      )}
    </>
  );
};

export default CurrentUserProfile;
