const formartOnlyNumber = (number) => {
  if (!number) {
    return null;
  }
  const value = number.match(/\d+/g);

  if (value) {
    return value.join('');
  }

  return null;
};

export default formartOnlyNumber;
