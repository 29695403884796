import React from 'react';
import { Layout } from 'antd';
import Sidebar from 'components/Sidebar/Sidebar';
import { Routes, useAuthContext, RouteRedirect } from 'react-structure-admin';
import TopbarAdmin from 'components/Topbar/TopbarAdmin';
const { Content } = Layout;

const AdminContainer = (props) => {
  const { match, routes, roles, basePath } = props;
  const { isAuthenticated, tenantId, isAdmin } = useAuthContext();

  const onBeforeRouteRender = () => {
    if (isAuthenticated && isAdmin() && tenantId) {
      return <RouteRedirect to="/" />;
    }

    return null;
  };

  return (
    <Layout className="gx-app-layout">
      <Sidebar routes={routes} match={match} />
      <Layout>
        <TopbarAdmin />
        <Content className="gx-layout-content gx-container-wrap">
          <Routes
            match={match}
            routes={routes}
            roles={roles}
            basePath={basePath}
            onBeforeRouteRender={onBeforeRouteRender}
            isContainer
          />
        </Content>
      </Layout>
    </Layout>
  );
};

export default AdminContainer;
