const { SERVICEINVOICE, PRODUCTINVOICE } = require('constants/types');

const getEndpointByInvoiceModel = (model) => {
  switch (model) {
    case SERVICEINVOICE:
      return 'service-invoices';
    case PRODUCTINVOICE:
      return 'product-invoices';
    default:
      return null;
  }
};

export default getEndpointByInvoiceModel;
