import {
  updateResourceState,
  createResourceReducer,
  RESOURCE
} from 'react-structure-admin';
import {
  SUBSCRIPTION,
  SUBSCRIPTION_REFRESH,
  SUBSCRIPTION_CURRENT_GET,
  SUBSCRIPTION_CURRENT_GET_FINISHED,
  SUBSCRIPTION_PAYMENTS,
  SUBSCRIPTION_PAYMENTS_FINISHED,
  SUBSCRIPTION_SHOW_PLANS,
  SUBSCRIPTION_CLOSE_PLANS,
  SUBSCRIPTION_CLOSE,
  SUBSCRIPTION_UPDATE,
  SUBSCRIPTION_UPDATE_FINISHED,
  SUBSCRIPTION_CANCEL,
  SUBSCRIPTION_CANCEL_FINISHED,
  SUBSCRIPTION_SHOW_CHANGE_PAYMENT_METHOD,
  SUBSCRIPTION_CLOSE_CHANGE_PAYMENT_METHOD,
  SUBSCRIPTION_CHANGE_PAYMENT_METHOD,
  SUBSCRIPTION_CHANGE_PAYMENT_METHOD_FINISHED,
  SUBSCRIPTION_CLOSE_REQUEST_CANCELLATION,
  SUBSCRIPTION_REQUEST_CANCELLATION,
  SUBSCRIPTION_REQUEST_CANCELLATION_FINISHED,
  SUBSCRIPTION_REQUEST_CANCELLATION_SETTINGS,
  SUBSCRIPTION_REQUEST_CANCELLATION_SETTINGS_FINISHED,
  SUBSCRIPTION_GET_RESUME,
  SUBSCRIPTION_GET_RESUME_FINISHED,
  SUBSCRIPTION_CLOSE_RESUME,
  SUBSCRIPTION_OPEN_APPLY_DISCOUNT,
  SUBSCRIPTION_CLOSE_APPLY_DISCOUNT,
  SUBSCRIPTION_APPLY_DISCOUNT,
  SUBSCRIPTION_APPLY_DISCOUNT_FINISHED,
  SUBSCRIPTION_OPEN_CHANGE_AVAILABLE_PAYMENT_METHOD,
  SUBSCRIPTION_CLOSE_CHANGE_AVAILABLE_PAYMENT_METHOD,
  SUBSCRIPTION_OPEN_INCLUDE_ADDITIONAL_COMPANIES,
  SUBSCRIPTION_CLOSE_INCLUDE_ADDITIONAL_COMPANIES,
  SUBSCRIPTION_INCLUDE_ADDITIONAL_COMPANIES,
  SUBSCRIPTION_INCLUDE_ADDITIONAL_COMPANIES_FINISHED,
  SUBSCRIPTION_REACTIVATE,
  SUBSCRIPTION_REACTIVATE_FINISHED,
  SUBSCRIPTION_OPEN_UPDATE,
  SUBSCRIPTION_CLOSE_UPDATE,
  SUBSCRIPTION_UPDATE_PLAN,
  SUBSCRIPTION_UPDATE_PLAN_FINISHED,
  SUBSCRIPTION_GET_CHECKOUT,
  SUBSCRIPTION_GET_CHECKOUT_FINISHED,
  SUBSCRIPTION_POST_CHECKOUT,
  SUBSCRIPTION_POST_CHECKOUT_FINISHED
} from './subscriptionActions';

const handleError = (state, action) => {
  return updateResourceState(state, action, RESOURCE, () => ({
    saving: false,
    loading: false,
    canceling: false,
    changingPaymentMethod: false,
    requestingCancellation: false,
    gettingPayments: false,
    loadingPlanId: null,
    applyingDiscount: false,
    includingAdditionalCompanies: false,
    editing: false
  }));
};

const subscriptionReducer = createResourceReducer(
  SUBSCRIPTION,
  [],
  {
    [SUBSCRIPTION_CURRENT_GET](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        subscriptionShow: true,
        subscription: null,
        loading: true,
        plans: false
      }));
    },

    [SUBSCRIPTION_CURRENT_GET_FINISHED](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        subscription: action.payload.data.result,
        loading: false,
        loaded: true,
        plans: false
      }));
    },

    [SUBSCRIPTION_CLOSE](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        subscriptionShow: false,
        subscription: null,
        payments: null,
        loading: false,
        loaded: false,
        checkoutResume: null,
        plans: false
      }));
    },

    [SUBSCRIPTION_SHOW_PLANS](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        plans: true
      }));
    },

    [SUBSCRIPTION_CLOSE_PLANS](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        plans: false,
        checkoutResume: null
      }));
    },

    [SUBSCRIPTION_PAYMENTS](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        payments: null,
        gettingPayments: true
      }));
    },

    [SUBSCRIPTION_PAYMENTS_FINISHED](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        payments: action.payload.data.result,
        gettingPayments: false,
        checkoutResume: null
      }));
    },

    [SUBSCRIPTION_UPDATE](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        saving: true
      }));
    },

    [SUBSCRIPTION_UPDATE_FINISHED](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        subscription: action.payload.data.result,
        plans: false,
        saving: false,
        checkoutResume: null,
        loadingResume: false
      }));
    },

    [SUBSCRIPTION_CANCEL](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        canceling: true,
        checkoutResume: null
      }));
    },

    [SUBSCRIPTION_SHOW_CHANGE_PAYMENT_METHOD](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        changePaymentMethod: true
      }));
    },

    [SUBSCRIPTION_CLOSE_CHANGE_PAYMENT_METHOD](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        changePaymentMethod: false
      }));
    },

    [SUBSCRIPTION_CHANGE_PAYMENT_METHOD](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        changingPaymentMethod: true
      }));
    },

    [SUBSCRIPTION_CHANGE_PAYMENT_METHOD_FINISHED](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        changingPaymentMethod: false
      }));
    },

    [SUBSCRIPTION_REQUEST_CANCELLATION_SETTINGS_FINISHED](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        requestCancellation: true,
        requestCancellationSettings: action.payload.data.result
      }));
    },

    [SUBSCRIPTION_CLOSE_REQUEST_CANCELLATION](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        requestCancellation: false,
        requestCancellationSettings: null
      }));
    },

    [SUBSCRIPTION_REQUEST_CANCELLATION](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        requestingCancellation: true
      }));
    },

    [SUBSCRIPTION_REQUEST_CANCELLATION_FINISHED](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        requestingCancellation: false,
        requestCancellation: false
      }));
    },

    [SUBSCRIPTION_GET_RESUME](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        loadingPlanId: action.payload
      }));
    },

    [SUBSCRIPTION_GET_RESUME_FINISHED](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        loadingPlanId: null,
        checkoutResume: action.payload?.data
      }));
    },

    [SUBSCRIPTION_CLOSE_RESUME](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        checkoutResume: null,
        loadingPlanId: false
      }));
    },

    [SUBSCRIPTION_OPEN_APPLY_DISCOUNT](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        activeModal: SUBSCRIPTION_OPEN_APPLY_DISCOUNT
      }));
    },

    [SUBSCRIPTION_CLOSE_APPLY_DISCOUNT](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        activeModal: null
      }));
    },

    [SUBSCRIPTION_APPLY_DISCOUNT](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        applyingDiscount: true
      }));
    },

    [SUBSCRIPTION_APPLY_DISCOUNT_FINISHED](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        activeModal: null,
        applyingDiscount: false,
        loaded: false
      }));
    },

    [SUBSCRIPTION_OPEN_CHANGE_AVAILABLE_PAYMENT_METHOD](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        changeAvailablePaymentMethod: true
      }));
    },

    [SUBSCRIPTION_CLOSE_CHANGE_AVAILABLE_PAYMENT_METHOD](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        changeAvailablePaymentMethod: false
      }));
    },

    [SUBSCRIPTION_OPEN_INCLUDE_ADDITIONAL_COMPANIES](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        activeModal: SUBSCRIPTION_OPEN_INCLUDE_ADDITIONAL_COMPANIES
      }));
    },

    [SUBSCRIPTION_CLOSE_INCLUDE_ADDITIONAL_COMPANIES](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        activeModal: null
      }));
    },

    [SUBSCRIPTION_INCLUDE_ADDITIONAL_COMPANIES](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        includingAdditionalCompanies: true
      }));
    },

    [SUBSCRIPTION_INCLUDE_ADDITIONAL_COMPANIES_FINISHED](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        activeModal: null,
        includingAdditionalCompanies: false,
        loaded: false
      }));
    },

    [SUBSCRIPTION_REACTIVATE_FINISHED](state, action) {
      if (state.accounts) {
        state.accounts = { ...state.accounts, loaded: false };
      }
      return updateResourceState(state, action, RESOURCE, () => ({
        loaded: false
      }));
    },

    [SUBSCRIPTION_OPEN_UPDATE](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        activeModal: SUBSCRIPTION_OPEN_UPDATE
      }));
    },

    [SUBSCRIPTION_CLOSE_UPDATE](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        activeModal: null
      }));
    },

    [SUBSCRIPTION_UPDATE_PLAN](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        editing: true
      }));
    },

    [SUBSCRIPTION_UPDATE_PLAN_FINISHED](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        activeModal: null,
        editing: false,
        loaded: false
      }));
    },

    [SUBSCRIPTION_GET_CHECKOUT](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        catching: true
      }));
    },

    [SUBSCRIPTION_GET_CHECKOUT_FINISHED](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        catching: false
      }));
    },

    [SUBSCRIPTION_POST_CHECKOUT](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        loading: true
      }));
    },

    [SUBSCRIPTION_POST_CHECKOUT_FINISHED](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        loading: false
      }));
    }
  },
  handleError,

  (state, action) => {
    if (state.accounts) {
      state.accounts = { ...state.accounts, loaded: false };
    }
    return updateResourceState(state, action, RESOURCE, () => ({
      loading: false,
      applyingDiscount: false,
      includingAdditionalCompanies: false,
      editing: false,
      catching: false
    }));
  }
);

export default subscriptionReducer;
