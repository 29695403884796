import { globalEvents } from 'react-structure-admin';
import {
  BANNER_SHOW,
  CHAT_LOADED,
  SWITCH_LANGUAGE,
  TOGGLE_COLLAPSED_NAV,
  WINDOW_WIDTH
} from 'stores/settings/settingsActions';

import {
  LAYOUT_TYPE_FULL,
  NAV_STYLE_FIXED,
  THEME_COLOR_SELECTION_PRESET,
  THEME_TYPE_LITE,
  THEME_TYPE,
  THEME_COLOR,
  THEME_TYPE_SEMI_DARK,
  NAV_STYLE,
  LAYOUT_TYPE,
  APP_DEFAULT,
  APP_ADMIN
} from 'constants/themeSettings';

const initialState = {
  navCollapsed: false,
  navStyle: NAV_STYLE_FIXED,
  layoutType: LAYOUT_TYPE_FULL,
  themeType: THEME_TYPE_LITE,
  colorSelection: THEME_COLOR_SELECTION_PRESET,
  currentApp: APP_DEFAULT,
  pathname: '',
  width: window.innerWidth,
  isDirectionRTL: false,
  bannerShown: true,
  locale: {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us'
  }
};

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE': {
      const themeType = THEME_TYPE_SEMI_DARK;
      let currentApp = APP_DEFAULT;

      if (
        action.payload.location.pathname &&
        (action.payload.location.pathname.endsWith('/a') ||
          action.payload.location.pathname.startsWith('/a/'))
      ) {
        currentApp = APP_ADMIN;
      }

      globalEvents.invokeEvents('locationChanged', {
        pathname: action.payload.location.pathname
      });

      localStorage.setItem('currentApp', currentApp);

      return {
        ...state,
        pathname: action.payload.location.pathname || '',
        themeType,
        currentApp,
        navCollapsed: false
      };
    }
    case TOGGLE_COLLAPSED_NAV:
      return {
        ...state,
        navCollapsed: action.navCollapsed
      };
    case WINDOW_WIDTH:
      return {
        ...state,
        width: action.width
      };
    case CHAT_LOADED:
      return {
        ...state,
        chatLoaded: true
      };
    case THEME_TYPE:
      return {
        ...state,
        themeType: action.themeType
      };
    case THEME_COLOR:
      return {
        ...state,
        themeColor: action.themeColor
      };

    case NAV_STYLE:
      return {
        ...state,
        navStyle: action.navStyle
      };
    case LAYOUT_TYPE:
      return {
        ...state,
        layoutType: action.layoutType
      };

    case SWITCH_LANGUAGE:
      return {
        ...state,
        locale: action.payload
      };
    case BANNER_SHOW:
      return { ...state, bannerShown: action.show };
    default:
      return state;
  }
};

export default settingsReducer;
