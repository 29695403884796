/* eslint-disable react/prefer-es6-class */
/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import createReactClass from 'create-react-class';
import { useSelector } from 'react-redux';

const ReduxRenderInternal = ({ children, condition, mapProps, ...rest }) => {
  const state = useSelector((state) => state);
  const props = mapProps(state);

  if (!condition(state)) {
    return null;
  }

  return React.cloneElement(children, { ...rest, ...props });
};

class ReduxRender {
  static create(WrappedComponent, { condition, mapProps }) {
    return createReactClass({
      render() {
        const { children, ...rest } = this.props;

        return (
          <ReduxRenderInternal
            {...rest}
            condition={condition}
            mapProps={mapProps}
          >
            <WrappedComponent>{children}</WrappedComponent>
          </ReduxRenderInternal>
        );
      }
    });
  }
}

export default ReduxRender;
