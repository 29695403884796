import { dispatcher, api } from 'react-structure-admin';

export const NOTIFICATION = 'NOTIFICATION';

export const NOTIFICATION_FETCH = 'NOTIFICATION_FETCH';
export const NOTIFICATION_FETCH_FINISHED = 'NOTIFICATION_FETCH_FINISHED';

export const fetch = (_, params) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'notifications',
    actionType: NOTIFICATION_FETCH,
    effect: async () => api.fetch('notifications', params)
  });
};
