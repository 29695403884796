import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { configManager, useCrud } from 'react-structure-admin';
import Pagination from 'components/Pagination';

const ListTable = (props) => {
  const {
    dataSource,
    loading,
    pagination,
    children,
    initEditing,
    rowSelection,
    className,
    handleTableChange,
    columns,
    components,
    moveRow,
    onDoubleClick,
    disableDoubleClick,
    disablePagination,
    removeRecord,
    showHeader,
    expandable
  } = props;

  const [currentPage, setCurrentPage] = useState(pagination?.current);

  const { paginationChanged } = useCrud({
    resource: props.path?.replace('/', '')
  });

  useEffect(() => {
    setCurrentPage(pagination?.current);
    paginationChanged(pagination?.current);
  }, [pagination?.current]);

  const handleDoubleClick = (record) => {
    if (disableDoubleClick) {
      return;
    }

    if (onDoubleClick) {
      onDoubleClick(record, initEditing);
      return;
    }

    if (initEditing) {
      initEditing(record);
    }
  };

  const handlePageChange = (isNext = false) => {
    let newPage = currentPage;

    if (isNext) newPage++;
    else newPage--;

    setCurrentPage(newPage);

    pagination.current = newPage;
    paginationChanged(pagination?.current);
  };

  return (
    <>
      <Table
        scroll={{ x: 400 }}
        size={configManager.getConfig().layout.list.table.size}
        className={className}
        loading={loading}
        rowKey={(record) => record.id ?? record.uuid}
        rowSelection={rowSelection}
        pagination={pagination?.total == null ? false : pagination}
        onChange={handleTableChange}
        dataSource={dataSource}
        showHeader={showHeader}
        columns={columns}
        expandable={expandable}
        components={components}
        onRow={(record, index) => {
          return {
            onDoubleClick: () => handleDoubleClick(record),
            index,
            moveRow
          };
        }}
      >
        {typeof children === 'function'
          ? children({ initEditing, removeRecord })
          : children}
      </Table>

      {pagination.total == null && (
        <Pagination
          loaded={!props.loading}
          onClickNext={() => handlePageChange(true)}
          onClickPrev={() => handlePageChange()}
          pagination={pagination}
        />
      )}
    </>
  );
};

export default ListTable;
