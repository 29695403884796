import React from 'react';
import { Select } from 'antd';

const SelectAccountType = ({ ...rest }) => {
  return (
    <Select {...rest}>
      <Select.Option value="customer">Cliente</Select.Option>
      <Select.Option value="partner">Parceiro</Select.Option>
    </Select>
  );
};

export default SelectAccountType;
