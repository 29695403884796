import React from 'react';
import { Select } from 'antd';
import getStates from 'util/getStates';

const SelectStates = ({ showForeign = false, ...rest }) => {
  return (
    <Select {...rest} labelInValue>
      {getStates(showForeign).map(({ initials, name }) => (
        <Select.Option key={initials} value={initials}>
          {name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default SelectStates;
