import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const ListHeader = ({
  onSearch,
  setQueryParams,
  queryParams,
  menu,
  title,
  create,
  renderButton
}) => {
  const [delayedSearch, setDelayedSearch] = useState(null);

  const onSearchChange = (e) => {
    if (delayedSearch) {
      clearTimeout(delayedSearch);
    }

    const input = e.target;

    if (onSearch && e.target) {
      setDelayedSearch(setTimeout(() => onSearch(input.value), 600));
      return;
    }

    setDelayedSearch(
      setTimeout(
        () =>
          setQueryParams({
            ...queryParams,
            page: 1,
            filterText: input.value
          }),
        600
      )
    );
  };

  const openToCreate = (record = {}) => {
    create(record);
  };

  return (
    <div className="gx-page-heading">
      <div className="gx-page-heading-title">
        <span className="ant-avatar ant-avatar-square ant-avatar-icon">
          {menu && menu.icon && (
            <FontAwesomeIcon className="icon" icon={menu.icon} />
          )}
        </span>
        {title}
      </div>
      <div className="gx-page-heading-search">
        <div className="gx-search-bar gx-lt-icon-search-bar-lg gx-module-search-bar gx-d-none gx-d-sm-block">
          <div className="gx-form-group">
            <input
              className="ant-input gx-border-0"
              type="search"
              placeholder="Pesquisar..."
              onChange={onSearchChange}
            />
            <span className="gx-search-icon gx-pointer">
              <i className="icon icon-search" />
            </span>
          </div>
        </div>
      </div>
      <div className="gx-page-heading-extra">
        {renderButton ? (
          renderButton()
        ) : (
          <div className="gx-page-heading-extra-button">
            <Button type="primary" onClick={() => openToCreate()}>
              <PlusOutlined />
              Novo
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ListHeader;
