import React from 'react';
import { formatCurrency } from 'util/formatters';

const CurrencyDisplay = ({
  className = '',
  amount = 0,
  strikethrough = false,
  hideValueEqualToZero = true,
  nameForZeroValue
}) => {
  if (hideValueEqualToZero && !(amount > 0)) {
    return null;
  }

  if (strikethrough) {
    className = `${className} gx-text-strikethrough gx-fs-11`;
  }

  if (!amount && nameForZeroValue) {
    return (
      <div className={`${className} gx-text-right`}>{nameForZeroValue}</div>
    );
  }

  return (
    <div className={`${className} gx-text-right`}>{formatCurrency(amount)}</div>
  );
};

export default CurrencyDisplay;
