import React, { useEffect, useState } from 'react';
import { Form, Switch } from 'antd';

const notifications = [
  { value: 'expiredCertificate', title: 'Certificado vencido' },
  {
    value: 'rejectedInvoices',
    title: 'Notas fiscais rejeitadas ',
    tooltip: { title: 'Notas fiscais rejeitadas nos últimos 7 dias.' }
  }
];

const NotificationSwitchEdit = ({ value, onChange }) => {
  const [form] = Form.useForm();
  const [loaded, setLoaded] = useState(false);

  const handleValuesChange = (_, values) => {
    onChange(Object.keys(values).filter((c) => values[c]));
  };

  const handleBeforeBinding = () => {
    return (value ?? []).reduce(
      (prev, current) => ({ ...prev, [current]: true }),
      {}
    );
  };

  useEffect(() => {
    if (!loaded && Array.isArray(value) && value.length > 0) {
      setLoaded(true);
      form.setFieldsValue(handleBeforeBinding());
    }
  }, [value]);

  return (
    <Form
      form={form}
      name="NotificationSwitchEdit"
      onValuesChange={handleValuesChange}
    >
      {notifications.sort().map((c) => (
        <Form.Item
          key={c.value}
          name={c.value}
          label={c.title}
          tooltip={c.tooltip}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
      ))}
    </Form>
  );
};

export default NotificationSwitchEdit;
