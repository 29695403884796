const taxCodes = {
  csosn: [
    {
      code: 101,
      title: '101 - Tributada pelo Simples Nacional com permissão de crédito'
    },
    {
      code: 102,
      title: '102 - Tributada pelo Simples Nacional sem permissão de crédito'
    },
    {
      code: 103,
      title:
        '103 - Isenção do ICMS no Simples Nacional para faixa de receita bruta'
    },
    {
      code: 201,
      title:
        '201 - Tributada pelo Simples Nacional com permissão de crédito e com cobrança do ICMS por Substituição Tributária'
    },
    {
      code: 202,
      title:
        '202 - Tributada pelo Simples Nacional sem permissão de crédito e com cobrança do ICMS por Substituição Tributária'
    },
    {
      code: 203,
      title:
        '203 - Isenção do ICMS nos Simples Nacional para faixa de receita bruta e com cobrança do ICMS por Substituição Tributária'
    },
    {
      code: 300,
      title: '300 – Imune'
    },
    {
      code: 400,
      title: '400 – Não tributada pelo Simples Nacional'
    },
    {
      code: 500,
      title:
        '500 – ICMS cobrado anteriormente por substituição tributária (substituído) ou porantecipação'
    },
    {
      code: 900,
      title: '900 – Outros'
    }
  ],
  cstIcms: [
    { code: 0, title: '00 – Tributada Integralmente' },
    {
      code: 10,
      title: '10 – Tributada e com cobrança do ICMS por substituição tributária'
    },
    { code: 20, title: '20 – Com redução de base de cálculo' },
    {
      code: 30,
      title:
        '30 – Isenta ou não tributada e com cobrança do ICMS por substituição tributária'
    },
    { code: 40, title: '40 – Isenta' },
    { code: 41, title: '41 – Não tributada' },
    { code: 50, title: '50 – Suspensão' },
    { code: 51, title: '51 – Diferimento' },
    {
      code: 60,
      title: '60 – ICMS cobrado anteriormente por substituição tributária'
    },
    {
      code: 70,
      title:
        '70 – Com redução de base de cálculo e cobrança do ICMS por substituição tributária'
    },
    { code: 90, title: '90 – Outros' }
  ],
  cstPisCofins: [
    { code: 1, title: '1 – Operação Tributável com Alíquota Básica' },
    {
      code: 2,
      title:
        '2 – Operação Tributável com Alíquota por Unidade de Medida de Produto'
    },
    {
      code: 3,
      title:
        '3 – Operação Tributável com Alíquota por Unidade de Medida de Produto'
    },
    {
      code: 4,
      title: '4 – Operação Tributável Monofásica – Revenda a Alíquota Zero'
    },
    { code: 5, title: '5 – Operação Tributável por Substituição Tributária' },
    { code: 6, title: '6 – Operação Tributável a Alíquota Zero' },
    { code: 7, title: '7 – Operação Isenta da Contribuição' },
    { code: 8, title: '8 – Operação sem Incidência da Contribuição' },
    { code: 9, title: '9 – Operação com Suspensão da Contribuição' },
    { code: 49, title: '49 – Outras Operações de Saída' },
    {
      code: 50,
      title:
        '50 – Operação com Direito a Crédito – Vinculada Exclusivamente a Receita Tributada no Mercado Interno'
    },
    {
      code: 51,
      title:
        '51 – Operação com Direito a Crédito – Vinculada Exclusivamente a Receita Não-Tributada no Mercado Interno'
    },
    {
      code: 52,
      title:
        '52 – Operação com Direito a Crédito – Vinculada Exclusivamente a Receita de Exportação'
    },
    {
      code: 53,
      title:
        '53 – Operação com Direito a Crédito – Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno'
    },
    {
      code: 54,
      title:
        '54 – Operação com Direito a Crédito – Vinculada a Receitas Tributadas no Mercado Interno e de Exportação'
    },
    {
      code: 55,
      title:
        '55 – Operação com Direito a Crédito – Vinculada a Receitas Não Tributadas Mercado Interno e de Exportação'
    },
    {
      code: 56,
      title:
        '56 – Oper. c/ Direito a Créd. Vinculada a Rec. Tributadas e Não-Tributadas Mercado Interno e de Exportação'
    },
    {
      code: 60,
      title:
        '60 – Crédito Presumido – Oper. de Aquisição Vinculada Exclusivamente a Rec. Tributada no Mercado Interno'
    },
    {
      code: 61,
      title:
        '61 – Créd. Presumido – Oper. de Aquisição Vinculada Exclusivamente a Rec. Não-Tributada Mercado Interno'
    },
    {
      code: 62,
      title:
        '62 – Crédito Presumido – Operação de Aquisição Vinculada Exclusivamente a Receita de Exportação'
    },
    {
      code: 63,
      title:
        '63 – Créd. Presumido – Oper. de Aquisição Vinculada a Rec.Tributadas e Não-Tributadas no Mercado Interno'
    },
    {
      code: 64,
      title:
        '64 – Créd. Presumido – Oper. de Aquisição Vinculada a Rec. Tributadas no Mercado Interno e de Exportação'
    },
    {
      code: 65,
      title:
        '65 – Créd. Presumido – Oper. de Aquisição Vinculada a Rec. Não-Tributadas Mercado Interno e Exportação'
    },
    {
      code: 66,
      title:
        '66 – Créd. Presumido – Oper. de Aquisição Vinculada a Rec. Trib. e Não-Trib. Mercado Interno e Exportação'
    },
    { code: 67, title: '67 – Crédito Presumido – Outras Operações' },
    { code: 70, title: '70 – Operação de Aquisição sem Direito a Crédito' },
    { code: 71, title: '71 – Operação de Aquisição com Isenção' },
    { code: 72, title: '72 – Operação de Aquisição com Suspensão' },
    { code: 73, title: '73 – Operação de Aquisição a Alíquota Zero' },
    {
      code: 74,
      title: '74 – Operação de Aquisição sem Incidência da Contribuição'
    },
    {
      code: 75,
      title: '75 – Operação de Aquisição por Substituição Tributária'
    },
    { code: 98, title: '98 – Outras Operações de Entrada' },
    { code: 99, title: '99 – Outras Operações' }
  ],
  baseTaxModalities: [
    { code: 0, title: '0 - Margem de Valor Agregado (%)' },
    { code: 1, title: '1 - Pauta (Valor)' },
    { code: 2, title: '2 - Preço tabelado Máx. (Valor)' },
    { code: 3, title: '3 - Valor da operação' }
  ],
  cstIpi: [
    { code: null, title: 'Sem Configuração' },
    { code: 50, title: '50 - Saída Tributada' },
    { code: 51, title: '51 - Saída Tributável com Alíquota Zero' },
    { code: 52, title: '52 - Saída Isenta' },
    { code: 53, title: '53 - Saída Não-Tributada' },
    { code: 54, title: '54 - Saída Imune' },
    { code: 55, title: '55 - Saída com Suspensão' },
    { code: 99, title: '99 - Outras Saídas' }
  ]
};

const getDescriptionCstPisCofins = (code) => {
  return taxCodes.cstPisCofins.find((c) => c.code === code).title;
};

const getDescriptionCsosn = (code) => {
  return taxCodes.csosn.find((c) => c.code === code)?.title;
};

const getDescriptionCstIcms = (code) => {
  return taxCodes.cstIcms.find((c) => c.code === code)?.title;
};

const getBaseTaxModalities = (code) => {
  return taxCodes.baseTaxModalities.find((c) => c.code === code)?.title;
};

export {
  taxCodes,
  getDescriptionCstPisCofins,
  getDescriptionCsosn,
  getDescriptionCstIcms,
  getBaseTaxModalities
};
