import { dispatcher, api } from 'react-structure-admin';

export const SUBSCRIPTION = 'SUBSCRIPTION';

export const SUBSCRIPTION_CURRENT_GET = 'SUBSCRIPTION_CURRENT_GET';
export const SUBSCRIPTION_CURRENT_GET_FINISHED =
  'SUBSCRIPTION_CURRENT_GET_FINISHED';

export const SUBSCRIPTION_PAYMENTS = 'SUBSCRIPTION_PAYMENTS';
export const SUBSCRIPTION_PAYMENTS_FINISHED = 'SUBSCRIPTION_PAYMENTS_FINISHED';

export const SUBSCRIPTION_CLOSE = 'SUBSCRIPTION_CLOSE';

export const SUBSCRIPTION_SHOW_PLANS = 'SUBSCRIPTION_SHOW_PLANS';
export const SUBSCRIPTION_CLOSE_PLANS = 'SUBSCRIPTION_CLOSE_PLANS';

export const SUBSCRIPTION_UPDATE = 'SUBSCRIPTION_UPDATE';
export const SUBSCRIPTION_UPDATE_FINISHED = 'SUBSCRIPTION_UPDATE_FINISHED';

export const SUBSCRIPTION_CANCEL = 'SUBSCRIPTION_CANCEL';
export const SUBSCRIPTION_CANCEL_FINISHED = 'SUBSCRIPTION_CANCEL_FINISHED';

export const SUBSCRIPTION_SHOW_CHANGE_PAYMENT_METHOD =
  'SUBSCRIPTION_SHOW_CHANGE_PAYMENT_METHOD';
export const SUBSCRIPTION_CLOSE_CHANGE_PAYMENT_METHOD =
  'SUBSCRIPTION_CLOSE_CHANGE_PAYMENT_METHOD';

export const SUBSCRIPTION_CHANGE_PAYMENT_METHOD =
  'SUBSCRIPTION_CHANGE_PAYMENT_METHOD';
export const SUBSCRIPTION_CHANGE_PAYMENT_METHOD_FINISHED =
  'SUBSCRIPTION_CHANGE_PAYMENT_METHOD_FINISHED';

export const SUBSCRIPTION_CLOSE_REQUEST_CANCELLATION =
  'SUBSCRIPTION_CLOSE_REQUEST_CANCELLATION';

export const SUBSCRIPTION_REQUEST_CANCELLATION =
  'SUBSCRIPTION_REQUEST_CANCELLATION';
export const SUBSCRIPTION_REQUEST_CANCELLATION_FINISHED =
  'SUBSCRIPTION_REQUEST_CANCELLATION_FINISHED';

export const SUBSCRIPTION_REQUEST_CANCELLATION_SETTINGS =
  'SUBSCRIPTION_REQUEST_CANCELLATION_SETTINGS';
export const SUBSCRIPTION_REQUEST_CANCELLATION_SETTINGS_FINISHED =
  'SUBSCRIPTION_REQUEST_CANCELLATION_SETTINGS_FINISHED';

export const SUBSCRIPTION_GET_RESUME = 'SUBSCRIPTION_GET_RESUME';
export const SUBSCRIPTION_GET_RESUME_FINISHED =
  'SUBSCRIPTION_GET_RESUME_FINISHED';

export const SUBSCRIPTION_CLOSE_RESUME = 'SUBSCRIPTION_CLOSE_RESUME';

export const SUBSCRIPTION_OPEN_APPLY_DISCOUNT =
  'SUBSCRIPTION_OPEN_APPLY_DISCOUNT';
export const SUBSCRIPTION_CLOSE_APPLY_DISCOUNT =
  'SUBSCRIPTION_CLOSE_APPLY_DISCOUNT';
export const SUBSCRIPTION_APPLY_DISCOUNT = 'SUBSCRIPTION_APPLY_DISCOUNT';
export const SUBSCRIPTION_APPLY_DISCOUNT_FINISHED =
  'SUBSCRIPTION_APPLY_DISCOUNT_FINISHED';

export const SUBSCRIPTION_OPEN_CHANGE_AVAILABLE_PAYMENT_METHOD =
  'SUBSCRIPTION_OPEN_CHANGE_AVAILABLE_PAYMENT_METHOD';
export const SUBSCRIPTION_CLOSE_CHANGE_AVAILABLE_PAYMENT_METHOD =
  'SUBSCRIPTION_CLOSE_CHANGE_AVAILABLE_PAYMENT_METHOD';

export const SUBSCRIPTION_OPEN_INCLUDE_ADDITIONAL_COMPANIES =
  'SUBSCRIPTION_OPEN_INCLUDE_ADDITIONAL_COMPANIES';
export const SUBSCRIPTION_CLOSE_INCLUDE_ADDITIONAL_COMPANIES =
  'SUBSCRIPTION_CLOSE_INCLUDE_ADDITIONAL_COMPANIES';
export const SUBSCRIPTION_INCLUDE_ADDITIONAL_COMPANIES =
  'SUBSCRIPTION_INCLUDE_ADDITIONAL_COMPANIES';
export const SUBSCRIPTION_INCLUDE_ADDITIONAL_COMPANIES_FINISHED =
  'SUBSCRIPTION_INCLUDE_ADDITIONAL_COMPANIES_FINISHED';

export const SUBSCRIPTION_REACTIVATE = 'SUBSCRIPTION_REACTIVATE';
export const SUBSCRIPTION_REACTIVATE_FINISHED =
  'SUBSCRIPTION_REACTIVATE_FINISHED';

export const SUBSCRIPTION_OPEN_UPDATE = 'SUBSCRIPTION_OPEN_UPDATE';
export const SUBSCRIPTION_CLOSE_UPDATE = 'SUBSCRIPTION_CLOSE_UPDATE';
export const SUBSCRIPTION_UPDATE_PLAN = 'SUBSCRIPTION_UPDATE_PLAN';
export const SUBSCRIPTION_UPDATE_PLAN_FINISHED =
  'SUBSCRIPTION_UPDATE_PLAN_FINISHED';

export const SUBSCRIPTION_GET_CHECKOUT = 'SUBSCRIPTION_GET_CHECKOUT';
export const SUBSCRIPTION_GET_CHECKOUT_FINISHED =
  'SUBSCRIPTION_GET_CHECKOUT_FINISHED';
export const SUBSCRIPTION_POST_CHECKOUT = 'SUBSCRIPTION_POST_CHECKOUT';
export const SUBSCRIPTION_POST_CHECKOUT_FINISHED =
  'SUBSCRIPTION_POST_CHECKOUT_FINISHED';

export const getActiveSubscription = (tenant, onSuccess) => async (
  dispatch
) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'subscriptions',
    actionType: SUBSCRIPTION_CURRENT_GET,
    effect: async () =>
      api.fetch('subscriptions/current-subscription', {}, {}, tenant),
    onSuccess
  });
};

export const close = () => (dispatch) => {
  dispatch(dispatcher.createAction(SUBSCRIPTION_CLOSE, 'subscriptions'));
};

export const changeSubscriptionPlan = () => (dispatch) => {
  dispatch(dispatcher.createAction(SUBSCRIPTION_SHOW_PLANS, 'subscriptions'));
};

export const showSubscriptionPaymentMethod = () => (dispatch) => {
  dispatch(
    dispatcher.createAction(
      SUBSCRIPTION_SHOW_CHANGE_PAYMENT_METHOD,
      'subscriptions'
    )
  );
};

export const closeSubscriptionPaymentMethod = () => (dispatch) => {
  dispatch(
    dispatcher.createAction(
      SUBSCRIPTION_CLOSE_CHANGE_PAYMENT_METHOD,
      'subscriptions'
    )
  );
};

export const changeSubscriptionPaymentMethod = (
  id,
  params,
  onSuccess
) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'subscriptions',
    actionType: SUBSCRIPTION_CHANGE_PAYMENT_METHOD,
    effect: async () => api.put(`subscriptions/${id}/payment-method`, params),
    onSuccess
  });
};

export const closeChangeSubscriptionPlan = () => (dispatch) => {
  dispatch(dispatcher.createAction(SUBSCRIPTION_CLOSE_PLANS, 'subscriptions'));
};

export const getPayments = (id) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'subscriptions',
    actionType: SUBSCRIPTION_PAYMENTS,
    effect: async () => api.fetch(`subscriptions/${id}/payments`)
  });
};

export const updateSubscription = (
  subscriptionId,
  payload = {},
  onSuccess
) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'subscriptions',
    actionType: SUBSCRIPTION_UPDATE,
    payload,
    effect: async () =>
      api.put(`subscriptions/${subscriptionId}/plan`, payload),
    onSuccess
  });
};

export const cancelSubscriptionModal = () => (dispatch) => {
  dispatch(dispatcher.createAction(SUBSCRIPTION_CANCEL_MODAL, 'subscriptions'));
};

export const cancelSubscriptionCloseModal = () => (dispatch) => {
  dispatch(
    dispatcher.createAction(SUBSCRIPTION_CANCEL_CLOSE_MODAL, 'subscriptions')
  );
};

export const cancelSubscription = (id) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'subscriptions',
    actionType: SUBSCRIPTION_CANCEL,
    effect: async () => api.del('subscriptions', id)
  });
};

export const closeRequestCancellation = () => async (dispatch) => {
  return dispatch(
    dispatcher.createAction(
      SUBSCRIPTION_CLOSE_REQUEST_CANCELLATION,
      'subscriptions'
    )
  );
};

export const requestCancellation = (data, onSuccess) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'subscriptions',
    actionType: SUBSCRIPTION_REQUEST_CANCELLATION,
    effect: async () => api.post('subscriptions/request-cancellation', data),
    onSuccess
  });
};

export const openRequestCancellation = (tenantId, onSuccess) => async (
  dispatch
) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'subscriptions',
    actionType: SUBSCRIPTION_REQUEST_CANCELLATION_SETTINGS,
    effect: async () =>
      api.get('subscriptions/cancellation-settings', `?tenantId=${tenantId}`),
    onSuccess
  });
};

export const getResume = (
  { planId, couponCode, federalTaxNumber },
  onSuccess
) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'subscriptions',
    actionType: SUBSCRIPTION_GET_RESUME,
    payload: planId,
    effect: async () =>
      api.fetch('subscriptions/resume', {
        planId,
        couponCode,
        federalTaxNumber
      }),
    onSuccess
  });
};

export const closeResume = () => (dispatch) => {
  dispatch(dispatcher.createAction(SUBSCRIPTION_CLOSE_RESUME, 'subscriptions'));
};

export const openApplyDiscount = () => (dispatch) => {
  dispatch(
    dispatcher.createAction(SUBSCRIPTION_OPEN_APPLY_DISCOUNT, 'subscriptions')
  );
};

export const closeApplyDiscount = () => (dispatch) => {
  dispatch(
    dispatcher.createAction(SUBSCRIPTION_CLOSE_APPLY_DISCOUNT, 'subscriptions')
  );
};

export const applyDiscount = (data) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'subscriptions',
    actionType: SUBSCRIPTION_APPLY_DISCOUNT,
    effect: async () => api.post('subscriptions/apply-discount', data)
  });
};

export const openSubscriptionAvailablePaymentMethod = () => (dispatch) => {
  dispatch(
    dispatcher.createAction(
      SUBSCRIPTION_OPEN_CHANGE_AVAILABLE_PAYMENT_METHOD,
      'subscriptions'
    )
  );
};

export const closeSubscriptionAvailablePaymentMethod = () => (dispatch) => {
  dispatch(
    dispatcher.createAction(
      SUBSCRIPTION_CLOSE_CHANGE_AVAILABLE_PAYMENT_METHOD,
      'subscriptions'
    )
  );
};

export const openIncludeAdditionalCompanies = () => (dispatch) => {
  dispatch(
    dispatcher.createAction(
      SUBSCRIPTION_OPEN_INCLUDE_ADDITIONAL_COMPANIES,
      'subscriptions'
    )
  );
};

export const closeIncludeAdditionalCompanies = () => (dispatch) => {
  dispatch(
    dispatcher.createAction(
      SUBSCRIPTION_CLOSE_INCLUDE_ADDITIONAL_COMPANIES,
      'subscriptions'
    )
  );
};

export const includeAdditionalCompanies = (data) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'subscriptions',
    actionType: SUBSCRIPTION_INCLUDE_ADDITIONAL_COMPANIES,
    effect: async () =>
      api.post('subscriptions/include-additional-companies', data)
  });
};

export const reactivateSubscription = (id) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'subscriptions',
    actionType: SUBSCRIPTION_REACTIVATE,
    effect: async () => api.post(`subscriptions/${id}/reactivate`)
  });
};

export const openChangePlan = () => (dispatch) => {
  dispatch(dispatcher.createAction(SUBSCRIPTION_OPEN_UPDATE, 'subscriptions'));
};

export const closeChangePlan = () => (dispatch) => {
  dispatch(dispatcher.createAction(SUBSCRIPTION_CLOSE_UPDATE, 'subscriptions'));
};

export const changePlan = (id, data) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'subscriptions',
    actionType: SUBSCRIPTION_UPDATE_PLAN,
    effect: async () => api.put(`subscriptions/${id}`, data)
  });
};

export const getCheckout = (paymentId, onSuccess) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'subscriptions',
    actionType: SUBSCRIPTION_GET_CHECKOUT,
    effect: async () => api.fetch(`subscriptions/${paymentId}/payment`),
    onSuccess
  });
};

export const postCheckout = (paymentId, params, onSuccess) => async (
  dispatch
) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'subscriptions',
    actionType: SUBSCRIPTION_POST_CHECKOUT,
    effect: async () => api.post(`subscriptions/${paymentId}/payment`, params),
    onSuccess
  });
};
