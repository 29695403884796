import React, { forwardRef } from 'react';
import { Input } from 'antd';
import ReactInputMask from 'react-input-mask';

const InputMask = (props, ref) => {
  const { disabled } = props;

  return (
    <ReactInputMask {...props}>
      {(inputProps) => (
        <Input {...inputProps} ref={ref} disabled={disabled ? true : null} />
      )}
    </ReactInputMask>
  );
};

export default forwardRef(InputMask);
