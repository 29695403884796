import {
  updateResourceState,
  createResourceReducer,
  normalizeResourceState,
  RESOURCE
} from 'react-structure-admin';
import {
  NOTIFICATION,
  NOTIFICATION_FETCH,
  NOTIFICATION_FETCH_FINISHED
} from './notificationActions';

const notificationReducer = createResourceReducer(
  NOTIFICATION,
  [],
  {
    [NOTIFICATION_FETCH](state, action) {
      const newState = normalizeResourceState(state, action.resource);
      return updateResourceState(newState, action, RESOURCE, () => ({
        fetching: true
      }));
    },

    [NOTIFICATION_FETCH_FINISHED](state, action) {
      return updateResourceState(state, action, RESOURCE, (resource) => {
        const { items = [] } = resource;

        const current = Number(action.payload.config.params.page) || 1;

        return {
          ...resource,
          pagination: {
            ...resource.pagination,
            total: action.payload.data.result.totalCount,
            pageCount: action.payload.data.result.pageCount,
            current
          },
          totalCount: action.payload.data.result.totalCount,
          items:
            current === 1
              ? action.payload.data.result.items
              : [...items, ...action.payload.data.result.items],
          queryParams: action.payload.config.params,
          loaded: true,
          fetching: false
        };
      });
    }
  },

  (state, action) => {
    return updateResourceState(state, action, RESOURCE, () => ({
      loading: false,
      fetching: false
    }));
  }
);

export default notificationReducer;
