import React, { useEffect, useState } from 'react';
import {
  DrawerEdit,
  FormItemWrap,
  useCrudEditContext
} from 'react-structure-admin';
import { Input, Form, Button, Table, Row, Col, Divider, Radio } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ActionsList } from 'components';
import TaxationGroupRuleEdit from './TaxationGroupRuleEdit';
import TaxationGroupListItem from './TaxationGroupListItem';

const TaxationGroupEdit = (props) => {
  const [rule, setRule] = useState(null);
  const { form } = useCrudEditContext();

  const [hasItems, setHasItems] = useState([]);

  const handleEditRule = (initialValue = {}) => {
    const { type } = form.getFieldsValue();

    setRule({ ...initialValue, type });
  };

  const handleRemoveRule = (_, index) => {
    const { rules = [] } = form.getFieldsValue();
    const newRules = rules.filter((_, elementIndex) => index !== elementIndex);

    setHasItems(newRules?.length > 0);
    form.setFieldsValue({ rules: newRules });
  };

  const handleCloseRuleEdit = () => {
    setRule(null);
  };

  const handleBeforeBinding = ({ type, ...rest }) => {
    if (!type) {
      type = 'productInvoice';
    }

    return {
      type,
      ...rest
    };
  };

  const handleSubmit = (values) => {
    return values;
  };

  useEffect(() => {
    const { rules = [] } = form.getFieldsValue();
    setHasItems(rules?.length > 0);
  }, [rule]);

  const allowEditType = props.data?.type
    ? !props.data?.type
    : !props.data?.id && !hasItems;

  return (
    <DrawerEdit
      {...props}
      size="md"
      onBeforeBinding={handleBeforeBinding}
      onSubmit={handleSubmit}
    >
      <Form layout="vertical">
        <Row>
          <Col span={18}>
            <FormItemWrap name="name" label="Nome" whitespace required>
              <Input maxLength={60} />
            </FormItemWrap>
          </Col>
          <Col span={6}>
            <FormItemWrap name="type" label="Modelo" required>
              <Radio.Group disabled={!allowEditType} buttonStyle="solid">
                <Radio.Button
                  style={{ marginBottom: 0 }}
                  value="productInvoice"
                >
                  NF-e
                </Radio.Button>
                <Radio.Button
                  style={{ marginBottom: 0 }}
                  value="serviceInvoice"
                >
                  NFS-e
                </Radio.Button>
              </Radio.Group>
            </FormItemWrap>
          </Col>
        </Row>
        <Row justify="end">
          <Col>
            <Button onClick={() => handleEditRule({})} icon={<PlusOutlined />}>
              Adicionar regra
            </Button>
          </Col>
        </Row>
        <Divider className="gx-mt-0" orientation="left">
          Regras
        </Divider>
        <Form.Item name="rules" valuePropName="dataSource">
          <Table pagination={false} showHeader={false}>
            <Table.Column
              dataIndex="states"
              render={(_, values) => <TaxationGroupListItem {...values} />}
            />
            <Table.Column
              render={(_, record, index) => (
                <ActionsList
                  record={record}
                  initEditing={handleEditRule}
                  removeRecord={() => handleRemoveRule(record, index)}
                />
              )}
            />
          </Table>
        </Form.Item>
        {rule ? (
          <TaxationGroupRuleEdit
            editingRule={rule}
            onClose={handleCloseRuleEdit}
          />
        ) : null}
      </Form>
    </DrawerEdit>
  );
};

export default TaxationGroupEdit;
