import { createResourceReducer, RECORD_FIELD } from 'react-structure-admin';

import {
  ONBOARDING_SHOW,
  ONBOARDING_FINISH,
  ONBOARDING_SET_VALUES,
  ONBOARDING_SET_LOADING,
  ONBOARDING_SET_STEP,
  ONBOARDING_UNLOAD
} from './onboardingActions';

const initialState = {
  currentStep: 0,
  show: false
};

const onboardingReducer = (state = initialState, action) => {
  switch (action.type) {
    case ONBOARDING_SHOW:
      return {
        ...state,
        visible: true
      };

    case ONBOARDING_UNLOAD:
      return {};

    case ONBOARDING_SET_LOADING:
      return {
        ...state,
        loading: action.payload
      };

    case ONBOARDING_SET_VALUES:
      if (!state[RECORD_FIELD]) {
        state[RECORD_FIELD] = {};
      }

      return {
        ...state,
        [RECORD_FIELD]: {
          ...state[RECORD_FIELD],
          ...action.payload,
          loading: false
        }
      };

    case ONBOARDING_SET_STEP:
      return {
        ...state,
        currentStep: action.payload.step
      };

    case ONBOARDING_FINISH:
      return {
        ...state,
        visible: false
      };
    default:
      return state;
  }
};

export default onboardingReducer;
