import React from 'react';
import { Col, Form } from 'antd';

const ResCol = ({
  className,
  span = 24,
  xs = 24,
  sm = 24,
  md,
  lg,
  xl,
  xxl,
  children
}) => {
  if (!xs) {
    xs = 24;
  }

  if (!sm) {
    sm = 24;
  }

  if (!md) {
    md = span;
  }

  if (!lg) {
    lg = span;
  }

  return (
    <Col className={className} span={span} xs={xs} sm={sm} md={md} lg={lg}>
      {children}
    </Col>
  );
};

export default ResCol;
