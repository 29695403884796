import React from 'react';
import { Select } from 'antd';

const SelectSubscriptionFrequency = ({ ...rest }) => {
  return (
    <Select {...rest}>
      <Select.Option value="monthly">Mensal</Select.Option>
      <Select.Option value="yearly">Anual</Select.Option>
    </Select>
  );
};

export default SelectSubscriptionFrequency;
