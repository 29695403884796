import { message } from 'antd';

const useClipboard = () => {
  const copy = (text, customMessage) => {
    navigator.clipboard.writeText(text);

    message.success(
      typeof customMessage === 'string'
        ? customMessage
        : 'Copiado para área de transferência'
    );
  };

  return { copy };
};

export default useClipboard;
