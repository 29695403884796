import React from 'react';
import { Select } from 'antd';

const SelectProvisionSupportMechanism = ({ ...rest }) => {
  return (
    <Select {...rest}>
      <Select.Option value="00">
        Desconhecido (tipo não informado na nota de origem)
      </Select.Option>
      <Select.Option value="01">Nenhum</Select.Option>
      <Select.Option value="02">
        ACC - Adiantamento sobre Contrato de Câmbio – Redução a Zero do IR e do
        IOF
      </Select.Option>
      <Select.Option value="03">
        ACE – Adiantamento sobre Cambiais Entregues - Redução a Zero do IR e do
        IOF
      </Select.Option>
      <Select.Option value="04">
        BNDES-Exim Pós-Embarque – Serviços
      </Select.Option>
      <Select.Option value="05">
        BNDES-Exim Pré-Embarque - Serviços
      </Select.Option>
      <Select.Option value="06">
        FGE - Fundo de Garantia à Exportação
      </Select.Option>
      <Select.Option value="07">PROEX - EQUALIZAÇÃO</Select.Option>
      <Select.Option value="08">PROEX - Financiamento</Select.Option>
    </Select>
  );
};

export default SelectProvisionSupportMechanism;
