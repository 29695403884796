import React from 'react';
import { Tooltip } from 'antd';
import {
  platformDefaultIcons,
  platformAltIcons,
  getPlatformLabel
} from 'constants/platforms';

const PlatformImageTooltip = ({ children, platform, showTooltip }) => {
  if (!showTooltip) {
    return children;
  }
  return <Tooltip title={getPlatformLabel(platform)}>{children}</Tooltip>;
};

const PlatformImage = ({
  className,
  platform,
  type = 'default',
  showTooltip = true
}) => {
  const icons = type === 'default' ? platformDefaultIcons : platformAltIcons;
  return icons[platform] ? (
    <PlatformImageTooltip platform={platform} showTooltip={showTooltip}>
      <img
        className={className}
        src={icons[platform]?.default}
        alt={platform}
      />
    </PlatformImageTooltip>
  ) : null;
};

export default PlatformImage;
