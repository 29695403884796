import {
  updateResourceState,
  createResourceReducer,
  RESOURCE,
  RECORD_FIELD
} from 'react-structure-admin';
import {
  COMPANY,
  COMPANY_CANCEL,
  COMPANY_GET,
  COMPANY_GET_FINISHED,
  COMPANY_GET_SETTINGS,
  COMPANY_GET_SETTINGS_FINISHED,
  COMPANY_GET_SETUP_STEPS,
  COMPANY_GET_SETUP_STEPS_FINISHED,
  COMPANY_UPDATE,
  COMPANY_UPDATE_FINISHED,
  COMPANY_SETTINGS_UPDATE,
  COMPANY_SETTINGS_UPDATE_FINISHED,
  COMPANY_UPLOAD_CERTIFICATE,
  COMPANY_UPLOAD_CERTIFICATE_ERROR,
  COMPANY_UPLOAD_CERTIFICATE_FINISHED,
  COMPANY_CLOSE_SETTINGS,
  COMPANY_GET_TENANT,
  COMPANY_GET_TENANT_FINISHED,
  COMPANY_OPEN_CREATE,
  COMPANY_CLOSE_CREATE,
  COMPANY_CREATE,
  COMPANY_CREATE_FINISHED,
  COMPANY_REMOVE_FINISHED,
  COMPANY_OPEN_API_KEY,
  COMPANY_CLOSE_API_KEY,
  COMPANY_GENERATE_API_KEY,
  COMPANY_GENERATE_API_KEY_FINISHED
} from './companyActions';

const companyReducer = createResourceReducer(
  COMPANY,
  [],
  {
    [COMPANY_GET](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        loading: true,
        tab: action.payload.tab
      }));
    },

    [COMPANY_GET_FINISHED](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        [RECORD_FIELD]: action.payload.data.result,
        loading: false,
        visible: true
      }));
    },

    [COMPANY_GET_SETTINGS](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        editingSettings: null,
        loading: true
      }));
    },

    [COMPANY_GET_SETTINGS_FINISHED](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        editingSettings: action.payload.data.result,
        loading: false,
        visible: true
      }));
    },

    [COMPANY_UPDATE](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        [RECORD_FIELD]: action.payload,
        saving: true
      }));
    },

    [COMPANY_UPDATE_FINISHED](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        [RECORD_FIELD]: null,
        saving: false,
        visible: false,
        tab: null
      }));
    },

    [COMPANY_SETTINGS_UPDATE](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        editingSettings: action.payload,
        loading: true,
        saving: true
      }));
    },

    [COMPANY_CLOSE_SETTINGS](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        editingSettings: null,
        loading: false,
        saving: false
      }));
    },

    [COMPANY_SETTINGS_UPDATE_FINISHED](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        editingSettings: null,
        loading: false,
        saving: false
      }));
    },

    [COMPANY_CANCEL](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        [RECORD_FIELD]: null,
        loading: false,
        saving: false,
        visible: false
      }));
    },

    [COMPANY_UPLOAD_CERTIFICATE](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        uploading: true
      }));
    },

    [COMPANY_UPLOAD_CERTIFICATE_FINISHED](state, action) {
      return updateResourceState(state, action, RESOURCE, (resource) => ({
        [RECORD_FIELD]: {
          ...resource[RECORD_FIELD],
          certificate: action.payload.data.result
        },
        uploading: false
      }));
    },

    [COMPANY_UPLOAD_CERTIFICATE_ERROR](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        uploading: false
      }));
    },

    [COMPANY_GET_SETUP_STEPS](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        loading: true
      }));
    },

    [COMPANY_GET_SETUP_STEPS_FINISHED](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        setupSteps: action.payload.data.result,
        loading: false
      }));
    },

    [COMPANY_GET_TENANT](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        companies: null,
        loading: true
      }));
    },

    [COMPANY_GET_TENANT_FINISHED](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        companies: action.payload.data.result.items,
        loading: false
      }));
    },

    [COMPANY_OPEN_CREATE](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        activeModal: COMPANY_OPEN_CREATE
      }));
    },

    [COMPANY_CLOSE_CREATE](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        activeModal: null
      }));
    },

    [COMPANY_CREATE](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        creating: true
      }));
    },

    [COMPANY_CREATE_FINISHED](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        activeModal: null,
        creating: false,
        loaded: false
      }));
    },

    [COMPANY_REMOVE_FINISHED](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        loaded: false
      }));
    },

    [COMPANY_OPEN_API_KEY](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        company: action.payload,
        activeModal: COMPANY_OPEN_API_KEY
      }));
    },

    [COMPANY_CLOSE_API_KEY](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        activeModal: null
      }));
    },

    [COMPANY_GENERATE_API_KEY](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        loading: true
      }));
    },

    [COMPANY_GENERATE_API_KEY_FINISHED](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        loading: false
      }));
    }
  },

  (state, action) => {
    return updateResourceState(state, action, RESOURCE, () => ({
      loading: false,
      creating: false
    }));
  }
);

export default companyReducer;
