import React from 'react';
import { Tag } from 'antd';
import { display } from 'constants/types';

const colors = {
  activated: 'green ',
  canceled: 'grey',
  waitingForActivation: 'orange'
};

const AccountStatus = ({ status }) => {
  return <Tag color={colors[status]}>{display[status]}</Tag>;
};

export default AccountStatus;
