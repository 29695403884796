import React, { useState } from 'react';
import PlanCard from './PlanCard';

const ListCardPlan = ({
  plans,
  frequency = 'monthly',
  onSubscribeClick,
  onCardClick,
  loadingPlanId,
  selectedPlan,
  currentPlan
}) => {
  const handleSubscribeClick = (plan) => {
    onSubscribeClick?.(plan);
  };

  return (
    <>
      {plans
        .filter((c) => c.frequency === frequency)
        .map((plan) => (
          <PlanCard
            key={plan.id}
            plan={plan}
            onCardClick={onCardClick}
            onSubscribeClick={handleSubscribeClick}
            loading={loadingPlanId === plan?.id}
            disabled={
              (loadingPlanId && selectedPlan && selectedPlan?.id !== plan.id) ||
              (currentPlan?.amount > 0 && plan?.amount === 0)
            }
            isCurrent={currentPlan && currentPlan?.id === plan.id}
          />
        ))}
    </>
  );
};

export default ListCardPlan;
