const currencies = {
  BRL: 'pt-BR',
  USD: 'en-US'
};

function formatCurrency(value, fractionDigits = 2, currency = 'BRL') {
  let valueInt = Number(value);
  if (Number.isNaN(valueInt)) {
    valueInt = 0;
  }
  if (!(typeof fractionDigits === 'number')) {
    fractionDigits = 2;
  }

  const formattedValue = valueInt.toLocaleString(currencies[currency], {
    style: 'currency',
    currency,
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits
  });

  return formattedValue;
}

export default formatCurrency;
