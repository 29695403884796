import React, { useEffect } from 'react';
import {
  useCrudEditContext,
  configManager,
  RESOURCE_ACTION_EDIT,
  RESOURCE_ACTION_CREATE
} from 'react-structure-admin';

import ModalContainer from './ModalContainer';

const ModalEdit = ({
  children,
  cancelEdit,
  submit,
  update,
  handleOk,
  action,
  data,
  resource,
  onSubmit,
  onBeforeBinding,
  onDataChanged,
  updateFormOnDataChanged,
  beforeSubmit,
  ...rest
}) => {
  const { form } = useCrudEditContext();

  const handleSubmit = () => {
    if (!action) {
      action = data.id ? RESOURCE_ACTION_EDIT : RESOURCE_ACTION_CREATE;
    }

    form.validateFields().then((values) => {
      let dataToSend = { ...data, ...values };

      if (beforeSubmit) {
        dataToSend = beforeSubmit(dataToSend);
      }

      if (onSubmit) {
        dataToSend = onSubmit(dataToSend);
        if (!dataToSend) {
          return;
        }
      }

      if (action === RESOURCE_ACTION_EDIT) {
        update(data.id, dataToSend);
      } else {
        handleOk(dataToSend);
      }
    });
  };

  useEffect(() => {
    const dataChanged = onBeforeBinding ? onBeforeBinding(data) : data;
    if (updateFormOnDataChanged) {
      form.setFieldsValue(dataChanged);
    }
    if (onDataChanged) {
      onDataChanged(dataChanged);
    }
  }, [updateFormOnDataChanged, data]);

  return (
    <ModalContainer
      resource={resource}
      onOkClick={handleSubmit}
      onBackClick={cancelEdit}
      okButtonText="Salvar"
      backButtonText="Cancelar"
      {...rest}
    >
      {React.cloneElement(children, {
        form,
        initialValues: !updateFormOnDataChanged
          ? onBeforeBinding
            ? onBeforeBinding(data)
            : data
          : undefined,
        ...configManager.getConfig().layout.form
      })}
    </ModalContainer>
  );
};

export default ModalEdit;
