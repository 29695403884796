import React from 'react';
import { Button, Input } from 'antd';

const InputCoupon = ({ value, onChange, onSubmit, loading }) => {
  return (
    <Input.Group compact>
      <Input
        placeholder="Insira o código do cupom"
        style={{ width: 'calc(100% - 180px)' }}
        value={value}
        onChange={onChange}
        disabled={loading}
      />
      <Button
        type="primary"
        style={{ height: '36px' }}
        onClick={onSubmit}
        loading={loading}
      >
        Aplicar
      </Button>
    </Input.Group>
  );
};

export default InputCoupon;
