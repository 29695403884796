import formartOnlyNumber from './formartOnlyNumber';

const formatFederalTaxNumber = (federalTaxNumber) => {
  if (!federalTaxNumber) {
    return '';
  }

  const onlyNumber = formartOnlyNumber(federalTaxNumber);
  
  if (!onlyNumber) {
    return '';
  }

  const hasCaracter = () => {
    const regex = /[a-zA-Z]/;
    return regex.test(federalTaxNumber);
  };

  if (onlyNumber.length === 14 && !hasCaracter()) {
    return federalTaxNumber.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
      '$1.$2.$3/$4-$5'
    );
  }

  if (onlyNumber.length === 11 && !hasCaracter()) {
    return federalTaxNumber.replace(
      /(\d{3})(\d{3})(\d{3})(\d{2})/g,
      '$1.$2.$3-$4'
    );
  }

  return federalTaxNumber;
};

export default formatFederalTaxNumber;
