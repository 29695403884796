import React from 'react';
import { Select } from 'antd';

const CfopSelect = (props) => {
  return (
    <Select showSearch {...props}>
      <Select.Option value="" />
      <Select.Option value="5101">
        5101 - Venda de produção do estabelecimento
      </Select.Option>
      <Select.Option value="6101">
        6101 - Venda de produção do estabelecimento
      </Select.Option>
      <Select.Option value="5949">
        5949 - Outra saída de mercadoria ou prestação de serviço não
        especificado
      </Select.Option>
      <Select.Option value="6949">
        6949 - Outra saída de mercadoria ou prestação de serviço não
        especificado
      </Select.Option>
      <Select.Option value="1101">
        1101 - Compra p/ industrialização ou produção rural
      </Select.Option>
      <Select.Option value="1102">
        1102 - Compra p/ comercialização
      </Select.Option>
      <Select.Option value="1111">
        1111 - Compra p/ industrialização de mercadoria recebida anteriormente
        em consignação industrial
      </Select.Option>
      <Select.Option value="1113">
        1113 - Compra p/ comercialização, de mercadoria recebida anteriormente
        em consignação mercantil
      </Select.Option>
      <Select.Option value="1116">
        1116 - Compra p/ industrialização ou produção rural originada de
        encomenda p/ recebimento futuro
      </Select.Option>
      <Select.Option value="1117">
        1117 - Compra p/ comercialização originada de encomenda p/ recebimento
        futuro
      </Select.Option>
      <Select.Option value="1118">
        1118 - Compra de mercadoria p/ comercialização pe...dor remetente ao
        destinatário, em venda à ordem.
      </Select.Option>
      <Select.Option value="1120">
        1120 - Compra p/ industrialização, em venda à ordem, já recebida do
        vendedor remetente
      </Select.Option>
      <Select.Option value="1121">
        1121 - Compra p/ comercialização, em venda à ordem, já recebida do
        vendedor remetente
      </Select.Option>
      <Select.Option value="1122">
        1122 - Compra p/ industrialização em que a mercad...or sem transitar
        pelo estabelecimento adquirente
      </Select.Option>
      <Select.Option value="1124">
        1124 - Industrialização efetuada por outra empresa
      </Select.Option>
      <Select.Option value="1125">
        1125 - Industrialização efetuada por outra empres...ou pelo
        estabelecimento adquirente da mercadoria
      </Select.Option>
      <Select.Option value="1126">
        1126 - Compra p/ utilização na prestação de serviço sujeita ao ICMS
      </Select.Option>
      <Select.Option value="1128">
        1128 - Compra p/ utilização na prestação de serviço sujeita ao ISSQN
      </Select.Option>
      <Select.Option value="1131">
        1131 - Entrada de mercadoria com previsão de post...reço, decorrente de
        operação de ato cooperativo.
      </Select.Option>
      <Select.Option value="1132">
        1132 - Fixação de preço de produção do estabelecimento produtor
      </Select.Option>
      <Select.Option value="1135">
        1135 - Fixação de preço de produção do estabelecimento produtor
      </Select.Option>
      <Select.Option value="1151">
        1151 - Transferência p/ industrialização ou produção rural
      </Select.Option>
      <Select.Option value="1152">
        1152 - Transferência p/ comercialização
      </Select.Option>
      <Select.Option value="1153">
        1153 - Transferência de energia elétrica p/ distribuição
      </Select.Option>
      <Select.Option value="1154">
        1154 - Transferência p/ utilização na prestação de serviço
      </Select.Option>
      <Select.Option value="1159">
        1159 - Entrada decorrente do fornecimento de produto ou mercadoria de
        ato cooperativo.
      </Select.Option>
      <Select.Option value="1201">
        1201 - Devolução de venda de produção do estabelecimento
      </Select.Option>
      <Select.Option value="1202">
        1202 - Devolução de venda de mercadoria adquirida ou recebida de
        terceiros
      </Select.Option>
      <Select.Option value="1203">
        1203 - Devolução de venda de produção do estabelecimento, destinada à
        ZFM ou ALC
      </Select.Option>
      <Select.Option value="1204">
        1204 - Devolução de venda de mercadoria adquirida ou recebida de
        terceiros, destinada à ZFM ou ALC
      </Select.Option>
      <Select.Option value="1205">
        1205 - Anulação de valor relativo à prestação de serviço de comunicação
      </Select.Option>
      <Select.Option value="1206">
        1206 - Anulação de valor relativo à prestação de serviço de transporte
      </Select.Option>
      <Select.Option value="1207">
        1207 - Anulação de valor relativo à venda de energia elétrica
      </Select.Option>
      <Select.Option value="1208">
        1208 - Devolução de produção do estabelecimento, remetida em
        transferência
      </Select.Option>
      <Select.Option value="1209">
        1209 - Devolução de mercadoria adquirida ou recebida de terceiros,
        remetida em transferência
      </Select.Option>
      <Select.Option value="1212">
        1212 - Devolução de venda no mercado interno de m...o Especial de
        Entreposto Industrial (Recof-Sped)
      </Select.Option>
      <Select.Option value="1213">
        1213 - Devolução de remessa de produção do estabe...previsão de
        posterior ajuste ou fixação de preço
      </Select.Option>
      <Select.Option value="1214">
        1214 - Devolução de fixação de preço de produção do estabelecimento
        produtor
      </Select.Option>
      <Select.Option value="1215">
        1215 - Devolução de fornecimento de produção do estabelecimento de ato
        cooperativo
      </Select.Option>
      <Select.Option value="1216">
        1216 - Devolução de fornecimento de mercadoria adquirida ou recebida de
        terceiros de ato cooperativo
      </Select.Option>
      <Select.Option value="1251">
        1251 - Compra de energia elétrica p/ distribuição ou comercialização
      </Select.Option>
      <Select.Option value="1252">
        1252 - Compra de energia elétrica por estabelecimento industrial
      </Select.Option>
      <Select.Option value="1253">
        1253 - Compra de energia elétrica por estabelecimento comercial
      </Select.Option>
      <Select.Option value="1254">
        1254 - Compra de energia elétrica por estabelecimento prestador de
        serviço de transporte
      </Select.Option>
      <Select.Option value="1255">
        1255 - Compra de energia elétrica por estabelecimento prestador de
        serviço de comunicação
      </Select.Option>
      <Select.Option value="1256">
        1256 - Compra de energia elétrica por estabelecimento de produtor rural
      </Select.Option>
      <Select.Option value="1257">
        1257 - Compra de energia elétrica p/ consumo por demanda contratada
      </Select.Option>
      <Select.Option value="1301">
        1301 - Aquisição de serviço de comunicação p/ execução de serviço da
        mesma natureza
      </Select.Option>
      <Select.Option value="1302">
        1302 - Aquisição de serviço de comunicação por estabelecimento
        industrial
      </Select.Option>
      <Select.Option value="1303">
        1303 - Aquisição de serviço de comunicação por estabelecimento comercial
      </Select.Option>
      <Select.Option value="1304">
        1304 - Aquisição de serviço de comunicação por estabelecimento de
        prestador de serviço de transporte
      </Select.Option>
      <Select.Option value="1305">
        1305 - Aquisição de serviço de comunicação por es...geradora ou de
        distribuidora de energia elétrica
      </Select.Option>
      <Select.Option value="1306">
        1306 - Aquisição de serviço de comunicação por estabelecimento de
        produtor rural
      </Select.Option>
      <Select.Option value="1351">
        1351 - Aquisição de serviço de transporte p/ execução de serviço da
        mesma natureza
      </Select.Option>
      <Select.Option value="1352">
        1352 - Aquisição de serviço de transporte por estabelecimento industrial
      </Select.Option>
      <Select.Option value="1353">
        1353 - Aquisição de serviço de transporte por estabelecimento comercial
      </Select.Option>
      <Select.Option value="1354">
        1354 - Aquisição de serviço de transporte por estabelecimento de
        prestador de serviço de comunicação
      </Select.Option>
      <Select.Option value="1355">
        1355 - Aquisição de serviço de transporte por est...geradora ou de
        distribuidora de energia elétrica
      </Select.Option>
      <Select.Option value="1356">
        1356 - Aquisição de serviço de transporte por estabelecimento de
        produtor rural
      </Select.Option>
      <Select.Option value="1360">
        1360 - Aquisição de serviço de transporte por con...e-substituto em
        relação ao serviço de transporte
      </Select.Option>
      <Select.Option value="1401">
        1401 - Compra p/ industrialização ou produção rural de mercadoria
        sujeita a ST
      </Select.Option>
      <Select.Option value="1403">
        1403 - Compra p/ comercialização em operação com mercadoria sujeita a ST
      </Select.Option>
      <Select.Option value="1406">
        1406 - Compra de bem p/ o ativo imobilizado cuja mercadoria está sujeita
        a ST
      </Select.Option>
      <Select.Option value="1407">
        1407 - Compra de mercadoria p/ uso ou consumo cuja mercadoria está
        sujeita a ST
      </Select.Option>
      <Select.Option value="1408">
        1408 - Transferência p/ industrialização ou produção rural de mercadoria
        sujeita a ST
      </Select.Option>
      <Select.Option value="1409">
        1409 - Transferência p/ comercialização em operação com mercadoria
        sujeita a ST
      </Select.Option>
      <Select.Option value="1410">
        1410 - Devolução de venda de mercadoria, de produção do estabelecimento,
        sujeita a ST
      </Select.Option>
      <Select.Option value="1411">
        1411 - Devolução de venda de mercadoria adquirida...erceiros em operação
        com mercadoria sujeita a ST
      </Select.Option>
      <Select.Option value="1414">
        1414 - Retorno de mercadoria de produção do estab...a p/ venda fora do
        estabelecimento, sujeita a ST
      </Select.Option>
      <Select.Option value="1415">
        1415 - Retorno de mercadoria adquirida ou recebid...ecimento em operação
        com mercadoria sujeita a ST
      </Select.Option>
      <Select.Option value="1450">
        1450 - Sistemas de integração e parceria rural
      </Select.Option>
      <Select.Option value="1451">
        1451 - Entrada de animal - Sistema de Integração e Parceria Rural
      </Select.Option>
      <Select.Option value="1452">
        1452 - Entrada de insumo - Sistema de Integração e Parceria Rural
      </Select.Option>
      <Select.Option value="1453">
        1453 - Retorno do animal ou da produção - Sistema de Integração e
        Parceria Rural
      </Select.Option>
      <Select.Option value="1454">
        1454 - Retorno simbólico do animal ou da produção - Sistema de
        Integração e Parceria Rural
      </Select.Option>
      <Select.Option value="1455">
        1455 - Retorno de insumo não utilizado na produção - Sistema de
        Integração e Parceria Rural
      </Select.Option>
      <Select.Option value="1456">
        1456 - Entrada referente a remuneração do produtor no Sistema de
        Integração e Parceria Rural
      </Select.Option>
      <Select.Option value="1501">
        1501 - Entrada de mercadoria recebida com fim específico de exportação
      </Select.Option>
      <Select.Option value="1503">
        1503 - Entrada decorrente de devolução de produto...mento, remetido com
        fim específico de exportação
      </Select.Option>
      <Select.Option value="1504">
        1504 - Entrada decorrente de devolução de mercado...e exportação,
        adquirida ou recebida de terceiros
      </Select.Option>
      <Select.Option value="1505">
        1505 - Entrada decorrente de devolução de mercado...ação ou produzidos
        pelo próprio estabelecimento.
      </Select.Option>
      <Select.Option value="1506">
        1506 - Entrada decorrente de devolução de mercadorias exportação.
      </Select.Option>
      <Select.Option value="1551">
        1551 - Compra de bem p/ o ativo imobilizado
      </Select.Option>
      <Select.Option value="1552">
        1552 - Transferência de bem do ativo imobilizado
      </Select.Option>
      <Select.Option value="1553">
        1553 - Devolução de venda de bem do ativo imobilizado
      </Select.Option>
      <Select.Option value="1554">
        1554 - Retorno de bem do ativo imobilizado remetido p/ uso fora do
        estabelecimento
      </Select.Option>
      <Select.Option value="1555">
        1555 - Entrada de bem do ativo imobilizado de terceiro, remetido p/ uso
        no estabelecimento
      </Select.Option>
      <Select.Option value="1556">
        1556 - Compra de material p/ uso ou consumo
      </Select.Option>
      <Select.Option value="1557">
        1557 - Transferência de material p/ uso ou consumo
      </Select.Option>
      <Select.Option value="1601">
        1601 - Recebimento, por transferência, de crédito de ICMS
      </Select.Option>
      <Select.Option value="1602">
        1602 - Recebimento, por transferência, de saldo c...esa, p/ compensação
        de saldo devedor do imposto.
      </Select.Option>
      <Select.Option value="1603">
        1603 - Ressarcimento de ICMS retido por substituição tributária
      </Select.Option>
      <Select.Option value="1604">
        1604 - Lançamento do crédito relativo à compra de bem p/ o ativo
        imobilizado
      </Select.Option>
      <Select.Option value="1605">
        1605 - Recebimento, por transferência, de saldo d...o ICMS de outro
        estabelecimento da mesma empresa
      </Select.Option>
      <Select.Option value="1651">
        1651 - Compra de combustível ou lubrificante p/ industrialização
        subseqüente
      </Select.Option>
      <Select.Option value="1652">
        1652 - Compra de combustível ou lubrificante p/ comercialização
      </Select.Option>
      <Select.Option value="1653">
        1653 - Compra de combustível ou lubrificante por consumidor ou usuário
        final
      </Select.Option>
      <Select.Option value="1657">
        1657 - Retorno de remessa de combustível ou lubrificante para venda fora
        do estabelecimento.
      </Select.Option>
      <Select.Option value="1658">
        1658 - Transferência de combustível ou lubrificante p/ industrialização
      </Select.Option>
      <Select.Option value="1659">
        1659 - Transferência de combustível ou lubrificante p/ comercialização
      </Select.Option>
      <Select.Option value="1660">
        1660 - Devolução de venda de combustível ou lubrificante destinados à
        industrialização subseqüente
      </Select.Option>
      <Select.Option value="1661">
        1661 - Devolução de venda de combustível ou lubrificante destinados à
        comercialização
      </Select.Option>
      <Select.Option value="1662">
        1662 - Devolução de venda de combustível ou lubrificante destinados a
        consumidor ou usuário final
      </Select.Option>
      <Select.Option value="1663">
        1663 - Entrada de combustível ou lubrificante p/ armazenagem
      </Select.Option>
      <Select.Option value="1664">
        1664 - Retorno de combustível ou lubrificante remetidos p/ armazenagem
      </Select.Option>
      <Select.Option value="1901">
        1901 - Entrada p/ industrialização por encomenda
      </Select.Option>
      <Select.Option value="1902">
        1902 - Retorno de mercadoria remetida p/ industrialização por encomenda
      </Select.Option>
      <Select.Option value="1903">
        1903 - Entrada de mercadoria remetida p/ industrialização e não aplicada
        no referido processo
      </Select.Option>
      <Select.Option value="1904">
        1904 - Retorno de remessa p/ venda fora do estabelecimento
      </Select.Option>
      <Select.Option value="1905">
        1905 - Entrada de mercadoria recebida p/ depósito em depósito fechado ou
        armazém geral
      </Select.Option>
      <Select.Option value="1906">
        1906 - Retorno de mercadoria remetida p/ depósito fechado ou armazém
        geral
      </Select.Option>
      <Select.Option value="1907">
        1907 - Retorno simbólico de mercadoria remetida p/ depósito fechado ou
        armazém geral
      </Select.Option>
      <Select.Option value="1908">
        1908 - Entrada de bem por conta de contrato de comodato ou locação
      </Select.Option>
      <Select.Option value="1909">
        1909 - Retorno de bem remetido por conta de contrato de comodato ou
        locação
      </Select.Option>
      <Select.Option value="1910">
        1910 - Entrada de bonificação, doação ou brinde
      </Select.Option>
      <Select.Option value="1911">
        1911 - Entrada de amostra grátis
      </Select.Option>
      <Select.Option value="1912">
        1912 - Entrada de mercadoria ou bem recebido p/ demonstração
      </Select.Option>
      <Select.Option value="1913">
        1913 - Retorno de mercadoria ou bem remetido p/ demonstração
      </Select.Option>
      <Select.Option value="1914">
        1914 - Retorno de mercadoria ou bem remetido p/ exposição ou feira
      </Select.Option>
      <Select.Option value="1915">
        1915 - Entrada de mercadoria ou bem recebido p/ conserto ou reparo
      </Select.Option>
      <Select.Option value="1916">
        1916 - Retorno de mercadoria ou bem remetido p/ conserto ou reparo
      </Select.Option>
      <Select.Option value="1917">
        1917 - Entrada de mercadoria recebida em consignação mercantil ou
        industrial
      </Select.Option>
      <Select.Option value="1918">
        1918 - Devolução de mercadoria remetida em consignação mercantil ou
        industrial
      </Select.Option>
      <Select.Option value="1919">
        1919 - Devolução simbólica de mercadoria vendida ...riormente em
        consignação mercantil ou industrial
      </Select.Option>
      <Select.Option value="1920">
        1920 - Entrada de vasilhame ou sacaria
      </Select.Option>
      <Select.Option value="1921">
        1921 - Retorno de vasilhame ou sacaria
      </Select.Option>
      <Select.Option value="1922">
        1922 - Lançamento efetuado a título de simples fa...mento decorrente de
        compra p/ recebimento futuro
      </Select.Option>
      <Select.Option value="1923">
        1923 - Entrada de mercadoria recebida do vendedor remetente, em venda à
        ordem
      </Select.Option>
      <Select.Option value="1924">
        1924 - Entrada p/ industrialização por conta e or...não transitar pelo
        estabelecimento do adquirente
      </Select.Option>
      <Select.Option value="1925">
        1925 - Retorno de mercadoria remetida p/ industri...não transitar pelo
        estabelecimento do adquirente
      </Select.Option>
      <Select.Option value="1926">
        1926 - Lançamento efetuado a título de reclassifi...rrente de formação
        de kit ou de sua desagregação
      </Select.Option>
      <Select.Option value="1931">
        1931 - Lançamento efetuado pelo tomador do serviç...inscrito na UF onde
        se tenha iniciado o serviço.
      </Select.Option>
      <Select.Option value="1932">
        1932 - Aquisição de serviço de transporte iniciad...diversa daquela onde
        esteja inscrito o prestador
      </Select.Option>
      <Select.Option value="1933">
        1933 - Aquisição de serviço tributado pelo Imposto sobre Serviços de
        Qualquer Natureza
      </Select.Option>
      <Select.Option value="1934">
        1934 - Entrada simbólica de mercadoria recebida p/ depósito fechado ou
        armazém geral
      </Select.Option>
      <Select.Option value="1949">
        1949 - Outra entrada de mercadoria ou prestação de serviço não
        especificada
      </Select.Option>
      <Select.Option value="2101">
        2101 - Compra p/ industrialização ou produção rural
      </Select.Option>
      <Select.Option value="2102">
        2102 - Compra p/ comercialização
      </Select.Option>
      <Select.Option value="2111">
        2111 - Compra p/ industrialização de mercadoria recebida anteriormente
        em consignação industrial
      </Select.Option>
      <Select.Option value="2113">
        2113 - Compra p/ comercialização, de mercadoria recebida anteriormente
        em consignação mercantil
      </Select.Option>
      <Select.Option value="2116">
        2116 - Compra p/ industrialização ou produção rural originada de
        encomenda p/ recebimento futuro
      </Select.Option>
      <Select.Option value="2117">
        2117 - Compra p/ comercialização originada de encomenda p/ recebimento
        futuro
      </Select.Option>
      <Select.Option value="2118">
        2118 - Compra de mercadoria p/ comercialização pe...edor remetente ao
        destinatário, em venda à ordem
      </Select.Option>
      <Select.Option value="2120">
        2120 - Compra p/ industrialização, em venda à ordem, já recebida do
        vendedor remetente
      </Select.Option>
      <Select.Option value="2121">
        2121 - Compra p/ comercialização, em venda à ordem, já recebida do
        vendedor remetente
      </Select.Option>
      <Select.Option value="2122">
        2122 - Compra p/ industrialização em que a mercad...or sem transitar
        pelo estabelecimento adquirente
      </Select.Option>
      <Select.Option value="2124">
        2124 - Industrialização efetuada por outra empresa
      </Select.Option>
      <Select.Option value="2125">
        2125 - Industrialização efetuada por outra empres...ou pelo
        estabelecimento adquirente da mercadoria
      </Select.Option>
      <Select.Option value="2126">
        2126 - Compra p/ utilização na prestação de serviço sujeita ao ICMS
      </Select.Option>
      <Select.Option value="2128">
        2128 - Compra p/ utilização na prestação de serviço sujeita ao ISSQN
      </Select.Option>
      <Select.Option value="2131">
        2131 - Entrada de mercadoria com previsão de posterior ajuste ou fixação
        de preço
      </Select.Option>
      <Select.Option value="2132">
        2132 - Fixação de preço de produção do estabelecimento produtor
      </Select.Option>
      <Select.Option value="2135">
        2135 - Fixação de preço de produção do estabelecimento produtor
      </Select.Option>
      <Select.Option value="2151">
        2151 - Transferência p/ industrialização ou produção rural
      </Select.Option>
      <Select.Option value="2152">
        2152 - Transferência p/ comercialização
      </Select.Option>
      <Select.Option value="2153">
        2153 - Transferência de energia elétrica p/ distribuição
      </Select.Option>
      <Select.Option value="2154">
        2154 - Transferência p/ utilização na prestação de serviço
      </Select.Option>
      <Select.Option value="2159">
        2159 - Entrada decorrente do fornecimento de produto ou mercadoria de
        ato cooperativo.
      </Select.Option>
      <Select.Option value="2201">
        2201 - Devolução de venda de produção do estabelecimento
      </Select.Option>
      <Select.Option value="2202">
        2202 - Devolução de venda de mercadoria adquirida ou recebida de
        terceiros
      </Select.Option>
      <Select.Option value="2203">
        2203 - Devolução de venda de produção do estabelecimento destinada à ZFM
        ou ALC
      </Select.Option>
      <Select.Option value="2204">
        2204 - Devolução de venda de mercadoria adquirida ou recebida de
        terceiros, destinada à ZFM ou ALC
      </Select.Option>
      <Select.Option value="2205">
        2205 - Anulação de valor relativo à prestação de serviço de comunicação
      </Select.Option>
      <Select.Option value="2206">
        2206 - Anulação de valor relativo à prestação de serviço de transporte
      </Select.Option>
      <Select.Option value="2207">
        2207 - Anulação de valor relativo à venda de energia elétrica
      </Select.Option>
      <Select.Option value="2208">
        2208 - Devolução de produção do estabelecimento, remetida em
        transferência.
      </Select.Option>
      <Select.Option value="2209">
        2209 - Devolução de mercadoria adquirida ou recebida de terceiros e
        remetida em transferência
      </Select.Option>
      <Select.Option value="2212">
        2212 - Devolução de venda no mercado interno de m...o Especial de
        Entreposto Industrial (Recof-Sped)
      </Select.Option>
      <Select.Option value="2213">
        2213 - Devolução de remessa de produção do estabe...previsão de
        posterior ajuste ou fixação de preço
      </Select.Option>
      <Select.Option value="2214">
        2214 - Devolução de fixação de preço de produção do estabelecimento
        produtor
      </Select.Option>
      <Select.Option value="2215">
        2215 - Devolução de fornecimento de produção do estabelecimento de ato
        cooperativo
      </Select.Option>
      <Select.Option value="2216">
        2216 - Devolução de fornecimento de mercadoria adquirida ou recebida de
        terceiros de ato cooperativo
      </Select.Option>
      <Select.Option value="2251">
        2251 - Compra de energia elétrica p/ distribuição ou comercialização
      </Select.Option>
      <Select.Option value="2252">
        2252 - Compra de energia elétrica por estabelecimento industrial
      </Select.Option>
      <Select.Option value="2253">
        2253 - Compra de energia elétrica por estabelecimento comercial
      </Select.Option>
      <Select.Option value="2254">
        2254 - Compra de energia elétrica por estabelecimento prestador de
        serviço de transporte
      </Select.Option>
      <Select.Option value="2255">
        2255 - Compra de energia elétrica por estabelecimento prestador de
        serviço de comunicação
      </Select.Option>
      <Select.Option value="2256">
        2256 - Compra de energia elétrica por estabelecimento de produtor rural
      </Select.Option>
      <Select.Option value="2257">
        2257 - Compra de energia elétrica p/ consumo por demanda contratada
      </Select.Option>
      <Select.Option value="2301">
        2301 - Aquisição de serviço de comunicação p/ execução de serviço da
        mesma natureza
      </Select.Option>
      <Select.Option value="2302">
        2302 - Aquisição de serviço de comunicação por estabelecimento
        industrial
      </Select.Option>
      <Select.Option value="2303">
        2303 - Aquisição de serviço de comunicação por estabelecimento comercial
      </Select.Option>
      <Select.Option value="2304">
        2304 - Aquisição de serviço de comunicação por estabelecimento de
        prestador de serviço de transporte
      </Select.Option>
      <Select.Option value="2305">
        2305 - Aquisição de serviço de comunicação por es...geradora ou de
        distribuidora de energia elétrica
      </Select.Option>
      <Select.Option value="2306">
        2306 - Aquisição de serviço de comunicação por estabelecimento de
        produtor rural
      </Select.Option>
      <Select.Option value="2351">
        2351 - Aquisição de serviço de transporte p/ execução de serviço da
        mesma natureza
      </Select.Option>
      <Select.Option value="2352">
        2352 - Aquisição de serviço de transporte por estabelecimento industrial
      </Select.Option>
      <Select.Option value="2353">
        2353 - Aquisição de serviço de transporte por estabelecimento comercial
      </Select.Option>
      <Select.Option value="2354">
        2354 - Aquisição de serviço de transporte por estabelecimento de
        prestador de serviço de comunicação
      </Select.Option>
      <Select.Option value="2355">
        2355 - Aquisição de serviço de transporte por est...geradora ou de
        distribuidora de energia elétrica
      </Select.Option>
      <Select.Option value="2356">
        2356 - Aquisição de serviço de transporte por estabelecimento de
        produtor rural
      </Select.Option>
      <Select.Option value="2401">
        2401 - Compra p/ industrialização ou produção rural de mercadoria
        sujeita a ST
      </Select.Option>
      <Select.Option value="2403">
        2403 - Compra p/ comercialização em operação com mercadoria sujeita a ST
      </Select.Option>
      <Select.Option value="2406">
        2406 - Compra de bem p/ o ativo imobilizado cuja mercadoria está sujeita
        a ST
      </Select.Option>
      <Select.Option value="2407">
        2407 - Compra de mercadoria p/ uso ou consumo cuja mercadoria está
        sujeita a ST
      </Select.Option>
      <Select.Option value="2408">
        2408 - Transferência p/ industrialização ou produção rural de mercadoria
        sujeita a ST
      </Select.Option>
      <Select.Option value="2409">
        2409 - Transferência p/ comercialização em operação com mercadoria
        sujeita a ST
      </Select.Option>
      <Select.Option value="2410">
        2410 - Devolução de venda de produção do estabelecimento, quando o
        produto sujeito a ST
      </Select.Option>
      <Select.Option value="2411">
        2411 - Devolução de venda de mercadoria adquirida...erceiros em operação
        com mercadoria sujeita a ST
      </Select.Option>
      <Select.Option value="2414">
        2414 - Retorno de produção do estabelecimento, re...o estabelecimento,
        quando o produto sujeito a ST
      </Select.Option>
      <Select.Option value="2415">
        2415 - Retorno de mercadoria adquirida ou recebid...ecimento em operação
        com mercadoria sujeita a ST
      </Select.Option>
      <Select.Option value="2450">
        2450 - Sistemas de integração e parceria rural
      </Select.Option>
      <Select.Option value="2451">
        2451 - Entrada de animal - Sistema de Integração e Parceria Rural
      </Select.Option>
      <Select.Option value="2452">
        2452 - Entrada de insumo - Sistema de Integração e Parceria Rural
      </Select.Option>
      <Select.Option value="2453">
        2453 - Retorno do animal ou da produção - Sistema de Integração e
        Parceria Rural
      </Select.Option>
      <Select.Option value="2454">
        2454 - Retorno simbólico do animal ou da produção - Sistema de
        Integração e Parceria Rural
      </Select.Option>
      <Select.Option value="2455">
        2455 - Retorno de insumo não utilizado na produção - Sistema de
        Integração e Parceria Rural
      </Select.Option>
      <Select.Option value="2456">
        2456 - Entrada referente a remuneração do produtor no Sistema de
        Integração e Parceria Rural
      </Select.Option>
      <Select.Option value="2501">
        2501 - Entrada de mercadoria recebida com fim específico de exportação
      </Select.Option>
      <Select.Option value="2503">
        2503 - Entrada decorrente de devolução de produto...mento, remetido com
        fim específico de exportação
      </Select.Option>
      <Select.Option value="2504">
        2504 - Entrada decorrente de devolução de mercado...e exportação,
        adquirida ou recebida de terceiros
      </Select.Option>
      <Select.Option value="2505">
        2505 - Entrada decorrente de devolução de mercado...ação ou produzidos
        pelo próprio estabelecimento.
      </Select.Option>
      <Select.Option value="2506">
        2506 - Entrada decorrente de devolução de mercadorias exportação.
      </Select.Option>
      <Select.Option value="2551">
        2551 - Compra de bem p/ o ativo imobilizado
      </Select.Option>
      <Select.Option value="2552">
        2552 - Transferência de bem do ativo imobilizado
      </Select.Option>
      <Select.Option value="2553">
        2553 - Devolução de venda de bem do ativo imobilizado
      </Select.Option>
      <Select.Option value="2554">
        2554 - Retorno de bem do ativo imobilizado remetido p/ uso fora do
        estabelecimento
      </Select.Option>
      <Select.Option value="2555">
        2555 - Entrada de bem do ativo imobilizado de terceiro, remetido p/ uso
        no estabelecimento
      </Select.Option>
      <Select.Option value="2556">
        2556 - Compra de material p/ uso ou consumo
      </Select.Option>
      <Select.Option value="2557">
        2557 - Transferência de material p/ uso ou consumo
      </Select.Option>
      <Select.Option value="2603">
        2603 - Ressarcimento de ICMS retido por substituição tributária
      </Select.Option>
      <Select.Option value="2651">
        2651 - Compra de combustível ou lubrificante p/ industrialização
        subseqüente
      </Select.Option>
      <Select.Option value="2652">
        2652 - Compra de combustível ou lubrificante p/ comercialização
      </Select.Option>
      <Select.Option value="2653">
        2653 - Compra de combustível ou lubrificante por consumidor ou usuário
        final
      </Select.Option>
      <Select.Option value="2657">
        2657 - Retorno de remessa de combustível ou lubrificante para venda fora
        do estabelecimento.
      </Select.Option>
      <Select.Option value="2658">
        2658 - Transferência de combustível ou lubrificante p/ industrialização
      </Select.Option>
      <Select.Option value="2659">
        2659 - Transferência de combustível ou lubrificante p/ comercialização
      </Select.Option>
      <Select.Option value="2660">
        2660 - Devolução de venda de combustível ou lubrificante destinados à
        industrialização subseqüente
      </Select.Option>
      <Select.Option value="2661">
        2661 - Devolução de venda de combustível ou lubrificante destinados à
        comercialização
      </Select.Option>
      <Select.Option value="2662">
        2662 - Devolução de venda de combustível ou lubrificante destinados a
        consumidor ou usuário final
      </Select.Option>
      <Select.Option value="2663">
        2663 - Entrada de combustível ou lubrificante p/ armazenagem
      </Select.Option>
      <Select.Option value="2664">
        2664 - Retorno de combustível ou lubrificante remetidos p/ armazenagem
      </Select.Option>
      <Select.Option value="2901">
        2901 - Entrada p/ industrialização por encomenda
      </Select.Option>
      <Select.Option value="2902">
        2902 - Retorno de mercadoria remetida p/ industrialização por encomenda
      </Select.Option>
      <Select.Option value="2903">
        2903 - Entrada de mercadoria remetida p/ industrialização e não aplicada
        no referido processo
      </Select.Option>
      <Select.Option value="2904">
        2904 - Retorno de remessa p/ venda fora do estabelecimento
      </Select.Option>
      <Select.Option value="2905">
        2905 - Entrada de mercadoria recebida p/ depósito em depósito fechado ou
        armazém geral
      </Select.Option>
      <Select.Option value="2906">
        2906 - Retorno de mercadoria remetida p/ depósito fechado ou armazém
        geral
      </Select.Option>
      <Select.Option value="2907">
        2907 - Retorno simbólico de mercadoria remetida p/ depósito fechado ou
        armazém geral
      </Select.Option>
      <Select.Option value="2908">
        2908 - Entrada de bem por conta de contrato de comodato ou locação
      </Select.Option>
      <Select.Option value="2909">
        2909 - Retorno de bem remetido por conta de contrato de comodato ou
        locação
      </Select.Option>
      <Select.Option value="2910">
        2910 - Entrada de bonificação, doação ou brinde
      </Select.Option>
      <Select.Option value="2911">
        2911 - Entrada de amostra grátis
      </Select.Option>
      <Select.Option value="2912">
        2912 - Entrada de mercadoria ou bem recebido p/ demonstração
      </Select.Option>
      <Select.Option value="2913">
        2913 - Retorno de mercadoria ou bem remetido p/ demonstração
      </Select.Option>
      <Select.Option value="2914">
        2914 - Retorno de mercadoria ou bem remetido p/ exposição ou feira
      </Select.Option>
      <Select.Option value="2915">
        2915 - Entrada de mercadoria ou bem recebido p/ conserto ou reparo
      </Select.Option>
      <Select.Option value="2916">
        2916 - Retorno de mercadoria ou bem remetido p/ conserto ou reparo
      </Select.Option>
      <Select.Option value="2917">
        2917 - Entrada de mercadoria recebida em consignação mercantil ou
        industrial
      </Select.Option>
      <Select.Option value="2918">
        2918 - Devolução de mercadoria remetida em consignação mercantil ou
        industrial
      </Select.Option>
      <Select.Option value="2919">
        2919 - Devolução simbólica de mercadoria vendida ...riormente em
        consignação mercantil ou industrial
      </Select.Option>
      <Select.Option value="2920">
        2920 - Entrada de vasilhame ou sacaria
      </Select.Option>
      <Select.Option value="2921">
        2921 - Retorno de vasilhame ou sacaria
      </Select.Option>
      <Select.Option value="2922">
        2922 - Lançamento efetuado a título de simples fa...mento decorrente de
        compra p/ recebimento futuro
      </Select.Option>
      <Select.Option value="2923">
        2923 - Entrada de mercadoria recebida do vendedor remetente, em venda à
        ordem
      </Select.Option>
      <Select.Option value="2924">
        2924 - Entrada p/ industrialização por conta e or...não transitar pelo
        estabelecimento do adquirente
      </Select.Option>
      <Select.Option value="2925">
        2925 - Retorno de mercadoria remetida p/ industri...não transitar pelo
        estabelecimento do adquirente
      </Select.Option>
      <Select.Option value="2931">
        2931 - Lançamento efetuado pelo tomador do serviç...-inscrito na UF onde
        se tenha iniciado o serviço
      </Select.Option>
      <Select.Option value="2932">
        2932 - Aquisição de serviço de transporte iniciad...diversa daquela onde
        esteja inscrito o prestador
      </Select.Option>
      <Select.Option value="2933">
        2933 - Aquisição de serviço tributado pelo Imposto Sobre Serviços de
        Qualquer Natureza
      </Select.Option>
      <Select.Option value="2934">
        2934 - Entrada simbólica de mercadoria recebida p/ depósito fechado ou
        armazém geral
      </Select.Option>
      <Select.Option value="2949">
        2949 - Outra entrada de mercadoria ou prestação de serviço não
        especificado
      </Select.Option>
      <Select.Option value="3101">
        3101 - Compra p/ industrialização ou produção rural
      </Select.Option>
      <Select.Option value="3102">
        3102 - Compra p/ comercialização
      </Select.Option>
      <Select.Option value="3128">
        3128 - Compra p/ utilização na prestação de serviço sujeita ao ISSQN
      </Select.Option>
      <Select.Option value="3129">
        3129 - Compra para industrialização sob o Regime ...o Especial de
        Entreposto Industrial (Recof-Sped)
      </Select.Option>
      <Select.Option value="3126">
        3126 - Compra p/ utilização na prestação de serviço sujeita ao ICMS
      </Select.Option>
      <Select.Option value="3127">
        3127 - Compra p/ industrialização sob o regime de drawback
      </Select.Option>
      <Select.Option value="3200">
        3200 - Devoluções de vendas de produção própria, de terceiros ou
        anulações de valores
      </Select.Option>
      <Select.Option value="3201">
        3201 - Devolução de venda de produção do estabelecimento
      </Select.Option>
      <Select.Option value="3202">
        3202 - Devolução de venda de mercadoria adquirida ou recebida de
        terceiros
      </Select.Option>
      <Select.Option value="3205">
        3205 - Anulação de valor relativo à prestação de serviço de comunicação
      </Select.Option>
      <Select.Option value="3206">
        3206 - Anulação de valor relativo à prestação de serviço de transporte
      </Select.Option>
      <Select.Option value="3207">
        3207 - Anulação de valor relativo à venda de energia elétrica
      </Select.Option>
      <Select.Option value="3211">
        3211 - Devolução de venda de produção do estabelecimento sob o regime de
        drawback
      </Select.Option>
      <Select.Option value="3212">
        3212 - Devolução de venda no mercado externo de m...o Especial de
        Entreposto Industrial (Recof-Sped)
      </Select.Option>
      <Select.Option value="3251">
        3251 - Compra de energia elétrica p/ distribuição ou comercialização
      </Select.Option>
      <Select.Option value="3301">
        3301 - Aquisição de serviço de comunicação p/ execução de serviço da
        mesma natureza
      </Select.Option>
      <Select.Option value="3351">
        3351 - Aquisição de serviço de transporte p/ execução de serviço da
        mesma natureza
      </Select.Option>
      <Select.Option value="3352">
        3352 - Aquisição de serviço de transporte por estabelecimento industrial
      </Select.Option>
      <Select.Option value="3353">
        3353 - Aquisição de serviço de transporte por estabelecimento comercial
      </Select.Option>
      <Select.Option value="3354">
        3354 - Aquisição de serviço de transporte por estabelecimento de
        prestador de serviço de comunicação
      </Select.Option>
      <Select.Option value="3355">
        3355 - Aquisição de serviço de transporte por est...geradora ou de
        distribuidora de energia elétrica
      </Select.Option>
      <Select.Option value="3356">
        3356 - Aquisição de serviço de transporte por estabelecimento de
        produtor rural
      </Select.Option>
      <Select.Option value="3503">
        3503 - Devolução de mercadoria exportada que tenha sido recebida com fim
        específico de exportação
      </Select.Option>
      <Select.Option value="3551">
        3551 - Compra de bem p/ o ativo imobilizado
      </Select.Option>
      <Select.Option value="3553">
        3553 - Devolução de venda de bem do ativo imobilizado
      </Select.Option>
      <Select.Option value="3556">
        3556 - Compra de material p/ uso ou consumo
      </Select.Option>
      <Select.Option value="3651">
        3651 - Compra de combustível ou lubrificante p/ industrialização
        subseqüente
      </Select.Option>
      <Select.Option value="3652">
        3652 - Compra de combustível ou lubrificante p/ comercialização
      </Select.Option>
      <Select.Option value="3653">
        3653 - Compra de combustível ou lubrificante por consumidor ou usuário
        final
      </Select.Option>
      <Select.Option value="3900">
        3900 - translation missing: pt-BR.enums.cfop.c3900
      </Select.Option>
      <Select.Option value="3930">
        3930 - Lançamento efetuado a título de entrada de...regime especial
        aduaneiro de admissão temporária
      </Select.Option>
      <Select.Option value="3949">
        3949 - Outra entrada de mercadoria ou prestação de serviço não
        especificado
      </Select.Option>
      <Select.Option value="5102">
        5102 - Venda de mercadoria adquirida ou recebida de terceiros
      </Select.Option>
      <Select.Option value="5103">
        5103 - Venda de produção do estabelecimento efetuada fora do
        estabelecimento
      </Select.Option>
      <Select.Option value="5104">
        5104 - Venda de mercadoria adquirida ou recebida de terceiros, efetuada
        fora do estabelecimento
      </Select.Option>
      <Select.Option value="5105">
        5105 - Venda de produção do estabelecimento que não deva por ele
        transitar
      </Select.Option>
      <Select.Option value="5106">
        5106 - Venda de mercadoria adquirida ou recebida de terceiros, que não
        deva por ele transitar
      </Select.Option>
      <Select.Option value="5109">
        5109 - Venda de produção do estabelecimento destinada à ZFM ou ALC
      </Select.Option>
      <Select.Option value="5110">
        5110 - Venda de mercadoria, adquirida ou recebida de terceiros,
        destinada à ZFM ou ALC
      </Select.Option>
      <Select.Option value="5111">
        5111 - Venda de produção do estabelecimento remetida anteriormente em
        consignação industrial
      </Select.Option>
      <Select.Option value="5112">
        5112 - Venda de mercadoria adquirida ou recebida ...remetida
        anteriormente em consignação industrial
      </Select.Option>
      <Select.Option value="5113">
        5113 - Venda de produção do estabelecimento remetida anteriormente em
        consignação mercantil
      </Select.Option>
      <Select.Option value="5114">
        5114 - Venda de mercadoria adquirida ou recebida ... remetida
        anteriormente em consignação mercantil
      </Select.Option>
      <Select.Option value="5115">
        5115 - Venda de mercadoria adquirida ou recebida ... recebida
        anteriormente em consignação mercantil
      </Select.Option>
      <Select.Option value="5116">
        5116 - Venda de produção do estabelecimento originada de encomenda p/
        entrega futura
      </Select.Option>
      <Select.Option value="5117">
        5117 - Venda de mercadoria adquirida ou recebida ...ceiros, originada de
        encomenda p/ entrega futura
      </Select.Option>
      <Select.Option value="5118">
        5118 - Venda de produção do estabelecimento entre...ordem do adquirente
        originário, em venda à ordem
      </Select.Option>
      <Select.Option value="5119">
        5119 - Venda de mercadoria adquirida ou recebida ...ordem do adquirente
        originário, em venda à ordem
      </Select.Option>
      <Select.Option value="5120">
        5120 - Venda de mercadoria adquirida ou recebida ...atário pelo vendedor
        remetente, em venda à ordem
      </Select.Option>
      <Select.Option value="5122">
        5122 - Venda de produção do estabelecimento remet...sem transitar pelo
        estabelecimento do adquirente
      </Select.Option>
      <Select.Option value="5123">
        5123 - Venda de mercadoria adquirida ou recebida ...sem transitar pelo
        estabelecimento do adquirente
      </Select.Option>
      <Select.Option value="5124">
        5124 - Industrialização efetuada p/ outra empresa
      </Select.Option>
      <Select.Option value="5125">
        5125 - Industrialização efetuada p/ outra empresa...ar pelo
        estabelecimento adquirente da mercadoria
      </Select.Option>
      <Select.Option value="5129">
        5129 - Venda de insumo importado e de mercadoria ...o Especial de
        Entreposto Industrial (Recof-Sped)
      </Select.Option>
      <Select.Option value="5131">
        5131 - Remessa de produção do estabelecimento
      </Select.Option>
      <Select.Option value="5132">
        5132 - Fixação de preço de produção do estabelecimento
      </Select.Option>
      <Select.Option value="5151">
        5151 - Transferência de produção do estabelecimento
      </Select.Option>
      <Select.Option value="5152">
        5152 - Transferência de mercadoria adquirida ou recebida de terceiros
      </Select.Option>
      <Select.Option value="5153">
        5153 - Transferência de energia elétrica
      </Select.Option>
      <Select.Option value="5155">
        5155 - Transferência de produção do estabelecimento, que não deva por
        ele transitar
      </Select.Option>
      <Select.Option value="5156">
        5156 - Transferência de mercadoria adquirida ou r...ida de terceiros,
        que não deva por ele transitar
      </Select.Option>
      <Select.Option value="5159">
        5159 - Fornecimento de produção do estabelecimento de ato cooperativo.
      </Select.Option>
      <Select.Option value="5160">
        5160 - Fornecimento de mercadoria adquirida ou recebida de terceiros de
        ato cooperativo.
      </Select.Option>
      <Select.Option value="5201">
        5201 - Devolução de compra p/ industrialização ou produção rural
      </Select.Option>
      <Select.Option value="5202">
        5202 - Devolução de compra p/ comercialização
      </Select.Option>
      <Select.Option value="5205">
        5205 - Anulação de valor relativo a aquisição de serviço de comunicação
      </Select.Option>
      <Select.Option value="5206">
        5206 - Anulação de valor relativo a aquisição de serviço de transporte
      </Select.Option>
      <Select.Option value="5207">
        5207 - Anulação de valor relativo à compra de energia elétrica
      </Select.Option>
      <Select.Option value="5208">
        5208 - Devolução de mercadoria recebida em transferência p/
        industrialização ou produção rural
      </Select.Option>
      <Select.Option value="5209">
        5209 - Devolução de mercadoria recebida em transferência p/
        comercialização
      </Select.Option>
      <Select.Option value="5210">
        5210 - Devolução de compra p/ utilização na prestação de serviço
      </Select.Option>
      <Select.Option value="5213">
        5213 - Devolução de entrada de mercadoria com previsão de posterior
        ajuste ou fixação de preço
      </Select.Option>
      <Select.Option value="5214">
        5214 - Devolução de fixação de preço de produção do estabelecimento
        produtor
      </Select.Option>
      <Select.Option value="5215">
        5215 - Devolução de fixação de preço de produção do estabelecimento
        produtor
      </Select.Option>
      <Select.Option value="5216">
        5216 - Devolução de entrada decorrente do fornecimento de produto ou
        mercadoria de ato cooperativo
      </Select.Option>
      <Select.Option value="5251">
        5251 - Venda de energia elétrica p/ distribuição ou comercialização
      </Select.Option>
      <Select.Option value="5252">
        5252 - Venda de energia elétrica p/ estabelecimento industrial
      </Select.Option>
      <Select.Option value="5253">
        5253 - Venda de energia elétrica p/ estabelecimento comercial
      </Select.Option>
      <Select.Option value="5254">
        5254 - Venda de energia elétrica p/ estabelecimento prestador de serviço
        de transporte
      </Select.Option>
      <Select.Option value="5255">
        5255 - Venda de energia elétrica p/ estabelecimento prestador de serviço
        de comunicação
      </Select.Option>
      <Select.Option value="5256">
        5256 - Venda de energia elétrica p/ estabelecimento de produtor rural
      </Select.Option>
      <Select.Option value="5257">
        5257 - Venda de energia elétrica p/ consumo por demanda contratada
      </Select.Option>
      <Select.Option value="5258">
        5258 - Venda de energia elétrica a não contribuinte
      </Select.Option>
      <Select.Option value="5301">
        5301 - Prestação de serviço de comunicação p/ execução de serviço da
        mesma natureza
      </Select.Option>
      <Select.Option value="5302">
        5302 - Prestação de serviço de comunicação a estabelecimento industrial
      </Select.Option>
      <Select.Option value="5303">
        5303 - Prestação de serviço de comunicação a estabelecimento comercial
      </Select.Option>
      <Select.Option value="5304">
        5304 - Prestação de serviço de comunicação a estabelecimento de
        prestador de serviço de transporte
      </Select.Option>
      <Select.Option value="5305">
        5305 - Prestação de serviço de comunicação a esta...geradora ou de
        distribuidora de energia elétrica
      </Select.Option>
      <Select.Option value="5306">
        5306 - Prestação de serviço de comunicação a estabelecimento de produtor
        rural
      </Select.Option>
      <Select.Option value="5307">
        5307 - Prestação de serviço de comunicação a não contribuinte
      </Select.Option>
      <Select.Option value="5351">
        5351 - Prestação de serviço de transporte p/ execução de serviço da
        mesma natureza
      </Select.Option>
      <Select.Option value="5352">
        5352 - Prestação de serviço de transporte a estabelecimento industrial
      </Select.Option>
      <Select.Option value="5353">
        5353 - Prestação de serviço de transporte a estabelecimento comercial
      </Select.Option>
      <Select.Option value="5354">
        5354 - Prestação de serviço de transporte a estabelecimento de prestador
        de serviço de comunicação
      </Select.Option>
      <Select.Option value="5355">
        5355 - Prestação de serviço de transporte a estab...geradora ou de
        distribuidora de energia elétrica
      </Select.Option>
      <Select.Option value="5356">
        5356 - Prestação de serviço de transporte a estabelecimento de produtor
        rural
      </Select.Option>
      <Select.Option value="5357">
        5357 - Prestação de serviço de transporte a não contribuinte
      </Select.Option>
      <Select.Option value="5359">
        5359 - Prestação de serviço de transporte a contr...tada esteja
        dispensada de emissão de Nota Fiscal
      </Select.Option>
      <Select.Option value="5360">
        5360 - Prestação de serviço de transporte a contr...e-substituto em
        relação ao serviço de transporte
      </Select.Option>
      <Select.Option value="5401">
        5401 - Venda de produção do estabelecimento quando o produto esteja
        sujeito a ST
      </Select.Option>
      <Select.Option value="5402">
        5402 - Venda de produção do estabelecimento de pr...entre contribuintes
        substitutos do mesmo produto
      </Select.Option>
      <Select.Option value="5403">
        5403 - Venda de mercadoria, adquirida ou recebida...ita a ST, na
        condição de contribuinte-substituto
      </Select.Option>
      <Select.Option value="5405">
        5405 - Venda de mercadoria, adquirida ou recebida...ta a ST, na condição
        de contribuinte-substituído
      </Select.Option>
      <Select.Option value="5408">
        5408 - Transferência de produção do estabelecimento quando o produto
        sujeito a ST
      </Select.Option>
      <Select.Option value="5409">
        5409 - Transferência de mercadoria adquirida ou r...erceiros em operação
        com mercadoria sujeita a ST
      </Select.Option>
      <Select.Option value="5410">
        5410 - Devolução de compra p/ industrialização de mercadoria sujeita a
        ST
      </Select.Option>
      <Select.Option value="5411">
        5411 - Devolução de compra p/ comercialização em operação com mercadoria
        sujeita a ST
      </Select.Option>
      <Select.Option value="5412">
        5412 - Devolução de bem do ativo imobilizado, em operação com mercadoria
        sujeita a ST
      </Select.Option>
      <Select.Option value="5413">
        5413 - Devolução de mercadoria destinada ao uso ou consumo, em operação
        com mercadoria sujeita a ST.
      </Select.Option>
      <Select.Option value="5414">
        5414 - Remessa de produção do estabelecimento p/ ...o estabelecimento,
        quando o produto sujeito a ST
      </Select.Option>
      <Select.Option value="5415">
        5415 - Remessa de mercadoria adquirida ou recebid...cimento, em operação
        com mercadoria sujeita a ST
      </Select.Option>
      <Select.Option value="5450">
        5450 - Sistemas de integração e parceria rural
      </Select.Option>
      <Select.Option value="5451">
        5451 - Remessa de animal - Sistema de Integração e Parceria Rural
      </Select.Option>
      <Select.Option value="5452">
        5452 - Remessa de insumo - Sistema de Integração e Parceria Rural
      </Select.Option>
      <Select.Option value="5453">
        5453 - Retorno de animal ou da produção - Sistema de Integração e
        Parceria Rural
      </Select.Option>
      <Select.Option value="5454">
        5454 - Retorno simbólico de animal ou da produção - Sistema de
        Integração e Parceria Rural
      </Select.Option>
      <Select.Option value="5455">
        5455 - Retorno de insumos não utilizados na produção - Sistema de
        Integração e Parceria Rural
      </Select.Option>
      <Select.Option value="5456">
        5456 - Saída referente a remuneração do produtor - Sistema de Integração
        e Parceria Rural
      </Select.Option>
      <Select.Option value="5500">
        5500 - Remessas com fim específico de exportação e eventuais devoluções
      </Select.Option>
      <Select.Option value="5501">
        5501 - Remessa de produção do estabelecimento, com fim específico de
        exportação
      </Select.Option>
      <Select.Option value="5502">
        5502 - Remessa de mercadoria adquirida ou recebida de terceiros, com fim
        específico de exportação
      </Select.Option>
      <Select.Option value="5503">
        5503 - Devolução de mercadoria recebida com fim específico de exportação
      </Select.Option>
      <Select.Option value="5504">
        5504 - Remessa de mercadoria p/ formação de lote ...izado ou produzido
        pelo próprio estabelecimento.
      </Select.Option>
      <Select.Option value="5505">
        5505 - Remessa de mercadoria, adquirida ou recebida de terceiros, p/
        formação de lote de exportação.
      </Select.Option>
      <Select.Option value="5551">
        5551 - Venda de bem do ativo imobilizado
      </Select.Option>
      <Select.Option value="5552">
        5552 - Transferência de bem do ativo imobilizado
      </Select.Option>
      <Select.Option value="5553">
        5553 - Devolução de compra de bem p/ o ativo imobilizado
      </Select.Option>
      <Select.Option value="5554">
        5554 - Remessa de bem do ativo imobilizado p/ uso fora do
        estabelecimento
      </Select.Option>
      <Select.Option value="5555">
        5555 - Devolução de bem do ativo imobilizado de terceiro, recebido p/
        uso no estabelecimento
      </Select.Option>
      <Select.Option value="5556">
        5556 - Devolução de compra de material de uso ou consumo
      </Select.Option>
      <Select.Option value="5557">
        5557 - Transferência de material de uso ou consumo
      </Select.Option>
      <Select.Option value="5601">
        5601 - Transferência de crédito de ICMS acumulado
      </Select.Option>
      <Select.Option value="5602">
        5602 - Transferência de saldo credor do ICMS, p/ ...destinado à
        compensação de saldo devedor do ICMS
      </Select.Option>
      <Select.Option value="5603">
        5603 - Ressarcimento de ICMS retido por substituição tributária
      </Select.Option>
      <Select.Option value="5605">
        5605 - Transferência de saldo devedor do ICMS de outro estabelecimento
        da mesma empresa
      </Select.Option>
      <Select.Option value="5606">
        5606 - Utilização de saldo credor do ICMS p/ extinção por compensação de
        débitos fiscais
      </Select.Option>
      <Select.Option value="5651">
        5651 - Venda de combustível ou lubrificante de pr...imento destinados à
        industrialização subseqüente
      </Select.Option>
      <Select.Option value="5652">
        5652 - Venda de combustível ou lubrificante, de p...do estabelecimento,
        destinados à comercialização
      </Select.Option>
      <Select.Option value="5653">
        5653 - Venda de combustível ou lubrificante, de p...imento, destinados a
        consumidor ou usuário final
      </Select.Option>
      <Select.Option value="5654">
        5654 - Venda de combustível ou lubrificante, adqu...eiros, destinados à
        industrialização subseqüente
      </Select.Option>
      <Select.Option value="5655">
        5655 - Venda de combustível ou lubrificante, adqu...bidos de terceiros,
        destinados à comercialização
      </Select.Option>
      <Select.Option value="5656">
        5656 - Venda de combustível ou lubrificante, adqu...ceiros, destinados a
        consumidor ou usuário final
      </Select.Option>
      <Select.Option value="5657">
        5657 - Remessa de combustível ou lubrificante, ad...s de terceiros, p/
        venda fora do estabelecimento
      </Select.Option>
      <Select.Option value="5658">
        5658 - Transferência de combustível ou lubrificante de produção do
        estabelecimento
      </Select.Option>
      <Select.Option value="5659">
        5659 - Transferência de combustível ou lubrificante adquiridos ou
        recebidos de terceiros
      </Select.Option>
      <Select.Option value="5660">
        5660 - Devolução de compra de combustível ou lubrificante adquiridos p/
        industrialização subseqüente
      </Select.Option>
      <Select.Option value="5661">
        5661 - Devolução de compra de combustível ou lubrificante adquiridos p/
        comercialização
      </Select.Option>
      <Select.Option value="5662">
        5662 - Devolução de compra de combustível ou lubrificante adquiridos por
        consumidor ou usuário final
      </Select.Option>
      <Select.Option value="5663">
        5663 - Remessa p/ armazenagem de combustível ou lubrificante
      </Select.Option>
      <Select.Option value="5664">
        5664 - Retorno de combustível ou lubrificante recebidos p/ armazenagem
      </Select.Option>
      <Select.Option value="5665">
        5665 - Retorno simbólico de combustível ou lubrificante recebidos p/
        armazenagem
      </Select.Option>
      <Select.Option value="5666">
        5666 - Remessa, por conta e ordem de terceiros, d...ustível ou
        lubrificante recebidos p/ armazenagem
      </Select.Option>
      <Select.Option value="5667">
        5667 - Venda de combustível ou lubrificante a consumidor ou usuário
        final estabelecido em outra UF
      </Select.Option>
      <Select.Option value="5901">
        5901 - Remessa p/ industrialização por encomenda
      </Select.Option>
      <Select.Option value="5902">
        5902 - Retorno de mercadoria utilizada na industrialização por encomenda
      </Select.Option>
      <Select.Option value="5903">
        5903 - Retorno de mercadoria recebida p/ industrialização e não aplicada
        no referido processo
      </Select.Option>
      <Select.Option value="5904">
        5904 - Remessa p/ venda fora do estabelecimento
      </Select.Option>
      <Select.Option value="5905">
        5905 - Remessa p/ depósito fechado ou armazém geral
      </Select.Option>
      <Select.Option value="5906">
        5906 - Retorno de mercadoria depositada em depósito fechado ou armazém
        geral
      </Select.Option>
      <Select.Option value="5907">
        5907 - Retorno simbólico de mercadoria depositada em depósito fechado ou
        armazém geral
      </Select.Option>
      <Select.Option value="5908">
        5908 - Remessa de bem por conta de contrato de comodato ou locação
      </Select.Option>
      <Select.Option value="5909">
        5909 - Retorno de bem recebido por conta de contrato de comodato ou
        locação
      </Select.Option>
      <Select.Option value="5910">
        5910 - Remessa em bonificação, doação ou brinde
      </Select.Option>
      <Select.Option value="5911">
        5911 - Remessa de amostra grátis
      </Select.Option>
      <Select.Option value="5912">
        5912 - Remessa de mercadoria ou bem p/ demonstração
      </Select.Option>
      <Select.Option value="5913">
        5913 - Retorno de mercadoria ou bem recebido p/ demonstração
      </Select.Option>
      <Select.Option value="5914">
        5914 - Remessa de mercadoria ou bem p/ exposição ou feira
      </Select.Option>
      <Select.Option value="5915">
        5915 - Remessa de mercadoria ou bem p/ conserto ou reparo
      </Select.Option>
      <Select.Option value="5916">
        5916 - Retorno de mercadoria ou bem recebido p/ conserto ou reparo
      </Select.Option>
      <Select.Option value="5917">
        5917 - Remessa de mercadoria em consignação mercantil ou industrial
      </Select.Option>
      <Select.Option value="5918">
        5918 - Devolução de mercadoria recebida em consignação mercantil ou
        industrial
      </Select.Option>
      <Select.Option value="5919">
        5919 - Devolução simbólica de mercadoria vendida ...riormente em
        consignação mercantil ou industrial
      </Select.Option>
      <Select.Option value="5920">
        5920 - Remessa de vasilhame ou sacaria
      </Select.Option>
      <Select.Option value="5921">
        5921 - Devolução de vasilhame ou sacaria
      </Select.Option>
      <Select.Option value="5922">
        5922 - Lançamento efetuado a título de simples faturamento decorrente de
        venda p/ entrega futura
      </Select.Option>
      <Select.Option value="5923">
        5923 - Remessa de mercadoria por conta e ordem de...operações com
        armazém geral ou depósito fechado.
      </Select.Option>
      <Select.Option value="5924">
        5924 - Remessa p/ industrialização por conta e or...não transitar pelo
        estabelecimento do adquirente
      </Select.Option>
      <Select.Option value="5925">
        5925 - Retorno de mercadoria recebida p/ industri...não transitar pelo
        estabelecimento do adquirente
      </Select.Option>
      <Select.Option value="5926">
        5926 - Lançamento efetuado a título de reclassifi...rrente de formação
        de kit ou de sua desagregação
      </Select.Option>
      <Select.Option value="5927">
        5927 - Lançamento efetuado a título de baixa de estoque decorrente de
        perda, roubo ou deterioração
      </Select.Option>
      <Select.Option value="5928">
        5928 - Lançamento efetuado a título de baixa de e...corrente do
        encerramento da atividade da empresa
      </Select.Option>
      <Select.Option value="5929">
        5929 - Lançamento efetuado em decorrência de emis...ambém acobertada por
        documento fiscal do varejo.
      </Select.Option>
      <Select.Option value="5931">
        5931 - Lançamento efetuado em decorrência da resp...tador não inscrito
        na UF onde iniciado o serviço
      </Select.Option>
      <Select.Option value="5932">
        5932 - Prestação de serviço de transporte iniciada em UF diversa daquela
        onde inscrito o prestador
      </Select.Option>
      <Select.Option value="5933">
        5933 - Prestação de serviço tributado pelo Imposto Sobre Serviços de
        Qualquer Natureza
      </Select.Option>
      <Select.Option value="5934">
        5934 - Remessa simbólica de mercadoria depositada em armazém geral ou
        depósito fechado.
      </Select.Option>
      <Select.Option value="6102">
        6102 - Venda de mercadoria adquirida ou recebida de terceiros
      </Select.Option>
      <Select.Option value="6103">
        6103 - Venda de produção do estabelecimento, efetuada fora do
        estabelecimento
      </Select.Option>
      <Select.Option value="6104">
        6104 - Venda de mercadoria adquirida ou recebida de terceiros, efetuada
        fora do estabelecimento
      </Select.Option>
      <Select.Option value="6105">
        6105 - Venda de produção do estabelecimento que não deva por ele
        transitar
      </Select.Option>
      <Select.Option value="6106">
        6106 - Venda de mercadoria adquirida ou recebida de terceiros, que não
        deva por ele transitar
      </Select.Option>
      <Select.Option value="6107">
        6107 - Venda de produção do estabelecimento, destinada a não
        contribuinte
      </Select.Option>
      <Select.Option value="6108">
        6108 - Venda de mercadoria adquirida ou recebida de terceiros, destinada
        a não contribuinte
      </Select.Option>
      <Select.Option value="6109">
        6109 - Venda de produção do estabelecimento destinada à ZFM ou ALC
      </Select.Option>
      <Select.Option value="6110">
        6110 - Venda de mercadoria, adquirida ou recebida de terceiros,
        destinada à ZFM ou ALC
      </Select.Option>
      <Select.Option value="6111">
        6111 - Venda de produção do estabelecimento remetida anteriormente em
        consignação industrial
      </Select.Option>
      <Select.Option value="6112">
        6112 - Venda de mercadoria adquirida ou recebida ...remetida
        anteriormente em consignação industrial
      </Select.Option>
      <Select.Option value="6113">
        6113 - Venda de produção do estabelecimento remetida anteriormente em
        consignação mercantil
      </Select.Option>
      <Select.Option value="6114">
        6114 - Venda de mercadoria adquirida ou recebida ... remetida
        anteriormente em consignação mercantil
      </Select.Option>
      <Select.Option value="6115">
        6115 - Venda de mercadoria adquirida ou recebida ... recebida
        anteriormente em consignação mercantil
      </Select.Option>
      <Select.Option value="6116">
        6116 - Venda de produção do estabelecimento originada de encomenda p/
        entrega futura
      </Select.Option>
      <Select.Option value="6117">
        6117 - Venda de mercadoria adquirida ou recebida ...ceiros, originada de
        encomenda p/ entrega futura
      </Select.Option>
      <Select.Option value="6118">
        6118 - Venda de produção do estabelecimento entre...ordem do adquirente
        originário, em venda à ordem
      </Select.Option>
      <Select.Option value="6119">
        6119 - Venda de mercadoria adquirida ou recebida ...ordem do adquirente
        originário, em venda à ordem
      </Select.Option>
      <Select.Option value="6120">
        6120 - Venda de mercadoria adquirida ou recebida ...atário pelo vendedor
        remetente, em venda à ordem
      </Select.Option>
      <Select.Option value="6122">
        6122 - Venda de produção do estabelecimento remet...sem transitar pelo
        estabelecimento do adquirente
      </Select.Option>
      <Select.Option value="6123">
        6123 - Venda de mercadoria adquirida ou recebida ...sem transitar pelo
        estabelecimento do adquirente
      </Select.Option>
      <Select.Option value="6124">
        6124 - Industrialização efetuada p/ outra empresa
      </Select.Option>
      <Select.Option value="6125">
        6125 - Industrialização efetuada p/ outra empresa...ar pelo
        estabelecimento adquirente da mercadoria
      </Select.Option>
      <Select.Option value="6129">
        6129 - Venda de insumo importado e de mercadoria ...o Especial de
        Entreposto Industrial (Recof-Sped)
      </Select.Option>
      <Select.Option value="6131">
        6131 - Remessa de produção de estabelecimento
      </Select.Option>
      <Select.Option value="6132">
        6132 - Fixação de preço de produção do estabelecimento
      </Select.Option>
      <Select.Option value="6151">
        6151 - Transferência de produção do estabelecimento
      </Select.Option>
      <Select.Option value="6152">
        6152 - Transferência de mercadoria adquirida ou recebida de terceiros
      </Select.Option>
      <Select.Option value="6153">
        6153 - Transferência de energia elétrica
      </Select.Option>
      <Select.Option value="6155">
        6155 - Transferência de produção do estabelecimento, que não deva por
        ele transitar
      </Select.Option>
      <Select.Option value="6156">
        6156 - Transferência de mercadoria adquirida ou r...ida de terceiros,
        que não deva por ele transitar
      </Select.Option>
      <Select.Option value="6159">
        6159 - Fornecimento de produção do estabelecimento de ato cooperativo.
      </Select.Option>
      <Select.Option value="6160">
        6160 - Fornecimento de mercadoria adquirida ou recebida de terceiros de
        ato cooperativo.
      </Select.Option>
      <Select.Option value="6201">
        6201 - Devolução de compra p/ industrialização ou produção rural
      </Select.Option>
      <Select.Option value="6202">
        6202 - Devolução de compra p/ comercialização
      </Select.Option>
      <Select.Option value="6205">
        6205 - Anulação de valor relativo a aquisição de serviço de comunicação
      </Select.Option>
      <Select.Option value="6206">
        6206 - Anulação de valor relativo a aquisição de serviço de transporte
      </Select.Option>
      <Select.Option value="6207">
        6207 - Anulação de valor relativo à compra de energia elétrica
      </Select.Option>
      <Select.Option value="6208">
        6208 - Devolução de mercadoria recebida em transferência p/
        industrialização ou produção rural
      </Select.Option>
      <Select.Option value="6209">
        6209 - Devolução de mercadoria recebida em transferência p/
        comercialização
      </Select.Option>
      <Select.Option value="6210">
        6210 - Devolução de compra p/ utilização na prestação de serviço
      </Select.Option>
      <Select.Option value="6213">
        6213 - Devolução de entrada de mercadoria com previsão de posterior
        ajuste ou fixação de preço
      </Select.Option>
      <Select.Option value="6214">
        6214 - Devolução de fixação de preço de produção do estabelecimento
        produtor
      </Select.Option>
      <Select.Option value="6215">
        6215 - Devolução de fixação de preço de produção do estabelecimento
        produtor
      </Select.Option>
      <Select.Option value="6216">
        6216 - Devolução de entrada decorrente do fornecimento de produto ou
        mercadoria de ato cooperativo
      </Select.Option>
      <Select.Option value="6251">
        6251 - Venda de energia elétrica p/ distribuição ou comercialização
      </Select.Option>
      <Select.Option value="6252">
        6252 - Venda de energia elétrica p/ estabelecimento industrial
      </Select.Option>
      <Select.Option value="6253">
        6253 - Venda de energia elétrica p/ estabelecimento comercial
      </Select.Option>
      <Select.Option value="6254">
        6254 - Venda de energia elétrica p/ estabelecimento prestador de serviço
        de transporte
      </Select.Option>
      <Select.Option value="6255">
        6255 - Venda de energia elétrica p/ estabelecimento prestador de serviço
        de comunicação
      </Select.Option>
      <Select.Option value="6256">
        6256 - Venda de energia elétrica p/ estabelecimento de produtor rural
      </Select.Option>
      <Select.Option value="6257">
        6257 - Venda de energia elétrica p/ consumo por demanda contratada
      </Select.Option>
      <Select.Option value="6258">
        6258 - Venda de energia elétrica a não contribuinte
      </Select.Option>
      <Select.Option value="6301">
        6301 - Prestação de serviço de comunicação p/ execução de serviço da
        mesma natureza
      </Select.Option>
      <Select.Option value="6302">
        6302 - Prestação de serviço de comunicação a estabelecimento industrial
      </Select.Option>
      <Select.Option value="6303">
        6303 - Prestação de serviço de comunicação a estabelecimento comercial
      </Select.Option>
      <Select.Option value="6304">
        6304 - Prestação de serviço de comunicação a estabelecimento de
        prestador de serviço de transporte
      </Select.Option>
      <Select.Option value="6305">
        6305 - Prestação de serviço de comunicação a esta...geradora ou de
        distribuidora de energia elétrica
      </Select.Option>
      <Select.Option value="6306">
        6306 - Prestação de serviço de comunicação a estabelecimento de produtor
        rural
      </Select.Option>
      <Select.Option value="6307">
        6307 - Prestação de serviço de comunicação a não contribuinte
      </Select.Option>
      <Select.Option value="6351">
        6351 - Prestação de serviço de transporte p/ execução de serviço da
        mesma natureza
      </Select.Option>
      <Select.Option value="6352">
        6352 - Prestação de serviço de transporte a estabelecimento industrial
      </Select.Option>
      <Select.Option value="6353">
        6353 - Prestação de serviço de transporte a estabelecimento comercial
      </Select.Option>
      <Select.Option value="6354">
        6354 - Prestação de serviço de transporte a estabelecimento de prestador
        de serviço de comunicação
      </Select.Option>
      <Select.Option value="6355">
        6355 - Prestação de serviço de transporte a estab...geradora ou de
        distribuidora de energia elétrica
      </Select.Option>
      <Select.Option value="6356">
        6356 - Prestação de serviço de transporte a estabelecimento de produtor
        rural
      </Select.Option>
      <Select.Option value="6357">
        6357 - Prestação de serviço de transporte a não contribuinte
      </Select.Option>
      <Select.Option value="6359">
        6359 - Prestação de serviço de transporte a contr...tada esteja
        dispensada de emissão de Nota Fiscal
      </Select.Option>
      <Select.Option value="6360">
        6360 - Prestação de serviço de transporte a contr...e substituto em
        relação ao serviço de transporte
      </Select.Option>
      <Select.Option value="6401">
        6401 - Venda de produção do estabelecimento quando o produto sujeito a
        ST
      </Select.Option>
      <Select.Option value="6402">
        6402 - Venda de produção do estabelecimento de pr...entre contribuintes
        substitutos do mesmo produto
      </Select.Option>
      <Select.Option value="6403">
        6403 - Venda de mercadoria adquirida ou recebida ...ita a ST, na
        condição de contribuinte substituto
      </Select.Option>
      <Select.Option value="6404">
        6404 - Venda de mercadoria sujeita a ST, cujo imposto já tenha sido
        retido anteriormente
      </Select.Option>
      <Select.Option value="6505">
        6505 - Remessa de mercadoria, adquirida ou recebida de terceiros, p/
        formação de lote de exportação.
      </Select.Option>
      <Select.Option value="6408">
        6408 - Transferência de produção do estabelecimento quando o produto
        sujeito a ST
      </Select.Option>
      <Select.Option value="6409">
        6409 - Transferência de mercadoria adquirida ou recebida de terceiros,
        sujeita a ST
      </Select.Option>
      <Select.Option value="6410">
        6410 - Devolução de compra p/ industrialização ou ptrodução rural quando
        a mercadoria sujeita a ST
      </Select.Option>
      <Select.Option value="6411">
        6411 - Devolução de compra p/ comercialização em operação com mercadoria
        sujeita a ST
      </Select.Option>
      <Select.Option value="6412">
        6412 - Devolução de bem do ativo imobilizado, em operação com mercadoria
        sujeita a ST
      </Select.Option>
      <Select.Option value="6413">
        6413 - Devolução de mercadoria destinada ao uso ou consumo, em operação
        com mercadoria sujeita a ST
      </Select.Option>
      <Select.Option value="6414">
        6414 - Remessa de produção do estabelecimento p/ ...o estabelecimento,
        quando o produto sujeito a ST
      </Select.Option>
      <Select.Option value="6415">
        6415 - Remessa de mercadoria adquirida ou recebid...ndo a referida ração
        com mercadoria sujeita a ST
      </Select.Option>
      <Select.Option value="6450">
        6450 - Sistemas de integração e parceria rural
      </Select.Option>
      <Select.Option value="6451">
        6451 - Remessa de animal - Sistema de Integração e Parceria Rural
      </Select.Option>
      <Select.Option value="6452">
        6452 - Remessa de insumo - Sistema de Integração e Parceria Rural
      </Select.Option>
      <Select.Option value="6453">
        6453 - Retorno de animal ou da produção - Sistema de Integração e
        Parceria Rural
      </Select.Option>
      <Select.Option value="6454">
        6454 - Retorno simbólico de animal ou da produção - Sistema de
        Integração e Parceria Rural
      </Select.Option>
      <Select.Option value="6455">
        6455 - Retorno de insumos não utilizados na produção - Sistema de
        Integração e Parceria Rural
      </Select.Option>
      <Select.Option value="6456">
        6456 - Saída referente a remuneração do produtor - Sistema de Integração
        e Parceria Rural
      </Select.Option>
      <Select.Option value="6501">
        6501 - Remessa de produção do estabelecimento, com fim específico de
        exportação
      </Select.Option>
      <Select.Option value="6502">
        6502 - Remessa de mercadoria adquirida ou recebida de terceiros, com fim
        específico de exportação
      </Select.Option>
      <Select.Option value="6503">
        6503 - Devolução de mercadoria recebida com fim específico de exportação
      </Select.Option>
      <Select.Option value="6504">
        6504 - Remessa de mercadoria p/ formação de lote ...izado ou produzido
        pelo próprio estabelecimento.
      </Select.Option>
      <Select.Option value="6551">
        6551 - Venda de bem do ativo imobilizado
      </Select.Option>
      <Select.Option value="6552">
        6552 - Transferência de bem do ativo imobilizado
      </Select.Option>
      <Select.Option value="6553">
        6553 - Devolução de compra de bem p/ o ativo imobilizado
      </Select.Option>
      <Select.Option value="6554">
        6554 - Remessa de bem do ativo imobilizado p/ uso fora do
        estabelecimento
      </Select.Option>
      <Select.Option value="6555">
        6555 - Devolução de bem do ativo imobilizado de terceiro, recebido p/
        uso no estabelecimento
      </Select.Option>
      <Select.Option value="6556">
        6556 - Devolução de compra de material de uso ou consumo
      </Select.Option>
      <Select.Option value="6557">
        6557 - Transferência de material de uso ou consumo
      </Select.Option>
      <Select.Option value="6603">
        6603 - Ressarcimento de ICMS retido por substituição tributária
      </Select.Option>
      <Select.Option value="6651">
        6651 - Venda de combustível ou lubrificante, de p...mento, destinados à
        industrialização subseqüente
      </Select.Option>
      <Select.Option value="6652">
        6652 - Venda de combustível ou lubrificante, de p...do estabelecimento,
        destinados à comercialização
      </Select.Option>
      <Select.Option value="6653">
        6653 - Venda de combustível ou lubrificante, de p...imento, destinados a
        consumidor ou usuário final
      </Select.Option>
      <Select.Option value="6654">
        6654 - Venda de combustível ou lubrificante, adqu...eiros, destinados à
        industrialização subseqüente
      </Select.Option>
      <Select.Option value="6655">
        6655 - Venda de combustível ou lubrificante, adqu...bidos de terceiros,
        destinados à comercialização
      </Select.Option>
      <Select.Option value="6656">
        6656 - Venda de combustível ou lubrificante, adqu...ceiros, destinados a
        consumidor ou usuário final
      </Select.Option>
      <Select.Option value="6657">
        6657 - Remessa de combustível ou lubrificante, ad...s de terceiros, p/
        venda fora do estabelecimento
      </Select.Option>
      <Select.Option value="6658">
        6658 - Transferência de combustível ou lubrificante de produção do
        estabelecimento
      </Select.Option>
      <Select.Option value="6659">
        6659 - Transferência de combustível ou lubrificante adquiridos ou
        recebidos de terceiros
      </Select.Option>
      <Select.Option value="6660">
        6660 - Devolução de compra de combustível ou lubrificante adquiridos p/
        industrialização subseqüente
      </Select.Option>
      <Select.Option value="6661">
        6661 - Devolução de compra de combustível ou lubrificante adquiridos p/
        comercialização
      </Select.Option>
      <Select.Option value="6662">
        6662 - Devolução de compra de combustível ou lubrificante adquiridos por
        consumidor ou usuário final
      </Select.Option>
      <Select.Option value="6663">
        6663 - Remessa p/ armazenagem de combustível ou lubrificante
      </Select.Option>
      <Select.Option value="6664">
        6664 - Retorno de combustível ou lubrificante recebidos p/ armazenagem
      </Select.Option>
      <Select.Option value="6665">
        6665 - Retorno simbólico de combustível ou lubrificante recebidos p/
        armazenagem
      </Select.Option>
      <Select.Option value="6666">
        6666 - Remessa, por conta e ordem de terceiros, d...ustível ou
        lubrificante recebidos p/ armazenagem
      </Select.Option>
      <Select.Option value="6667">
        6667 - Venda de combustível ou lubrificante a con...o em outra UF
        diferente da que ocorrer o consumo
      </Select.Option>
      <Select.Option value="6901">
        6901 - Remessa p/ industrialização por encomenda
      </Select.Option>
      <Select.Option value="6902">
        6902 - Retorno de mercadoria utilizada na industrialização por encomenda
      </Select.Option>
      <Select.Option value="6903">
        6903 - Retorno de mercadoria recebida p/ industrialização e não aplicada
        no referido processo
      </Select.Option>
      <Select.Option value="6904">
        6904 - Remessa p/ venda fora do estabelecimento
      </Select.Option>
      <Select.Option value="6905">
        6905 - Remessa p/ depósito fechado ou armazém geral
      </Select.Option>
      <Select.Option value="6906">
        6906 - Retorno de mercadoria depositada em depósito fechado ou armazém
        geral
      </Select.Option>
      <Select.Option value="6907">
        6907 - Retorno simbólico de mercadoria depositada em depósito fechado ou
        armazém geral
      </Select.Option>
      <Select.Option value="6908">
        6908 - Remessa de bem por conta de contrato de comodato ou locação
      </Select.Option>
      <Select.Option value="6909">
        6909 - Retorno de bem recebido por conta de contrato de comodato ou
        locação
      </Select.Option>
      <Select.Option value="6910">
        6910 - Remessa em bonificação, doação ou brinde
      </Select.Option>
      <Select.Option value="6911">
        6911 - Remessa de amostra grátis
      </Select.Option>
      <Select.Option value="6912">
        6912 - Remessa de mercadoria ou bem p/ demonstração
      </Select.Option>
      <Select.Option value="6913">
        6913 - Retorno de mercadoria ou bem recebido p/ demonstração
      </Select.Option>
      <Select.Option value="6914">
        6914 - Remessa de mercadoria ou bem p/ exposição ou feira
      </Select.Option>
      <Select.Option value="6915">
        6915 - Remessa de mercadoria ou bem p/ conserto ou reparo
      </Select.Option>
      <Select.Option value="6916">
        6916 - Retorno de mercadoria ou bem recebido p/ conserto ou reparo
      </Select.Option>
      <Select.Option value="6917">
        6917 - Remessa de mercadoria em consignação mercantil ou industrial
      </Select.Option>
      <Select.Option value="6918">
        6918 - Devolução de mercadoria recebida em consignação mercantil ou
        industrial
      </Select.Option>
      <Select.Option value="6919">
        6919 - Devolução simbólica de mercadoria vendida ...riormente em
        consignação mercantil ou industrial
      </Select.Option>
      <Select.Option value="6920">
        6920 - Remessa de vasilhame ou sacaria
      </Select.Option>
      <Select.Option value="6921">
        6921 - Devolução de vasilhame ou sacaria
      </Select.Option>
      <Select.Option value="6922">
        6922 - Lançamento efetuado a título de simples faturamento decorrente de
        venda p/ entrega futura
      </Select.Option>
      <Select.Option value="6923">
        6923 - Remessa de mercadoria por conta e ordem de... operações com
        armazém geral ou depósito fechado
      </Select.Option>
      <Select.Option value="6924">
        6924 - Remessa p/ industrialização por conta e or...não transitar pelo
        estabelecimento do adquirente
      </Select.Option>
      <Select.Option value="6925">
        6925 - Retorno de mercadoria recebida p/ industri...não transitar pelo
        estabelecimento do adquirente
      </Select.Option>
      <Select.Option value="6929">
        6929 - Lançamento efetuado em decorrência de emis...ada em equipamento
        Emissor de Cupom Fiscal - ECF
      </Select.Option>
      <Select.Option value="6931">
        6931 - Lançamento efetuado em decorrência da resp...tador não inscrito
        na UF onde iniciado o serviço
      </Select.Option>
      <Select.Option value="6932">
        6932 - Prestação de serviço de transporte iniciada em UF diversa daquela
        onde inscrito o prestador
      </Select.Option>
      <Select.Option value="6933">
        6933 - Prestação de serviço tributado pelo Imposto Sobre Serviços de
        Qualquer Natureza
      </Select.Option>
      <Select.Option value="6934">
        6934 - Remessa simbólica de mercadoria depositada em armazém geral ou
        depósito fechado
      </Select.Option>
      <Select.Option value="7101">
        7101 - Venda de produção do estabelecimento
      </Select.Option>
      <Select.Option value="7102">
        7102 - Venda de mercadoria adquirida ou recebida de terceiros
      </Select.Option>
      <Select.Option value="7105">
        7105 - Venda de produção do estabelecimento, que não deva por ele
        transitar
      </Select.Option>
      <Select.Option value="7106">
        7106 - Venda de mercadoria adquirida ou recebida de terceiros, que não
        deva por ele transitar
      </Select.Option>
      <Select.Option value="7127">
        7127 - Venda de produção do estabelecimento sob o regime de drawback
      </Select.Option>
      <Select.Option value="7129">
        7129 - Venda de produção do estabelecimento ao me...o Especial de
        Entreposto Industrial (Recof-Sped)
      </Select.Option>
      <Select.Option value="7201">
        7201 - Devolução de compra p/ industrialização ou produção rural
      </Select.Option>
      <Select.Option value="7202">
        7202 - Devolução de compra p/ comercialização
      </Select.Option>
      <Select.Option value="7205">
        7205 - Anulação de valor relativo à aquisição de serviço de comunicação
      </Select.Option>
      <Select.Option value="7206">
        7206 - Anulação de valor relativo a aquisição de serviço de transporte
      </Select.Option>
      <Select.Option value="7207">
        7207 - Anulação de valor relativo à compra de energia elétrica
      </Select.Option>
      <Select.Option value="7210">
        7210 - Devolução de compra p/ utilização na prestação de serviço
      </Select.Option>
      <Select.Option value="7211">
        7211 - Devolução de compras p/ industrialização sob o regime de drawback
      </Select.Option>
      <Select.Option value="7212">
        7212 - Devolução de compras para industrialização...o Especial de
        Entreposto Industrial (Recof-Sped)
      </Select.Option>
      <Select.Option value="7251">
        7251 - Venda de energia elétrica p/ o exterior
      </Select.Option>
      <Select.Option value="7301">
        7301 - Prestação de serviço de comunicação p/ execução de serviço da
        mesma natureza
      </Select.Option>
      <Select.Option value="7358">
        7358 - Prestação de serviço de transporte
      </Select.Option>
      <Select.Option value="7501">
        7501 - Exportação de mercadorias recebidas com fim específico de
        exportação
      </Select.Option>
      <Select.Option value="7504">
        7504 - Exportação de mercadoria que foi objeto de formação de lote de
        exportação.
      </Select.Option>
      <Select.Option value="7551">
        7551 - Venda de bem do ativo imobilizado
      </Select.Option>
      <Select.Option value="7553">
        7553 - Devolução de compra de bem p/ o ativo imobilizado
      </Select.Option>
      <Select.Option value="7556">
        7556 - Devolução de compra de material de uso ou consumo
      </Select.Option>
      <Select.Option value="7651">
        7651 - Venda de combustível ou lubrificante de produção do
        estabelecimento
      </Select.Option>
      <Select.Option value="7654">
        7654 - Venda de combustível ou lubrificante adquiridos ou recebidos de
        terceiros
      </Select.Option>
      <Select.Option value="7667">
        7667 - Venda de combustível ou lubrificante a consumidor ou usuário
        final
      </Select.Option>
      <Select.Option value="7930">
        7930 - Lançamento efetuado a título de devolução ...regime especial
        aduaneiro de admissão temporária
      </Select.Option>
      <Select.Option value="7949">
        7949 - Outra saída de mercadoria ou prestação de serviço não
        especificado
      </Select.Option>
    </Select>
  );
};
export default CfopSelect;
