import React, { useEffect } from 'react';
import { Alert } from 'antd';
import RawHtml from './RawHtml';
import { useDispatch, useSelector } from 'react-redux';
import {
  closeActiveAnnouncement,
  getActiveAnnouncements
} from 'stores/announcements/announcementActions';
import { useCompany } from 'core';

const AlertAnnouncement = () => {
  const dispatch = useDispatch();
  const { company } = useCompany();
  const { activeItems = [], closedAnnouncements = [] } =
    useSelector((c) => c.resources.announcements) ?? {};

  useEffect(() => {
    if (company) {
      dispatch(getActiveAnnouncements());
    }
  }, [company]);

  if (activeItems.length === 0) {
    return null;
  }

  const allowShow = () => {
    const pathname = new URL(window.location.href).pathname.replace('/p', '');
    return pathname === '/' || pathname === '';
  };

  const handleClose = (announcementId) => {
    dispatch(closeActiveAnnouncement(announcementId));
  };

  return (
    <>
      {allowShow()
        ? activeItems
            .filter((c) => !closedAnnouncements.includes(c.id))
            .map((announcement) => (
              <Alert
                key={announcement.id}
                className="gx-mb-2"
                type={announcement.type}
                showIcon
                message={<RawHtml html={announcement.message} />}
                style={{ marginTop: '10px' }}
                closable
                onClose={() => handleClose(announcement.id)}
              />
            ))
        : null}
    </>
  );
};

export default AlertAnnouncement;
