import React from 'react';
import { Layout } from 'antd';
import Sidebar from 'components/Sidebar/Sidebar';
import { Routes, RouteRedirect, useAuthContext } from 'react-structure-admin';
import Topbar from 'components/Topbar/Topbar';
import {
  AccountWaitingForActivationModal,
  AlertAnnouncement,
  CrispChat
} from 'components';
import { useCompany, useTenant } from 'core';
import { AWAITING_PAYMENT } from 'constants/types';

const { Content } = Layout;

const AppContainer = (props) => {
  const { match, routes, roles, basePath } = props;
  const { enabled } = useCompany();
  const { isPartner } = useTenant();
  const {
    isAuthenticated,
    company,
    tenantId,
    tenant,
    isMultiTenant,
    isAdmin,
    subscription
  } = useAuthContext();

  const onBeforeRoutePartnerRender = (route) => {
    if (tenant?.status === 'waitingForActivation') {
      return <RouteRedirect to="/r/waiting-for-activation" />;
    }

    return null;
  };

  const onBeforeRouteRender = ({ route }) => {
    if (!isAuthenticated) {
      return null;
    }

    if (isAdmin() && !tenantId) {
      return <RouteRedirect to="/a/customers" />;
    }

    if (isMultiTenant && !tenantId) {
      return <RouteRedirect to="/accounts-chooser" />;
    }

    if (isPartner) {
      return onBeforeRoutePartnerRender(route);
    }

    if (
      (company === null || subscription?.status === AWAITING_PAYMENT) &&
      route?.path !== '/onboarding'
    ) {
      return <RouteRedirect to="/p/onboarding" />;
    }

    if (!enabled && route?.path === '/platforms') {
      return <RouteRedirect to="/p/onboarding" />;
    }

    return null;
  };

  function removeRouteByInactiveCompanies(routes) {
    for (let i = routes.length - 1; i >= 0; i--) {
      const route = routes[i];

      if (route.hideInactiveCompanies === true && !enabled) {
        routes.splice(i, 1);
      }

      if (route.routes && Array.isArray(route.routes)) {
        route.routes = removeRouteByInactiveCompanies(route.routes);
      }
    }

    return routes;
  }

  return (
    <Layout className="gx-app-layout">
      <CrispChat />
      <AccountWaitingForActivationModal />
      <Sidebar
        routes={removeRouteByInactiveCompanies([...routes])}
        match={match}
      />
      <Layout>
        <Topbar />
        <Content
          className="gx-layout-content gx-container-wrap"
          key={company?.id || '0'}
        >
          <AlertAnnouncement />
          <Routes
            match={match}
            routes={routes}
            roles={roles}
            basePath={basePath}
            onBeforeRouteRender={onBeforeRouteRender}
            isContainer
          />
        </Content>
      </Layout>
    </Layout>
  );
};

export default AppContainer;
