/* eslint-disable no-empty */
import { globalEvents, configManager } from 'react-structure-admin';
import { Crisp } from 'crisp-sdk-web';

export default class globalEventsRegister {
  static allowChatPaths = ['/p', '/p/'];
  static drawerCount = 0;
  static currentPath = '';

  static registerEvents() {
    globalEvents.registerEvent('drawerVisibleChanged', (args) => {
      const { allowChat = false } = configManager.getConfig();

      if (args.visible) {
        globalEventsRegister.drawerCount++;
        Crisp.chat.hide();
      } else {
        globalEventsRegister.drawerCount--;
        if (allowChat && globalEventsRegister.drawerCount == 0) {
          Crisp.chat.show();
        }
      }

      if (globalEventsRegister.drawerCount < 0) {
        globalEventsRegister.drawerCount = 0;
      }
    });

    globalEvents.registerEvent('locationChanged', (args) => {
      globalEventsRegister.currentPath = args.pathname;
    });
  }
}
