import React from 'react';
import { Link } from 'react-router-dom';

import { useSelector } from 'react-redux';

import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE,
  THEME_TYPE_LITE
} from 'constants/themeSettings';

const SidebarLogo = () => {
  const { width, themeType } = useSelector((state) => state.settings);

  let { navStyle } = useSelector((state) => state.settings);

  if (width < TAB_SIZE && navStyle === NAV_STYLE_FIXED) {
    navStyle = NAV_STYLE_DRAWER;
  }
  return (
    <div className="gx-layout-sider-header">
      <Link to="/" className="gx-site-logo">
        {navStyle === NAV_STYLE_MINI_SIDEBAR ? (
          <img alt="Spedy" src={require('assets/images/m-logo.svg').default} />
        ) : themeType === THEME_TYPE_LITE ? (
          <img alt="Spedy" src={require('assets/images/logo.svg').default} />
        ) : (
          <img alt="Spedy" src={require('assets/images/logo-app.svg').default} />
        )}
      </Link>
    </div>
  );
};

export default SidebarLogo;
