import { dispatcher } from 'react-structure-admin';

export const ONBOARDING = 'ONBOARDING';
export const ONBOARDING_UNLOAD = 'ONBOARDING_UNLOAD';
export const ONBOARDING_SHOW = 'ONBOARDING_SHOW';
export const ONBOARDING_FINISH = 'ONBOARDING_FINISH';
export const ONBOARDING_SET_VALUES = 'ONBOARDING_SET_VALUES';
export const ONBOARDING_SET_LOADING = 'ONBOARDING_SET_LOADING';
export const ONBOARDING_SET_STEP = 'ONBOARDING_SET_STEP';

export const startOnboarding = () => (dispatch) => {
  dispatch(dispatcher.createAction(ONBOARDING_SHOW, 'onboarding'));
};

export const unload = () => (dispatch) => {
  dispatch(dispatcher.createAction(ONBOARDING_UNLOAD, 'onboarding'));
};

export const setValues = (values) => (dispatch) => {
  dispatch(
    dispatcher.createAction(ONBOARDING_SET_VALUES, 'onboarding', values)
  );
};

export const setLoading = (loading) => (dispatch) => {
  dispatch(
    dispatcher.createAction(ONBOARDING_SET_LOADING, 'onboarding', loading)
  );
};

export const setStep = (step) => (dispatch) => {
  dispatch(
    dispatcher.createAction(ONBOARDING_SET_STEP, 'onboarding', { step })
  );
};
