import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faBusinessTime,
  faUniversity,
  faGavel,
  faCity,
  faBuilding,
  faTachometerAlt,
  faUser,
  faList,
  faClipboardCheck,
  faForward,
  faPencilAlt,
  faTrashAlt,
  faSave,
  faTimes,
  faClipboardList,
  faLayerGroup,
  faBook,
  faHome,
  faFileAlt,
  faFileSignature,
  faPlusCircle,
  faCaretRight,
  faEye,
  faUserShield,
  faUserAlt,
  faChevronLeft,
  faCheck,
  faReply,
  faFilter,
  faDownload,
  faArrowRight,
  faFileContract,
  faInfoCircle,
  faCopy as fasFaCopy,
  faUpload,
  faUndoAlt,
  faUserEdit,
  faLightbulb,
  faExclamationTriangle,
  faCircle,
  faUsers,
  faUserFriends,
  faBox,
  faBoxOpen,
  faThLarge,
  faPrint,
  faHandHoldingUsd,
  faShoppingCart,
  faFileInvoice,
  faHourglassHalf,
  faTimesCircle,
  faSpinner,
  faCog,
  faSyncAlt,
  faCogs,
  faBullhorn,
  faPlug,
  faRedoAlt,
  faSignInAlt,
  faReceipt,
  faCheckCircle,
  faAngleDown,
  faShareSquare,
  faSync,
  faTicketAlt,
  faBell,
  faBan,
  faUpRightFromSquare,
  faFolderTree,
  faCreditCard,
  faBarcode,
  faQrcode,
  faMobileScreenButton,
  faHandshake
} from '@fortawesome/free-solid-svg-icons';

import {
  faCcVisa,
  faCcMastercard,
  faCcDinersClub,
  faCcAmex,
  faPix
} from '@fortawesome/free-brands-svg-icons';

import {
  faCopy as farFaCopy,
  faFileAlt as farFile,
  faTimesCircle as farTimesCircle,
  faPaperPlane as farPaperPlane,
  faEnvelope as farEnvelope,
  faThumbsUp as farThumbsUp,
  faUser as farUser,
  faCheckCircle as farCheckCircle
} from '@fortawesome/free-regular-svg-icons';

library.add(
  fasFaCopy,
  farFaCopy,
  faUniversity,
  faGavel,
  faCity,
  faBuilding,
  faTachometerAlt,
  faUser,
  faList,
  faClipboardCheck,
  faForward,
  faPencilAlt,
  faTrashAlt,
  faSave,
  faTimes,
  faClipboardList,
  faLayerGroup,
  faBook,
  faHome,
  faFileAlt,
  faFileSignature,
  faEye,
  faInfoCircle,
  faPlusCircle,
  faCaretRight,
  faUserShield,
  faChevronLeft,
  faCheck,
  faReply,
  faFilter,
  faDownload,
  faArrowRight,
  faUpload,
  faFileContract,
  faUndoAlt,
  faUserEdit,
  faLightbulb,
  faExclamationTriangle,
  faCircle,
  farFile,
  faUsers,
  faUserFriends,
  faBox,
  faShoppingCart,
  faBoxOpen,
  faThLarge,
  faPrint,
  faBusinessTime,
  faHandHoldingUsd,
  faShoppingCart,
  faFileInvoice,
  faHourglassHalf,
  farTimesCircle,
  faTimesCircle,
  faSpinner,
  farPaperPlane,
  faCcVisa,
  faCcMastercard,
  faCcDinersClub,
  faCcAmex,
  farEnvelope,
  farThumbsUp,
  farUser,
  faCog,
  faSyncAlt,
  faCogs,
  faBullhorn,
  faUserAlt,
  faPlug,
  faUserAlt,
  faSignInAlt,
  faReceipt,
  faRedoAlt,
  farCheckCircle,
  faCheckCircle,
  faAngleDown,
  faShareSquare,
  faSync,
  faTicketAlt,
  faBell,
  faBan,
  faUpRightFromSquare,
  faFolderTree,
  faPix,
  faCreditCard,
  faBarcode,
  faQrcode,
  faMobileScreenButton,
  faHandshake
);
