import React from 'react';
import { Select } from 'antd';

const SelectBorrowerSupportMechanism = ({ ...rest }) => {
  return (
    <Select {...rest}>
      <Select.Option value="00">
        Desconhecido (tipo não informado na nota de origem)
      </Select.Option>
      <Select.Option value="01">Nenhum</Select.Option>
      <Select.Option value="02">
        Adm. Pública e Repr. Internacional
      </Select.Option>
      <Select.Option value="03">
        Alugueis e Arrend. Mercantil de maquinas, equip., embarc. e aeronaves
      </Select.Option>
      <Select.Option value="04">
        Arrendamento Mercantil de aeronave para empresa de transporte aéreo
        público
      </Select.Option>
      <Select.Option value="05">
        Comissão a agentes externos na exportação
      </Select.Option>
      <Select.Option value="06">
        Despesas de armazenagem, mov. e transporte de carga no exterior
      </Select.Option>
      <Select.Option value="07">Eventos FIFA (subsidiária)</Select.Option>
      <Select.Option value="08">Eventos FIFA</Select.Option>
      <Select.Option value="09">
        Fretes, arrendamentos de embarcações ou aeronaves e outros
      </Select.Option>
      <Select.Option value="10">Material Aeronáutico</Select.Option>
      <Select.Option value="11">Promoção de Bens no Exterior</Select.Option>
      <Select.Option value="12">
        Promoção de Dest. Turísticos Brasileiros
      </Select.Option>
      <Select.Option value="13">Promoção do Brasil no Exterior</Select.Option>
      <Select.Option value="14">Promoção Serviços no Exterior</Select.Option>
      <Select.Option value="15">RECINE</Select.Option>
      <Select.Option value="16">RECOPA</Select.Option>
      <Select.Option value="17">
        Registro e Manutenção de marcas, patentes e cultivares
      </Select.Option>
      <Select.Option value="18">REICOMP</Select.Option>
      <Select.Option value="19">REIDI</Select.Option>
      <Select.Option value="20">REPENEC</Select.Option>
      <Select.Option value="21">REPES</Select.Option>
      <Select.Option value="22">RETAERO</Select.Option>
      <Select.Option value="23">RETID</Select.Option>
      <Select.Option value="24">
        Royalties, Assistência Técnica, Científica e Assemelhados
      </Select.Option>
      <Select.Option value="25">
        Serviços de avaliação da conformidade vinculados aos Acordos da OMC
      </Select.Option>
      <Select.Option value="26">ZPE</Select.Option>
    </Select>
  );
};

export default SelectBorrowerSupportMechanism;
