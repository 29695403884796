import { dispatcher, api } from 'react-structure-admin';

export const ANNOUNCEMENT = 'ANNOUNCEMENT';
export const ANNOUNCEMENT_GET_ACTIVE = 'ANNOUNCEMENT_GET_ACTIVE';
export const ANNOUNCEMENT_GET_ACTIVE_FINISHED =
  'ANNOUNCEMENT_GET_ACTIVE_FINISHED';
export const ANNOUNCEMENT_CLOSE_ACTIVE = 'ANNOUNCEMENT_CLOSE_ACTIVE';

export const getActiveAnnouncements = () => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'announcements',
    actionType: ANNOUNCEMENT_GET_ACTIVE,
    effect: async () => api.get('announcements', 'active')
  });
};

export const closeActiveAnnouncement = (announcementId) => async (dispatch) => {
  dispatch(
    dispatcher.createAction(ANNOUNCEMENT_CLOSE_ACTIVE, 'announcements', {
      announcementId
    })
  );
};
