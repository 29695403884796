import React from 'react';
import { Select } from 'antd';

const SelectBoolean = (props, ref) => {
  return (
    <Select
      ref={ref}
      {...props}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      <Select.Option key="true" value="true">
        Sim
      </Select.Option>
      <Select.Option key="false" value="false">
        Não
      </Select.Option>
    </Select>
  );
};

export default React.forwardRef(SelectBoolean);
