import React, { useState } from 'react';
import { Input, Row, Select, Form } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import {
  api,
  RemoteSelect,
  useCrudEditContext,
  FormItemWrap
} from 'react-structure-admin';
import ResCol from 'components/forms/ResCol';
import postalCodeValidator from 'util/validators/postalCodeValidator';
import { cityNormalizeToSelect } from 'util/normalizers';
import lodash from 'lodash';

const AddressV2 = ({ required = true, fieldNames = ['address'] }) => {
  const [state, setState] = useState({
    lastPostalCode: null,
    loadingAddress: false
  });

  const { form } = useCrudEditContext();

  const setAddress = (newAddress) => {
    form.setFieldsValue(
      lodash.set({}, fieldNames, {
        ...newAddress,
        city: cityNormalizeToSelect(newAddress.city)
      })
    );
  };

  const findAddress = (postalCode) => {
    postalCode = postalCode.replace(/[^0-9.]/g, '');

    if (state.lastPostalCode === postalCode) {
      return;
    }

    setState({ ...state, lastPostalCode: postalCode, loadingAddress: true });

    api.fetch('localizations', { postalCode }).then(({ data }) => {
      setState({ ...state, loadingAddress: false });
      setAddress(
        data
          ? data.result
          : {
              street: null,
              number: null,
              district: null,
              additionalInformation: null,
              city: undefined
            }
      );
    });
  };

  const postalCodeChangeHandle = (sender) => {
    const postalCode = sender.target.value.replace(/[^0-9.]/g, '');
    if (postalCode.length === 8) {
      findAddress(postalCode);
    }
  };

  return (
    <>
      <Row>
        <ResCol span={6}>
          <FormItemWrap
            name={[...fieldNames, 'postalCode']}
            label="CEP"
            required={required}
            validateTrigger="onBlur"
            rules={[{ validator: postalCodeValidator }]}
            hasFeedback={state.loadingAddress}
            validateStatus={state.loadingAddress ? 'validating' : undefined}
          >
            <MaskedInput mask="11111-111" onChange={postalCodeChangeHandle} />
          </FormItemWrap>
        </ResCol>
        <ResCol span={18}>
          <FormItemWrap
            name={[...fieldNames, 'street']}
            label="Logradouro"
            required={required}
            whitespace
          >
            <Input maxLength={100} />
          </FormItemWrap>
        </ResCol>
      </Row>
      <Row>
        <ResCol span={6}>
          <FormItemWrap
            name={[...fieldNames, 'number']}
            label="Número"
            required={required}
            whitespace
          >
            <Input maxLength={40} />
          </FormItemWrap>
        </ResCol>
        <ResCol span={18}>
          <FormItemWrap
            name={[...fieldNames, 'additionalInformation']}
            label="Complemento"
          >
            <Input maxLength={40} />
          </FormItemWrap>
        </ResCol>
      </Row>
      <Row>
        <ResCol span={14}>
          <FormItemWrap
            name={[...fieldNames, 'district']}
            label="Bairro"
            required={required}
            whitespace
          >
            <Input maxLength={100} />
          </FormItemWrap>
        </ResCol>
        <ResCol span={10}>
          <FormItemWrap
            name={[...fieldNames, 'city']}
            label="Cidade/Estado"
            required={required}
          >
            <RemoteSelect
              resource="cities"
              optionRender={(c) => (
                <Select.Option key={c.key} value={c.key} label={c.label}>
                  {`${c.item.name} - ${c.item.state.toUpperCase()}`}
                </Select.Option>
              )}
            />
          </FormItemWrap>
        </ResCol>
      </Row>
    </>
  );
};

export default AddressV2;
