import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Alert,
  Collapse,
  Divider,
  Form,
  Row,
  Checkbox,
  Col,
  Button
} from 'antd';
import { FormItemWrap, api, configManager } from 'react-structure-admin';
import { getResume } from 'stores/subscriptions/subscriptionActions';
import { CaretRightOutlined, CloseOutlined } from '@ant-design/icons';
import useCookieHandler from 'core/useCookieHandler';
import { PIX, display } from 'constants/types';
import { formartOnlyNumber } from 'util/formatters';
import Iugu from 'util/iugu';
import CurrencyDisplay from './CurrencyDisplay';
import InputCoupon from './InputCoupon';
import ResCol from './forms/ResCol';
import CreditCardInfo from './CreditCardInfo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const identifiers = ['issues_per_period', 'cost_for_additional_invoice'];
const cookieNames = ['cp', 'planId'];

const CheckboxCollapse = ({ children, value, onChange }) => {
  const handleChange = (keys = []) => {
    if (keys.length > 0) {
      onChange(keys[keys.length - 1]);
      return;
    }
  };

  return (
    <Collapse
      className="gx-bg-white gx-checkoutplan-mb60"
      bordered
      activeKey={value}
      destroyInactivePanel
      onChange={handleChange}
      expandIcon={({ isActive }) => (
        <div style={{ top: -1 }}>
          <Checkbox checked={isActive} />
        </div>
      )}
    >
      {children}
    </Collapse>
  );
};

const CheckoutPlan = ({
  resume,
  allowCoupon = true,
  applyingCoupon = false,
  onCancel,
  onSubmit,
  loading,
  federalTaxNumber = null
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const config = configManager.getConfig();
  const [couponEnabled, setCouponEnabled] = useState(false);
  const { cookies, setCookie, removeCookie } = useCookieHandler(cookieNames);
  const hasError = resume?.errors?.length > 0;
  const { plan } = resume;

  const checkIfCouponIsEnabled = () => {
    api
      .fetch('coupons/has-coupon-enabled', { planId: plan.id })
      .then(({ data }) => {
        if (data?.result) {
          setCouponEnabled(data?.result);
        }
      });
  };

  const handleChangeResume = (couponCode) => {
    dispatch(getResume({ planId: plan?.id, couponCode, federalTaxNumber }));
  };

  const getFeatures = () => {
    return plan.features.filter((c) => identifiers.includes(c.identifier));
  };

  const createMessageError = () => {
    return (resume?.errors ?? []).map(({ message }) => <div>{message}</div>);
  };

  const setMessageErrorCouponCode = (message) => {
    form.setFields([
      {
        name: 'couponCode',
        errors: ['Prencha corretamente o código promocional']
      }
    ]);
  };

  const validateCreditCard = ({
    number,
    expirationDate,
    cardholderName,
    securityCode,
    installments = null,
    couponCode
  }) => {
    const month = expirationDate.substr(0, 2);
    const year = expirationDate.substr(3, 6);
    const [firstName, ...lastNames] = cardholderName.split(' ');
    const lastName = lastNames.join(' ');

    const cc = Iugu.CreditCard(
      formartOnlyNumber(number),
      month,
      year,
      firstName,
      lastName,
      securityCode
    );

    if (cc.valid()) {
      if (config.iuguUseJsTokenization) {
        Iugu.createPaymentToken(cc, (response) => {
          onSubmit({
            couponCode,
            paymentMethod: {
              creditCard: {
                token: response.id,
                holder: {
                  name: cardholderName
                }
              },
              installments
            }
          });
        });
      } else {
        onSubmit({
          couponCode,
          paymentMethod: {
            creditCard: {
              number: formartOnlyNumber(number),
              securityCode: securityCode,
              month: month,
              year: year,
              holder: {
                name: cardholderName
              }
            },
            installments
          }
        });
      }
    }
  };

  const handleFinish = ({ paymentMethod, couponCode }) => {
    if (paymentMethod.type === PIX) {
      onSubmit({ couponCode, paymentMethod });
      return;
    }

    if (resume.amount > 0) {
      validateCreditCard({ ...paymentMethod, couponCode });
    } else {
      onSubmit({
        couponCode: resume.coupon?.code
      });
    }
  };

  const applyCoupon = (couponCode) => {
    if (!couponCode) {
      setMessageErrorCouponCode();
      return;
    }

    form.setFields([{ name: 'couponCode', value: couponCode }]);

    handleChangeResume(couponCode);
  };

  const handleApplyCoupon = () => {
    const { couponCode } = form.getFieldsValue();
    applyCoupon(couponCode);
  };

  const removeCoupon = () => {
    form.setFields([{ name: 'couponCode', value: '' }]);
    handleChangeResume(null);
  };

  const setDefaultPayment = () => {
    if (plan?.paymentMethods?.length === 1) {
      form.setFieldsValue({
        paymentMethod: {
          type: plan?.paymentMethods[0]
        }
      });
    }
  };

  useEffect(() => {
    if (allowCoupon) {
      checkIfCouponIsEnabled();
    }

    setDefaultPayment();
  }, []);

  useEffect(() => {
    if (couponEnabled) {
      if (cookies.cp) {
        applyCoupon(cookies.cp);
      }
    }

    if (cookies.planId) {
      removeCookie('planId');
    }
  }, [cookies, couponEnabled]);

  return (
    <Form
      form={form}
      name="checkout"
      className="gx-h-100 "
      validateTrigger={false}
      onFinish={handleFinish}
      initialValues={{ installments: 1 }}
      {...config.layout.form}
    >
      <Row>
        <ResCol className="gx-h-100" span={12}>
          <div className="gx-h-100 gx-flex-column gx-justify-content-between">
            <div>
              <Divider orientation="left">Resumo da assinatura</Divider>
              <div className="gx-card-plan-list gx-pb-0 gx-pt-0">
                <div className="gx-pb-0">
                  <ul>
                    <div className="gx-fs-xl gx-font-weight-semi-bold gx-mb-3">
                      {resume.plan.name}
                    </div>
                    {getFeatures().map((feature) => (
                      <li key={feature.id}>
                        <span>{feature.description}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <Divider className="gx-mt-4 gx-mb-2" />

              <div>
                {hasError && (
                  <Alert
                    type="error"
                    className="gx-ml-2"
                    message={createMessageError()}
                  />
                )}
                {allowCoupon && couponEnabled && (
                  <>
                    <div className="gx-mb-2">
                      <Collapse
                        className="gx-bg-white gx-checkoutplan-collapse"
                        bordered={false}
                        defaultActiveKey={cookies.cp ? '1' : undefined}
                        expandIcon={({ isActive }) => (
                          <CaretRightOutlined rotate={isActive ? 90 : 0} />
                        )}
                      >
                        <Collapse.Panel
                          key="1"
                          header="Eu tenho um código promocional"
                        >
                          <FormItemWrap
                            name="couponCode"
                            whitespace
                            autoFocus
                            noStyle
                          >
                            <InputCoupon
                              onSubmit={handleApplyCoupon}
                              loading={applyingCoupon}
                            />
                          </FormItemWrap>

                          {resume.coupon && (
                            <Row
                              className="no-margin no-padding"
                              justify="start"
                            >
                              <Col>{resume?.coupon?.code}</Col>
                              <Col>
                                <CloseOutlined
                                  className="gx-pointer gx-text-red"
                                  onClick={removeCoupon}
                                />
                              </Col>
                            </Row>
                          )}
                        </Collapse.Panel>
                      </Collapse>
                    </div>
                    <Divider className="gx-mt-2 gx-mb-2" />
                  </>
                )}
              </div>
            </div>

            <div className="gx-mb-3">
              <table className="gx-checkoutplan-summary gx-ml-3">
                <tr>
                  <td style={{ width: 230 }}>{`Plano ${plan.name} (${
                    display[plan.frequency]
                  })`}</td>
                  <td className="gx-text-right">
                    <CurrencyDisplay amount={plan.baseAmount} strikethrough />
                    <CurrencyDisplay
                      amount={plan.amount}
                      hideValueEqualToZero={false}
                    />
                  </td>
                </tr>

                {resume.baseTaxFee > 0 ? (
                  <tr>
                    <td>Taxa de adesão</td>
                    <td className="gx-text-right">
                      {resume?.baseTaxFee !== resume.taxFee ? (
                        <CurrencyDisplay
                          amount={resume.baseTaxFee}
                          strikethrough
                        />
                      ) : null}
                      <CurrencyDisplay
                        amount={resume.taxFee}
                        hideValueEqualToZero={false}
                        nameForZeroValue="Grátis"
                      />
                    </td>
                  </tr>
                ) : null}

                {resume.discount < 0 ? (
                  <tr>
                    <td>
                      <>
                        Descontos
                        {resume.coupon && resume.coupon.cycleLimit > 0 ? (
                          <>
                            <br />
                            <span className="gx-fs-sm gx-text-grey">
                              {resume.coupon.cycleLimit === 1
                                ? 'No primeiro mês'
                                : `Nos ${resume.coupon.cycleLimit} primeiros meses`}
                            </span>
                          </>
                        ) : null}
                      </>
                    </td>
                    <td className="gx-text-right">
                      <CurrencyDisplay
                        amount={resume.discount}
                        hideValueEqualToZero={false}
                      />
                    </td>
                  </tr>
                ) : null}
              </table>
              <Divider className="gx-mt-2 gx-mb-2" />
              <table className="gx-ml-3">
                <tr>
                  <td style={{ width: 230 }}>Total cobrado hoje</td>
                  <td className="gx-text-right">
                    <CurrencyDisplay
                      className="gx-font-weight-semi-bold"
                      amount={resume.amount}
                      hideValueEqualToZero={false}
                    />
                  </td>
                </tr>
              </table>
              <div className="gx-ml-3 gx-pt-2">
                <span className="gx-fs-sm gx-text-grey">
                  {plan.frequency === 'monthly'
                    ? 'Cobrado mensalmente'
                    : 'Cobrado anualmente'}
                </span>
              </div>
            </div>
          </div>
        </ResCol>
        <ResCol span={12}>
          <Divider orientation="left">Pagamento</Divider>
          <FormItemWrap
            name={['paymentMethod', 'type']}
            rules={[
              { required: true, message: 'Forma de pagamento é obrigatório' }
            ]}
          >
            <CheckboxCollapse>
              {plan.paymentMethods.includes('creditCard') ? (
                <Collapse.Panel
                  key="creditCard"
                  header="Cartão de crédito"
                  extra={<FontAwesomeIcon icon="fa-solid fa-credit-card" />}
                >
                  <CreditCardInfo
                    name="paymentMethod"
                    resume={resume}
                    allowInstallments={plan.frequency === 'yearly'}
                    numberOfInstallments={plan.installments}
                  />
                </Collapse.Panel>
              ) : null}

              {plan.paymentMethods.includes('pix') ? (
                <Collapse.Panel
                  className="gx-checkoutplan-collapse-remove-content"
                  key="pix"
                  header="Pix"
                  extra={<FontAwesomeIcon icon="fa-brands fa-pix" />}
                >
                  <Alert type="warning" message="Em construção" showIcon />
                </Collapse.Panel>
              ) : null}

              {/* {plan.paymentMethods.includes('billetBank') ? (
                <Collapse.Panel
                  className="gx-checkoutplan-collapse-remove-content"
                  key="billetBank"
                  header="Boleto"
                  extra={<FontAwesomeIcon icon="fa-solid fa-barcode" />}
                >
                  <Alert type="warning" message="Em construção" showIcon />
                </Collapse.Panel>
              ) : null} */}
            </CheckboxCollapse>
          </FormItemWrap>
        </ResCol>
      </Row>
      <div className="gx-checkoutplan-footer">
        <Button onClick={onCancel} disabled={loading}>
          Voltar
        </Button>
        <Button
          type="primary"
          onClick={form.submit}
          loading={loading}
          disabled={loading}
        >
          Confirmar pagamento
        </Button>
      </div>
    </Form>
  );
};

export default CheckoutPlan;
