import React, { useState } from 'react';
import { Alert, Button, Checkbox, Collapse, Form, message } from 'antd';
import { DrawerWrapper, Footer } from 'components';
import CreditCardInfo from 'components/CreditCardInfo';
import { useDispatch } from 'react-redux';
import { FormItemWrap, configManager } from 'react-structure-admin';
import {
  changeSubscriptionPaymentMethod,
  closeSubscriptionAvailablePaymentMethod
} from 'stores/subscriptions/subscriptionActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CREDITCARD, PIX } from 'constants/types';
import Iugu from 'util/iugu';
import { formartOnlyNumber } from 'util/formatters';

const CheckboxCollapse = ({ children, value, onChange }) => {
  const handleChange = (keys = []) => {
    if (keys.length > 0) {
      onChange(keys[keys.length - 1]);
      return;
    }
  };

  return (
    <Collapse
      className="gx-bg-white gx-checkoutplan-mb60"
      bordered
      activeKey={value}
      destroyInactivePanel
      onChange={handleChange}
      expandIcon={({ isActive }) => (
        <div style={{ top: -1 }}>
          <Checkbox checked={isActive} />
        </div>
      )}
    >
      {children}
    </Collapse>
  );
};

const SubscriptionChangeAvailablePaymentMethod = ({
  subscription,
  loading
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [validatingCard, setValidatingCard] = useState(false);

  const { plan, paymentMethod } = subscription;

  const validateCreditCard = ({
    number,
    expirationDate,
    cardholderName,
    securityCode,
    installments = null
  }) => {
    const month = expirationDate.substr(0, 2);
    const year = expirationDate.substr(3, 6);
    const [firstName, ...lastNames] = cardholderName.split(' ');
    const lastName = lastNames.join(' ');

    const cc = Iugu.CreditCard(
      formartOnlyNumber(number),
      month,
      year,
      firstName,
      lastName,
      securityCode
    );

    if (cc.valid()) {
      setValidatingCard(true);
      Iugu.createPaymentToken(cc, (response) => {
        setValidatingCard(false);

        handleOnSubmit({
          creditCard: {
            number: formartOnlyNumber(number),
            securityCode: securityCode,
            month: month,
            year: year,
            holder: {
              name: cardholderName
            }
          },
          installments
        });
      });
    }
  };

  const handleSuccess = () => {
    message.success('Forma de pagamento alterado com sucesso.');
    handleClose();
  };

  const handleOnSubmit = (data) => {
    dispatch(
      changeSubscriptionPaymentMethod(subscription.id, data, handleSuccess)
    );
  };

  const handleFinish = () => {
    form.validateFields().then((values) => {
      if (values.paymentMethod.type === 'creditCard') {
        return validateCreditCard(values);
      }

      handleOnSubmit(values.paymentMethod);
    });
  };

  const handleClose = () => {
    dispatch(closeSubscriptionAvailablePaymentMethod());
  };

  return (
    <DrawerWrapper
      title="Alterar forma de pagamento"
      visible
      width={500}
      onClose={handleClose}
    >
      <Form
        {...configManager.getConfig().layout.form}
        name="payments"
        className="gx-h-100"
        form={form}
        validateTrigger={false}
      >
        <FormItemWrap
          name={['paymentMethod', 'type']}
          rules={[
            { required: true, message: 'Forma de pagamento é obrigatório' }
          ]}
        >
          <CheckboxCollapse>
            {plan.paymentMethod.includes('creditCard') &&
            paymentMethod?.type !== CREDITCARD ? (
              <Collapse.Panel
                key="creditCard"
                header="Cartão de crédito"
                extra={<FontAwesomeIcon icon="fa-solid fa-credit-card" />}
              >
                <CreditCardInfo />
              </Collapse.Panel>
            ) : null}

            {plan.paymentMethod.includes('pix') &&
            paymentMethod?.type !== PIX ? (
              <Collapse.Panel
                className="gx-checkoutplan-collapse-remove-content"
                key="pix"
                header="Pix"
                extra={<FontAwesomeIcon icon="fa-brands fa-pix" />}
              >
                <Alert type="warning" message="Em construção" showIcon />
              </Collapse.Panel>
            ) : null}
          </CheckboxCollapse>
        </FormItemWrap>
        <Footer>
          <Button onClick={handleClose}>Voltar</Button>
          <Button type="primary" onClick={handleFinish} loading={loading}>
            Alterar
          </Button>
        </Footer>
      </Form>
    </DrawerWrapper>
  );
};

export default SubscriptionChangeAvailablePaymentMethod;
