import { dispatcher, api } from 'react-structure-admin';

export const USER = 'USER';
export const USER_UPDATE_PASSWORD = 'USER_UPDATE_PASSWORD';
export const USER_SAVE_PASSWORD = 'USER_SAVE_PASSWORD';
export const USER_SAVE_PASSWORD_FINISHED = 'USER_SAVE_PASSWORD_FINISHED';
export const USER_SAVE_PASSWORD_FAILED = 'USER_SAVE_PASSWORD_FAILED';
export const USER_CURRENT_GET = 'USER_CURRENT_GET';
export const USER_CURRENT_GET_FINISHED = 'USER_CURRENT_GET_FINISHED';
export const USER_SETTINGS_CURRENT_GET_FINISHED =
  'USER_SETTINGS_CURRENT_GET_FINISHED';
export const USER_CURRENT_CANCEL = 'USER_CURRENT_CANCEL';
export const USER_CURRENT_UPDATE = 'USER_CURRENT_UPDATE';
export const USER_CURRENT_UPDATE_FAILED = 'USER_CURRENT_UPDATE_FAILED';
export const USER_CURRENT_UPDATE_FINISHED = 'USER_CURRENT_UPDATE_FINISHED';

export const USER_REPORT_INITIALIZE = 'USER_REPORT_INITIALIZE';
export const USER_REPORT_CANCEL = 'USER_REPORT_CANCEL';
export const USER_REPORT_SEND = 'USER_REPORT_SEND';
export const USER_REPORT_FAIL = 'USER_REPORT_FAIL';
export const USER_REPORT_SEND_FINISHED = 'USER_REPORT_SEND_FINISHED';
export const USER_RESEND_INVITATION = 'USER_RESEND_INVITATION';
export const USER_RESEND_INVITATION_FINISHED =
  'USER_RESEND_INVITATION_FINISHED';
export const USER_CANCEL_INVITATION = 'USER_CANCEL_INVITATION';
export const USER_CANCEL_INVITATION_FINISHED =
  'USER_CANCEL_INVITATION_FINISHED';

export const USER_CREATE_CONTRIBUTOR = 'USER_CREATE_CONTRIBUTOR';
export const USER_CREATE_CONTRIBUTOR_FINISHED =
  'USER_CREATE_CONTRIBUTOR_FINISHED';

export const USER_CONFIRM_EMAIL = 'USER_CONFIRM_EMAIL';
export const USER_CONFIRM_EMAIL_FINISHED = 'USER_CONFIRM_EMAIL_FINISHED';

export const USER_GET_TENANT_SETTINGS = 'USER_GET_TENANT_SETTINGS';
export const USER_GET_TENANT_SETTINGS_FINISHED =
  'USER_GET_TENANT_SETTINGS_FINISHED';

export const USER_UPDATE_TENANT_SETTINGS = 'USER_UPDATE_TENANT_SETTINGS';
export const USER_UPDATE_TENANT_SETTINGS_FINISHED =
  'USER_UPDATE_TENANT_SETTINGS_FINISHED';

export const USER_CLOSE_TENANT_SETTINGS = 'USER_CLOSE_TENANT_SETTINGS';

export const USER_REMOVE_TENANT = 'USER_REMOVE_TENANT';
export const USER_REMOVE_TENANT_FINISHED = 'USER_REMOVE_TENANT_FINISHED';

export const USER_SET_EDITING_BY_ADMIN = 'USER_EDITING_BY_ADMIN';

export const updatePassword = (payload) => (dispatch) => {
  dispatch(dispatcher.createAction(USER_UPDATE_PASSWORD, 'users', payload));
};

export const savePassword = (userId, payload, onSuccess, onFail) => async (
  dispatch
) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'users',
    actionType: USER_SAVE_PASSWORD,
    effect: async () => api.put('users/password', { ...payload, id: userId }),
    onSuccess,
    onFail
  });
};

export const savePasswordFailed = () => (dispatch) => {
  dispatch(dispatcher.createAction(USER_SAVE_PASSWORD_FAILED, 'users'));
};

export const currentUserUpdateFailed = () => (dispatch) => {
  dispatch(dispatcher.createAction(USER_CURRENT_UPDATE_FAILED, 'users'));
};

export const currentUserUpdate = (data, onSuccess) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'users',
    actionType: USER_CURRENT_UPDATE,
    payload: data,
    effect: async () => api.put(`users/${data.id}`, data),
    onSuccess,
    onFail: () => dispatch(currentUserUpdateFailed())
  });
};

export const currentUserGet = (id) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'users',
    actionType: USER_CURRENT_GET,
    effect: async () => api.get('users', id)
  });
};

export const setEditingByAdmin = () => (dispatch) => {
  dispatch(dispatcher.createAction(USER_SET_EDITING_BY_ADMIN, 'users'));
};

export const currentUserSettingsGet = (id) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'users',
    actionType: USER_SETTINGS_CURRENT_GET_FINISHED,
    effect: async () => api.get('users', id)
  });
};

export const currentUserCancel = () => (dispatch) => {
  dispatch(dispatcher.createAction(USER_CURRENT_CANCEL, 'users'));
};

export const createContributor = (params, onSuccess) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'users',
    actionType: USER_CREATE_CONTRIBUTOR,
    effect: async () => api.post('users/contributor', params),
    onSuccess
  });
};

export const resendInvitation = (userId, onSuccess) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'users',
    actionType: USER_RESEND_INVITATION,
    effect: async () =>
      api.post(`users/resend-invitation/?userId=${userId}`, userId),
    onSuccess
  });
};

export const cancelInvitation = (userId, onSuccess) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'users',
    actionType: USER_CANCEL_INVITATION,
    effect: async () => api.del('users/cancel-invitation', `?userId=${userId}`),
    onSuccess
  });
};

export const confirmEmail = (userId, tenantId, onSuccess) => async (
  dispatch
) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'users',
    actionType: USER_CONFIRM_EMAIL,
    effect: async () =>
      api.post(`users/${userId}/confirm-email?tenantId=${tenantId}`, {}),
    onSuccess
  });
};

export const getTenantSettings = (userId) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'users',
    actionType: USER_GET_TENANT_SETTINGS,
    payload: userId,
    effect: async () => api.get('users', `${userId}/tenant-settings`)
  });
};

export const updateTenantSettings = (userId, data, onSuccess) => async (
  dispatch
) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'users',
    actionType: USER_UPDATE_TENANT_SETTINGS,
    payload: data,
    effect: async () => api.put(`users/${userId}/tenant-settings`, data),
    onSuccess
  });
};

export const closeTenantSettings = () => (dispatch) => {
  dispatch(dispatcher.createAction(USER_CLOSE_TENANT_SETTINGS, 'users'));
};

export const removeTenant = (userId, onSuccess) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'users',
    actionType: USER_REMOVE_TENANT,
    effect: async () => api.del('users', `${userId}/tenant`),
    onSuccess
  });
};
