import React, { useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';

const InputPercent = ({
  suffix = '%',
  decimalSeparator = ',',
  groupSeparator = '.',
  decimalPlaces = 2,
  onChange,
  value,
  ...rest
}) => {
  const [userValue, setUserValue] = useState(value);

  const triggerChange = (changedValue) => {
    const number = changedValue
      ? parseFloat(changedValue.replace(decimalSeparator, '.'))
      : null;

    if (number > -1 && number <= 100) {
      onChange?.(number);
      setUserValue(changedValue);
    } else {
      onChange?.(null);
      setUserValue(null);
    }
  };

  useEffect(() => {
    setUserValue(value ?? 0);
  }, [value]);

  return (
    <>
      <CurrencyInput
        className="ant-input"
        suffix={suffix}
        decimalScale={decimalPlaces}
        decimalSeparator={decimalSeparator}
        groupSeparator={groupSeparator}
        decimalsLimit={decimalPlaces}
        allowNegativeValue={false}
        min={0}
        max={100}
        onValueChange={triggerChange}
        value={userValue}
        {...rest}
      />
    </>
  );
};
export default InputPercent;
