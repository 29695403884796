import React, { useState } from 'react';
import { Input, Row, Col } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import {
  FormItem,
  api,
  RemoteSelect,
  useFormContext,
  normalizeToSelect
} from 'react-structure-admin';
import { cityNormalizeToSelect } from 'util/normalizers';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 9 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 15 }
  },
  labelAlign: 'left',
  colon: false
};

const Address = ({ address }) => {
  if (!address) {
    address = {};
  }

  const [state, setState] = useState({
    lastPostalCode: null,
    loadingAddress: false
  });

  const { form } = useFormContext();

  const setAddress = (newAddress) => {
    form.setFieldsValue({
      address: {
        ...newAddress,
        city: cityNormalizeToSelect(newAddress.city)
      }
    });
  };

  const findAddress = (postalCode) => {
    postalCode = postalCode.replace(/[^0-9.]/g, '');

    if (state.lastPostalCode === postalCode) {
      return;
    }

    setState({ ...state, lastPostalCode: postalCode, loadingAddress: true });

    api.fetch('localizations', { postalCode }).then(({ data }) => {
      setState({ ...state, loadingAddress: false });
      setAddress(
        data
          ? data.result
          : {
              street: null,
              number: null,
              district: null,
              additionalInformation: null,
              city: undefined
            }
      );
    });
  };

  const handlePostalCodeChange = (sender) => {
    const postalCode = sender.target.value.replace(/[^0-9.]/g, '');
    if (postalCode.length === 8) {
      findAddress(postalCode);
    }
  };

  const {
    postalCode,
    number,
    district,
    additionalInformation,
    city,
    street
  } = address;

  return (
    <>
      <Row>
        <Col span={12}>
          <FormItem
            {...formItemLayout}
            name="address.postalCode"
            label="CEP"
            isRequired
            initialValue={postalCode}
            hasFeedback={state.loadingAddress}
            validateStatus={state.loadingAddress ? 'validating' : undefined}
          >
            <MaskedInput onChange={handlePostalCodeChange} mask="11111-111" />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            {...formItemLayout}
            name="address.street"
            label="Logradouro"
            isRequired
            initialValue={street}
          >
            <Input maxLength={100} />
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <FormItem
            {...formItemLayout}
            name="address.number"
            label="Número"
            isRequired
            initialValue={number}
          >
            <Input maxLength={40} />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            {...formItemLayout}
            name="address.district"
            label="Bairro"
            isRequired
            initialValue={district}
          >
            <Input maxLength={40} />
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <FormItem
            {...formItemLayout}
            name="address.additionalInformation"
            label="Complemento"
            initialValue={additionalInformation}
          >
            <Input maxLength={100} />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            {...formItemLayout}
            name="address.city"
            label="Cidade"
            isRequired
            initialValue={normalizeToSelect(city)}
          >
            <RemoteSelect resource="cities" />
          </FormItem>
        </Col>
      </Row>
    </>
  );
};

export default Address;
