import { dispatcher, api } from 'react-structure-admin';

export const PRODUCT = 'PRODUCT';
export const PRODUCT_GET_SETTINGS = 'PRODUCT_GET_SETTINGS';

export const PRODUCT_SHOW_COPRODUCTION_INVOICES =
  'PRODUCT_SHOW_COPRODUCTION_INVOICES';
export const PRODUCT_CLOSE_COPRODUCTION_INVOICES =
  'PRODUCT_CLOSE_COPRODUCTION_INVOICES';

export const PRODUCT_GET_COPRODUCTION_INVOICES =
  'PRODUCT_GET_COPRODUCTION_INVOICES';
export const PRODUCT_GET_COPRODUCTION_INVOICES_FINISHED =
  'PRODUCT_GET_COPRODUCTION_INVOICES_FINISHED';

export const PRODUCT_SHOW_BATCH = 'PRODUCT_SHOW_BATCH';
export const PRODUCT_CLOSE_BATCH = 'PRODUCT_CLOSE_BATCH';
export const PRODUCT_CHANGED_BATCH = 'PRODUCT_CHANGED_BATCH';

export const PRODUCT_SAVE_PRODUCTS_BATCH = 'PRODUCT_SAVE_PRODUCTS_BATCH';
export const PRODUCT_SAVE_PRODUCTS_BATCH_FINISHED =
  'PRODUCT_SAVE_PRODUCTS_BATCH_FINISHED';

export const PRODUCT_REPROCESS_COPRODUCTION_SPLIT_INVOICES =
  'PRODUCT_REPROCESS_COPRODUCTION_SPLIT_INVOICES';
export const PRODUCT_REPROCESS_COPRODUCTION_SPLIT_INVOICES_FINISHED =
  'PRODUCT_REPROCESS_COPRODUCTION_SPLIT_INVOICES_FINISHED';

export const getSettings = (productId, onSuccess) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'products',
    actionType: PRODUCT_GET_SETTINGS,
    effect: async () => api.get('products', `${productId}/invoice-settings`),
    onSuccess
  });
};

export const showCoproductionInvoices = (params) => (dispatch) => {
  dispatch(
    dispatcher.createAction(
      PRODUCT_SHOW_COPRODUCTION_INVOICES,
      'products',
      params
    )
  );
};

export const closeCoproductionInvoices = () => (dispatch) => {
  dispatch(
    dispatcher.createAction(PRODUCT_CLOSE_COPRODUCTION_INVOICES, 'products')
  );
};

export const getCoproductionInvoices = (code, platformType) => async (
  dispatch
) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'products',
    actionType: PRODUCT_GET_COPRODUCTION_INVOICES,
    effect: async () =>
      api.get(
        'reports',
        `coproduction-invoice-summaries/?productCode=${code}&platformType=${platformType}`
      )
  });
};

export const addOrReplaceChangedProductInBatch = (product) => (dispatch) => {
  dispatch(dispatcher.createAction(PRODUCT_CHANGED_BATCH, 'products', product));
};

export const showBatch = () => (dispatch) => {
  dispatch(dispatcher.createAction(PRODUCT_SHOW_BATCH, 'products'));
};

export const closeBatch = () => (dispatch) => {
  dispatch(dispatcher.createAction(PRODUCT_CLOSE_BATCH, 'products'));
};

export const updateProducts = (products) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'products',
    actionType: PRODUCT_SAVE_PRODUCTS_BATCH,
    effect: async () => api.put('products/batch', products)
  });
};

export const reprocessCoproductionSplitInvoices = (
  code,
  platformType,
  onSuccess
) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'products',
    actionType: PRODUCT_REPROCESS_COPRODUCTION_SPLIT_INVOICES,
    effect: async () =>
      api.post(
        `products/reprocess-coproduction-split-invoices/?code=${code}&platformType=${platformType}`
      ),
    onSuccess
  });
};
