import {
  createResourceReducer,
  updateResourceState,
  RESOURCE
} from 'react-structure-admin';
import {
  ACCOUNT,
  ACCOUNT_REFRESH,
  ACCOUNT_CURRENT_CLOSE,
  ACCOUNT_CURRENT_GET,
  ACCOUNT_CURRENT_GET_FINISHED,
  ACCOUNT_CURRENT_EDIT,
  ACCOUNT_CURRENT_EDIT_FINISHED,
  ACCOUNT_OPEN_REPLACE_COMPANY,
  ACCOUNT_CLOSE_REPLACE_COMPANY,
  ACCOUNT_COMPANY_REPLACE_COMPANY,
  ACCOUNT_COMPANY_REPLACE_COMPANY_FINISHED,
  ACCOUNT_ACTIVATE_FINISHED
} from './accountActions';

const accountReducer = createResourceReducer(
  ACCOUNT,
  [],
  {
    [ACCOUNT_REFRESH](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        loaded: false
      }));
    },

    [ACCOUNT_CURRENT_GET](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({}));
    },

    [ACCOUNT_CURRENT_GET_FINISHED](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        currentAccount: action.payload.data.result,
        editingCurrentAccount: true
      }));
    },

    [ACCOUNT_CURRENT_CLOSE](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        currentAccount: null,
        editingCurrentAccount: false
      }));
    },

    [ACCOUNT_CURRENT_EDIT](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        currentAccount: action.payload,
        saving: true
      }));
    },

    [ACCOUNT_CURRENT_EDIT_FINISHED](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        editingCurrentAccount: false,
        currentAccount: null,
        resourceToEdit: action.payload.data.result,
        saving: false
      }));
    },

    [ACCOUNT_OPEN_REPLACE_COMPANY](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        replacingCompany: true,
        replacedCompany: action.payload
      }));
    },

    [ACCOUNT_CLOSE_REPLACE_COMPANY](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        replacingCompany: false,
        replacingCurrentCompany: null
      }));
    },

    [ACCOUNT_COMPANY_REPLACE_COMPANY](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        saving: true
      }));
    },

    [ACCOUNT_COMPANY_REPLACE_COMPANY_FINISHED](state, action) {
      return {
        ...updateResourceState(state, action, RESOURCE, () => ({
          replacingCompany: false,
          replacingCurrentCompany: null,
          saving: false
        })),
        companies: {
          ...state.companies,
          loaded: false
        }
      };
    },
    [ACCOUNT_ACTIVATE_FINISHED](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        loaded: false
      }));
    }
  },
  (state, action) => {
    return updateResourceState(state, action, RESOURCE, () => ({
      saving: false
    }));
  }
);

export default accountReducer;
