import { Input } from 'antd';
import React from 'react';

const regex = /^-?\d*(\.\d*)?$/;

const InputNumeric = ({
  style,
  onChange,
  value,
  onBlur,
  maxLength,
  placeholder
}) => {
  const handleChange = (e) => {
    if (!onChange) {
      return;
    }
    const { value: inputValue } = e.target;

    if (regex.test(inputValue) || inputValue === '') {
      onChange(inputValue);
    }
  };

  return (
    <Input
      style={style}
      onChange={handleChange}
      value={value}
      onBlur={onBlur}
      maxLength={maxLength}
      placeholder={placeholder}
    />
  );
};

export default InputNumeric;
