import {
  updateResourceState,
  createResourceReducer,
  RESOURCE,
  RECORD_FIELD,
  RESOURCE_ACTION_EDIT
} from 'react-structure-admin';
import {
  PRODUCT,
  PRODUCT_CLOSE_BATCH,
  PRODUCT_CLOSE_COPRODUCTION_INVOICES,
  PRODUCT_GET_COPRODUCTION_INVOICES,
  PRODUCT_GET_COPRODUCTION_INVOICES_FINISHED,
  PRODUCT_CHANGED_BATCH,
  PRODUCT_SHOW_BATCH,
  PRODUCT_SHOW_COPRODUCTION_INVOICES,
  PRODUCT_SAVE_PRODUCTS_BATCH_FINISHED,
  PRODUCT_SAVE_PRODUCTS_BATCH,
  PRODUCT_REPROCESS_COPRODUCTION_SPLIT_INVOICES,
  PRODUCT_REPROCESS_COPRODUCTION_SPLIT_INVOICES_FINISHED
} from './productActions';

const productReducer = createResourceReducer(
  PRODUCT,
  [],
  {
    [PRODUCT_SHOW_COPRODUCTION_INVOICES](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        coproductionInvoices: action.payload
      }));
    },

    [PRODUCT_CLOSE_COPRODUCTION_INVOICES](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        coproductionInvoices: null
      }));
    },

    [PRODUCT_GET_COPRODUCTION_INVOICES](state, action) {
      return updateResourceState(
        state,
        action,
        RESOURCE,
        ({ coproductionInvoices = {} }) => ({
          coproductionInvoices: {
            ...coproductionInvoices,
            loading: true
          }
        })
      );
    },

    [PRODUCT_GET_COPRODUCTION_INVOICES_FINISHED](state, action) {
      return updateResourceState(
        state,
        action,
        RESOURCE,
        ({ coproductionInvoices = {} }) => ({
          coproductionInvoices: {
            ...coproductionInvoices,
            loading: false,
            items: action.payload.data.result
          }
        })
      );
    },

    [PRODUCT_SHOW_BATCH](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        batchEditing: true,
        batchChangedProducts: []
      }));
    },

    [PRODUCT_CLOSE_BATCH](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        batchEditing: false,
        batchChangedProducts: []
      }));
    },

    [PRODUCT_CHANGED_BATCH](state, action) {
      return updateResourceState(
        state,
        action,
        RESOURCE,
        ({ batchChangedProducts = [] }) => {
          const filterRemove = (c) => {
            return c.id !== action.payload.id;
          };

          return {
            batchChangedProducts: [
              ...batchChangedProducts.filter(filterRemove),
              action.payload
            ]
          };
        }
      );
    },

    [PRODUCT_SAVE_PRODUCTS_BATCH](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        batchSaving: true
      }));
    },

    [PRODUCT_SAVE_PRODUCTS_BATCH_FINISHED](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        batchSaving: false,
        batchEditing: false,
        batchChangedProducts: []
      }));
    },

    [PRODUCT_REPROCESS_COPRODUCTION_SPLIT_INVOICES](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        reprocessingSplitInvoices: true
      }));
    },

    [PRODUCT_REPROCESS_COPRODUCTION_SPLIT_INVOICES_FINISHED](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        reprocessingSplitInvoices: false
      }));
    }
  },
  (state, action) => {
    return updateResourceState(state, action, RESOURCE, () => ({
      batchSaving: false,
      reprocessingSplitInvoices: false
    }));
  }
);

export default productReducer;
