import React from 'react';
import { Button, Popconfirm } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ActionsList = ({ editing = true, record, initEditing, removeRecord }) => {
  return (
    <span
      key={record.id}
      className="gx-flex-row gx-flex-nowrap gx-justify-content-end"
    >
      {editing ? (
        <Button
          size="small"
          onClick={() => initEditing(record)}
          icon={<FontAwesomeIcon className="icon gx-mr-1" icon="pencil-alt" />}
        >
          Editar
        </Button>
      ) : null}
      <Popconfirm
        title="Deseja excluir?"
        onConfirm={() => removeRecord(record.id)}
        cancelText="Não"
        okText="Sim"
      >
        <Button
          type="danger"
          size="small"
          icon={<FontAwesomeIcon className="icon gx-mr-1" icon="trash-alt" />}
        >
          Excluir
        </Button>
      </Popconfirm>
    </span>
  );
};

export default ActionsList;
