import React from 'react';
import { Tag } from 'antd';
import {
  customerTypeDisplay,
  locationTypesDisplay,
  taxationTypeDisplay
} from 'constants/types';
import getStates from 'util/getStates';

const TaxationGroupListItem = ({
  type,
  states: listOfStates = [],
  customerTypes = [],
  locationTypes = [],
  taxationType
}) => {
  if (type === 'productInvoice') {
    return (
      <div>
        <div className="gx-mb-3">
          <div className="gx-text-grey">Tipo de cliente</div>
          <div className="gx-fs-md gx-mt-1">
            {customerTypes.map((type, index) => (
              <>
                <span key={type} className="gx-mr-1">
                  {customerTypeDisplay[type]}
                </span>
                <span className="gx-mr-1">
                  {index !== customerTypes.length - 1 && ' | '}
                </span>
              </>
            ))}
          </div>
        </div>

        <div className="gx-mb-1">
          <div className="gx-text-grey">Estados</div>
          <div className="gx-mt-1">
            {listOfStates.map((c) => {
              const name = getStates(true).find((d) => d.initials === c)?.name;
              return <Tag key={name}>{name}</Tag>;
            })}
          </div>
        </div>
      </div>
    );
  }

  if (type === 'serviceInvoice') {
    return (
      <div>
        <div className="gx-mb-3">
          <div className="gx-text-grey">Localidade do cliente</div>
          <div className="gx-fs-md gx-mt-1">
            {locationTypes.map((type, index) => (
              <React.Fragment key={type}>
                <span key={type} className="gx-mr-1">
                  {locationTypesDisplay[type]}
                </span>
                <span className="gx-mr-1">
                  {index !== locationTypes.length - 1 && ' | '}
                </span>
              </React.Fragment>
            ))}
          </div>
        </div>

        <div className="gx-mb-1">
          <div className="gx-text-grey">Natureza da Operação</div>
          <div className="gx-mt-1">{taxationTypeDisplay[taxationType]}</div>
        </div>
      </div>
    );
  }

  return null;
};

export default TaxationGroupListItem;
