import React, { useEffect, useState } from 'react';
import { Select } from 'antd';

const SelectAccountPartnerType = ({ value, onChange }) => {
  const [loaded, setLoaded] = useState(false);
  const [item, setItem] = useState(value);

  useEffect(() => {
    if (loaded) {
      setItem(value);
    }
  }, [value]);

  useEffect(() => {
    if (loaded && onChange) {
      onChange(item);
    }
  }, [item]);

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
    }
  }, []);

  return (
    <Select
      onChange={setItem}
      value={item}
      className={item ? 'ant-selected-item' : undefined}
    >
      <Select.Option value="accounting">Contabilidade</Select.Option>
      <Select.Option value="launchAgency">Agência de lançamento</Select.Option>
      <Select.Option value="affiliate">Afiliado</Select.Option>
    </Select>
  );
};

export default SelectAccountPartnerType;
