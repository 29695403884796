import {
  updateResourceState,
  createResourceReducer,
  RESOURCE
} from 'react-structure-admin';

import {
  REFERRALPROGRAM,
  REFERRALPROGRAM_CLOSE_WITHDRAW,
  REFERRALPROGRAM_GET_WITHDRAW,
  REFERRALPROGRAM_GET_WITHDRAW_FINISHED,
  REFERRALPROGRAM_SAVE_WITHDRAW,
  REFERRALPROGRAM_SAVE_WITHDRAW_FINISHED
} from './referralProgramActions';

const referralProgramReducer = createResourceReducer(REFERRALPROGRAM, [], {
  [REFERRALPROGRAM_GET_WITHDRAW](state, action) {
    return updateResourceState(state, action, RESOURCE, () => ({
      creatingWithdraw: null
    }));
  },

  [REFERRALPROGRAM_GET_WITHDRAW_FINISHED](state, action) {
    return updateResourceState(state, action, RESOURCE, () => ({
      creatingWithdraw: action.payload.data.result
    }));
  },

  [REFERRALPROGRAM_CLOSE_WITHDRAW](state, action) {
    return updateResourceState(state, action, RESOURCE, () => ({
      creatingWithdraw: null
    }));
  },

  [REFERRALPROGRAM_SAVE_WITHDRAW](state, action) {
    return updateResourceState(state, action, RESOURCE, () => ({
      saving: true
    }));
  },

  [REFERRALPROGRAM_SAVE_WITHDRAW_FINISHED](state, action) {
    return updateResourceState(state, action, RESOURCE, () => ({
      creatingWithdraw: null,
      saving: false,
      loaded: false
    }));
  }
});

export default referralProgramReducer;
