function formatNumber(value, fractionDigits = 2) {
  let valueInt = Number(value);
  if (Number.isNaN(valueInt)) {
    valueInt = 0;
  }
  if (!(typeof fractionDigits === 'number')) {
    fractionDigits = 2;
  }

  const formattedValue = valueInt.toLocaleString('pt-BR', {    
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits
  });

  return formattedValue;
}
export default formatNumber;
