import { message } from 'antd';
import { dispatcher, api } from 'react-structure-admin';

export const MONTHLY_CLOSING = 'MONTHLY_CLOSING';
export const MONTHLY_CLOSING_GET = 'MONTHLY_CLOSING_GET';
export const MONTHLY_CLOSING_GET_FINISHED = 'MONTHLY_CLOSING_GET_FINISHED';
export const MONTHLY_CLOSING_GENERATE = 'MONTHLY_CLOSING_GENERATE';
export const MONTHLY_CLOSING_GENERATE_FAILED =
  'MONTHLY_CLOSING_GENERATE_FAILED';
export const MONTHLY_CLOSING_GENERATE_FINISHED =
  'MONTHLY_CLOSING_GENERATE_FINISHED';
export const MONTHLY_CLOSING_SETTINGS_GET = 'MONTHLY_CLOSING_SETTINGS_GET';
export const MONTHLY_CLOSING_SETTINGS_GET_FINISHED =
  'MONTHLY_CLOSING_SETTINGS_GET_FINISHED';
export const MONTHLY_CLOSING_SETTINGS_UPDATE =
  'MONTHLY_CLOSING_SETTINGS_UPDATE';
export const MONTHLY_CLOSING_SETTINGS_UPDATE_FINISHED =
  'MONTHLY_CLOSING_SETTINGS_UPDATE_FINISHED';

export const MONTHLY_CLOSING_DELETE_INVOICE_ISSUANCE_UNLOCK =
  'MONTHLY_CLOSING_DELETE_INVOICE_ISSUANCE_UNLOCK';
export const MONTHLY_CLOSING_DELETE_INVOICE_ISSUANCE_UNLOCK_FINISHED =
  'MONTHLY_CLOSING_DELETE_INVOICE_ISSUANCE_UNLOCK_FINISHED';

export const get = (year, month, onSuccess) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'monthlyClosings',
    actionType: MONTHLY_CLOSING_GET,
    onSuccess,
    onFail: (response) => {
      if (response.status !== 404) {
        return false;
      }
      return true;
    },
    effect: async () =>
      api.fetch('monthly-closings/:year/:month', { year, month })
  });
};

export const generateFailed = () => (dispatch) => {
  dispatch(
    dispatcher.createAction(MONTHLY_CLOSING_GENERATE_FAILED, 'monthlyClosings')
  );
};

export const generate = (payload = {}, onSuccess) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'monthlyClosings',
    actionType: MONTHLY_CLOSING_GENERATE,
    payload,
    effect: async () => api.post('monthly-closings', { ...payload }),
    onSuccess: (response) => {
      if (onSuccess) {
        onSuccess(response);
      }

      if (response.status === 200) {
        message.success('Fechamento em processamento');
      }
    },
    onFail: () => dispatch(generateFailed())
  });
};

export const getSettings = (onFail) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    onFail,
    resource: 'monthlyClosings',
    actionType: MONTHLY_CLOSING_SETTINGS_GET,
    effect: async () => api.fetch('monthly-closings/settings')
  });
};

export const updateSettings = (payload = {}, onFail) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    onFail,
    resource: 'monthlyClosings',
    actionType: MONTHLY_CLOSING_SETTINGS_UPDATE,
    effect: async () => api.post('monthly-closings/settings', { ...payload }),
    onSuccess: (response) => {
      if (response.status === 200) {
        message.success('Dados salvos com sucesso');
      }
    }
  });
};

export const deleteInvoiceIssuanceLock = (year, month, onSuccess) => async (
  dispatch
) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'monthlyClosings',
    actionType: MONTHLY_CLOSING_DELETE_INVOICE_ISSUANCE_UNLOCK,
    onSuccess,
    onFail: (response) => {
      if (response.status !== 404) {
        return false;
      }

      return true;
    },
    effect: async () =>
      api.del('monthly-closings', `invoice-issuance-lock/${year}/${month}`)
  });
};
