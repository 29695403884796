import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const CircularProgress = ({ className }) => {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  return (
    <div className={`loader ${className}`}>
      <Spin indicator={antIcon} />
    </div>
  );
};

export default CircularProgress;
