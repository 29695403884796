import React from 'react';
import { Select } from 'antd';

const SelectSubscriptionCancellationCharge = ({ ...rest }) => {
  return (
    <Select {...rest}>
      <Select.Option value="immediately">
        Imediatamente
      </Select.Option>
      <Select.Option value="afterTheNextCharge">
        Após a próxima cobrança
      </Select.Option>
    </Select>
  );
};

export default SelectSubscriptionCancellationCharge;
