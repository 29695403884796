import React from 'react';
import { useCrudEditContext } from 'react-structure-admin';
import TaxationGroupProductInvoiceRuleEdit from './TaxationGroupProductInvoiceRuleEdit';
import TaxationGroupServiceInvoiceRuleEdit from './TaxationGroupServiceInvoiceRuleEdit';

const TaxationGroupRuleEdit = (props) => {
  const { form } = useCrudEditContext();

  const { type } = form.getFieldsValue();

  if (type === 'productInvoice') {
    return <TaxationGroupProductInvoiceRuleEdit {...props} model={type} />;
  }

  if (type === 'serviceInvoice') {
    return <TaxationGroupServiceInvoiceRuleEdit {...props} model={type} />;
  }

  return null;
};

export default TaxationGroupRuleEdit;
