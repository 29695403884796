import { normalizeToSelect } from 'react-structure-admin';

const cityNormalizeToSelect = (city) => {
  return normalizeToSelect(city, {
    label: (c) => {
      return `${c.name} - ${c.state?.toUpperCase()}`;
    }
  });
};

export default cityNormalizeToSelect;
