import {
  updateResourceState,
  createResourceReducer,
  RESOURCE,
  RECORD_FIELD
} from 'react-structure-admin';
import {
  ORDER,
  ORDER_CANCEL_BATCH,
  ORDER_CANCEL_BATCH_FINISHED,
  ORDER_CLOSE_ISSUE_INVOICE,
  ORDER_ISSUE_BATCH_INVOICE,
  ORDER_ISSUE_BATCH_INVOICE_FINISHED,
  ORDER_ISSUE_BATCH_INVOICE_FAIL,
  ORDER_ISSUE_INVOICE,
  ORDER_ITEMS_SELECTED,
  ORDER_VIEW_GET_FINISHED,
  ORDER_REFRESH,
  ORDER_EXPORT_CSV,
  ORDER_EXPORT_CSV_FINISHED,
  ORDER_UPDATE_RECURRING_FINISHED,
  ORDER_UPDATE_RECURRING,
  ORDER_OPEN_RECURRING,
  ORDER_CLOSE_RECURRING,
  ORDER_OPEN_IMPORT_CSV,
  ORDER_CLOSE_IMPORT_CSV,
  ORDER_IMPORT_CSV,
  ORDER_IMPORT_CSV_FINISHED,
  ORDER_OPEN_REMOVE_PENDING_INVOICES,
  ORDER_CLOSE_REMOVE_PENDING_INVOICES,
  ORDER_REMOVE_PENDING_INVOICES,
  ORDER_REMOVE_PENDING_INVOICES_FINISHED,
  ORDER_REQUEST_STATUS_CHECK_FINISHED,
  ORDER_REQUEST_STATUS_CHECK,
  ORDER_ENQUEUE_ISSUE_AFFILIATE_ORDERS,
  ORDER_ENQUEUE_ISSUE_AFFILIATE_ORDERS_FINISHED,
  ORDER_OPEN_UPDATE_COST,
  ORDER_CLOSE_UPDATE_COST,
  ORDER_UPDATE_COST,
  ORDER_UPDATE_COST_FINISHED,
  ORDER_OPEN_CHANGE_ISSUE_INVOICE_MODE,
  ORDER_CLOSE_CHANGE_ISSUE_INVOICE_MODE,
  ORDER_CHANGE_ISSUE_INVOICE_MODE,
  ORDER_CHANGE_ISSUE_INVOICE_MODE_FINISHED,
  ORDER_UNLINK_INVOICE_FINISHED,
  ORDER_OPEN_CANCEL,
  ORDER_CLOSE_CANCEL,
  ORDER_SHOW_STATUS_CHANGE,
  ORDER_CLOSE_STATUS_CHANGE,
  ORDER_CHANGE_STATUS,
  ORDER_CHANGE_STATUS_FINISHED,
  ORDER_ENQUEUE_ISSUE_BATCH,
  ORDER_ENQUEUE_ISSUE_BATCH_FINISHED,
  ORDER_OPEN_DISABLE_NUMERING_PRODUCT_INVOICE,
  ORDER_CLOSE_DISABLE_NUMERING_PRODUCT_INVOICE,
  ORDER_DISABLE_NUMERING_PRODUCT_INVOICE,
  ORDER_DISABLE_NUMERING_PRODUCT_INVOICE_FINISHED,
  ORDER_REQUEST_STATUS_CHECK_BATCH,
  ORDER_REQUEST_STATUS_CHECK_BATCH_FINISHED,
  ORDER_OPEN_ENQUEUE_CANCELLATION,
  ORDER_CLOSE_ENQUEUE_CANCELLATION,
  ORDER_ENQUEUE_CANCELLATION,
  ORDER_ENQUEUE_CANCELLATION_FINISHED
} from './orderActions';
import { STATUS_CHANGE } from 'pages/app/orders/orderModalTypes';

const orderReducer = createResourceReducer(
  ORDER,
  [],
  {
    [ORDER_ITEMS_SELECTED](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        selecteds: action.payload
      }));
    },

    [ORDER_ISSUE_INVOICE](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        issueInvoice: true,
        issueInvoiceParams: action.payload
      }));
    },

    [ORDER_SHOW_STATUS_CHANGE](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        activeModal: STATUS_CHANGE
      }));
    },

    [ORDER_CLOSE_STATUS_CHANGE](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        activeModal: null
      }));
    },

    [ORDER_CHANGE_STATUS](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        loading: true
      }));
    },

    [ORDER_CHANGE_STATUS_FINISHED](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        activeModal: null,
        selecteds: [],
        loading: false,
        loaded: false
      }));
    },

    [ORDER_CLOSE_ISSUE_INVOICE](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        issueInvoice: false
      }));
    },

    [ORDER_ITEMS_SELECTED](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        selecteds: action.payload
      }));
    },

    [ORDER_CANCEL_BATCH](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        canceling: true
      }));
    },

    [ORDER_CANCEL_BATCH_FINISHED](state, action) {
      const { result = [] } = action.payload.data;

      return updateResourceState(state, action, RESOURCE, ({ items = [] }) => ({
        items: items.map((c) => {
          const item = result.find((d) => d.id === c.id);

          if (item) {
            return item;
          }

          return c;
        }),
        selecteds: [],
        canceling: false,
        loaded: false,
        cancelingOrderIds: null
      }));
    },

    [ORDER_ISSUE_BATCH_INVOICE](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        issuingInvoice: true
      }));
    },

    [ORDER_ISSUE_BATCH_INVOICE_FAIL](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        issuingInvoice: false
      }));
    },

    [ORDER_VIEW_GET_FINISHED](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        [RECORD_FIELD]: {
          ...action.payload.data.result
        },
        editing: true,
        readOnly: true
      }));
    },

    [ORDER_ISSUE_BATCH_INVOICE_FINISHED](state, action) {
      const { result = [] } = action.payload.data;

      return updateResourceState(state, action, RESOURCE, ({ items = [] }) => ({
        items: items.map((c) => {
          const item = result.find((d) => d.id === c.id);

          if (item) {
            return item;
          }

          return c;
        }),
        selecteds: [],
        issuingInvoice: false,
        issueInvoice: false
      }));
    },

    [ORDER_REFRESH](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        loaded: false
      }));
    },

    [ORDER_EXPORT_CSV](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        exporting: true
      }));
    },

    [ORDER_EXPORT_CSV_FINISHED](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        exporting: false
      }));
    },

    [ORDER_UPDATE_RECURRING](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        savingRecurring: true
      }));
    },

    [ORDER_UPDATE_RECURRING_FINISHED](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        savingRecurring: false
      }));
    },

    [ORDER_OPEN_RECURRING](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        editingRecurring: true
      }));
    },

    [ORDER_CLOSE_RECURRING](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        editingRecurring: false
      }));
    },

    [ORDER_IMPORT_CSV](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        importFileLoading: true
      }));
    },

    [ORDER_IMPORT_CSV_FINISHED](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        importFileLoading: false,
        importFile: false
      }));
    },

    [ORDER_OPEN_IMPORT_CSV](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        importFile: true
      }));
    },

    [ORDER_CLOSE_IMPORT_CSV](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        importFile: false,
        importFileLoading: false
      }));
    },

    [ORDER_OPEN_REMOVE_PENDING_INVOICES](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        isRemovePendingInvoices: true
      }));
    },

    [ORDER_CLOSE_REMOVE_PENDING_INVOICES](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        isRemovePendingInvoices: false
      }));
    },

    [ORDER_REMOVE_PENDING_INVOICES](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        removingPendingInvoices: true
      }));
    },

    [ORDER_REMOVE_PENDING_INVOICES_FINISHED](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        removingPendingInvoices: false,
        isRemovePendingInvoices: false,
        loaded: false
      }));
    },

    [ORDER_REQUEST_STATUS_CHECK](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        requestingStatusCheck: true,
        loaded: true
      }));
    },

    [ORDER_REQUEST_STATUS_CHECK_FINISHED](state, action) {
      const { result = {} } = action.payload.data;
      const { order } = result;

      return updateResourceState(state, action, RESOURCE, ({ items = [] }) => ({
        items: items.map((c) => {
          if (order.id === c.id) {
            return { ...c, processingDetail: result.processingDetail };
          }

          return c;
        }),
        requestingStatusCheck: false,
        loaded: false
      }));
    },

    [ORDER_ENQUEUE_ISSUE_AFFILIATE_ORDERS](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        issuingInvoice: true
      }));
    },

    [ORDER_ENQUEUE_ISSUE_AFFILIATE_ORDERS_FINISHED](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        issuingInvoice: false,
        issueInvoice: false
      }));
    },

    [ORDER_OPEN_UPDATE_COST](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        showUpdateCost: true
      }));
    },

    [ORDER_CLOSE_UPDATE_COST](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        showUpdateCost: false
      }));
    },

    [ORDER_UPDATE_COST](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        updatingCost: true
      }));
    },

    [ORDER_UPDATE_COST_FINISHED](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        updatingCost: false,
        showUpdateCost: false
      }));
    },

    [ORDER_UNLINK_INVOICE_FINISHED](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        loaded: false,
        selecteds: []
      }));
    },

    [ORDER_OPEN_CHANGE_ISSUE_INVOICE_MODE](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        changingIssueInvoiceMode: true
      }));
    },

    [ORDER_CLOSE_CHANGE_ISSUE_INVOICE_MODE](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        changingIssueInvoiceMode: false
      }));
    },

    [ORDER_CHANGE_ISSUE_INVOICE_MODE](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        saving: true
      }));
    },

    [ORDER_CHANGE_ISSUE_INVOICE_MODE_FINISHED](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        saving: false,
        changingIssueInvoiceMode: false,
        loaded: false
      }));
    },

    [ORDER_OPEN_CANCEL](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        cancelingOrderIds: action.payload
      }));
    },

    [ORDER_CLOSE_CANCEL](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        cancelingOrderIds: null
      }));
    },

    [ORDER_ENQUEUE_ISSUE_BATCH](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        issuingInvoice: true
      }));
    },

    [ORDER_ENQUEUE_ISSUE_BATCH_FINISHED](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        issuingInvoice: false,
        issueInvoice: false,
        loaded: false
      }));
    },

    [ORDER_OPEN_DISABLE_NUMERING_PRODUCT_INVOICE](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        disablingInvoiceIds: action.payload
      }));
    },

    [ORDER_CLOSE_DISABLE_NUMERING_PRODUCT_INVOICE](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        disablingInvoiceIds: null
      }));
    },

    [ORDER_DISABLE_NUMERING_PRODUCT_INVOICE](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        disabling: true
      }));
    },

    [ORDER_DISABLE_NUMERING_PRODUCT_INVOICE_FINISHED](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        selecteds: [],
        disabling: false,
        loaded: false,
        disablingInvoiceIds: null
      }));
    },

    [ORDER_REQUEST_STATUS_CHECK_BATCH](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        requestingStatusCheck: true,
        loaded: true
      }));
    },

    [ORDER_REQUEST_STATUS_CHECK_BATCH_FINISHED](state, action) {
      const { result = {} } = action.payload.data;
      const order = result.map((c) => c.order);

      return updateResourceState(state, action, RESOURCE, ({ items = [] }) => ({
        items: items.map((c) => {
          if (order.map((orderItem) => orderItem.id).includes(c.id)) {
            return { ...c, processingDetail: result.processingDetail };
          }

          return c;
        }),
        requestingStatusCheck: false,
        loaded: false
      }));
    },

    [ORDER_OPEN_ENQUEUE_CANCELLATION](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        activeModal: ORDER_OPEN_ENQUEUE_CANCELLATION,
        profileType: action.payload
      }));
    },

    [ORDER_CLOSE_ENQUEUE_CANCELLATION](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        activeModal: null
      }));
    },

    [ORDER_ENQUEUE_CANCELLATION](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        loading: true
      }));
    },

    [ORDER_ENQUEUE_CANCELLATION_FINISHED](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        activeModal: null,
        loading: false,
        loaded: false
      }));
    }
  },

  (state, action) => {
    return updateResourceState(state, action, RESOURCE, () => ({
      issuingInvoice: false,
      exporting: false,
      savingRecurring: false,
      importFileLoading: false,
      removingPendingInvoices: false,
      requestingStatusCheck: false,
      updatingCost: false,
      loading: false,
      canceling: false
    }));
  }
);

export default orderReducer;
