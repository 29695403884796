import states from 'constants/states';

const getStates = (includeForeign = false) => {
  let statesAll = [...states];

  if (includeForeign) {
    if (statesAll.map(({ initials }) => initials !== 'ex')) {
      statesAll.push({
        initials: 'ex',
        name: 'Exterior'
      });
    }
    return statesAll;
}

  return states;
};

export default getStates;
