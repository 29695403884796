import React from 'react';
import { Form, Button } from 'antd';
import { FormItemWrap } from 'react-structure-admin';
import { PlusOutlined } from '@ant-design/icons';
import lodash from 'lodash';
import { rules } from 'eslint-config-prettier';

const FormListContext = React.createContext({ form: null });

const FormListItemWrap = ({ children, name, noStyle, ...rest }) => {
  const { field } = React.useContext(FormListContext);

  if (!field) {
    throw new Error('Missing FormListWrap in its parent.');
  }

  const getName = () => {
    if (typeof name === 'string') {
      return [name];
    }

    return name;
  };

  return (
    <FormItemWrap
      {...field}
      name={[field.name, ...getName()]}
      fieldKey={[field.fieldKey, ...getName()]}
      noStyle={noStyle}
      {...rest}
    >
      {children}
    </FormItemWrap>
  );
};

const FormListWrap = ({
  form,
  name,
  onBeforeCreateItem,
  onCreateItem,
  onRemoveItem,
  onAllowRemoveItem = () => true,
  onRemove,
  addText = 'Adicionar',
  hideAddButton = false,
  children,
  rules
}) => {
  const handleCreateItem = () => {
    if (onCreateItem) {
      onCreateItem();
    } else {
      const items = form.getFieldValue(name) ?? [];
      let item = {};

      if (onBeforeCreateItem) {
        item = onBeforeCreateItem(item);
      }

      form.setFieldsValue({
        [name]: [...items, item]
      });
    }
  };

  const handleRemoveItem = (fieldIndex) => {
    if (onRemoveItem) {
      onRemoveItem(fieldIndex);
      return;
    }

    const items = form.getFieldValue(name) ?? [];
    const item = items.find((_, index) => index === fieldIndex);

    if (!onAllowRemoveItem(item, items)) {
      return;
    }

    form.setFieldsValue({
      [name]: items.filter((_, index) => index !== fieldIndex)
    });

    if (onRemove) {
      onRemove(item);
    }
  };

  return (
    <Form.List name={name} rules={rules}>
      {(fields, {}, { errors }) => (
        <>
          {fields.map((field, index) => (
            <FormListContext.Provider value={{ field }} key={field.name}>
              <Form.Item
                noStyle
                key={field.name}
                shouldUpdate={(prevValues, curValues) => {
                  return lodash.isEqual(prevValues, curValues);
                }}
              >
                {({ getFieldValue }) => {
                  return children({
                    onCreate: handleCreateItem,
                    onRemove: () => handleRemoveItem(field.name),
                    field,
                    fields,
                    value: getFieldValue([name, field.name]),
                    index
                  });
                }}
              </Form.Item>
            </FormListContext.Provider>
          ))}

          {!hideAddButton && (
            <Button icon={<PlusOutlined />} onClick={handleCreateItem}>
              {addText}
            </Button>
          )}
          <Form.ErrorList errors={errors} />
        </>
      )}
    </Form.List>
  );
};

export { FormListWrap, FormListItemWrap, FormListContext };
