import React from 'react';
import { Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { close } from 'stores/subscriptions/subscriptionActions';
import SubscriptionPaymentHistory from './SubscriptionPaymentHistory';
import SubscriptionDetail from './SubscriptionDetail';
import SubscriptionPlanList from './SubscriptionPlanList';
import SubscriptionChangePaymentMethod from './SubscriptionChangePaymentMethod';
import { DrawerWrapper } from 'components';
import SubscriptionChangeAvailablePaymentMethod from './SubscriptionChangeAvailablePaymentMethod';

const SubscriptionPlanEdit = ({ data }) => {
  const dispatch = useDispatch();
  const { TabPane } = Tabs;

  const {
    plans,
    changePaymentMethod,
    changingPaymentMethod,
    changeAvailablePaymentMethod,
    subscription
  } = useSelector((state) => state.resources.subscriptions);

  const handleClose = () => {
    dispatch(close());
  };

  if (!subscription) {
    return null;
  }

  return (
    <DrawerWrapper
      className="gx-drawer-form md"
      visible
      onClose={handleClose}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Tabs size="md" defaultActiveKey="Dados">
        <TabPane tab="Dados da assinatura" key="detail">
          <SubscriptionDetail subscription={subscription} />
          {plans ? <SubscriptionPlanList current={subscription} /> : null}
          {changePaymentMethod ? (
            <SubscriptionChangePaymentMethod
              subscription={subscription}
              loading={changingPaymentMethod}
            />
          ) : null}
          {changeAvailablePaymentMethod ? (
            <SubscriptionChangeAvailablePaymentMethod
              subscription={subscription}
              loading={changingPaymentMethod}
            />
          ) : null}
        </TabPane>
        {subscription?.amount > 0 ? (
          <TabPane tab="Pagamentos" key="payments">
            <SubscriptionPaymentHistory data={subscription} />
          </TabPane>
        ) : null}
      </Tabs>
    </DrawerWrapper>
  );
};

export default SubscriptionPlanEdit;
