import { NAV_STYLE } from 'constants/themeSettings';

export const TOGGLE_COLLAPSED_NAV = 'SETTINGS_TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'SETTINGS_WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SETTINGS_SWITCH-LANGUAGE';
export const CHAT_LOADED = 'CHAT_LOADED';
export const BANNER_SHOW = 'BANNER_SHOW';

export const updateWindowWidth = (width) => {
  return { type: WINDOW_WIDTH, width };
};

export function toggleCollapsedSideNav(navCollapsed) {
  return { type: TOGGLE_COLLAPSED_NAV, navCollapsed };
}

export function onNavStyleChange(navStyle) {
  return { type: NAV_STYLE, navStyle };
}

export function chatLoad() {
  return { type: CHAT_LOADED };
}

export function bannerShow(show) {
  return { type: BANNER_SHOW, show };
}
