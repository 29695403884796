import React from 'react';
import { Input, Row } from 'antd';
import { RemoteSelect, FormItemWrap } from 'react-structure-admin';
import ResCol from 'components/forms/ResCol';

const AddressForForeign = ({
  required = true,
  requiredCountry = false,
  fieldNames = ['address']
}) => {
  return (
    <>
      <Row>
        <ResCol span={6}>
          <FormItemWrap
            name={[...fieldNames, 'postalCode']}
            label="Código Postal"
            required={required}
          >
            <Input />
          </FormItemWrap>
        </ResCol>
        <ResCol span={18}>
          <FormItemWrap
            name={[...fieldNames, 'street']}
            label="Logradouro"
            required={required}
            whitespace
          >
            <Input maxLength={100} />
          </FormItemWrap>
        </ResCol>
      </Row>
      <Row>
        <ResCol span={6}>
          <FormItemWrap
            name={[...fieldNames, 'number']}
            label="Número"
            required={required}
            whitespace
          >
            <Input maxLength={40} />
          </FormItemWrap>
        </ResCol>
        <ResCol span={18}>
          <FormItemWrap
            name={[...fieldNames, 'additionalInformation']}
            label="Complemento"
          >
            <Input maxLength={40} />
          </FormItemWrap>
        </ResCol>
      </Row>
      <Row>
        <ResCol span={8}>
          <FormItemWrap
            name={[...fieldNames, 'district']}
            label="Bairro"
            required={required}
            whitespace
          >
            <Input maxLength={100} />
          </FormItemWrap>
        </ResCol>
        <ResCol span={8}>
          <FormItemWrap
            name={[...fieldNames, 'cityName']}
            label="Cidade/Estado"
            required={required}
          >
            <Input maxLength={100} />
          </FormItemWrap>
        </ResCol>

        <ResCol span={8}>
          <FormItemWrap
            name={[...fieldNames, 'country']}
            label="País"
            required={required || requiredCountry}
          >
            <RemoteSelect
              resource="countries"
              keyProp="code"
              params={{ foreigner: true }}
            />
          </FormItemWrap>
        </ResCol>
      </Row>
    </>
  );
};

export default AddressForForeign;
