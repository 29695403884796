const states = [
  {
    initials: 'ac',
    name: 'Acre'
  },
  {
    initials: 'al',
    name: 'Alagoas'
  },
  {
    initials: 'am',
    name: 'Amazonas'
  },
  {
    initials: 'ap',
    name: 'Amapá'
  },
  {
    initials: 'ba',
    name: 'Bahia'
  },
  {
    initials: 'ce',
    name: 'Ceará'
  },
  {
    initials: 'df',
    name: 'Distrito Federal'
  },
  {
    initials: 'es',
    name: 'Espírito Santo'
  },
  {
    initials: 'go',
    name: 'Goiás'
  },
  {
    initials: 'ma',
    name: 'Maranhão'
  },
  {
    initials: 'mg',
    name: 'Minas Gerais'
  },
  {
    initials: 'ms',
    name: 'Mato Grosso do Sul'
  },
  {
    initials: 'mt',
    name: 'Mato Grosso'
  },
  {
    initials: 'pa',
    name: 'Pará'
  },
  {
    initials: 'pb',
    name: 'Paraíba'
  },
  {
    initials: 'pe',
    name: 'Pernambuco'
  },
  {
    initials: 'pi',
    name: 'Piauí'
  },
  {
    initials: 'pr',
    name: 'Paraná'
  },
  {
    initials: 'rj',
    name: 'Rio de Janeiro'
  },
  {
    initials: 'rn',
    name: 'Rio Grande do Norte'
  },
  {
    initials: 'ro',
    name: 'Rondônia'
  },
  {
    initials: 'rr',
    name: 'Roraima'
  },
  {
    initials: 'rs',
    name: 'Rio Grande do Sul'
  },
  {
    initials: 'sc',
    name: 'Santa Catarina'
  },
  {
    initials: 'se',
    name: 'Sergipe'
  },
  {
    initials: 'sp',
    name: 'São Paulo'
  },
  {
    initials: 'to',
    name: 'Tocantins'
  }
];

export default states;
