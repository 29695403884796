import React from 'react';
import { platforms } from 'constants/platforms';
import FilterSelect from './FilterSelect';

const SelectPlatformType = () => {
  return (
    <FilterSelect
      name="platforms"
      label="Canal"
      data={platforms}
      mode="multiple"
      allowClear
      showSearch
    />
  );
};

export default SelectPlatformType;
