import { useMemo } from 'react';
import { useAuthContext } from 'react-structure-admin';

const useCompany = () => {
  const { company, isMultiCompany } = useAuthContext();
  const { taxRegime = 'simplesNacional' } = company ?? {};
  const isSimplesNacional =
    taxRegime === 'simplesNacional' ||
    taxRegime === 'simplesNacionalExcessoSublimite';

  return {
    company,
    isMultiCompany,
    isSimplesNacional,
    enabled: company?.enabled ?? false
  };
};

export default useCompany;
