import React from 'react';
import { Input, Select } from 'antd';
import InputCurrency from './InputCurrency';
import currencies, { costCurrencies } from 'constants/Currencies';
import InputPercent from './InputPercent';

const currencyInfo = Object.values(currencies).filter((c) =>
  costCurrencies.includes(c.code)
);

const initialValue = { value: 0, currencyCode: 'BRL' };

const Currency = ({ value = initialValue, onChange, onBlur }) => {
  const handleOnChange = (newValue) => {
    onChange({ ...value, ...newValue });
  };

  const {
    prefix,
    decimalSeparator,
    groupSeparator,
    code,
    suffix
  } = currencyInfo.find((c) => c.code === value?.currencyCode);

  return (
    <Input.Group
      compact
      style={{ display: 'flex', flexWrap: 'nowrap', width: '100%' }}
    >
      {code === '%' ? (
        <InputPercent
          suffix={suffix}
          value={value?.value}
          style={{ width: '100%' }}
          decimalSeparator={decimalSeparator}
          groupSeparator={groupSeparator}
          onChange={(newValue) => handleOnChange({ value: newValue })}
          onBlur={onBlur}
        />
      ) : (
        <InputCurrency
          prefix={prefix}
          value={value?.value}
          style={{ width: '100%' }}
          decimalSeparator={decimalSeparator}
          groupSeparator={groupSeparator}
          onChange={(newValue) => handleOnChange({ value: newValue })}
          onBlur={onBlur}
        />
      )}
      <Select
        value={value?.currencyCode}
        style={{ maxWidth: '90px' }}
        onChange={(newValue) => handleOnChange({ currencyCode: newValue })}
        onBlur={onBlur}
      >
        {currencyInfo.map((c) => (
          <Select.Option key={c.code} value={c.code} title={c.code}>
            {c.code}
          </Select.Option>
        ))}
      </Select>
    </Input.Group>
  );
};

export default Currency;
