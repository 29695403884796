import { dispatcher, api, RESOURCE_FETCH } from 'react-structure-admin';

export const LOGIN_REQUEST = 'AUTH_LOGIN_REQUEST';
export const LOGIN_REQUEST_FINISHED = 'AUTH_LOGIN_REQUEST_FINISHED';
export const AUTH_REFRESH_TOKEN = 'AUTH_REFRESH_TOKEN';
export const AUTH_REFRESH_TOKEN_FINISHED = 'AUTH_REFRESH_TOKEN_FINISHED';
export const LOGOUT = 'AUTH_LOGOUT';
export const AUTH_REFRESH_USER = 'AUTH_UPDATE_USER';
export const AUTH_COMPANY_UPDATE = 'AUTH_COMPANY_UPDATE';

export const AUTH_CREATE_SUBSCRIBE = 'AUTH_CREATE_SUBSCRIBE';
export const AUTH_CREATE_SUBSCRIBE_FINISHED = 'AUTH_CREATE_SUBSCRIBE_FINISHED';
export const AUTH_SUBSCRIPTION_VALIDATE = 'AUTH_SUBSCRIPTION_VALIDATE';
export const AUTH_SUBSCRIPTION_VALIDATE_FINISHED =
  'AUTH_SUBSCRIPTION_VALIDATE_FINISHED';

const onAuthSuccess = (data) => {
  if (data && data.user) {
    const expirationDate = new Date();
    expirationDate.setSeconds(
      expirationDate.getSeconds() + data.expireInSeconds
    );

    if (data.tenantId) {
      localStorage.setItem('tenantId', data.tenantId);
    } else {
      localStorage.removeItem('tenantId');
    }
    localStorage.setItem(
      'auth_data',
      btoa(JSON.stringify({ ...data, expirationDate }))
    );
    localStorage.setItem('auth_accesstoken', data.accessToken);
    localStorage.setItem('auth_refreshtoken', data.refreshToken);
  }
};

export const createAccount = (params, onSuccess) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'auth',
    actionType: LOGIN_REQUEST,
    effect: async () => api.post('auth', params),
    onSuccess: ({ data }) => {
      onAuthSuccess(data);
      if (onSuccess) {
        onSuccess(data);
      }
    }
  });
};

export const confirmInvite = (params, onSuccess, onFail) => async (
  dispatch
) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'auth',
    actionType: LOGIN_REQUEST,
    effect: async () => api.post('auth/confirm-invite', params),
    onSuccess: ({ data }) => {
      onAuthSuccess(data);
      onSuccess(data);
    },
    onFail
  });
};

export const subscribe = (params, onSuccess, onFail) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'auth',
    actionType: AUTH_CREATE_SUBSCRIBE,
    effect: async () => api.post('subscriptions', params),
    onSuccess,
    onFail
  });
};

export const activateSubscription = (payload = {}) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'subscriptions',
    actionType: AUTH_SUBSCRIPTION_VALIDATE,
    effect: async () => api.post('subscriptions/activate-by-token', payload)
  });
};

export const companyUpdate = (payload = {}) => async (dispatch) => {
  return dispatch(
    dispatcher.createAction(AUTH_COMPANY_UPDATE, 'auth', payload)
  );
};

export const changeTenant = (params, onSuccess) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'auth',
    actionType: LOGIN_REQUEST,
    effect: async () => api.post(`auth/change-tenant`, params),
    onAfterEffect: ({ data }, isError) => {
      if (isError) {
        return;
      }
      onAuthSuccess(data);
      if (onSuccess) {
        onSuccess(data);
      }
    }
  });
};

export const changeCompany = (companyId, onSuccess) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'auth',
    actionType: LOGIN_REQUEST,
    effect: async () => api.post(`auth/change-company`, { companyId }),
    onAfterEffect: ({ data }, isError) => {
      if (isError) {
        return;
      }
      onAuthSuccess(data);
      if (onSuccess) {
        onSuccess(data);
      }
    }
  });
};
