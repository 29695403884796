import React from 'react';
import { Button, Col, Layout, Row, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE
} from 'constants/themeSettings';
import {
  onNavStyleChange,
  toggleCollapsedSideNav
} from 'stores/settings/settingsActions';
import CurrentUserProfile from 'components/CurrentUserProfile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuthContext } from 'react-structure-admin';
import { changeTenant } from 'stores/auth/authActions';
import { useHistory } from 'react-router';
import { CompanyChooserPopover } from 'components';
import { QuestionCircleOutlined } from '@ant-design/icons';

const { Header } = Layout;

const Topbar = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isAdmin } = useAuthContext();

  const { width, themeType, navCollapsed } = useSelector(
    (state) => state.settings
  );

  let { navStyle } = useSelector((state) => state.settings);

  if (width < TAB_SIZE && navStyle === NAV_STYLE_FIXED) {
    navStyle = NAV_STYLE_DRAWER;
  }

  const toggleClickHandle = () => {
    if (navStyle === NAV_STYLE_DRAWER) {
      dispatch(toggleCollapsedSideNav(!navCollapsed));
    } else if (navStyle === NAV_STYLE_FIXED) {
      dispatch(onNavStyleChange(NAV_STYLE_MINI_SIDEBAR));
    } else if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      dispatch(toggleCollapsedSideNav(!navCollapsed));
    } else {
      dispatch(onNavStyleChange(NAV_STYLE_FIXED));
    }
  };

  const handleBackToRootTenant = () => {
    dispatch(
      changeTenant({}, () => {
        history.push('/');
      })
    );
  };

  const handleClickHelp = () => {
    window.open('https://ajuda.spedy.com.br', '_blank');
  };

  return (
    <>
      <Header>
        <Row className="gx-w-100 gx-mx-0 gx-my-0" justify="space-between">
          <Col>
            <div className="gx-flex-row gx-align-items-center">
              <div className="gx-linebar gx-mr-3">
                <i
                  className="gx-icon-btn icon icon-menu"
                  aria-hidden="true"
                  onClick={toggleClickHandle}
                />
              </div>
              <CompanyChooserPopover />
            </div>
          </Col>
          <Col>
            <div className="gx-flex-row gx-align-items-center">
              {isAdmin() && width >= 800 ? (
                <Button
                  className="gx-m-0 gx-pl-3 gx-pr-3"
                  type="primary"
                  size="small"
                  onClick={handleBackToRootTenant}
                >
                  Administração
                </Button>
              ) : null}

              <Tooltip title="Central de Ajuda">
                <QuestionCircleOutlined
                  style={{ fontSize: 20 }}
                  className="gx-ml-3 gx-mr-3 gx-pointer"
                  onClick={handleClickHelp}
                />
              </Tooltip>
              <ul className="gx-header-notifications gx-ml-auto">
                <li className="gx-user-nav">
                  <CurrentUserProfile />
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Header>
    </>
  );
};

export default Topbar;
