import React from 'react';
import { Card, Button, Divider } from 'antd';
import ResCol from 'components/forms/ResCol';
import formatCurrency from 'util/formatters/formatCurrency';
import CustomScrollbars from './CustomScrollbars';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const identifiers = [
  'issues_per_period',
  'cost_for_additional_invoice',
  'without_signin_tax',
  'revenue_limit',
  'installment_12x',
  'auto_issue',
  'all_integrations',
  'orders_import',
  'monthly_closing',
  'auto_monthly_closing',
  'registration_with_autocompletion',
  'fiscal_split',
  'api_access',
  'assisted_onboarding',
  'ticket_support',
  'help_center',
  'ticket_chat_support',
  'technical_support',
  'invoice_management'
];

const PlanCard = ({
  plan,
  selected,
  showRecommended = true,
  loading,
  disabled,
  onSubscribeClick,
  onCardClick,
  isCurrent = false
}) => {
  return (
    <ResCol span={6}>
      <Card
        key={plan.id}
        onClick={() => onCardClick?.(plan)}
        className={
          isCurrent
            ? 'gx-card-plan my-plan'
            : showRecommended && plan.recommended
            ? 'gx-card-plan selected'
            : 'gx-card-plan'
        }
      >
        <div className="card-plan-header">
          {isCurrent && (
            <h2
              className="card-plan-header-my-plan"
              style={{ marginBottom: '5px' }}
            >
              Meu plano
            </h2>
          )}
          <p className="gx-fs-xl" style={{ marginBottom: '2px' }}>
            {plan.name}
          </p>
          {plan.baseAmount > 0 ? (
            <span className="gx-fs-sm gx-text-strikethrough">
              {` ${formatCurrency(plan.baseAmount)} `}
            </span>
          ) : null}
          {plan.amount === 0 ? (
            <h3 className="gx-fs-xxl">Grátis</h3>
          ) : (
            <h3 className="gx-fs-xxl">{formatCurrency(plan.amount)}</h3>
          )}
          {(!plan.recommended || isCurrent) && (
            <Divider className="gx-mt-0 gx-mb-0" />
          )}
        </div>
        <div className="card-plan-body">
          <div>
            <ul>
              <CustomScrollbars className="gx-card-plan-scroll">
                {plan.features
                  .sort(
                    (a, b) =>
                      identifiers.indexOf(a.identifier) -
                      identifiers.indexOf(b.identifier)
                  )
                  .map((feature) => (
                    <li key={feature.id}>
                      <FontAwesomeIcon
                        icon="circle"
                        style={{ fontSize: '4.5px' }}
                      />
                      {feature.description}
                    </li>
                  ))}
              </CustomScrollbars>
            </ul>
          </div>
          {!isCurrent && (
            <Button
              disabled={disabled}
              loading={loading}
              onClick={() => onSubscribeClick(plan)}
            >
              Assinar
            </Button>
          )}
        </div>
      </Card>
    </ResCol>
  );
};

export default PlanCard;
