import React, { useEffect } from 'react';
import ptBR from 'antd/es/locale/pt_BR';
import moment from 'moment';
import 'moment/locale/pt-br';
import { Empty, ConfigProvider } from 'antd';
import { AuthContextProvider, Routes } from 'react-structure-admin';
import ChangeSubscriptionEventHandler from 'core/ChangeSubscriptionEventHandler';
import { Crisp } from 'crisp-sdk-web';
import config from './config';
import 'assets/vendors/style';
import 'styles/wieldy.less';
import 'components/IconLibrary';
import useCookieHandler from 'core/useCookieHandler';

const applyToDarkPathnames = [
  '/signin',
  '/signup',
  '/forgot-password',
  '/accounts-chooser',
  '/partners/signup',
  '/reset-password'
];

moment.locale('pt-br');
moment.updateLocale('pt-br', {
  weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
});

const customizeRenderEmpty = () => (
  <Empty
    image={Empty.PRESENTED_IMAGE_SIMPLE}
    description="Nenhum registro para exibir"
  />
);

const Main = ({ refreshToken, location }) => {
  const { cookies, setCookie } = useCookieHandler(['referrer']);
  const comparePathname = (c) =>
    location?.pathname === c || location?.pathname === `${c}/`;

  useEffect(() => {
    if (!cookies.referrer && document.referrer !== '') {
      setCookie('referrer', document.referrer, 30);
    }

    window.addEventListener('focus', onFocus);
    Crisp.configure('28808de3-2e1c-4f10-90d2-e9a118fb2a2c', {
      autoload: true
    });
    Crisp.chat.hide();

    return () => {
      window.removeEventListener('focus', onFocus);
    };
  }, []);

  const onFocus = () => {
    const storedRefreshToken =
      localStorage.getItem('auth_refreshtoken') ?? null;

    if (refreshToken && refreshToken !== storedRefreshToken) {
      window.location.reload();
    }
  };

  const handleBeforeRefreshToken = (authData) => {
    return {
      refreshToken: authData.refreshToken
    };
  };

  if (applyToDarkPathnames.some(comparePathname)) {
    document.body.classList.add('dark-bg');
  }

  return (
    <ConfigProvider renderEmpty={customizeRenderEmpty} locale={ptBR}>
      <AuthContextProvider
        onBeforeRefreshToken={handleBeforeRefreshToken}
        enableRefreshToken
      >
        <>
          <ChangeSubscriptionEventHandler />
          <Routes routes={config.routes} isContainer={false} />
        </>
      </AuthContextProvider>
    </ConfigProvider>
  );
};

export default Main;
