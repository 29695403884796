const cityNormalizeFromSelect = (value) => {
  if (!value) {
    return null;
  }

  if (!value.key) {
    return value;
  }

  const i = value.label.lastIndexOf(' - ');

  if (i < 0) {
    return {
      id: value.key,
      name: value.label.substring(0, i)
    };
  }

  return {
    id: value.key,
    name: value.label.substring(0, i),
    state: value.label.substring(i + 3)
  };
};

export default cityNormalizeFromSelect;
