import React from 'react';
import { useSelector } from 'react-redux';

const ShowChildrenIf = ({ children, when, resource }) => {
  const state = useSelector(
    (state) => (state && state.resources && state.resources[resource]) || {}
  );

  return when(state) ? children : null;
};

export default ShowChildrenIf;
