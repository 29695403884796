import React, { useState, useEffect } from 'react';
import { Upload, Spin, message } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { api, join, LinkButton } from 'react-structure-admin';

const UploadArchive = ({
  onChange,
  name,
  resource,
  extension = ['png', 'jpg'],
  type = 'image',
  displayDocument = '',
  params,
  initialValue
}) => {
  const [state, setState] = useState({
    loading: false,
    archiveUrl:
      type === 'image' && initialValue
        ? `data:image/png;base64,${initialValue}`
        : initialValue
  });

  const extractingExtension = (fileName) => {
    const text = fileName.match(/\.[0-9a-z]+$/i)[0];
    return text != null ? text : '';
  };

  const beforeUpload = (file) => {
    let isCorrectFormat = false;

    if (
      type === 'image' &&
      extension.includes(file.type.replace('image/', '').toLowerCase())
    ) {
      isCorrectFormat = true;
    } else if (
      extension.includes(extractingExtension(file.name).replace('.', ''))
    ) {
      isCorrectFormat = true;
    }

    if (!isCorrectFormat) {
      message.error(
        `Selecione arquivos com a extensão ${join(
          extension,
          ', ',
          ' e '
        )}.`
      );
    }

    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
      message.error('O tamanho do arquivo não pode ser superior a 2MB');
    }

    return isCorrectFormat && isLt2M;
  };

  const setArchive = (archiveUrl, uploadResult) => {
    setState({
      archiveUrl,
      loading: false
    });

    if (onChange) {
      onChange(uploadResult);
    }
  };

  const handleUploadChange = (info) => {
    if (info.file.status === 'uploading') {
      setState({ loading: true });
      return;
    }

    if (info.file.status === 'done') {
      let archive = '';

      if (type === 'image') {
        archive = `data:image/png;base64,${info.file.response.result.base64}`;
      } else {
        archive = info.file.response.result;
      }

      setArchive(archive, info.file.response.result);
    }
  };

  const handleRemoveClick = () => {
    setArchive(null, null);
  };

  const uploadButton = (
    <div>
      {state.loading ? (
        <Spin />
      ) : (
        <FontAwesomeIcon
          className="icon"
          icon="download"
          size="2x"
          color="#CCC"
        />
      )}
    </div>
  );

  const getContent = () => {
    const { archiveUrl } = state;

    if (!archiveUrl) {
      return uploadButton;
    }

    if (type === 'image') {
      return <img src={archiveUrl} alt={name} style={{ width: '100%' }} />;
    }

    if (type === 'document') {
      return (
        <div>
          <span>{displayDocument || initialValue}</span>
        </div>
      );
    }

    return null;
  };

  const { archiveUrl } = state;

  useEffect(() => {
    setState({
      loading: false,
      archiveUrl:
        type === 'image' && initialValue
          ? `data:image/png;base64,${initialValue}` ||
            `data:image/jpg;base64,${initialValue}`
          : initialValue
    });
  }, [initialValue]);

  return (
    <div className="gx-upload-archive-container">
      <Upload
        name={name}
        listType="picture-card"
        className="gx-upload-archive"
        customRequest={(options) => {
          const data = new FormData();

          if (params.id) {
            data.set('id', params.id);
          }

          data.append(name, options.file);
          api.post(resource, data).then((res) => {
            if (!(res && res.errors)) {
              options.onSuccess(res.data, options.file);
            } else {
              message.error(
                'Ocorreu um erro inesperado ao processar sua solicitação'
              );
              setState({ ...state, loading: false });
            }
          });
        }}
        showUploadList={false}
        beforeUpload={beforeUpload}
        onChange={handleUploadChange}
        data={params}
      >
        {getContent()}
      </Upload>
      {archiveUrl ? (
        <LinkButton onClick={handleRemoveClick} iconName="trash-alt">
          Remover
        </LinkButton>
      ) : null}
    </div>
  );
};

export default UploadArchive;
