import React, { useEffect } from 'react';
import { Drawer } from 'antd';
import { globalEvents } from 'react-structure-admin';

const DrawerWrapper = ({ visible, ...rest }) => {
  useEffect(() => {
    globalEvents.invokeEvents('drawerVisibleChanged', { visible });

    return () => {
      globalEvents.invokeEvents('drawerVisibleChanged', {
        visible: false,
        unloaded: true
      });
    };
  }, [visible]);

  return <Drawer visible={visible} {...rest} />;
};

export default DrawerWrapper;
