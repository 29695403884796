const platformDefaultIcons = {
  spedy: require('assets/images/platforms/spedy.png'),
  monetizze: require('assets/images/platforms/monetizze.png'),
  hotmart: require('assets/images/platforms/hotmart.png'),
  eduzz: require('assets/images/platforms/eduzz.png'),
  braip: require('assets/images/platforms/braip.png'),
  shopify: require('assets/images/platforms/shopify.png'),
  iugu: require('assets/images/platforms/iugu.png'),
  pagarme: require('assets/images/platforms/pagarme.png'),
  kiwify: require('assets/images/platforms/kiwify.png'),
  mercadoPago: require('assets/images/platforms/mercadopago.png'),
  yampi: require('assets/images/platforms/yampi.png'),
  perfectPay: require('assets/images/platforms/perfectpay.png'),
  nuvemshop: require('assets/images/platforms/nuvemshop.png'),
  cartpanda: require('assets/images/platforms/cartpanda.png'),
  stripe: require('assets/images/platforms/stripe.png'),
  evermart: require('assets/images/platforms/evermart.png'),
  cyclopay: require('assets/images/platforms/cyclopay.png'),
  greenn: require('assets/images/platforms/greenn.png'),
  woocommerce: require('assets/images/platforms/woocommerce.png'),
  afiliaPay: require('assets/images/platforms/afiliapay.png'),
  blueticket: require('assets/images/platforms/blueticket.png'),
  abmex: require('assets/images/platforms/abmex.png'),
  asaas: require('assets/images/platforms/asaas.png'),
  dooca: require('assets/images/platforms/dooca.png'),
  vindi: require('assets/images/platforms/vindi.png'),
  doppus: require('assets/images/platforms/doppus.png'),
  guru: require('assets/images/platforms/guru.png'),
  ticto: require('assets/images/platforms/ticto.png'),
  eadPlataforma: require('assets/images/platforms/eadplataforma.png'),
  blitzPay: require('assets/images/platforms/blitzpay.png'),
  provi: require('assets/images/platforms/provi.png'),
  eadPlataforma: require('assets/images/platforms/eadplataforma.png'),
  payt: require('assets/images/platforms/payt.png'),
  eventiza: require('assets/images/platforms/eventiza.png'),
  pepper: require('assets/images/platforms/pepper.png'),
  appMax: require('assets/images/platforms/appmax.png'),
  voomp: require('assets/images/platforms/voomp.png'),
  xgrow: require('assets/images/platforms/xgrow.png'),
  kirvano: require('assets/images/platforms/kirvano.png'),
  theMart: require('assets/images/platforms/themart.png'),
  hubla: require('assets/images/platforms/hubla.png'),
  payPal: require('assets/images/platforms/paypal.png'),
  tmb: require('assets/images/platforms/tmb.png'),
  lastlink: require('assets/images/platforms/lastlink.png'),
  vega: require('assets/images/platforms/vega.png'),
  doppusV2: require('assets/images/platforms/doppus.png'),
  cakto: require('assets/images/platforms/cakto.png'),
  wix: require('assets/images/platforms/wix.png')
};

const platformAltIcons = {
  spedy: require('assets/images/platforms/spedy.alt.png'),
  monetizze: require('assets/images/platforms/monetizze.alt.png'),
  hotmart: require('assets/images/platforms/hotmart.alt.png'),
  eduzz: require('assets/images/platforms/eduzz.alt.png'),
  braip: require('assets/images/platforms/braip.alt.png'),
  shopify: require('assets/images/platforms/shopify.alt.png'),
  iugu: require('assets/images/platforms/iugu.alt.png'),
  pagarme: require('assets/images/platforms/pagarme.alt.png'),
  kiwify: require('assets/images/platforms/kiwify.alt.png'),
  mercadoPago: require('assets/images/platforms/mercadopago.alt.png'),
  yampi: require('assets/images/platforms/yampi.alt.png'),
  perfectPay: require('assets/images/platforms/perfectpay.alt.png'),
  nuvemshop: require('assets/images/platforms/nuvemshop.alt.png'),
  cartpanda: require('assets/images/platforms/cartpanda.alt.png'),
  stripe: require('assets/images/platforms/stripe.alt.png'),
  evermart: require('assets/images/platforms/evermart.alt.png'),
  cyclopay: require('assets/images/platforms/cyclopay.alt.png'),
  greenn: require('assets/images/platforms/greenn.alt.png'),
  woocommerce: require('assets/images/platforms/woocommerce.alt.png'),
  afiliaPay: require('assets/images/platforms/afiliapay.alt.png'),
  blueticket: require('assets/images/platforms/blueticket.alt.png'),
  abmex: require('assets/images/platforms/abmex.alt.png'),
  asaas: require('assets/images/platforms/asaas.alt.png'),
  dooca: require('assets/images/platforms/dooca.alt.png'),
  vindi: require('assets/images/platforms/vindi.alt.png'),
  doppus: require('assets/images/platforms/doppus.alt.png'),
  guru: require('assets/images/platforms/guru.alt.png'),
  ticto: require('assets/images/platforms/ticto.alt.png'),
  eadPlataforma: require('assets/images/platforms/eadplataforma.alt.png'),
  blitzPay: require('assets/images/platforms/blitzpay.alt.png'),
  provi: require('assets/images/platforms/provi.alt.png'),
  eadPlataforma: require('assets/images/platforms/eadplataforma.alt.png'),
  payt: require('assets/images/platforms/payt.alt.png'),
  eventiza: require('assets/images/platforms/eventiza.alt.png'),
  pepper: require('assets/images/platforms/pepper.alt.png'),
  appMax: require('assets/images/platforms/appmax.alt.png'),
  voomp: require('assets/images/platforms/voomp.alt.png'),
  xgrow: require('assets/images/platforms/xgrow.alt.png'),
  kirvano: require('assets/images/platforms/kirvano.alt.png'),
  theMart: require('assets/images/platforms/themart.alt.png'),
  hubla: require('assets/images/platforms/hubla.alt.png'),
  payPal: require('assets/images/platforms/paypal.alt.png'),
  tmb: require('assets/images/platforms/tmb.alt.png'),
  lastlink: require('assets/images/platforms/lastlink.alt.png'),
  vega: require('assets/images/platforms/vega.alt.png'),
  doppusV2: require('assets/images/platforms/doppus.alt.png'),
  cakto: require('assets/images/platforms/cakto.alt.png'),
  wix: require('assets/images/platforms/wix.alt.png')
};

const platforms = [
  { key: 'spedy', label: 'Spedy' },
  { key: 'hotmart', label: 'Hotmart' },
  { key: 'monetizze', label: 'Monetizze' },
  { key: 'eduzz', label: 'Eduzz' },
  { key: 'braip', label: 'Braip' },
  { key: 'shopify', label: 'Shopify' },
  { key: 'iugu', label: 'Iugu' },
  { key: 'pagarme', label: 'Pagar.me' },
  { key: 'kiwify', label: 'Kiwify' },
  { key: 'mercadoPago', label: 'Mercado Pago' },
  { key: 'yampi', label: 'Yampi' },
  { key: 'cartpanda', label: 'Cartpanda' },
  { key: 'stripe', label: 'Stripe' },
  { key: 'perfectPay', label: 'PerfectPay' },
  { key: 'evermart', label: 'Evermart' },
  { key: 'cyclopay', label: 'Cyclopay' },
  { key: 'greenn', label: 'Greenn' },
  { key: 'woocommerce', label: 'Woocommerce' },
  { key: 'afiliaPay', label: 'AfiliaPay' },
  { key: 'blueticket', label: 'Blueticket' },
  { key: 'abmex', label: 'Abmex' },
  { key: 'asaas', label: 'Asaas' },
  { key: 'dooca', label: 'Dooca' },
  { key: 'vindi', label: 'Vindi' },
  { key: 'doppus', label: 'Doppus' },
  { key: 'guru', label: 'Digital Manager Guru' },
  { key: 'ticto', label: 'Ticto' },
  { key: 'eadPlataforma', label: 'EAD Plataforma' },
  { key: 'blitzPay', label: 'BlitzPay' },
  { key: 'provi', label: 'Provi' },
  { key: 'payt', label: 'Payt' },
  { key: 'eventiza', label: 'Eventiza' },
  { key: 'pepper', label: 'Pepper' },
  { key: 'appMax', label: 'AppMax' },
  { key: 'voomp', label: 'Voomp' },
  { key: 'xgrow', label: 'Xgrow' },
  { key: 'kirvano', label: 'Kirvano' },
  { key: 'theMart', label: 'TheMart' },
  { key: 'hubla', label: 'Hubla' },
  { key: 'payPal', label: 'PayPal' },
  { key: 'tmb', label: 'TMB' },
  { key: 'lastlink', label: 'Lastlink' },
  { key: 'vega', label: 'Vega' },
  { key: 'doppusV2', label: 'DoppusV2' },
  { key: 'cakto', label: 'Cakto' },
  { key: 'wix', label: 'Wix'}
];

const getPlatformLabel = (platform) => {
  return platforms.find((c) => c.key == platform).label;
};

export { platformDefaultIcons, platformAltIcons, platforms, getPlatformLabel };
