/* eslint-disable no-template-curly-in-string */
import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { configManager, authInitialState } from 'react-structure-admin';
import { APP_DEFAULT } from 'constants/themeSettings';
import globalEventsRegister from 'core/globalEventsRegister';
import Iugu from './util/iugu';
import rootStore from './stores/rootStore';
import Main from './Main';
import * as serviceWorker from './serviceWorker';

configManager.setConfig({
  apiUrl: process.env.REACT_APP_API_URL,
  iuguAccountId: process.env.REACT_APP_IUGU_ID,
  isTestMode: process.env.REACT_APP_IUGU_DEVELOPMENT,
  iuguUseJsTokenization: process.env.REACT_APP_IUGU_USE_JS_TOKENIZATION,
  inMaintenance: process.env.REACT_APP_IN_MAINTENANCE,
  isGoogleDebugMode: process.env.REACT_APP_GOOGLE_DEBUG_MODE,
  isFacebookDebugMode: process.env.REACT_APP_FACEBOOK_DEBUG_MODE,
  multiTenant: { enabled: true, host: 'a' },
  pageSize: 20,
  layout: {
    form: {
      layout: 'vertical',
      labelCol: null,
      wrapperCol: null,
      validateMessages: {
        pattern: {
          mismatch: '${label} é inválido'
        }
      }
    },
    list: { table: { size: 'middle' } }
  },
  currentApp: APP_DEFAULT,
  chatVisible: true,
  roles: [
    {
      name: 'Admin',
      defaultPath: '/a/customers'
    },
    {
      name: `*`,
      defaultPath: '/'
    }
  ]
});

globalEventsRegister.registerEvents();

const config = configManager.getConfig();
config.isTestMode = config.isTestMode === 'true';
config.iuguUseJsTokenization = config.iuguUseJsTokenization === 'true';
config.inMaintenance = config.inMaintenance === 'true';
config.isGoogleDebugMode = config.isGoogleDebugMode === 'true';
config.isFacebookDebugMode = config.isFacebookDebugMode === 'true';

if (Iugu != null) {
  Iugu.setAccountID(config.iuguAccountId);
  Iugu.setTestMode(config.isTestMode);
}

const initialState = {
  resources: [],
  auth: authInitialState
};

const history = createBrowserHistory();
const store = rootStore(initialState, history);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route component={Main} />
      </Switch>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
