import { useAuthContext } from 'react-structure-admin';

const useSubscription = () => {
  const { subscription } = useAuthContext();
  const { features = [] } = subscription ?? {};

  const hasFeature = (identifier) => {
    return features.findIndex((c) => c.identifier === identifier) >= 0;
  };

  return { subscription, features, hasFeature };
};

export default useSubscription;
