import React from 'react';
import { Select } from 'antd';

const SelectLinkBetweenPart = ({ ...rest }) => {
  return (
    <Select {...rest}>
      <Select.Option value="withoutLink">
        Sem vínculo com o Tomador / Prestador
      </Select.Option>
      <Select.Option value="controlled">Controlada</Select.Option>
      <Select.Option value="controller">Controladora</Select.Option>
      <Select.Option value="related">Coligada</Select.Option>
      <Select.Option value="matriz">Matrix</Select.Option>
      <Select.Option value="branch">Filial ou sucursal</Select.Option>
      <Select.Option value="otherLink">Outro vínculo</Select.Option>
    </Select>
  );
};

export default SelectLinkBetweenPart;
