import {
  updateResourceState,
  createResourceReducer,
  RESOURCE,
  RECORD_FIELD,
  RESOURCE_ACTION_EDIT
} from 'react-structure-admin';
import {
  PLATFORM,
  PLATFORM_CLOSE_CONNECTIONS,
  PLATFORM_CLOSE_FILE_IMPORT_ORDERS,
  PLATFORM_CLOSE_SYNC_ORDERS,
  PLATFORM_FILE_IMPORT_ORDERS,
  PLATFORM_FILE_IMPORT_ORDERS_FINISHED,
  PLATFORM_GET,
  PLATFORM_GET_FINISHED,
  PLATFORM_RELOAD,
  PLATFORM_RELOAD_CONNECTIONS,
  PLATFORM_SHOW_CONNECTIONS,
  PLATFORM_SHOW_FILE_IMPORT_ORDERS,
  PLATFORM_SHOW_SYNC_ORDERS,
  PLATFORM_SYNC_ORDERS,
  PLATFORM_SYNC_ORDERS_FINISHED,
  PLATFORM_UPDATE,
  PLATFORM_UPDATE_FINISHED,
  PLATFORM_SHOW_CONNECTION_REMOVE,
  PLATFORM_CLOSE_CONNECTION_REMOVE,
  PLATFORM_CONNECTION_REMOVE,
  PLATFORM_CONNECTION_REMOVE_FINISHED,
  PLATFORM_SHOW_ADVANCED_PARAMETERS,
  PLATFORM_CLOSE_ADVANCED_PARAMETERS,
  PLATFORM_UPDATE_CONNECTION_PARAMETER,
  PLATFORM_CLEAR_PARAMETERS
} from './platformActions';

const handleError = (state, action) => {
  return updateResourceState(state, action, RESOURCE, () => ({
    saving: false,
    loading: false,
    synchronizingOrders: false,
    importingOrdersFile: false
  }));
};

const platformReducer = createResourceReducer(
  PLATFORM,
  [],
  {
    [PLATFORM_GET](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        loading: true
      }));
    },

    [PLATFORM_GET_FINISHED](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        [RECORD_FIELD]: action.payload.data.result,
        loading: false,
        visible: true,
        editing: true,
        action: RESOURCE_ACTION_EDIT
      }));
    },

    [PLATFORM_UPDATE](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        saving: true
      }));
    },

    [PLATFORM_UPDATE_FINISHED](state, action) {
      return updateResourceState(state, action, RESOURCE, (resource) => {
        const { items = [], [RECORD_FIELD]: record } = resource;
        items.forEach((element) => {
          if (element.id === record.platform.id) {
            element.isActivated = record.isActivated;
          }
        });
        return {
          items,
          [RECORD_FIELD]: null,
          saving: false,
          visible: false,
          editing: false,
          action: null
        };
      });
    },

    [PLATFORM_SHOW_CONNECTIONS](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        showConnections: true,
        connectionsPlatform: action.payload
      }));
    },

    [PLATFORM_CLOSE_CONNECTIONS](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        showConnections: false,
        connectionsPlatform: null
      }));
    },

    [PLATFORM_RELOAD_CONNECTIONS](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        loaded: false,
        parameters: null
      }));
    },

    [PLATFORM_RELOAD](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        loaded: false,
        parameters: null
      }));
    },

    [PLATFORM_SHOW_SYNC_ORDERS](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        showSyncOrders: true,
        syncOrdersParams: action.payload
      }));
    },

    [PLATFORM_CLOSE_SYNC_ORDERS](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        showSyncOrders: false,
        syncOrdersParams: null
      }));
    },

    [PLATFORM_SYNC_ORDERS](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        synchronizingOrders: true
      }));
    },

    [PLATFORM_SYNC_ORDERS_FINISHED](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        loaded: false,
        showSyncOrders: false,
        syncOrdersParams: null,
        synchronizingOrders: false
      }));
    },

    [PLATFORM_SHOW_FILE_IMPORT_ORDERS](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        showFileImportOrders: true,
        fileImportOrdersParams: action.payload
      }));
    },

    [PLATFORM_CLOSE_FILE_IMPORT_ORDERS](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        showFileImportOrders: false,
        fileImportOrdersParams: null
      }));
    },

    [PLATFORM_FILE_IMPORT_ORDERS](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        importingOrdersFile: true
      }));
    },

    [PLATFORM_FILE_IMPORT_ORDERS_FINISHED](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        loaded: false,
        showFileImportOrders: false,
        importingOrdersFile: false,
        fileImportOrdersParams: null
      }));
    },

    [PLATFORM_SHOW_CONNECTION_REMOVE](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        activeModal: PLATFORM_SHOW_CONNECTION_REMOVE,
        connection: action.payload.connection,
        connectionList: action.payload.connectionList
      }));
    },

    [PLATFORM_CLOSE_CONNECTION_REMOVE](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        activeModal: null
      }));
    },

    [PLATFORM_CONNECTION_REMOVE](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        loading: true
      }));
    },

    [PLATFORM_CONNECTION_REMOVE_FINISHED](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        loaded: false,
        loading: false
      }));
    },

    [PLATFORM_SHOW_ADVANCED_PARAMETERS](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        activeModal: PLATFORM_SHOW_ADVANCED_PARAMETERS,
        data: action.payload.data
      }));
    },

    [PLATFORM_CLOSE_ADVANCED_PARAMETERS](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        activeModal: null
      }));
    },

    [PLATFORM_UPDATE_CONNECTION_PARAMETER](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        parameters: action.payload.parameters,
        removedParameters: action.payload.removedParameters
      }));
    },

    [PLATFORM_CLEAR_PARAMETERS](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        parameters: null
      }));
    }
  },
  handleError
);

export default platformReducer;
