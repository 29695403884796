import React from 'react';
import { Select } from 'antd';

const SelectProvisionMode = ({ ...rest }) => {
  return (
    <Select {...rest}>
      <Select.Option value="unknown">
        Desconhecido (tipo não informado na nota de origem)
      </Select.Option>
      <Select.Option value="crossBorder">Transfronteiriço</Select.Option>
      <Select.Option value="consumptionInBrazil">
        Consumo no Brasil
      </Select.Option>
      <Select.Option value="temporaryMovementPhysicalPerson">
        Movimento Temporário de Pessoas Físicas
      </Select.Option>
      <Select.Option value="consumptionOutSideBrazil">
        Consumo no Exterior
      </Select.Option>
    </Select>
  );
};

export default SelectProvisionMode;
