import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons';
import { Tag } from 'antd';
import React from 'react';

const icons = {
  approved: {
    color: 'success',
    title: 'Aprovado',
    icon: <CheckCircleOutlined />
  },
  pending: {
    color: 'default',
    title: 'Pendente',
    icon: <ClockCircleOutlined />
  },
  cancelled: {
    color: 'default',
    title: 'Cancelado',
    icon: <CloseCircleOutlined />
  },
  refunded: {
    color: 'error',
    title: 'Reembolsado',
    icon: <ExclamationCircleOutlined />
  },
  chargedBack: {
    color: 'error',
    title: 'Estornado',
    icon: <ExclamationCircleOutlined />
  },
  expired: {
    color: 'error',
    title: 'Expirado',
    icon: <ExclamationCircleOutlined />
  },
  inProtest: {
    color: 'error',
    title: 'Contestado',
    icon: <ExclamationCircleOutlined />
  }
};

const SubscriptionPaymentTag = ({ status }) => {
  let tag = icons[status];

  if (!tag) {
    tag = { title: 'Pendente', color: 'default' };
  }

  return (
    <Tag icon={tag.icon} color={tag.color}>
      {tag.title}
    </Tag>
  );
};

export default SubscriptionPaymentTag;
