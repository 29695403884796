import React, { useState } from 'react';
import { Button, Input, Modal, message } from 'antd';
import { useDispatch } from 'react-redux';
import { ReduxRender } from 'core';
import {
  COMPANY_OPEN_API_KEY,
  closeCompanyApiKey,
  generateApiKey
} from 'stores/companies/companyActions';
import { Footer } from 'components';
import { CopyOutlined } from '@ant-design/icons';

const CompanyApiKey = ({ company, loading }) => {
  const dispatch = useDispatch();
  const [apiKey, setApiKey] = useState(company.apiKey);
  const { id } = company;

  const handleGenerateApiKeyOnSuccess = () => {
    message.success('Nova chave de API criada.');
    handleClose();
  };

  const handleSubmit = () => {
    dispatch(generateApiKey(id, handleGenerateApiKeyOnSuccess));
  };

  const handleBeforeSubmit = () => {
    Modal.confirm({
      title: 'Deseja gerar uma nova Chave de API?',
      content: 'A chave atual será revogada.',
      width: '400px',
      cancelText: 'Voltar',
      okText: 'Confirmar',
      onOk: handleSubmit
    });
  };

  const handleClose = () => {
    dispatch(closeCompanyApiKey());
  };

  const copy = () => {
    navigator.clipboard.writeText(apiKey);
    message.success('Chave copiada para área de transferência');
  };

  const customButton = <Button icon={<CopyOutlined />} />;

  return (
    <Modal
      visible
      title="Credenciais da API"
      okText="Gerar nova chave"
      cancelText="Voltar"
      onOk={handleBeforeSubmit}
      onCancel={handleClose}
      confirmLoading={loading}
    >
      <Input.Search
        enterButton={customButton}
        value={apiKey}
        disabled={true}
        onSearch={copy}
      />
    </Modal>
  );
};

export default ReduxRender.create(CompanyApiKey, {
  condition: (state) =>
    state.resources?.companies?.activeModal == COMPANY_OPEN_API_KEY,
  mapProps: (state) => ({
    company: state.resources?.companies.company,
    loading: state.resources.companies?.loading
  })
});
