import React from 'react';
import { Row, Form, Divider, Button, Drawer, Input, Checkbox } from 'antd';
import {
  FormItemWrap,
  configManager,
  useCrudEditContext,
  generateUUID
} from 'react-structure-admin';
import {
  InputPercent,
  SelectStates,
  TaxCodeSelect,
  ResCol,
  TooltipInfoIcon,
  InputNumeric
} from 'components';
import { useCompany } from 'core';
import findDuplicates from 'util/functions/findDuplicates';
import join from 'util/functions/join';
import getStates from 'util/getStates';
import { customerTypeDisplay } from 'constants/types';

const TaxationGroupProductInvoiceRuleEdit = ({ editingRule, onClose }) => {
  const { isSimplesNacional } = useCompany();
  const [form] = Form.useForm();
  const { form: mainForm } = useCrudEditContext();

  const isEditing =
    editingRule?.id !== undefined || editingRule?.uuid !== undefined;

  const handleInsertOrUpdate = (values) => {
    if (!isEditing) {
      values.uuid = generateUUID();
    }

    const { rules = [] } = mainForm.getFieldsValue();
    const key = editingRule?.id ?? editingRule?.uuid;
    const index = rules.findIndex(
      (c) =>
        (c?.id !== undefined && c.id === key) ||
        (c?.uuid !== undefined && c.uuid) === key
    );

    if (index > -1) {
      rules[index] = values;
    } else {
      rules.push(values);
    }

    mainForm.setFieldsValue({ rules: [...rules] });
    form.resetFields();
    onClose();
  };

  const handleBeforeSave = ({ states: listOfStates = [], ...rest }) => {
    return {
      ...rest,
      states: listOfStates.map((c) => c.value)
    };
  };

  const isValid = (values) => {
    const { rules = [] } = mainForm.getFieldsValue();

    const key = editingRule?.id ?? editingRule?.uuid;
    const index = rules.findIndex(
      (c) =>
        (c?.id !== undefined && c.id === key) ||
        (c?.uuid !== undefined && c.uuid) === key
    );

    const extractAndFindDuplicates = (arr, key) => {
      const allDuplicates = arr
        .filter((_, elementIndex) => elementIndex !== index)
        .reduce((a, b) => a.concat(b[key]), values[key] ?? []);

      const uniqueValuesDuplicated = new Set(allDuplicates);
      const uniqueValuesArray = [...uniqueValuesDuplicated];

      return uniqueValuesArray;
    };

    const duplicateStates = extractAndFindDuplicates(rules, 'states');
    const duplicateCustomerTypes = extractAndFindDuplicates(
      rules,
      'customerTypes'
    );

    const duplicatesRules = rules
      .filter((_, elementIndex) => elementIndex !== index)
      .some(
        (c) =>
          c.states.some((d) => values.states.includes(d)) &&
          c.customerTypes.some((d) => values.customerTypes.includes(d))
      );

    if (duplicatesRules) {
      const state = duplicateStates
        .filter((state) => values.states.includes(state))
        .map((c) => c.toUpperCase());

      const customerType = duplicateCustomerTypes
        .filter((customerType) => values.customerTypes.includes(customerType))
        .map((c) => customerTypeDisplay[c]);

      const phraseStates =
        state.length > 1
          ? 'Já existe uma regra para os estados'
          : 'Já existe uma regra para o estado';

      const phraseCustomerTypes =
        customerType.length > 1 ? 'tipos de cliente' : 'tipo de cliente';

      form.setFields([
        {
          name: ['states'],
          errors: [
            `${phraseStates} ${join(
              state,
              ', ',
              ' e '
            )} e ${phraseCustomerTypes} ${join(customerType, ', ', ' e ')}`
          ]
        }
      ]);
    }

    return !duplicatesRules;
  };

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      values = handleBeforeSave({ ...editingRule, ...values });
      if (isValid(values)) {
        handleInsertOrUpdate(values);
      }
    });
  };

  const handleBeforeBinding = ({ states: listOfStates = [], ...rest }) => {
    return {
      ...rest,
      states: listOfStates.map((c) => {
        const name = getStates(true).find((d) => d.initials === c)?.name;
        return {
          value: c,
          label: name
        };
      })
    };
  };

  const initialValues = handleBeforeBinding(editingRule);

  const supportedCustomerTypes = ['taxpayer', 'nonTaxpayer', 'foreigner'];
  const customerTypes = supportedCustomerTypes.map((type) => ({
    label: customerTypeDisplay[type],
    value: type
  }));

  return (
    <Drawer
      visible
      width={650}
      title="Regra NF-e"
      bodyStyle={{ paddingBottom: 80 }}
      onClose={onClose}
      footer={
        <div
          style={{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right'
          }}
        >
          <Button onClick={onClose}>Voltar</Button>
          <Button type="primary" onClick={handleSubmit}>
            Salvar
          </Button>
        </div>
      }
    >
      <Form
        form={form}
        {...configManager.getConfig().layout.form}
        initialValues={initialValues}
      >
        <Row>
          <ResCol span={24}>
            <Divider orientation="left">Selecione os estados</Divider>
            <FormItemWrap name="states" label="Estados" required>
              <SelectStates mode="multiple" showForeign={true} />
            </FormItemWrap>
          </ResCol>
        </Row>

        <Row>
          <ResCol span={24}>
            <Divider orientation="left">Tipo de cliente</Divider>
            <FormItemWrap
              name="customerTypes"
              initialValue={supportedCustomerTypes}
              rules={[
                {
                  required: true,
                  message: 'Tipo de cliente é obrigatório.'
                }
              ]}
            >
              <Checkbox.Group options={customerTypes} />
            </FormItemWrap>
          </ResCol>
        </Row>

        <Divider orientation="left">ICMS</Divider>
        {isSimplesNacional ? (
          <Row>
            <ResCol span={17}>
              <FormItemWrap
                name={['icms', 'csosn']}
                label="CSOSN"
                required
                initialValue={102}
              >
                <TaxCodeSelect type="csosn" />
              </FormItemWrap>
            </ResCol>
            <ResCol span={7}>
              <FormItemWrap
                label="Cód. Benefício Fiscal"
                name={['icms', 'taxBenefitCode']}
              >
                <Input maxLength={20} />
              </FormItemWrap>
            </ResCol>
          </Row>
        ) : (
          <Row>
            <ResCol span={24}>
              <FormItemWrap
                name={['icms', 'cst']}
                label="CST"
                required
                initialValue={0}
              >
                <TaxCodeSelect type="cstIcms" />
              </FormItemWrap>
            </ResCol>
            <ResCol span={11}>
              <FormItemWrap
                label={
                  <>
                    <span>Mod det. da BC</span>
                    <TooltipInfoIcon title="Modalidade de determinação da Base de Cálculo do ICMS." />
                  </>
                }
                name={['icms', 'baseTaxModality']}
                initialValue={0}
              >
                <TaxCodeSelect type="baseTaxModalities" />
              </FormItemWrap>
            </ResCol>
            <ResCol span={7}>
              <FormItemWrap
                label="Cód. Benefício Fiscal"
                name={['icms', 'taxBenefitCode']}
              >
                <Input maxLength={20} />
              </FormItemWrap>
            </ResCol>
            <ResCol span={6}>
              <FormItemWrap label="Alíquota" name={['icms', 'rate']}>
                <InputPercent />
              </FormItemWrap>
            </ResCol>
          </Row>
        )}

        <Divider orientation="left">PIS</Divider>
        <Row>
          <ResCol span={18}>
            <FormItemWrap
              name={['pis', 'cst']}
              label="CST"
              required
              initialValue={7}
            >
              <TaxCodeSelect type="cstPisCofins" />
            </FormItemWrap>
          </ResCol>
          <ResCol span={6}>
            <FormItemWrap label="Alíquota" name={['pis', 'rate']}>
              <InputPercent />
            </FormItemWrap>
          </ResCol>
        </Row>

        <Divider orientation="left">COFINS</Divider>
        <Row>
          <ResCol span={18}>
            <FormItemWrap
              name={['cofins', 'cst']}
              label="CST"
              required
              initialValue={7}
            >
              <TaxCodeSelect type="cstPisCofins" />
            </FormItemWrap>
          </ResCol>
          <ResCol span={6}>
            <FormItemWrap label="Alíquota" name={['cofins', 'rate']}>
              <InputPercent />
            </FormItemWrap>
          </ResCol>
        </Row>

        <Divider orientation="left">IPI</Divider>
        <Row>
          <ResCol span={10}>
            <FormItemWrap name={['ipi', 'cst']} label="CST">
              <TaxCodeSelect type="cstIpi" />
            </FormItemWrap>
          </ResCol>
          <ResCol span={6}>
            <FormItemWrap label="Alíquota" name={['ipi', 'rate']}>
              <InputPercent />
            </FormItemWrap>
          </ResCol>
          <ResCol span={8}>
            <FormItemWrap
              label="Cód. Enquadramento Legal"
              name={['ipi', 'classificationCode']}
            >
              <InputNumeric maxLength={3} />
            </FormItemWrap>
          </ResCol>
        </Row>
      </Form>
    </Drawer>
  );
};

export default TaxationGroupProductInvoiceRuleEdit;
