import React, { Component } from 'react';
import { api } from 'react-structure-admin';
import { Input, Popover, Spin, Empty, Drawer } from 'antd';
import { debounce } from 'lodash';
import CustomScrollbars from './CustomScrollbars';

class RemoteSelectPopover extends Component {
  constructor(props) {
    super(props);

    this.handleSearch = debounce(this.fetchData, 800);
    this.lastFetchId = 0;
    this.fethOnMount = props.fethOnMount;
    this.refInput = React.createRef();

    this.state = {
      data: [],
      fetching: false,
      visible: false,
      textSearched: '',
      pagination: {
        hasNextPage: true,
        currentPage: 0
      }
    };
  }

  componentDidMount() {
    if (this.fethOnMount) {
      this.fetchData();
    }
  }

  fetchData = (value) => {
    const { pagination } = this.state;
    const { resource, params = {} } = this.props;

    if (!pagination.hasNextPage) {
      return;
    }

    this.setState({ fetching: true });
    this.lastFetchId += 1;
    const fetchId = this.lastFetchId;

    const filter = {
      filterText: value,
      page: pagination.currentPage + 1,
      ...params
    };

    api.fetch(resource, filter).then((response = {}) => {
      const { data = { result: {} } } = response;
      if (fetchId !== this.lastFetchId) {
        return;
      }
      const { totalCount = 0, items = [] } = data.result;

      this.setState((prevState) => ({
        ...prevState,
        data: filter.page === 1 ? items : [...prevState.data, ...items],
        fetching: false,
        textSearched: value,
        pagination: {
          hasNextPage:
            filter.page === 1
              ? items.length < totalCount
              : prevState.data.length + items.length < totalCount,
          currentPage: filter.page
        }
      }));
    });
  };

  handleOnClick = (record) => {
    const { onSelect, allowHide } = this.props;

    if (onSelect) {
      onSelect(record);
    }

    if (allowHide) {
      this.setState({ visible: !allowHide(record) });
      return;
    }

    this.setState({ visible: false });
  };

  handleOnChangeVisible = (visible) => {
    const { disabled } = this.props;

    if (disabled) {
      return;
    }

    if (visible) {
      this.fetchData();
    }

    this.setState({ visible });
  };

  handleOnSearch = (value) => {
    this.setState({ pagination: { hasNextPage: true, currentPage: 0 } });
    this.handleSearch(value);
  };

  clearSearchField = () => {
    if (this.refInput.current) {
      this.refInput.current.state.value = '';
    }
  };

  handleOnScroll = () => {
    const { textSearched } = this.state;
    this.fetchData(textSearched);
  };

  render() {
    const { fetching, visible, data } = this.state;
    const { children, renderItem, placeholder } = this.props;

    const component = (
      <>
        <Input
          className="gx-remoteselectpopover-content-input"
          maxLength={40}
          ref={this.refInput}
          type="search"
          onPressEnter={this.handleOnPressEnter}
          onChange={(e) => this.handleOnSearch(e.target.value)}
          placeholder={placeholder || 'Pesquisar...'}
        />
        <CustomScrollbars
          style={{ display: 'flex', height: '180px' }}
          onScrollEnd={this.handleOnScroll}
        >
          {data.length === 0 && !fetching ? (
            <Empty description="" image={Empty.PRESENTED_IMAGE_SIMPLE} />
          ) : null}
          {data.map((record) => (
            <div
              key={record.id}
              className="gx-remoteselectpopover-content-button"
              role="button"
              tabIndex="0"
              onKeyPress={() => {}}
              onClick={() => this.handleOnClick(record)}
            >
              {renderItem(record, data.length)}
            </div>
          ))}
          {fetching ? (
            <div className="gx-remoteselectpopover-content-spin">
              <Spin />
            </div>
          ) : null}
        </CustomScrollbars>
      </>
    );

    return (
      <Popover
        overlayClassName="gx-remoteselectpopover"
        placement="bottomLeft"
        trigger="click"
        visible={visible}
        onVisibleChange={this.handleOnChangeVisible}
        content={
          <div className="gx-remoteselectpopover-content">{component}</div>
        }
      >
        {children}
      </Popover>
    );
  }
}

RemoteSelectPopover.defaultProps = { fethOnMount: false };

export default RemoteSelectPopover;
