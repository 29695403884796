import {
  updateResourceState,
  createResourceReducer,
  RESOURCE,
  RECORD_FIELD,
  dispatch
} from 'react-structure-admin';
import {
  MONTHLY_CLOSING,
  MONTHLY_CLOSING_GET,
  MONTHLY_CLOSING_GET_FINISHED,
  MONTHLY_CLOSING_GENERATE,
  MONTHLY_CLOSING_GENERATE_FAILED,
  MONTHLY_CLOSING_GENERATE_FINISHED,
  MONTHLY_CLOSING_SETTINGS_GET,
  MONTHLY_CLOSING_SETTINGS_GET_FINISHED,
  MONTHLY_CLOSING_SETTINGS_UPDATE,
  MONTHLY_CLOSING_SETTINGS_UPDATE_FINISHED
} from './monthlyClosingsActions';

const monthlyClosingReducer = createResourceReducer(MONTHLY_CLOSING, [], {
  [MONTHLY_CLOSING_GET](state, action) {
    return updateResourceState(state, action, RESOURCE, () => ({
      loading: true
    }));
  },

  [MONTHLY_CLOSING_GET_FINISHED](state, action) {
    return updateResourceState(state, action, RESOURCE, () => ({
      [RECORD_FIELD]: action.payload.data.result,
      supportedFileTypes: action.payload.data.result?.supportedFileTypes ?? [],
      loading: false
    }));
  },

  [MONTHLY_CLOSING_GENERATE_FAILED](state, action) {
    return updateResourceState(state, action, RESOURCE, () => ({
      loading: false,
      saving: false
    }));
  },

  [MONTHLY_CLOSING_GENERATE](state, action) {
    return updateResourceState(state, action, RESOURCE, () => ({
      saving: true
    }));
  },

  [MONTHLY_CLOSING_GENERATE_FINISHED](state, action) {
    return updateResourceState(state, action, RESOURCE, () => ({
      [RECORD_FIELD]: action.payload.data.result,
      saving: false,
      loading: false
    }));
  },

  [MONTHLY_CLOSING_SETTINGS_GET](state, action) {
    return updateResourceState(state, action, RESOURCE, () => ({
      visible: true
    }));
  },

  [MONTHLY_CLOSING_SETTINGS_GET_FINISHED](state, action) {
    return updateResourceState(state, action, RESOURCE, () => ({
      settings: action.payload.data.result,
      visible: false
    }));
  },

  [MONTHLY_CLOSING_SETTINGS_UPDATE](state, action) {
    return updateResourceState(state, action, RESOURCE, () => ({
      settings: null,
      saving: true
    }));
  },

  [MONTHLY_CLOSING_SETTINGS_UPDATE_FINISHED](state, action) {
    return updateResourceState(state, action, RESOURCE, () => ({
      settings: action.payload.data.result,
      saving: false
    }));
  }
});

export default monthlyClosingReducer;
