import React, { useState } from 'react';
import 'moment/locale/pt-br';
import { Form, DatePicker } from 'antd';

const { RangePicker } = DatePicker;

const FilterRangePicker = ({
  name,
  initialValue,
  ranges = [],
  noStyle = true,
  limitedDate,
  onChange
}) => {
  const [dates, setDates] = useState(initialValue);

  const disabledDate = (current) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'days') > limitedDate;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') > limitedDate;
    return !!tooEarly || !!tooLate;
  };

  return (
    <Form.Item name={name} noStyle={noStyle} initialValue={initialValue}>
      <RangePicker
        value={dates}
        format="DD/MM/YYYY"
        style={{ width: '100%' }}
        ranges={ranges}
        disabledDate={limitedDate > 0 ? disabledDate : null}
        onCalendarChange={(value) => setDates(value)}
        onChange={onChange}
      />
    </Form.Item>
  );
};

export default FilterRangePicker;
