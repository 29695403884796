/*eslint-disable */
import { useClipboard } from 'core';
import React from 'react';

const ClipboardLabel = ({ children }) => {
  const { copy } = useClipboard();

  const handleCopy = () => {
    if (typeof children !== 'string') {
      throw new Error('ClipboardLabel only accept string type child');
    }

    copy(children);
  };

  return (
    <label className="gx-pointer" onClick={handleCopy}>
      {children}
    </label>
  );
};

export default ClipboardLabel;
