import { dispatcher, api } from 'react-structure-admin';

export const COMPANY = 'COMPANY';
export const COMPANY_GET = 'COMPANY_GET';
export const COMPANY_GET_FINISHED = 'COMPANY_GET_FINISHED';
export const COMPANY_GET_SETTINGS = 'COMPANY_GET_SETTINGS';
export const COMPANY_CLOSE_SETTINGS = 'COMPANY_CLOSE_SETTINGS';
export const COMPANY_GET_SETTINGS_FINISHED = 'COMPANY_GET_SETTINGS_FINISHED';
export const COMPANY_UPDATE = 'COMPANY_UPDATE';
export const COMPANY_UPDATE_FINISHED = 'COMPANY_UPDATE_FINISHED';
export const COMPANY_SETTINGS_UPDATE = 'COMPANY_SETTINGS_UPDATE';
export const COMPANY_SETTINGS_UPDATE_FINISHED =
  'COMPANY_SETTINGS_UPDATE_FINISHED';
export const COMPANY_CREATE = 'COMPANY_CREATE';
export const COMPANY_CREATE_FINISHED = 'COMPANY_CREATE_FINISHED';
export const COMPANY_UPLOAD_CERTIFICATE = 'COMPANY_UPLOAD_CERTIFICATE';
export const COMPANY_UPLOAD_CERTIFICATE_FINISHED =
  'COMPANY_UPLOAD_CERTIFICATE_FINISHED';
export const COMPANY_UPLOAD_CERTIFICATE_ERROR =
  'COMPANY_UPLOAD_CERTIFICATE_ERROR';
export const COMPANY_CANCEL = 'COMPANY_CANCEL';
export const COMPANY_FIND = 'COMPANY_FIND';
export const COMPANY_ISSUE_TYPES = 'COMPANY_ISSUE_TYPES';
export const COMPANY_ALLOW_REGISTER = 'COMPANY_ALLOW_REGISTER';
export const COMPANY_SAVE_CERTIFICATE = 'COMPANY_SAVE_CERTIFICATE';
export const COMPANY_GET_SETUP_STEPS = 'COMPANY_GET_SETUP_STEPS';
export const COMPANY_GET_SETUP_STEPS_FINISHED =
  'COMPANY_GET_SETUP_STEPS_FINISHED';
export const COMPANY_API_KEY_GET = 'COMPANY_API_KEY_GET';
export const COMPANY_API_KEY_FINISHED = 'COMPANY_API_KEY_FINISHED';

export const COMPANY_SERVICE_APPROXIMATE_TAXES_GET =
  'COMPANY_SERVICE_APPROXIMATE_TAXES_GET';

export const COMPANY_GET_TENANT = 'COMPANY_GET_TENANT';
export const COMPANY_GET_TENANT_FINISHED = 'COMPANY_GET_TENANT_FINISHED';

export const COMPANY_OPEN_CREATE = 'COMPANY_OPEN_CREATE';
export const COMPANY_CLOSE_CREATE = 'COMPANY_CLOSE_CREATE';

export const COMPANY_REMOVE = 'COMPANY_REMOVE';
export const COMPANY_REMOVE_FINISHED = 'COMPANY_REMOVE_FINISHED';

export const COMPANY_OPEN_API_KEY = 'COMPANY_OPEN_API_KEY';
export const COMPANY_CLOSE_API_KEY = 'COMPANY_CLOSE_API_KEY';
export const COMPANY_GENERATE_API_KEY = 'COMPANY_GENERATE_API_KEY';
export const COMPANY_GENERATE_API_KEY_FINISHED =
  'COMPANY_GENERATE_API_KEY_FINISHED';

export const get = (id, tab) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    payload: { tab },
    resource: 'companies',
    actionType: COMPANY_GET,
    effect: async () => api.get('companies', id)
  });
};

export const getCompanies = (tenant, onSuccess) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'companies',
    actionType: COMPANY_GET_TENANT,
    effect: async () => api.fetch('companies', {}, {}, tenant),
    onSuccess
  });
};

export const getSettings = (id) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'companies',
    actionType: COMPANY_GET_SETTINGS,
    effect: async () => api.fetch('companies/:id/settings', { id })
  });
};

export const closeSettings = () => (dispatch) => {
  dispatch(dispatcher.createAction(COMPANY_CLOSE_SETTINGS, 'companies'));
};

export const update = (payload = {}, onSuccess) => async (dispatch) => {
  const { id } = payload;
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'companies',
    actionType: COMPANY_UPDATE,
    payload,
    effect: async () => api.put(`companies/${id}`, { ...payload }),
    onSuccess
  });
};

export const updateSettings = (payload = {}, onSuccess) => async (dispatch) => {
  const { id } = payload;
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'companies',
    actionType: COMPANY_SETTINGS_UPDATE,
    payload,
    effect: async () => api.put(`companies/${id}/settings`, { ...payload }),
    onSuccess
  });
};

export const create = (payload = {}, onSuccess) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'companies',
    actionType: COMPANY_CREATE,
    payload,
    effect: async () => api.post(`companies`, { ...payload }),
    onSuccess
  });
};

export const postCertificateError = () => (dispatch) => {
  dispatch(
    dispatcher.createAction(COMPANY_UPLOAD_CERTIFICATE_ERROR, 'companies')
  );
};

export const postCertificate = (id, formData) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'companies',
    actionType: COMPANY_UPLOAD_CERTIFICATE,
    effect: async () => api.post(`companies/${id}/certificates`, formData),
    onFail: () => dispatch(postCertificateError())
  });
};

export const cancel = () => (dispatch) => {
  dispatch(dispatcher.createAction(COMPANY_CANCEL, 'companies'));
};

export const findByFederalTaxNumber = (
  federaltaxnumber,
  onSuccess,
  onFail
) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'companies',
    actionType: COMPANY_FIND,
    effect: async () => api.get('companies/find', federaltaxnumber),
    onSuccess,
    onFail
  });
};

export const getIssueTypes = (
  companyId,
  model,
  environmentType,
  onSuccess,
  onFail
) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'companies',
    actionType: COMPANY_ISSUE_TYPES,
    effect: async () =>
      api.fetch('companies/:companyId/issue-types', {
        companyId,
        model,
        environmentType
      }),
    onSuccess,
    onFail
  });
};

export const allowRegisterFederalTaxNumber = (
  federaltaxnumber,
  onSuccess,
  onFail
) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'companies',
    actionType: COMPANY_ALLOW_REGISTER,
    effect: async () =>
      api.get(
        'companies/is-allow-register-federal-tax-number',
        federaltaxnumber
      ),
    onSuccess,
    onFail
  });
};

export const saveCertificate = (formData, onSuccess) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'companies',
    actionType: COMPANY_SAVE_CERTIFICATE,
    effect: async () => api.post(`companies/certificates`, formData),
    onSuccess
  });
};

export const SetupSteps = (id) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'companies',
    actionType: COMPANY_GET_SETUP_STEPS,
    effect: async () => api.fetch('companies/:id/setup-steps-completed', { id })
  });
};

export const getApiKey = (companyId, onSuccess, onFail) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'companies',
    actionType: COMPANY_API_KEY_GET,
    effect: async () => api.get('companies', `${companyId}/api-key`),
    onSuccess,
    onFail
  });
};

export const getServiceApproximateTaxes = (
  federalServiceCode,
  onSuccess
) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'companies',
    actionType: COMPANY_SERVICE_APPROXIMATE_TAXES_GET,
    onSuccess,
    effect: async () =>
      api.get(
        'companies/service-approximate-taxes',
        `?federalservicecode=${federalServiceCode}`
      )
  });
};

export const openCreateCompany = () => (dispatch) => {
  dispatch(dispatcher.createAction(COMPANY_OPEN_CREATE, 'companies'));
};

export const closeCreateCompany = () => (dispatch) => {
  dispatch(dispatcher.createAction(COMPANY_CLOSE_CREATE, 'companies'));
};

export const removeCompany = (id, onSuccess) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'companies',
    actionType: COMPANY_REMOVE,
    effect: async () => api.del('companies', id),
    onSuccess
  });
};

export const openCompanyApiKey = (id, apiKey) => (dispatch) => {
  dispatch(
    dispatcher.createAction(COMPANY_OPEN_API_KEY, 'companies', { id, apiKey })
  );
};

export const closeCompanyApiKey = () => (dispatch) => {
  dispatch(dispatcher.createAction(COMPANY_CLOSE_API_KEY, 'companies'));
};

export const generateApiKey = (id, onSuccess) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'companies',
    actionType: COMPANY_GENERATE_API_KEY,
    effect: async () => api.post(`companies/${id}/generate-api-key`),
    onSuccess
  });
};
