import React, { useEffect, useState } from 'react';
import { Modal, Row, Button, Switch, message, Col } from 'antd';
import { List, useAuthContext, api } from 'react-structure-admin';
import ListCardPlan from 'components/ListCardPlan';
import { useDispatch, useSelector } from 'react-redux';
import {
  closeChangeSubscriptionPlan,
  closeResume,
  getResume,
  updateSubscription
} from 'stores/subscriptions/subscriptionActions';
import { CheckoutPlan, PaymentPlan } from 'components';
import useCookieHandler from 'core/useCookieHandler';

const SubscriptionPlanList = ({ data = {}, current }) => {
  const dispatch = useDispatch();
  const { items } = data;
  const { subscription, refreshToken, refresh } = useAuthContext();
  const { cookies, setCookie, removeCookie } = useCookieHandler(['planId']);
  const [isMonthly, setIsMonthly] = useState(
    current.plan.frequency === 'monthly'
  );
  const { saving = false, loadingPlanId, checkoutResume } = useSelector(
    (state) => state.resources.subscriptions
  );

  const handleToggle = () => {
    setIsMonthly(!isMonthly);
  };

  const handleClick = () => {
    dispatch(closeChangeSubscriptionPlan());
  };

  const handleSubmitSubscribe = ({ paymentMethod, couponCode }) => {
    dispatch(
      updateSubscription(
        subscription.id,
        {
          planId: checkoutResume?.plan?.id,
          paymentMethod,
          couponCode
        },
        ({ data: { result } }) => {
          message.success('Alteração realizada com sucesso');
          refresh({ refreshToken });
        }
      )
    );
  };

  const handleCloseResume = () => {
    dispatch(closeResume());
  };

  const handleChangeResume = ({ planId, couponCode }) => {
    dispatch(getResume({ planId, couponCode }));
  };

  const handleSelectPlan = (plan) => {
    if (plan.amount === 0) {
      handleSubmitSubscribe(plan);
    } else {
      handleChangeResume({ planId: plan.id });
    }
  };

  useEffect(() => {
    if (cookies.planId) {
      api.get('subscription-plans', cookies.planId).then(({ data }) => {
        handleSelectPlan(data.result);
        removeCookie('planId');
      });
    }
  }, [cookies]);

  const width = checkoutResume?.amount > 0 ? 1000 : 600;

  return (
    <>
      {items && (
        <Modal
          centered
          width={checkoutResume?.plan ? width : 1400}
          visible
          maskClosable={false}
          title="Alterar plano de assinatura"
          onCancel={() => handleClick()}
          footer={
            checkoutResume?.plan ? null : (
              <Button onClick={() => handleClick()}>Cancelar</Button>
            )
          }
        >
          {!checkoutResume?.plan && !cookies.planId && (
            <>
              <Row className="gx-onboarding-header">
                <span className={isMonthly && 'gx-onboarding-switch'}>
                  Mensal
                </span>
                <Switch defaultChecked={!isMonthly} onChange={handleToggle} />
                <span className={!isMonthly && 'gx-onboarding-switch'}>
                  Anual
                </span>
              </Row>

              <Row style={{ justifyContent: 'center' }}>
                <ListCardPlan
                  plans={items}
                  frequency={isMonthly ? 'monthly' : 'yearly'}
                  onSubscribeClick={handleSelectPlan}
                  currentPlan={current?.plan}
                  selectedPlan={checkoutResume?.plan}
                  loadingPlanId={loadingPlanId}
                />
              </Row>
            </>
          )}
          {checkoutResume?.plan && (
            <div className="gx-onboarding-container-payment">
              <CheckoutPlan
                resume={checkoutResume}
                loading={saving}
                applyingCoupon={!!loadingPlanId}
                onCancel={handleCloseResume}
                onSubmit={handleSubmitSubscribe}
                allowCoupon={!subscription.referenceId}
              />
            </div>
          )}
        </Modal>
      )}
    </>
  );
};

export default List.create(SubscriptionPlanList, {
  resource: 'subscription-plans',
  fixedQueryParams: { onlyVisible: true }
});
