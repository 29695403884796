import React from 'react';
import asyncComponent from 'util/asyncComponent';
import { default as AdminHome } from 'pages/admin/home/Home';
import AdminContainer from 'containers/admin/AdminContainer';
import AppContainer from 'containers/app/AppContainer';
import { INVOICE_MANAGEMENT } from 'constants/features';
import { Badge } from 'antd';
import { NotificationBadge } from 'components';

const platform = ['shopify'];

export default {
  routes: [
    {
      path: '/signin',
      component: asyncComponent(() => import('pages/signin/SignIn'))
    },
    {
      path: '/signout',
      component: asyncComponent(() => import('pages/signin/SignOut'))
    },
    {
      path: '/email-confirmation',
      component: asyncComponent(() =>
        import('pages/emailConfirmation/EmailConfirmation')
      )
    },
    {
      path: '/confirm-invite',
      component: asyncComponent(() =>
        import('pages/confirmInvite/ConfirmInvite')
      )
    },
    {
      path: '/signup',
      component: asyncComponent(() => import('pages/signup/SignUp'))
    },
    {
      path: '/partners/signup',
      component: asyncComponent(() => import('pages/signup/PartnerSignUp'))
    },
    {
      path: '/terms-of-use',
      component: asyncComponent(() => import('pages/signup/TermsOfUse'))
    },
    {
      path: '/integrated-cities',
      component: asyncComponent(() => import('pages/public/IntegratedCities'))
    },
    {
      path: '/platforms',
      component: asyncComponent(() =>
        import('pages/public/IntegratedPlatforms')
      )
    },
    {
      path: '/pay/:paymentid',
      component: asyncComponent(() =>
        import('pages/public/SubscriptionPayment')
      )
    },
    {
      path: '/forgot-password',
      component: asyncComponent(() =>
        import('pages/forgotpassword/ForgotPassword')
      )
    },
    {
      path: '/public/product-invoices/:invoiceid/pdf',
      component: asyncComponent(() =>
        import('pages/app/orders/OrderInvoiceView')
      )
    },
    {
      path: '/public/service-invoices/:invoiceid/pdf',
      component: asyncComponent(() =>
        import('pages/app/orders/OrderInvoiceView')
      )
    },
    {
      path: '/reset-password',
      component: asyncComponent(() =>
        import('pages/forgotpassword/ResetPassword')
      )
    },
    {
      path: '/apps/authorize',
      component: asyncComponent(() =>
        import('pages/app/platforms/PlatformAuthorize')
      )
    },
    {
      path: `/apps/${platform}/authorize/callback`,
      component: asyncComponent(() =>
        import('pages/app/platforms/PlatformAuthorizeCallback')
      )
    },
    {
      path: '/accounts-chooser',
      title: 'Alterar',
      component: asyncComponent(() => import('pages/shared/TenantChooser')),
      menu: { icon: 'th-large' }
    },
    {
      path: '/subscribe',
      component: asyncComponent(() =>
        import('pages/public/SubscribeSpecificPlan')
      )
    },
    {
      path: '/inmaintenance',
      component: asyncComponent(() => import('pages/signin/InMaintenance'))
    },
    {
      path: '/redirect',
      component: asyncComponent(() => import('pages/shared/MainRedirect'))
    },
    {
      path: '/a',
      component: (props) => <AdminContainer {...props} />,
      roles: ['Admin'],
      routes: [
        {
          path: '/customers',
          title: 'Clientes',
          resource: 'accounts',
          fixedQueryParams: { type: 'customer' },
          singularTitle: 'Cliente',
          component: asyncComponent(() =>
            import('pages/admin/accounts/AccountList')
          ),
          menu: { icon: 'user' }
        },
        {
          path: '/partners',
          title: 'Parceiros',
          resource: 'accounts',
          fixedQueryParams: { type: 'partner' },
          singularTitle: 'Parceiro',
          component: asyncComponent(() =>
            import('pages/admin/accounts/AccountList')
          ),
          menu: { icon: 'handshake' }
        },
        {
          path: '/coupons',
          title: 'Cupons',
          singularTitle: 'Cupom',
          component: asyncComponent(() =>
            import('pages/admin/coupons/CouponList')
          ),
          menu: { icon: 'ticket-alt' }
        },
        {
          path: '/subscription-plans',
          title: 'Planos',
          singularTitle: 'Plano',
          component: asyncComponent(() =>
            import('pages/admin/subscriptionPlans/SubscriptionPlanList')
          ),
          menu: { icon: 'hand-holding-usd' }
        },
        {
          path: '/cities',
          title: 'Cidades',
          singularTitle: 'Cidade',
          component: asyncComponent(() =>
            import('pages/admin/cities/CityList')
          ),
          menu: { icon: 'city' }
        },
        {
          path: '/announcements',
          title: 'Comunicados',
          singularTitle: 'Comunicado',
          component: asyncComponent(() =>
            import('pages/admin/announcements/AnnouncementList')
          ),
          menu: { icon: 'bullhorn' }
        }
      ]
    },
    {
      path: '/p',
      component: (props) => <AppContainer {...props} />,
      roles: ['*'],
      features: ['profile_customer'],
      routes: [
        {
          path: '/',
          title: 'Dashboard',
          exact: true,
          component: asyncComponent(() => import('pages/app/home/Home')),
          menu: { icon: 'tachometer-alt' }
        },
        {
          path: '/onboarding',
          title: 'Onboarding',
          exact: true,
          component: asyncComponent(() =>
            import('pages/app/onboarding/Onboarding')
          )
        },
        {
          path: '/activate',
          title: 'Ativação',
          exact: true,
          component: asyncComponent(() =>
            import('pages/app/onboarding/OnboardingActivation')
          )
        },
        {
          path: '/orders',
          title: 'Vendas',
          exact: true,
          component: asyncComponent(() => import('pages/app/orders/OrderList')),
          menu: { icon: 'shopping-cart' }
        },
        {
          path: '/invoices',
          title: 'Notas Fiscais',
          features: [INVOICE_MANAGEMENT],
          exact: true,
          component: asyncComponent(() =>
            import('pages/app/invoices/InvoiceList')
          ),
          menu: { icon: 'file-invoice' }
        },
        {
          path: '/product-invoices/disablements',
          endpoint: '/product-invoices/disablements',
          title: 'Inutilizações de NF-e',
          resource: 'disablements',
          component: asyncComponent(() =>
            import('pages/app/invoices/InvoiceDisablementList')
          )
        },
        {
          path: '/customers',
          title: 'Clientes',
          component: asyncComponent(() =>
            import('pages/app/customers/CustomerList')
          ),
          menu: { icon: 'user-friends' }
        },
        {
          path: '/contributors',
          title: 'Colaboradores',
          resource: 'users',
          singularTitle: 'Colaborador',
          component: asyncComponent(() =>
            import('pages/app/contributors/CollaboratorList')
          )
        },
        {
          path: '/taxation-groups',
          title: 'Tributação',
          resource: 'taxation-groups',
          singularTitle: 'Tributação',
          component: asyncComponent(() =>
            import('pages/app/taxationGroups/TaxationGroupList')
          )
        },
        {
          path: '/products',
          title: 'Produtos',
          component: asyncComponent(() =>
            import('pages/app/products/ProductList')
          ),
          menu: { icon: 'box-open' }
        },
        {
          path: '/monthly-closing',
          title: 'Fechamento',
          component: asyncComponent(() =>
            import('pages/app/monthly-close/MonthlyClosing')
          ),
          menu: { icon: 'business-time' }
        },
        {
          path: '/platforms',
          title: 'Apps',
          component: asyncComponent(() =>
            import('pages/app/platforms/PlatformList')
          ),
          hideInactiveCompanies: true,
          menu: { icon: 'th-large' }
        },
        {
          path: '/referral-program',
          title: 'Indique e ganhe',
          component: asyncComponent(() =>
            import('pages/app/referralProgram/ReferralProgramHome')
          ),
          menu: { icon: 'bullhorn' }
        },
        {
          path: '/referral-program-politics',
          title: 'Políticas do Programa Indique e Ganhe',
          component: asyncComponent(() =>
            import('pages/app/referralProgram/ReferralProgramPolitics')
          )
        }
        // {
        //   path: '/notifications',
        //   title: 'Notificações',
        //   component: asyncComponent(() =>
        //     import('pages/app/notifications/NotificationList')
        //   ),
        //   menu: { ComponentIcon: NotificationBadge }
        // }
      ]
    },
    {
      path: '/r',
      component: (props) => <AppContainer {...props} />,
      roles: ['*'],
      features: ['profile_partner'],
      routes: [
        {
          path: '/referral-program',
          title: 'Indique e ganhe',
          component: asyncComponent(() =>
            import('pages/app/referralProgram/ReferralProgramHome')
          ),
          menu: { icon: 'bullhorn' }
        },
        {
          path: '/referral-program-politics',
          title: 'Políticas do Programa Indique e Ganhe',
          component: asyncComponent(() =>
            import('pages/app/referralProgram/ReferralProgramPolitics')
          )
        }
      ]
    },
    {
      path: '/',
      exact: true,
      redirectTo: '/redirect'
    }
  ]
};
