import React, { useState } from 'react';
import { Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import { getApiKey, openCompanyApiKey } from 'stores/companies/companyActions';
import CompanyApiKey from './CompanyApiKey';
import Loading from 'components/Loading';

const CompanyApiKeyLeftToolbar = ({ id }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleGetApiKeyOnSuccess = ({ data }) => {
    const { key } = data.result;
    dispatch(openCompanyApiKey(id, key));
  };

  const handleGetApiKey = () => {
    dispatch(
      getApiKey(id, handleGetApiKeyOnSuccess, () => {
        setLoading(false);
      })
    );
  };

  return (
    <>
      <CompanyApiKey />
      <Button
        icon={
          loading ? (
            <Loading className="gx-m-0 gx-p-0 gx-mr-2 gx-mb-1" fontSize={16} />
          ) : (
            <FontAwesomeIcon icon="plug" className="gx-mr-2" />
          )
        }
        onClick={handleGetApiKey}
      >
        Credenciais da API
      </Button>
    </>
  );
};

export default CompanyApiKeyLeftToolbar;
