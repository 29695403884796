import React from 'react';
import { Form, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

const FilterInput = (props) => {
  const { placeholder, width, name = 'filterText' } = props;
  return (
    <Form.Item name={name}>
      <Input
        placeholder={placeholder}
        title={placeholder}
        style={{ width: width ?? '100%' }}
        prefix={<SearchOutlined />}
      />
    </Form.Item>
  );
};

export default FilterInput;
