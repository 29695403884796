const truncate = (text, limit, ellipsis = '...') => {
  if (text.length > limit) {
    limit -= 1;

    let last = text.substr(limit - 1, 1);

    while (last !== ' ' && limit > 0) {
      limit -= 1;
      last = text.substr(limit - 1, 1);
    }

    last = text.substr(limit - 2, 1);

    if (last === ',' || last === ';' || last === ':') {
      text = text.substr(0, limit - 2) + ellipsis;
    } else if (last === '.' || last === '?' || last === '!') {
      text = text.substr(0, limit - 1);
    } else {
      text = text.substr(0, limit - 1) + ellipsis;
    }
  }

  return text;
};

export default truncate;
