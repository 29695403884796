import { useCookies } from 'react-cookie';
import moment from 'moment';
import { useState } from 'react';

let COOKIE_DOMAIN = 'spedy.com.br';

if (process.env.NODE_ENV !== 'production') {
  COOKIE_DOMAIN = undefined;
}

const COOKIE_PATH = '/';

const useCookieHandler = (names = []) => {
  const [cookies, setCookie, removeCookie] = useCookies([names]);
  const [loaded, setLoaded] = useState(false);

  const getExpires = (days, minutes) => {
    let expires = moment();

    if (days) {
      expires = expires.add(days, 'days');
    }

    if (minutes) {
      expires = expires.add(minutes, 'minutes');
    }

    return expires.toDate();
  };

  const setCookieHandler = (name, value, days, minutes) => {
    if (!value) {
      return;
    }

    setLoaded(true);
    setCookie(name, value, {
      path: COOKIE_PATH,
      domain: COOKIE_DOMAIN,
      expires: getExpires(days, minutes)
    });
  };

  const removeCookieHandler = (name) => {
    if (!name) {
      return;
    }

    removeCookie(name, { domain: COOKIE_DOMAIN, path: COOKIE_PATH });
  };

  return {
    loaded,
    setCookie: setCookieHandler,
    removeCookie: removeCookieHandler,
    cookies
  };
};

export default useCookieHandler;
