import { formartOnlyNumber } from 'react-structure-admin';
const postalCodeValidator = (_, value = '') => {
  const formattedValue = formartOnlyNumber(value);
  if (formattedValue.length < 8 && formattedValue.length > 0) {
    return Promise.reject(new Error('Preencha o campo CEP'));
  }
  return Promise.resolve();
};

export default postalCodeValidator;
