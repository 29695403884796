import React from 'react';
import { useHistory } from 'react-router-dom';
import { Menu, Dropdown } from 'antd';
import { useAuthContext, RECORD_FIELD } from 'react-structure-admin';
import UserProfileImage from 'components/UserProfileImage';
import { useDispatch, useSelector } from 'react-redux';
import { updatePassword, currentUserGet } from 'stores/users/userActions';

import UpdatePassword from 'pages/shared/UpdatePassword';
import CurrentUserEdit from 'pages/shared/CurrentUserEdit';

const CurrentAdminProfile = () => {
  const { user } = useAuthContext();
  const dispatch = useDispatch();
  const history = useHistory();
  const { currentUser, [RECORD_FIELD]: resourceUsers } = useSelector(
    (state) => state.resources.users || {}
  );

  const signoutClickHandle = () => {
    history.push('/signout');
  };

  const editProfileClickHandle = () => {
    dispatch(currentUserGet(user.id));
  };

  const changePasswordClickHandle = () => {
    dispatch(updatePassword({ userId: user.id }));
  };

  const userMenuOptions = (
    <Menu>
      <Menu.Item onClick={editProfileClickHandle}>Meus dados</Menu.Item>
      <Menu.Item onClick={changePasswordClickHandle}>Alterar senha</Menu.Item>
      <Menu.Item onClick={signoutClickHandle}>Sair</Menu.Item>
    </Menu>
  );

  const userName = user ? user.displayName || user.name : null;

  if (!user) {
    return null;
  }

  return (
    <>
      <Dropdown overlay={userMenuOptions} trigger={['click']}>
        <div className="gx-d-flex gx-align-items-center gx-avatar-row">
          <UserProfileImage
            className="gx-size-40 gx-pointer gx-mr-2"
            id={user.id}
            base64={user.profileImage ? user.profileImage.base64 : undefined}
          />

          <div className="gx-avatar-name">
            {userName ? (
              <span>{userName}</span>
            ) : (
              <span style={{ color: '#ccc' }}>Carregando...</span>
            )}
            <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
          </div>
        </div>
      </Dropdown>
      {currentUser ? <CurrentUserEdit /> : null}
      {resourceUsers && resourceUsers.editingPassword && (
        <UpdatePassword updatePassword={resourceUsers.editingPassword} />
      )}
    </>
  );
};

export default CurrentAdminProfile;
