import { Tag } from 'antd';
import React from 'react';

import {
  couponDisplay,
  COUPON_DRAFT,
  COUPON_EXPIRED,
  COUPON_PUBLISHED,
  COUPON_USED
} from 'constants/types';

export const colors = {
  [COUPON_PUBLISHED]: 'green',
  [COUPON_DRAFT]: null,
  [COUPON_EXPIRED]: 'red',
  [COUPON_USED]: 'yellow'
};

const CouponStatus = ({ status }) => {
  if (!status) {
    return null;
  }

  return <Tag color={colors[status]}>{couponDisplay[status]}</Tag>;
};

export default CouponStatus;
