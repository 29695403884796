import { PlusOutlined } from '@ant-design/icons';
import { message, Select, Tag, Tooltip } from 'antd';
import React, { useEffect, useRef, useState } from 'react';

const { Option } = Select;

const TagList = ({
  tags = [],
  availableTags,
  onRemoveTag,
  onAddTag,
  isReadOnly = false
}) => {
  const [internalTags, setInternalTags] = useState(tags);
  const [selectAvailableTags, setSelectAvailableTags] = useState(availableTags);
  const [inputVisible, setInputVisible] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);
  const selectRef = useRef(null);

  useEffect(() => {
    if (inputVisible && selectRef.current) {
      selectRef.current.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    setInternalTags(tags);
  });

  const onSuccessRemoveTag = (tag) => {
    const newTags = internalTags.filter((c) => c.id !== tag.id);
    setInternalTags(newTags);

    setSelectAvailableTags((prevTags) => {
      if (!prevTags.find((c) => c.id === tag.id)) {
        return [...prevTags, tag];
      }
      return prevTags;
    });
  };

  const handleRemove = (removedTag) => {
    if (onRemoveTag) {
      if (!onRemoveTag(removedTag)) {
        return;
      }
    }

    onSuccessRemoveTag(removedTag);
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const onSuccessAddTag = (tag) => {
    setInternalTags([...internalTags, tag]);
    setSelectAvailableTags((prevTags) =>
      prevTags.filter((c) => c.id !== tag.id)
    );
  };

  const handleSelectChange = (value) => {
    const tag = availableTags.find((c) => c.id === value);

    if (onAddTag) {
      if (!onAddTag(tag)) {
        return;
      }
    }

    handleSelectConfirm();
    onSuccessAddTag(tag);
  };

  const handleSelectConfirm = () => {
    setInputVisible(false);
    setSelectedTag(null);
  };

  return (
    <>
      {internalTags.map((tag, index) => {
        const isLongTag = tag.name.length > 20;
        const tagElem = (
          <Tag
            color={tag.color}
            key={tag.id}
            closable={index >= 0 && !isReadOnly}
            onClose={() => handleRemove(tag)}
          >
            <span>{isLongTag ? `${tag.name.slice(0, 20)}...` : tag.name}</span>
          </Tag>
        );
        return isLongTag ? (
          <Tooltip title={tag.name} key={tag.id}>
            {tagElem}
          </Tooltip>
        ) : (
          tagElem
        );
      })}

      {inputVisible && (
        <Select
          className="gx-select-tag-list"
          ref={selectRef}
          value={selectedTag}
          onChange={handleSelectChange}
          onBlur={handleSelectConfirm}
          style={{ width: 160 }}
          autoFocus
          size="small"
        >
          {selectAvailableTags
            .filter(
              (tag) =>
                !internalTags.some((internalTag) => internalTag.id === tag.id)
            )
            .map((tag) => (
              <Option key={tag.id} value={tag.id}>
                {tag.name}
              </Option>
            ))}
        </Select>
      )}
      {!inputVisible && !isReadOnly && (
        <Tag onClick={showInput} style={{ cursor: 'pointer' }}>
          <PlusOutlined />
        </Tag>
      )}
    </>
  );
};

export default TagList;
