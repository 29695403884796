/* eslint-disable no-plusplus */
/* eslint-disable no-template-curly-in-string */
import { formartOnlyNumber } from 'util/formatters';

const federalTaxNumberValidator = (_, value) => {
  const federalTaxNumber = formartOnlyNumber(value);

  if (!federalTaxNumber) {
    return Promise.resolve();
  }

  if (federalTaxNumber.length === 11) {
    if (federalTaxNumber.split('').every((c) => c === federalTaxNumber[0])) {
      return Promise.reject(new Error('CPF é inválido'));
    }

    let sum = 0;
    let rest;

    for (let i = 1; i <= 9; i++)
      sum += parseInt(federalTaxNumber.substring(i - 1, i), 10) * (11 - i);
    rest = (sum * 10) % 11;

    if (rest === 10 || rest === 11) rest = 0;
    if (rest !== parseInt(federalTaxNumber.substring(9, 10), 10))
      return Promise.reject(new Error('CPF é inválido'));

    sum = 0;
    for (let i = 1; i <= 10; i++)
      sum += parseInt(federalTaxNumber.substring(i - 1, i), 10) * (12 - i);
    rest = (sum * 10) % 11;

    if (rest === 10 || rest === 11) rest = 0;
    if (rest !== parseInt(federalTaxNumber.substring(10, 11), 10))
      return Promise.reject(new Error('CPF é inválido'));

    return Promise.resolve();
  }

  if (federalTaxNumber.length === 14) {
    if (federalTaxNumber.split('').every((c) => c === federalTaxNumber[0])) {
      return Promise.reject(new Error('CNPJ é inválido'));
    }

    const validTypes =
      typeof value === 'string' ||
      Number.isInteger(value) ||
      Array.isArray(value);

    if (!validTypes) return Promise.reject(new Error('CNPJ é inválido'));

    const numbers = federalTaxNumber.split('').map(Number);

    const items = [...new Set(numbers)];
    if (items.length === 1) return Promise.reject(new Error('CNPJ é inválido'));

    const calc = (x) => {
      const slice = numbers.slice(0, x);
      let factor = x - 7;
      let sum = 0;

      for (let i = x; i >= 1; i--) {
        const n = slice[x - i];
        sum += n * factor--;
        if (factor < 2) factor = 9;
      }

      const result = 11 - (sum % 11);

      return result > 9 ? 0 : result;
    };

    const digits = numbers.slice(12);

    const digit0 = calc(12);
    if (digit0 !== digits[0]) {
      return Promise.reject(new Error('CNPJ é inválido'));
    }

    const digit1 = calc(13);
    return digit1 === digits[1]
      ? Promise.resolve()
      : Promise.reject(new Error('CNPJ é inválido'));
  }

  return Promise.reject(new Error('Preencha corretamente o campo'));
};

export default federalTaxNumberValidator;
