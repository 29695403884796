import { dispatcher, api, http, RESOURCE_FETCH } from 'react-structure-admin';

export const ACCOUNT = 'ACCOUNT';

export const ACCOUNT_ACCOUNT_TAG_LINK = 'ACCOUNT_ACCOUNT_TAG_LINK';
export const ACCOUNT_ACCOUNT_TAG_LINK_FINISHED =
  'ACCOUNT_ACCOUNT_TAG_LINK_FINISHED';

export const ACCOUNT_ACCOUNT_TAG_LINK_REMOVE =
  'ACCOUNT_ACCOUNT_TAG_LINK_REMOVE';

export const ACCOUNT_ACCOUNT_TAG_LINK_GET = 'ACCOUNT_ACCOUNT_TAG_LINK_GET';

export const ACCOUNT_REFRESH = 'ACCOUNT_REFRESH';

export const ACCOUNT_CURRENT_GET = 'ACCOUNT_CURRENT_GET';
export const ACCOUNT_CURRENT_GET_FINISHED = 'ACCOUNT_CURRENT_GET_FINISHED';

export const ACCOUNT_CURRENT_EDIT = 'ACCOUNT_CURRENT_EDIT';
export const ACCOUNT_CURRENT_EDIT_FINISHED = 'ACCOUNT_CURRENT_EDIT_FINISHED';
export const ACCOUNT_CURRENT_CLOSE = 'ACCOUNT_CURRENT_CLOSE';

export const ACCOUNT_OPEN_REPLACE_COMPANY = 'ACCOUNT_OPEN_REPLACE_COMPANY';
export const ACCOUNT_CLOSE_REPLACE_COMPANY = 'ACCOUNT_CLOSE_REPLACE_COMPANY';

export const ACCOUNT_COMPANY_REPLACE_COMPANY =
  'ACCOUNT_COMPANY_REPLACE_COMPANY';
export const ACCOUNT_COMPANY_REPLACE_COMPANY_FINISHED =
  'ACCOUNT_COMPANY_REPLACE_COMPANY_FINISHED';

export const ACCOUNT_ACTIVATE = 'ACCOUNT_ACTIVATE';
export const ACCOUNT_ACTIVATE_FINISHED = 'ACCOUNT_ACTIVATE_FINISHED';

export const fetch = (resource, params) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'accounts',
    actionType: RESOURCE_FETCH,
    effect: async () => api.fetch('accounts/accounts-with-details', params)
  });
};

export const accountTagLink = (id, data, onSuccess, onFail) => async (
  dispatch
) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'accounts',
    actionType: ACCOUNT_ACCOUNT_TAG_LINK,
    effect: async () => api.post(`accounts/${id}/tags`, data),
    onSuccess,
    onFail
  });
};

export const removeAccountTagLink = (tenantId, tagId, onSuccess) => async (
  dispatch
) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'accounts',
    actionType: ACCOUNT_ACCOUNT_TAG_LINK_REMOVE,
    effect: async () =>
      http.del(
        `${api.getUrlFromResource('accounts')}/${tenantId}/tags/${tagId}`
      ),
    onSuccess
  });
};

export const getAccountTagLink = (onSuccess) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'accounts',
    actionType: ACCOUNT_ACCOUNT_TAG_LINK_GET,
    effect: async () => api.fetch('accounts/tags'),
    onSuccess
  });
};

export const getTenants = (_, params) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'accounts',
    actionType: RESOURCE_FETCH,
    effect: async () => api.fetch('auth/tenants', params)
  });
};

export const refresh = () => async (dispatch) => {
  return dispatch(dispatcher.createAction(ACCOUNT_REFRESH, 'accounts'));
};

export const currentAccountGet = (id) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'accounts',
    actionType: ACCOUNT_CURRENT_GET,
    effect: async () => api.get('accounts', id)
  });
};

export const currentAccountPut = (data, onSuccess) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'accounts',
    actionType: ACCOUNT_CURRENT_EDIT,
    payload: data,
    effect: async () => api.put(`accounts/${data.id}`, data),
    onSuccess
  });
};

export const currentAccountClose = () => async (dispatch) => {
  return dispatch(dispatcher.createAction(ACCOUNT_CURRENT_CLOSE, 'accounts'));
};

export const openReplaceCompany = (replacedCompany) => async (dispatch) => {
  return dispatch(
    dispatcher.createAction(
      ACCOUNT_OPEN_REPLACE_COMPANY,
      'accounts',
      replacedCompany
    )
  );
};

export const closeReplaceCompany = () => async (dispatch) => {
  return dispatch(
    dispatcher.createAction(ACCOUNT_CLOSE_REPLACE_COMPANY, 'accounts')
  );
};

export const putReplaceCompany = (id, data, onSuccess) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'accounts',
    actionType: ACCOUNT_COMPANY_REPLACE_COMPANY,
    payload: data,
    effect: async () => api.put(`companies/${id}/replace`, data),
    onSuccess
  });
};

export const activate = (id, onSuccess) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'accounts',
    actionType: ACCOUNT_ACTIVATE,
    effect: async () => api.put(`accounts/${id}/activate`),
    onSuccess
  });
};
