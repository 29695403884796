import React from 'react';
import { Button, Modal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTenant } from 'core';
import { WAITINGFORACTIVATION } from 'constants/types';
import { useAuthContext } from 'react-structure-admin';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { changeTenant } from 'stores/auth/authActions';

const AccountWaitingForActivationModal = () => {
  const { isPartner, tenant } = useTenant();
  const { logout, isAdmin } = useAuthContext();
  const history = useHistory();
  const dispatch = useDispatch();

  if (!isPartner || (isPartner && tenant?.status !== WAITINGFORACTIVATION)) {
    return null;
  }

  const handleLogout = () => {
    logout();
    window.location.href = 'https://spedy.com.br';
  };

  const handleBackToRootTenant = () => {
    dispatch(
      changeTenant({}, () => {
        history.push('/');
      })
    );
  };

  return (
    <Modal
      className="gx-accountwaitingforactivationmodal-modal"
      visible
      width={900}
      footer={null}
      closable={false}
    >
      <div className="gx-accountwaitingforactivationmodal-container gx-text-center gx-d-flex gx-h-100 gx-align-items-center gx-justify-content-center">
        <div>
          <FontAwesomeIcon
            className="gx-accountwaitingforactivationmodal-success-icon"
            icon={['far', 'check-circle']}
          />
          <div className="gx-accountwaitingforactivationmodal-success-title">
            Cadastro realizado com sucesso
          </div>
          <div className="gx-accountwaitingforactivationmodal-success-description gx-mb-4">
            Agradecemos pelo seu interesse em se tornar parceiro Spedy. Iremos
            validar o seu cadastro e em breve você receberá um e-mail de
            ativação da sua conta de parceiro. Nos vemos em breve!
          </div>
        </div>
        <div>
          {isAdmin() ? (
            <Button type="default" onClick={handleBackToRootTenant}>
              Administração
            </Button>
          ) : null}
          <Button
            className="gx-accountwaitingforactivationmodal-button"
            type="primary"
            onClick={handleLogout}
          >
            Fechar
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AccountWaitingForActivationModal;
