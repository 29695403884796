import React from 'react';
import { Tag } from 'antd';
import { display } from 'constants/types';

const colors = {
  created: 'blue ',
  active: 'green ',
  canceled: 'grey',
  suspended: 'red',
  expired: 'orange ',
  cancellationRequested: 'grey'
};

const SubscriptionStatus = ({ status }) => {
  return <Tag color={colors[status]}>{display[status]}</Tag>;
};

export default SubscriptionStatus;
