import React from 'react';
import { Select } from 'antd';

const SelectSubscriptionCancellationReason = ({ ...rest }) => {
  return (
    <Select {...rest}>
      <Select.Option value="operationDiscontinuation">
        Descontinuidade da operação
      </Select.Option>
      <Select.Option value="occasionalUse">
        Uso esporádico
      </Select.Option>
      <Select.Option value="costReduction">Redução de custos</Select.Option>
      <Select.Option value="lackOfFeatures">Ausência de recursos no sistema</Select.Option>
      <Select.Option value="default">Inadimplência</Select.Option>
      <Select.Option value="dissatisfactionWithService">
        Insatisfação com atendimento
      </Select.Option>
      <Select.Option value="technicalProblems">
        Problemas técnicos
      </Select.Option>
      <Select.Option value="others">Outros</Select.Option>
    </Select>
  );
};

export default SelectSubscriptionCancellationReason;
