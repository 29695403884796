import React from 'react';
import { Form } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import InputNumeric from './InputNumeric';

const FilterInputNumber = (props) => {
  const { placeholder, name = 'filterText' } = props;
  return (
    <Form.Item name={name}>
      <InputNumeric
        placeholder={placeholder}
        title={placeholder}
        className="gx-w-100 gx-inputnumber-controls-hide"
        prefix={<SearchOutlined />}
      />
    </Form.Item>
  );
};

export default FilterInputNumber;
