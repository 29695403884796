import {
  updateResourceState,
  createResourceReducer,
  RESOURCE,
  RECORD_FIELD,
  RESOURCE_ACTION_EDIT
} from 'react-structure-admin';
import {
  REPORTS,
  REPORTS_GET,
  REPORTS_GET_FINISHED,
  REPORTS_GET_EVOLUTION,
  REPORTS_GET_EVOLUTION_FINISHED,
  REPORTS_GET_PRODUCT_RANKING,
  REPORTS_GET_PRODUCT_RANKING_FINISHED,
  REPORTS_GET_PLATFORM_RANKING,
  REPORTS_GET_PLATFORM_RANKING_FINISHED
} from './dashboardAction';

const dashboardReducer = createResourceReducer(REPORTS, [], {
  [REPORTS_GET](state, action) {
    return updateResourceState(state, action, RESOURCE, () => ({
      loading: true,
      report: null
    }));
  },

  [REPORTS_GET_FINISHED](state, action) {
    return updateResourceState(state, action, RESOURCE, () => ({
      report: action.payload.data.result,
      loading: false
    }));
  },

  [REPORTS_GET_EVOLUTION](state, action) {
    return updateResourceState(state, action, RESOURCE, () => ({
      monthlyEvolution: null
    }));
  },

  [REPORTS_GET_EVOLUTION_FINISHED](state, action) {
    return updateResourceState(state, action, RESOURCE, () => ({
      monthlyEvolution: action.payload.data.result
    }));
  },

  [REPORTS_GET_PRODUCT_RANKING](state, action) {
    return updateResourceState(state, action, RESOURCE, () => ({
      products: null,
      loading: true
    }));
  },

  [REPORTS_GET_PRODUCT_RANKING_FINISHED](state, action) {
    return updateResourceState(state, action, RESOURCE, () => ({
      products: action.payload.data.result,
      loading: false
    }));
  },

  [REPORTS_GET_PLATFORM_RANKING](state, action) {
    return updateResourceState(state, action, RESOURCE, () => ({
      platforms: null,
      loading: false
    }));
  },

  [REPORTS_GET_PLATFORM_RANKING_FINISHED](state, action) {
    return updateResourceState(state, action, RESOURCE, () => ({
      platforms: action.payload.data.result,
      loading: false
    }));
  }
});

export default dashboardReducer;
