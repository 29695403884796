import {
  updateResourceState,
  createResourceReducer,
  RESOURCE
} from 'react-structure-admin';
import {
  ANNOUNCEMENT,
  ANNOUNCEMENT_CLOSE_ACTIVE,
  ANNOUNCEMENT_GET_ACTIVE_FINISHED
} from './announcementActions';

const announcementReducer = createResourceReducer(ANNOUNCEMENT, [], {
  [ANNOUNCEMENT_GET_ACTIVE_FINISHED](state, action) {
    return updateResourceState(state, action, RESOURCE, (state) => ({
      ...state,
      activeItems: action.payload.data.result
    }));
  },

  [ANNOUNCEMENT_CLOSE_ACTIVE](state, action) {
    return updateResourceState(
      state,
      action,
      RESOURCE,
      ({ closedAnnouncements = [] }) => ({
        ...state,
        closedAnnouncements: [
          ...closedAnnouncements,
          action.payload.announcementId
        ]
      })
    );
  }
});

export default announcementReducer;
