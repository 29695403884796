import { dispatcher, api, RESOURCE_FETCH } from 'react-structure-admin';

export const CITY = 'CITY';

export const CITY_SET_AVAILABLE = 'CITY_SET_AVAILABLE';
export const CITY_SET_AVAILABLE_FINISHED = 'CITY_SET_AVAILABLE_FINISHED';

export const CITY_OPEN_PROVIDER_SETTINGS = 'CITY_OPEN_PROVIDER_SETTINGS';
export const CITY_CLOSE_PROVIDER_SETTINGS = 'CITY_CLOSE_PROVIDER_SETTINGS';
export const CITY_GET_PROVIDER_SETTINGS = 'CITY_GET_PROVIDER_SETTINGS';
export const CITY_GET_PROVIDER_SETTINGS_FINISHED =
  'CITY_GET_PROVIDER_SETTINGS_FINISHED';

export const CITY_PUT_PROVIDER_SETTINGS = 'CITY_PUT_PROVIDER_SETTINGS';
export const CITY_PUT_PROVIDER_SETTINGS_FINISHED =
  'CITY_PUT_PROVIDER_SETTINGS_FINISHED';

export const getIntegratedCities = (resource, params) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource,
    actionType: RESOURCE_FETCH,
    effect: async () => {
      return api.fetch(resource, params);
    }
  });
};

export const setAvailable = (id) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'cities',
    actionType: CITY_SET_AVAILABLE,
    effect: async () => {
      return api.put(`cities/${id}/available`, { isAvailable: true });
    }
  });
};

export const setUnavailable = (id) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'cities',
    actionType: CITY_SET_AVAILABLE,
    effect: async () => {
      return api.put(`cities/${id}/available`, { isAvailable: false });
    }
  });
};

export const openProviderSettings = (id, provider) => (dispatch) => {
  dispatch(
    dispatcher.createAction(CITY_OPEN_PROVIDER_SETTINGS, 'cities', {
      id,
      provider
    })
  );
};

export const closeProviderSettings = () => (dispatch) => {
  dispatch(dispatcher.createAction(CITY_CLOSE_PROVIDER_SETTINGS, 'cities'));
};

export const getProviderSettings = (provider) => async (dispatch) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'cities',
    actionType: CITY_GET_PROVIDER_SETTINGS,
    effect: async () => api.get(`semfaz-endpoint-providers`, provider)
  });
};

export const putProviderSettings = (payload = {}, onSuccess) => async (
  dispatch
) => {
  await dispatcher.dispatchResouceAction({
    dispatch,
    resource: 'cities',
    actionType: CITY_PUT_PROVIDER_SETTINGS,
    effect: async () => api.put(`semfaz-endpoint-providers`, { ...payload }),
    onSuccess
  });
};
