import React, { useState } from 'react';
import { Alert, Button, message } from 'antd';
import { api, useAuthContext } from 'react-structure-admin';

const AlertConfirmationEmail = () => {
  const { user, subscription } = useAuthContext();
  const [loading, setLoading] = useState(false);

  if (
    user.emailConfirmed ||
    (subscription &&
      (subscription.status === 'canceled' ||
        subscription.status === 'cancellationRequested'))
  ) {
    return null;
  }

  const handleResendEmailSuccess = () => {
    message.success(
      <div>
        <div className="gx-font-weight-semi-bold gx-mb-2">
          E-mail reenviado.
        </div>
        <div>
          <span>Verifique seu e-mail </span>
          <span className="gx-font-weight-semi-bold">{user.email}</span>
        </div>
      </div>
    );
  };

  const resendClickHandle = () => {
    setLoading(true);
    api
      .post(`auth/resending-confirmation-email/?userId=${user.id}`)
      .then((response) => {
        setLoading(false);
        if (!response.errors) {
          handleResendEmailSuccess();
        }
      });
  };

  return (
    <Alert
      className="gx-mb-2"
      message={
        <div className="gx-center">
          <div className="gx-font-weight-semi-bold">
            Confirmação de cadastro
          </div>
          <div>
            Confirme seu cadastro clicando no link que enviamos para o seu
            e-mail. <br /> Caso não tenha recebido, verifique na sua caixa de
            spam ou clique em Reenviar e-mail.
          </div>
        </div>
      }
      action={
        <Button onClick={resendClickHandle} type="primary" loading={loading}>
          Reenviar e-mail
        </Button>
      }
      type="warning"
    />
  );
};

export default AlertConfirmationEmail;
