import React, { useState } from 'react';
import { Button, Form, message } from 'antd';
import { DrawerWrapper, Footer } from 'components';
import CreditCardInfo from 'components/CreditCardInfo';
import { useDispatch } from 'react-redux';
import { FormItemWrap, configManager } from 'react-structure-admin';
import { formartOnlyNumber } from 'util/formatters';
import {
  changeSubscriptionPaymentMethod,
  closeSubscriptionPaymentMethod
} from 'stores/subscriptions/subscriptionActions';
import Iugu from 'util/iugu';

const SubscriptionChangePaymentMethod = ({ subscription, loading }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [validatingCard, setValidatingCard] = useState(false);

  const validateCreditCard = ({
    number,
    expirationDate,
    cardholderName,
    securityCode,
    installments = null
  }) => {
    const month = expirationDate.substr(0, 2);
    const year = expirationDate.substr(3, 6);
    const [firstName, ...lastNames] = cardholderName.split(' ');
    const lastName = lastNames.join(' ');

    const cc = Iugu.CreditCard(
      formartOnlyNumber(number),
      month,
      year,
      firstName,
      lastName,
      securityCode
    );

    if (cc.valid()) {
      setValidatingCard(true);
      Iugu.createPaymentToken(cc, (response) => {
        setValidatingCard(false);

        handleOnSubmit({
          creditCard: {
            number: formartOnlyNumber(number),
            securityCode: securityCode,
            month: month,
            year: year,
            holder: {
              name: cardholderName
            }
          },
          installments
        });
      });
    }
  };

  const handleClose = () => {
    dispatch(closeSubscriptionPaymentMethod());
  };

  const handleSuccess = () => {
    message.success('Cartão de crédito alterado com sucesso.');
    handleClose();
  };

  const handleOnSubmit = (data) => {
    dispatch(
      changeSubscriptionPaymentMethod(subscription.id, data, handleSuccess)
    );
  };

  const handleFinish = () => {
    form.validateFields().then((values) => {
      validateCreditCard(values);
    });
  };

  return (
    <DrawerWrapper
      title="Alterar cartão de crédito"
      visible
      width={500}
      onClose={handleClose}
    >
      <Form
        {...configManager.getConfig().layout.form}
        name="payments"
        className="gx-h-100"
        form={form}
        validateTrigger={false}
      >
        <FormItemWrap>
          <CreditCardInfo />
        </FormItemWrap>
        <Footer>
          <Button onClick={handleClose}>Voltar</Button>
          <Button type="primary" onClick={handleFinish} loading={loading}>
            Alterar
          </Button>
        </Footer>
      </Form>
    </DrawerWrapper>
  );
};

export default SubscriptionChangePaymentMethod;
