import { useAuthContext } from 'react-structure-admin';

const userTenant = () => {
  const { tenant } = useAuthContext();

  const isPartner = tenant?.types?.includes('partner') ?? false;

  return { tenant, isPartner };
};

export default userTenant;
