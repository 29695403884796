/* eslint-disable react/jsx-indent */
import React, { useState } from 'react';
import { Select, Form, Popover } from 'antd';

const FilterSelect = ({
  children,
  className,
  data,
  initialValue,
  isLast,
  name,
  multiple,
  placeholder,
  showSearch,
  label,
  ...rest
}) => {
  const [visible, setVisible] = useState({ popover: false, select: false });

  const handleVisiblePopover = (value) => {
    setVisible({ ...visible, popover: visible.select ? false : value });
  };

  const handleVisibleSelect = (value) => {
    setVisible({ select: value, popover: false });
  };

  const getPlaceholder = (values) => {
    if (values.length <= 0) {
      return '';
    }

    if (values.length === 1) {
      return values[0].label;
    }

    return (
      <Popover
        title={placeholder}
        visible={visible.popover}
        onVisibleChange={handleVisiblePopover}
        placement="bottomLeft"
        content={
          <div>
            {values.map((c) => (
              <div key={c.key} className="gx-mb-1">
                {c.label}
              </div>
            ))}
          </div>
        }
      >
        {`${values.length} selecionados`}
      </Popover>
    );
  };

  const onSearch = (input, option) => {
    return (option?.label ?? '').toLowerCase().startsWith(input.toLowerCase());
  };

  return (
    <Form.Item
      name={name}
      noStyle={!label}
      initialValue={initialValue}
      label={label}
    >
      <Select
        className={isLast ? '' : 'gx-mr-1  height-fixed'}
        showArrow
        showSearch={showSearch ?? false}
        placeholder={placeholder}
        style={{ width: '100%' }}
        maxTagPlaceholder={getPlaceholder}
        dropdownMatchSelectWidth={false}
        onDropdownVisibleChange={handleVisibleSelect}
        filterOption={onSearch}
        maxTagCount={0}
        {...rest}
      >
        {data
          ? data.map((c) => (
              <Select.Option key={c.key} value={c.key} label={c.label}>
                {c.label}
              </Select.Option>
            ))
          : children}
      </Select>
    </Form.Item>
  );
};

export default FilterSelect;
