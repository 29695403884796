import React, { useEffect } from 'react';
import { api } from 'react-structure-admin';
import { useSelector, useDispatch } from 'react-redux';
import { getPayments } from 'stores/subscriptions/subscriptionActions';
import formatCurrency from 'util/formatters/formatCurrency';
import formartDateTimeUTC from 'util/formatters/formartDateTimeUTC';
import { SubscriptionPaymentTag } from 'components';
import { Button, Row, Tooltip } from 'antd';
import ResCol from 'components/forms/ResCol';
import { DownloadOutlined } from '@ant-design/icons';

const PaymentHistory = ({ data }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPayments(data.id));
  }, []);

  const payments = useSelector(
    (state) => state.resources.subscriptions.payments
  );

  const handleClickInvoicePayment = (invoiceId) => {
    const url = api.getUrlFromResource('service-invoices', 'a');
    window.open(`${url}/${invoiceId}/pdf`, '_blank');
  };

  return (
    <>
      {payments && (
        <>
          {payments.map((payment) => (
            <div className="gx-payment-history">
              <Row justify="space-around" align="middle">
                <ResCol span={4} md={24}>
                  <span>{formartDateTimeUTC(payment.date, 'DD/MM/YYYY')}</span>
                </ResCol>
                <ResCol span={8} md={24}>
                  <h3>
                    <span>{formatCurrency(payment.amount)}</span>
                  </h3>
                </ResCol>
                <ResCol span={3} md={24}>
                  <>
                    {payment.invoiceId !== null &&
                    payment.status === 'approved' ? (
                      <Tooltip title="Baixar nota fiscal">
                        <Button
                          size="small"
                          type="ghost"
                          className="gx-mr-2"
                          onClick={() =>
                            handleClickInvoicePayment(payment.invoiceId)
                          }
                          icon={<DownloadOutlined className="gx-fs-lg" />}
                        >
                          <span>Nota fiscal</span>
                        </Button>
                      </Tooltip>
                    ) : null}
                  </>
                </ResCol>
                <ResCol span={5} md={24}>
                  <span>
                    <SubscriptionPaymentTag status={payment.status} />
                  </span>
                </ResCol>
              </Row>
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default PaymentHistory;
