import React from 'react';
import { Select } from 'antd';

const SelectLinkOperation = ({ ...rest }) => {
  return (
    <Select {...rest}>
      <Select.Option value="unknown">
        Desconhecido (tipo não informado na nota de origem)
      </Select.Option>
      <Select.Option value="no">Não</Select.Option>
      <Select.Option value="importDeclaration">
        Vinculada - Declaração de Importação
      </Select.Option>
      <Select.Option value="exportDeclaration">
        Vinculada - Declaração de Exportação
      </Select.Option>
    </Select>
  );
};

export default SelectLinkOperation;
