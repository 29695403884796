import React from 'react';
import { Select } from 'antd';

const ProductUnitOfMeasurement = (props) => {
  return (
    <Select {...props}>
      <Select.Option value="%">% - CENTO</Select.Option>
      <Select.Option value="AP">AP - AMPOLA</Select.Option>
      <Select.Option value="BAG">BAG - BAG</Select.Option>
      <Select.Option value="BB">BB - BOBINA</Select.Option>
      <Select.Option value="BD">BD - BALDE</Select.Option>
      <Select.Option value="BIS">BIS - BISNAGA</Select.Option>
      <Select.Option value="BJ">BJ - BANDEJA</Select.Option>
      <Select.Option value="BLC">BLC - BLOCO</Select.Option>
      <Select.Option value="BOMB">BOMB - BOMBONA</Select.Option>
      <Select.Option value="BR">BR - BARRA</Select.Option>
      <Select.Option value="CAPS">CAPS - CAPSULA</Select.Option>
      <Select.Option value="CB">CB - CABEÇA</Select.Option>
      <Select.Option value="CJ">CJ - CONJUNTO</Select.Option>
      <Select.Option value="CM">CM - CENTIMETRO</Select.Option>
      <Select.Option value="CM2">CM2 - CENTIMETRO QUADRADO</Select.Option>
      <Select.Option value="CT">CT - CARTELA</Select.Option>
      <Select.Option value="CX">CX - CAIXA</Select.Option>
      <Select.Option value="CX10">CX10 - CAIXA COM 10 UNIDADES</Select.Option>
      <Select.Option value="CX100">
        CX100 - CAIXA COM 100 UNIDADES
      </Select.Option>
      <Select.Option value="CX15">CX15 - CAIXA COM 15 UNIDADES</Select.Option>
      <Select.Option value="CX2">CX2 - CAIXA COM 2 UNIDADES</Select.Option>
      <Select.Option value="CX20">CX20 - CAIXA COM 20 UNIDADES</Select.Option>
      <Select.Option value="CX25">CX25 - CAIXA COM 25 UNIDADES</Select.Option>
      <Select.Option value="CX3">CX3 - CAIXA COM 3 UNIDADES</Select.Option>
      <Select.Option value="CX5">CX5 - CAIXA COM 5 UNIDADES</Select.Option>
      <Select.Option value="CX50">CX50 - CAIXA COM 50 UNIDADES</Select.Option>
      <Select.Option value="DI">DI - DISPLAY</Select.Option>
      <Select.Option value="DOSE">DOSE - DOSE</Select.Option>
      <Select.Option value="DZ">DZ - DUZIA</Select.Option>
      <Select.Option value="EMB">EMB - EMBALAGEM</Select.Option>
      <Select.Option value="FD">FD - FARDO</Select.Option>
      <Select.Option value="FOL">FOL - FOLHA</Select.Option>
      <Select.Option value="FRA">FRA - FRASCO</Select.Option>
      <Select.Option value="GB">GB - GADO BOVINO</Select.Option>
      <Select.Option value="GF">GF - GARRAFA</Select.Option>
      <Select.Option value="GL">GL - GALÃO</Select.Option>
      <Select.Option value="GR">GR - GRAMAS</Select.Option>
      <Select.Option value="JG">JG - JOGO</Select.Option>
      <Select.Option value="KG">KG - QUILOGRAMA</Select.Option>
      <Select.Option value="KIT">KIT - KIT</Select.Option>
      <Select.Option value="KT">KT - QUILATE</Select.Option>
      <Select.Option value="LA">LA - LATA</Select.Option>
      <Select.Option value="LT">LT - LITRO</Select.Option>
      <Select.Option value="M.ST">M.ST - M.ST</Select.Option>
      <Select.Option value="M2">M2 - METRO QUADRADO</Select.Option>
      <Select.Option value="M3">M3 - METRO CÚBICO</Select.Option>
      <Select.Option value="MI">MI - MILHEIRO</Select.Option>
      <Select.Option value="ML">ML - MILILITRO</Select.Option>
      <Select.Option value="MQ">MQ - MAQUINA</Select.Option>
      <Select.Option value="MT">MT - METRO</Select.Option>
      <Select.Option value="MWH">MWH - MEGAWATT HORA</Select.Option>
      <Select.Option value="NV">NV - NOVELO</Select.Option>
      <Select.Option value="PALETE">PALETE - PALETE</Select.Option>
      <Select.Option value="PAR">PAR - PARES</Select.Option>
      <Select.Option value="PC">PC - PEÇA</Select.Option>
      <Select.Option value="PCT">PCT - PACOTE</Select.Option>
      <Select.Option value="PEC">PEC - PEÇA</Select.Option>
      <Select.Option value="PT">PT - POTE</Select.Option>
      <Select.Option value="RL">RL - ROLO</Select.Option>
      <Select.Option value="RS">RS - RESMA</Select.Option>
      <Select.Option value="SC">SC - SACO</Select.Option>
      <Select.Option value="SCL">SCL - SACOLA</Select.Option>
      <Select.Option value="ST">ST - Estéreo</Select.Option>
      <Select.Option value="TANQUE">TANQUE - TANQUE</Select.Option>
      <Select.Option value="TB">TB - TUBO</Select.Option>
      <Select.Option value="TBR">TBR - TAMBOR</Select.Option>
      <Select.Option value="TO">TO - TONELADA</Select.Option>
      <Select.Option value="TON">TON - TONELADA</Select.Option>
      <Select.Option value="UD">UD - UNIDADE</Select.Option>
      <Select.Option value="UN">UN - UNIDADE</Select.Option>
      <Select.Option value="VASIL">VASIL - VASILHAME</Select.Option>
      <Select.Option value="VIDRO">VIDRO - VIDRO</Select.Option>
    </Select>
  );
};

export default ProductUnitOfMeasurement;
