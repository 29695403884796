import moment from 'moment';

const formartDateTimeUTC = (date, format = 'DD/MM/YYYY HH:mm') => {
  if (!date) {
    return null;
  }

  var momentDate = moment(date);

  if (momentDate.year() === 1) {
    return null;
  }

  return momentDate.format(format);
};

export default formartDateTimeUTC;
