/* eslint-disable no-restricted-syntax */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { api } from 'react-structure-admin';
import { Select, Spin } from 'antd';
import lodash from 'lodash';

const defaultSettings = {
  key: 'id',
  labels: ['name', 'description', 'title']
};

const initialState = {
  items: [],
  textSearched: '',
  fetching: false,
  pagination: {
    hasNextPage: true,
    currentPage: 0
  }
};

const normalizeItem = (item, settings) => {
  if (!item) {
    return item;
  }

  if (Array.isArray(item)) {
    return item.map((c) => normalizeItem(c));
  }

  const key = lodash.get(item, settings?.key ?? defaultSettings.key);
  const paths = settings?.labels ?? defaultSettings?.labels;
  let label = '';

  for (const path of paths) {
    label = lodash.get(item, path);
    if (label) {
      break;
    }
  }

  return { key, value: key, label, item };
};

const internalOptionRender = ({ key, value, label, item }, optionRender) => {
  if (typeof optionRender === 'function') {
    return optionRender(item);
  }

  return (
    <Select.Option key={key} value={value} item={item}>
      {label}
    </Select.Option>
  );
};

const RemoteSelectV2 = ({
  resource,
  params = {},
  tenant,
  value,
  autoFocus = false,
  showSearch = true,
  allowClear = true,
  defaultSorting = true,
  fethOnMount = true,
  sorting = defaultSorting ? 'Name' : null,
  optionRender,
  placeholder,
  onFetching,
  onFetched,
  onSearch,
  onChange,
  onDropdownVisibleChange,
  customDropdownRender,
  notFoundContent,
  settings = defaultSettings,
  style = { width: '100%' },
  autoFetchValue = false,
  ...rest
}) => {
  const lastFetchId = useRef(0);
  const ref = useRef(null);
  const [state, setState] = useState(initialState);
  const [open, setOpen] = useState(false);

  const distinctItems = (items = []) => {
    return lodash.uniqBy(items, settings?.key ?? defaultSettings.key);
  };

  const handleFetchSuccess = (data = {}, queryParams) => {
    const { totalCount = 0, items = [] } = data;
    const { page } = queryParams;

    setState((prevState) => ({
      ...prevState,
      items: page === 1 ? items : distinctItems([...prevState.items, ...items]),
      fetching: false,
      textSearched: queryParams.filterText,
      pagination: {
        hasNextPage:
          page === 1
            ? items.length < totalCount
            : prevState.items.length + items.length < totalCount,
        currentPage: page
      }
    }));

    if (autoFocus && lastFetchId.current === 1) {
      setOpen(true);
    }
  };

  const onFetch = (textSearched = '') => {
    const { pagination } = state;

    if (!pagination.hasNextPage) {
      return;
    }

    lastFetchId.current += 1;
    const fetchId = lastFetchId.current;

    onFetching?.(textSearched);
    setState((prevState) => ({ ...prevState, fetching: true }));

    const queryParams = {
      filterText: textSearched,
      page: pagination.currentPage + 1,
      sorting,
      ...params
    };

    api.fetch(resource, queryParams, null, tenant).then((response = {}) => {
      const { data = { result: {} } } = response;

      if (fetchId !== lastFetchId.current) {
        return;
      }

      onFetched?.(data.result);
      handleFetchSuccess(data.result, queryParams);
    });
  };

  const handleOpenChange = (changedOpen) => {
    setOpen(changedOpen);
    onDropdownVisibleChange?.(changedOpen);

    if (changedOpen && lastFetchId.current === 0) {
      onFetch();
    }
  };

  const debouncedFetch = useCallback(
    lodash.debounce((textSearched) => {
      onFetch(textSearched);
    }, 800),
    []
  );

  const handleOnSearch = (textSearched) => {
    onSearch?.(textSearched);

    setState((prevState) => ({
      ...prevState,
      pagination: { hasNextPage: true, currentPage: 0 }
    }));

    if (open) {
      debouncedFetch(textSearched);
    } else {
      lastFetchId.current = 0;
    }
  };

  const handleChange = (_, changedItem) => {
    if (Array.isArray(changedItem)) {
      onChange?.(changedItem.map((c) => c.item));
      return;
    }

    onChange?.(changedItem?.item);
  };

  const handleClear = () => {
    handleOnSearch(null);
  };

  const handleScroll = (event) => {
    const { textSearched } = state;
    const { target } = event;

    if (
      Math.ceil(target.scrollTop + target.offsetHeight) >= target.scrollHeight
    ) {
      onFetch(textSearched);
    }
  };

  const handleDropdownRender = (menu) => {
    if (typeof customDropdownRender === 'function') {
      return customDropdownRender({ menu, fetching: state.fetching });
    }

    return (
      <>
        {menu}
        {state.fetching && (
          <div className="gx-remote-select-spin">
            <Spin size="default" />
          </div>
        )}
      </>
    );
  };

  useEffect(() => {
    if (fethOnMount) {
      onFetch();
    }
  }, []);

  const dataSource = state.items.map((c) => normalizeItem(c, settings));
  const normalizedValue = normalizeItem(value, settings);

  return (
    <Select
      {...rest}
      ref={ref}
      labelInValue
      open={open}
      style={style}
      onChange={handleChange}
      value={normalizedValue}
      filterOption={false}
      allowClear={allowClear}
      showSearch={showSearch}
      placeholder={placeholder}
      onSearch={handleOnSearch}
      onClear={handleClear}
      dropdownRender={handleDropdownRender}
      onDropdownVisibleChange={handleOpenChange}
      notFoundContent={state.fetching ? null : notFoundContent?.()}
      onPopupScroll={handleScroll}
    >
      {dataSource.map((c) => internalOptionRender(c, optionRender, settings))}
    </Select>
  );
};

export default RemoteSelectV2;
